import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonLabel,
  IonList,
  IonListHeader,
} from "@ionic/react";
import React, {FC, useEffect} from "react";
import {useTranslation} from "react-i18next";
import { useAppSelector } from "src/hooks/useAppSelector";
import classes from "./StoreWalletCard.module.scss";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import {subscribeToStoreWalletTransactions} from "src/slices/storeWalletTransactions";
import {TransactionItemView} from "..";
import EmptyLoadingList from "../EmptyLoadingList";
import {Divider} from "src/components";

const StoreWalletCard: FC = () => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();

  const {customer} = useAppSelector((state) => state.customerReducer);
  const {transactions, isLoaded} = useAppSelector((state) => state.storeWalletTransactions);

  useEffect(() => {
    dispatch(subscribeToStoreWalletTransactions());
  }, [dispatch]);

  const renderTransactions = (): JSX.Element[] | JSX.Element => {
    return transactions.map((transaction) => {
      return <TransactionItemView transaction={transaction} key={transaction.id} />;
    });
  };

  const renderEmptyLoader = (): JSX.Element | undefined => {
    if (transactions.length) return;
    return <EmptyLoadingList isLoading={!isLoaded} />;
  };

  const renderTransactionsHeader = (): JSX.Element | undefined => {
    if (!transactions.length) return;
    return (
      <IonListHeader>
        <IonLabel>{t("STORE_WALLET_TRANSACTIONS")}</IonLabel>
      </IonListHeader>
    );
  };

  return (
    <IonCard className={classes.root}>
      <IonCardHeader>
        <IonCardSubtitle>{t("STORE_WALLET_CREDIT")}</IonCardSubtitle>
        <IonCardTitle>
          {customer!.walletCredit.toFixed(2)} {t("EGP")}
        </IonCardTitle>
      </IonCardHeader>
      <Divider />
      <IonCardContent>
        <IonList lines="full">
          {renderTransactionsHeader()}
          {renderTransactions()}
        </IonList>
      </IonCardContent>
      {renderEmptyLoader()}
    </IonCard>
  );
};
export default StoreWalletCard;
