import {IonInput, IonItem, IonLabel} from "@ionic/react";
import React, {useState, FC, FormEvent} from "react";
import {useTranslation} from "react-i18next";
import {validateEmail, validatePassword} from "src/utils/validation";
import InputHelperText from "src/components/InputHelperText";
import {LoginInfo, LoginInfoName} from "src/interfaces/Authentication";
import {
  GoogleSignInButton,
  ShowPasswordToggler,
  Divider,
  FacebookSignInButton,
  AppleSignInButton,
} from "src/components";
import {InputChangeEventDetail} from "@ionic/core";
import {SignupLink, SubmitButton, ForgetPasswordLink} from "..";
import { useAppSelector } from "src/hooks/useAppSelector";
import {Capacitor} from "@capacitor/core";

interface LoginFormProps {
  loginInfo: LoginInfo;
  isError: boolean;
  isSubmitError: boolean;
  onChangeLoginInfo: (e: CustomEvent<InputChangeEventDetail>) => void;
  onSubmit: (e: FormEvent<HTMLFormElement>) => Promise<void>;
}

const LoginForm: FC<LoginFormProps> = ({isError, isSubmitError, onChangeLoginInfo, onSubmit, loginInfo}) => {
  const {t} = useTranslation();

  const {store} = useAppSelector((state) => state.storeReducer);

  const [isShowPassword, setIsShowPassword] = useState(false);

  const passwordType = isShowPassword ? "text" : "password";

  const handleToggleShowPassword = (): void => {
    setIsShowPassword((prevState) => !prevState);
  };

  const renderInputError = (name: LoginInfoName, message: string): JSX.Element | undefined => {
    if (!isError) return;
    switch (name) {
      case LoginInfoName.email:
        if (validateEmail(loginInfo.email)) return;
        break;
      case LoginInfoName.password:
        if (validatePassword(loginInfo.password)) return;
        break;
      default:
        break;
    }
    return <InputHelperText color="danger" text={message} />;
  };

  const renderSignupLink = (): JSX.Element | undefined => {
    if (store.privateRegisteration) return;
    return <SignupLink />;
  };

  const renderAppleSignInButton = (): JSX.Element | undefined => {
    const isAndroid = Capacitor.getPlatform() === "android";
    if (isAndroid) return;
    return <AppleSignInButton />;
  };

  return (
    <form noValidate onSubmit={onSubmit}>
      <IonItem>
        <IonLabel position="floating">{t("EMAIL_ADDRESS")}</IonLabel>
        <IonInput
          onIonChange={onChangeLoginInfo}
          placeholder={t("EMAIL_ADDRESS")}
          value={loginInfo.email}
          name={LoginInfoName.email}
          type="email"
          inputMode="email"
          inputmode="email"
        />
      </IonItem>
      {renderInputError(LoginInfoName.email, t("INVALID_EMAIL_ADDRESS"))}

      <IonItem>
        <IonLabel position="floating">{t("PASSWORD")}</IonLabel>
        <IonInput
          placeholder={t("PASSWORD")}
          onIonChange={onChangeLoginInfo}
          type={passwordType}
          value={loginInfo.password}
          name={LoginInfoName.password}
        />
        <ShowPasswordToggler isShowPassword={isShowPassword} onToggleShowPassword={handleToggleShowPassword} />
      </IonItem>
      {renderInputError(LoginInfoName.password, t("INVALID_PASSWORD"))}

      <SubmitButton isError={isSubmitError} />
      <Divider my={8} />
      <GoogleSignInButton />
      <FacebookSignInButton />
      {renderAppleSignInButton()}
      {renderSignupLink()}
      <ForgetPasswordLink />
    </form>
  );
};
export default LoginForm;
