import { IonLabel } from "@ionic/react";
import { FC, useCallback, useMemo } from "react";
import { Product } from "src/interfaces/Product";
import { Variation } from "src/interfaces/Variation";
import getLocalOrDefault from "src/utils/getLocalOrDefault";
import { CartProduct } from "src/interfaces/CartProduct";
import { updateProductQuantity } from "src/utils/cart";
import { getFeaturedImage } from "src/utils/getFeaturedImage";
import Quantity from "./quantity";
import DetailsAccordion from "src/components/detailsAccordion";
import DeleteButton from "../deleteButton";
import Price from "src/components/Price";
import ProductDetails from "./ProductDetails";
import ItemThumbnail from "../../../components/ItemThumbnail";
import { Addon } from "src/interfaces/Addon";
import { AddonsGroup } from "src/interfaces/AddonsGroup";
import { Attribute } from "src/interfaces/Attribute";

interface CartProductItemProps {
  cartProduct: CartProduct;
  product: Product | Variation;
  addons: Addon[];
  addonsGroups: AddonsGroup[];
  attributes: Attribute[];
  onRemoveProduct: (id: string) => void;
}

const CartProductItem: FC<CartProductItemProps> = ({
  cartProduct,
  product,
  addons,
  addonsGroups,
  attributes,
  onRemoveProduct,
}) => {
  const isContainsDetails = useMemo(
    () =>
      Boolean(
        cartProduct.addons.length ||
          cartProduct.addonsGroups.length ||
          cartProduct.variationId ||
          cartProduct.requirements.length ||
          cartProduct.customVariations.length
      ),
    [cartProduct]
  );

  const image = getFeaturedImage(product.gallery);
  const name = getLocalOrDefault(product.name);

  const handleChangeQuantity = useCallback(
    async (quantity: number): Promise<void> => {
      await updateProductQuantity(cartProduct.id, quantity);
    },
    [cartProduct.id]
  );

  const renderDetailsAccordion = (): JSX.Element => {
    if (!isContainsDetails) return;
    return (
      <DetailsAccordion>
        <ProductDetails
          product={product}
          cartProduct={cartProduct}
          addons={addons}
          addonsGroups={addonsGroups}
          attributes={attributes}
        />
      </DetailsAccordion>
    );
  };

  return (
    <div className="ion-wrap ion-full-width ion-display-flex">
      <div className="ion-full-width ion-display-flex ion-align-items-center ion-margin-bottom-small">
        <ItemThumbnail src={image.src} alt={name} />
        <div className="ion-flex-grow ion-overflow-hidden">
          <IonLabel>{name}</IonLabel>
          <IonLabel className="ion-font-bold">
            <Price
              price={product.price}
              hasSalePrice={product.hasSalePrice}
              salePrice={product.salePrice}
            />
          </IonLabel>
        </div>
        <Quantity
          hasMaxQuantity={product.hasMaxQuantityPerOrder}
          maxQuantity={product.maxQuantityPerOrder}
          productQuantity={product.quantity}
          isUnlimitedQuantity={product.isUnlimitedQuantity}
          quantity={cartProduct.quantity}
          onChangeQuantity={handleChangeQuantity}
        />
        <DeleteButton id={cartProduct.id} onRemoveItem={onRemoveProduct} />
      </div>
      {renderDetailsAccordion()}
    </div>
  );
};

export default CartProductItem;
