import { FC } from "react";
import AddonItem from "./AddonItem";
import { useTranslation } from "react-i18next";
import { IonLabel } from "@ionic/react";
import { OrderAddon } from "src/interfaces/OrderAddon";
import { OrderAddonsGroup } from "src/interfaces/OrderAddonsGroup";

interface AddonsProps {
  addons: OrderAddon[];
  addonsGroups: OrderAddonsGroup[];
}

const Addons: FC<AddonsProps> = ({ addons, addonsGroups }) => {
  const { t } = useTranslation();

  const renderAddons = (addons: OrderAddon[]): JSX.Element[] => {
    return addons.map((addon) => {
      return <AddonItem key={addon.id} addon={addon} />;
    });
  };

  const renderAddonsGroups = (): JSX.Element[][] => {
    return addonsGroups.map((group) => {
      return renderAddons(group.addons);
    });
  };

  if (!addons.length && addonsGroups.length) return null;

  return (
    <div className="ion-margin-bottom-small">
      <IonLabel className="ion-font-bold" color="medium">
        {t("ADDONS")}
      </IonLabel>
        {renderAddons(addons)}
        {renderAddonsGroups()}
    </div>
  );
};

export default Addons;
