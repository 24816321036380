import {
  CollectionReference,
  doc,
  FirestoreError,
  getDoc,
  getDocFromCache,
  onSnapshot,
} from "firebase/firestore";
import { collection } from "firebase/firestore";
import { Category } from "src/interfaces/Category";
import { setServiceCategories } from "src/slices/servicesCategories";
import { store } from "src/store";
import { getFirestoreConverter } from "./converter";
import { getStoreDocRef } from "./store";

const COLLECTION = "service-categories";

const getCollection = (): CollectionReference<Category> => {
  return collection(getStoreDocRef(), COLLECTION).withConverter(getFirestoreConverter<Category>());
};

export const subscribeToServicesCategoriesFB = async (): Promise<void> => {
  try {
    onSnapshot(
      getCollection(),
      (snapshot) => {
        store.dispatch(setServiceCategories(snapshot.docs.map((doc) => doc.data())));
      },
      () => {
        store.dispatch(setServiceCategories([]));
      },
    );
  } catch {
    store.dispatch(setServiceCategories([]));
  }
};


export const getServicesCategoryByIdFB = async (id: string, fromCache = true): Promise<Category | undefined> => {
  try {
    const document = doc(getCollection(), id);
    const snapshot = fromCache ? await getDocFromCache(document) : await getDoc(document);
    return snapshot.data();
  } catch (err) {
    const error = err as FirestoreError;
    if (error.code === "unavailable" && fromCache) {
      return await getServicesCategoryByIdFB(id, false);
    }
  }
};
