import {
  collection,
  CollectionReference,
  doc,
  onSnapshot,
  query,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { Notification } from "src/interfaces/Notification";
import { setNotifications } from "src/slices/notifications";
import { store } from "src/store";
import { sortFirestoreDocuments } from "src/utils/sortFirestoreDocuments";
import { auth, firestore } from ".";
import { getFirestoreConverter } from "./converter";
import { getStoreDocRef } from "./store";

const COLLECTION = "users-notifications";

let unsubscribe = null;

const getCollection = (): CollectionReference<Notification> => collection(getStoreDocRef(), COLLECTION).withConverter(getFirestoreConverter<Notification>())

export const subscribeToNotificationsFB = (): void => {
  if (unsubscribe) return;
  unsubscribe = onSnapshot(
    query(getCollection(), where("users", "array-contains", auth.currentUser.uid)),
    (snapshot) => {
      store.dispatch(setNotifications(sortFirestoreDocuments(snapshot.docs.map((doc) => doc.data()))));
    },
    () => {
      store.dispatch(setNotifications([]));
    },
  );
};

export const setNotificationsToBeViewedFB = async (notifications: Notification[]): Promise<void> => {
  const batch = writeBatch(firestore);
  notifications.forEach((notification) => {
    batch.update(doc(getCollection(), notification.id), { views: [...notification.views, auth.currentUser.uid] });
  });
  await batch.commit();
};

export const setNotificationToBeClickedFB = async (notification: Notification): Promise<void> => {
  const uid = auth.currentUser.uid;
  const views = notification.views.includes(uid) ? notification.views : [...notification.views, uid];
  await updateDoc(doc(getCollection(), notification.id), { clicks: [...notification.clicks, uid], views });
};

export const unsubscribeToNotificationsFB = (): void => {
  if (!unsubscribe) return;
  unsubscribe();
  store.dispatch(setNotifications([]));
  unsubscribe = null;
};

