import {FC} from "react";
import {IonButton, IonContent, IonIcon, IonText} from "@ionic/react";
import {useTranslation} from "react-i18next";
import Page from "src/components/Page";
import classes from "./styles.module.scss";
import {informationCircleOutline} from "ionicons/icons";
import {useHistory} from "react-router";

const SuccessfulBooking: FC = () => {
  const {t} = useTranslation();
  const history = useHistory();

  const handleClickGoBack = (): void => {
    history.goBack();
  };

  return (
    <Page title={t("SUCCESSFUL_BOOKING")}>
      <IonContent>
        <IonText className={classes.message} color="primary">
          <IonIcon icon={informationCircleOutline} size="large" />
          <h3>{t("SUCCESSFUL_BOOKING_MESSAGE")}</h3>
          <IonButton onClick={handleClickGoBack}>{t("MORE_BOOKINGS_BUTTON")}</IonButton>
        </IonText>
      </IonContent>
    </Page>
  );
};

export default SuccessfulBooking;
