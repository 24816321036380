import { Order } from "src/interfaces/Order";
import { FC, memo } from "react";
import { useTranslation } from "react-i18next";
import {
  IonBadge,
  IonCard,
  IonCardContent,
  IonItem,
  IonLabel,
} from "@ionic/react";
import getPagePath from "src/utils/getStoreIdCustomPath";
import Price from "src/components/Price";
import { getOrderPaymentStatusColor, getOrderStatusColor } from "src/utils";
import PaymentMethodView from "src/components/PaymentMethodView";

interface OrderItemProps {
  order: Order;
}

const OrderItem: FC<OrderItemProps> = ({ order }) => {
  const { t } = useTranslation();
  const orderLink = getPagePath("order/".concat(order.id));

  const statusColor = getOrderStatusColor(order.orderStatus);
  const paymentColor = getOrderPaymentStatusColor(order.paymentStatus);

  const renderBillReference = (): JSX.Element => {
    if (!order.billReference) return;
    return <IonLabel className="ion-margin-end-small" color="medium">{order.billReference}</IonLabel>;
  };

  return (
    <IonItem className="ion-no-padding ion-no-inner-padding-end">
      <IonCard
        routerLink={orderLink}
        className="ion-full-width ion-margin-small"
      >
        <IonCardContent>
          <div className="ion-display-flex ion-justify-content-between">
            <div>
              <IonLabel color="dark" className="ion-font-bold">
                {order.invoiceId}
              </IonLabel>
              <IonLabel color="medium" className="ion-font-xsmall">
                {new Date(order.createdAt).toLocaleString()}
              </IonLabel>
              <IonBadge className="ion-margin-top" color={statusColor}>
                {t(order.orderStatus)}
              </IonBadge>
            </div>
            <div className="ion-text-end">
              <IonLabel color="dark" className="ion-font-bold ion-font-large">
                <Price price={order.total} />
              </IonLabel>
              <div className="ion-display-flex ion-justify-content-end">
                {renderBillReference()}
                <PaymentMethodView method={order.paymentMethod} />
              </div>
              <IonBadge className="ion-margin-top-small" color={paymentColor}>{t(order.paymentStatus)}</IonBadge>
            </div>
          </div>
        </IonCardContent>
      </IonCard>
    </IonItem>
  );
};

export default memo(OrderItem);
