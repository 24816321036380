import { FC } from "react";
import { IonIcon, IonButton, IonFooter } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { send } from "ionicons/icons";

interface FooterProps {
  disabled: boolean;
  onSubmit: () => void;
}

const Footer: FC<FooterProps> = ({ disabled, onSubmit }) => {
  const { t } = useTranslation();

  return (
    <IonFooter>
      <IonButton
        disabled={disabled}
        onClick={onSubmit}
        size="large"
        className="ion-no-margin ion-font-medium ion-no-text-transform ion-font-bold"
        fill="solid"
        expand="full"
      >
        <IonIcon icon={send} slot="start" />
        {t("ORDER")}
      </IonButton>
    </IonFooter>
  );
};

export default Footer;
