import { AppThunk } from "src/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Area } from "src/interfaces/Area";

interface AreasState {
    areas: Area[];
    isLoaded: boolean;
}

const initialState: AreasState = {
    areas: [],
    isLoaded: false,
};

const slice = createSlice({
    name: "areas",
    initialState,
    reducers: {
        setAreas(state: AreasState, action: PayloadAction<Area[]>): void {
            state.areas = action.payload;
            state.isLoaded = true;
        },
    },
});

export const { setAreas } = slice.actions;

export const getAreas = (): AppThunk => async (dispatch, getState): Promise<void> => {

};
export default slice.reducer;
