import {FC, memo} from "react";
import {
  CMSLink,
  CMSProductsList,
  CMSCategoriesList,
  CMSSlider,
  CMSHeading,
  CMSParagraph,
  CMSServicesList,
  CMSItemType,
  CMSBanner,
  CMSYoutubeVideo,
  CMSAttachment,
  CMSGallery,
  CMSForm,
  CMSAttendance,
  CMSItemComponent,
  CMSContainer,
} from "src/interfaces/CMS";
import {
  CMSCarousel,
  DraftEditorPreview,
  Banner,
  Heading,
  Link,
  HorizontalProductsList,
  HorizontalCategoriesList,
  YoutubeVideo,
  HorizontalServicesList,
  CMSAttachmentView,
  CMSFormView,
  CMSAttendanceView,
  CMSContainerView,
  CMSGalleryList,
} from "src/components";
import getLocalRawDrafOrDefault from "src/utils/getLocalRawDraftOrDefault";
import { useAppSelector } from "src/hooks/useAppSelector";

interface CMSItemComponentViewProps {
  component: CMSItemComponent;
  type: CMSItemType;
}

const CMSItemComponentView: FC<CMSItemComponentViewProps> = ({component, type}) => {
  const isFormsLoaded = useAppSelector((state) => state.formsReducer.isLoaded);

  const renderComponent = (): JSX.Element | undefined => {
    switch (type) {
      case CMSItemType.container:
        const container = component as CMSContainer;
        return <CMSContainerView container={container} />;

      case CMSItemType.heading:
        const heading = component as CMSHeading;
        return <Heading heading={heading} />;

      case CMSItemType.banner:
        const banner = component as CMSBanner;
        return <Banner banner={banner} />;

      case CMSItemType.paragraph:
        const state = (component as CMSParagraph).state;
        return <DraftEditorPreview state={getLocalRawDrafOrDefault(state)} />;

      case CMSItemType.link:
        const link = component as CMSLink;
        return <Link link={link} />;

      case CMSItemType.youtubeVideo:
        const video = (component as CMSYoutubeVideo).src;
        return <YoutubeVideo video={video} />;

      case CMSItemType.productsList:
        const productsList = component as CMSProductsList;
        return <HorizontalProductsList productsIds={productsList.products} options={productsList.itemOptions} />;

      case CMSItemType.servicesList:
        const servicesList = component as CMSServicesList;
        return <HorizontalServicesList servicesIds={servicesList.services} options={servicesList.itemOptions} />;

      case CMSItemType.categoriesList:
      case CMSItemType.servicesCategoriesList:
        const categoriesList = component as CMSCategoriesList;
        const isService = type === CMSItemType.servicesCategoriesList;
        return (
          <HorizontalCategoriesList
            isService={isService}
            categoriesIds={categoriesList.categories}
            options={categoriesList.itemOptions}
          />
        );

      case CMSItemType.slider:
        const slides = (component as CMSSlider).slides;
        return <CMSCarousel slides={slides} />;
      case CMSItemType.attachment:
        const attachment = component as CMSAttachment;
        return <CMSAttachmentView attachment={attachment} />;
      case CMSItemType.gallery:
        const gallery = component as CMSGallery;
        return <CMSGalleryList gallery={gallery} />;
      case CMSItemType.form:
        const form = component as CMSForm;
        return isFormsLoaded ? <CMSFormView form={form} /> : null;
      case CMSItemType.attendance:
        const attendance = component as CMSAttendance;
        return isFormsLoaded ? <CMSAttendanceView attendance={attendance} /> : null;
      default:
        return;
    }
  };

  return <div>{renderComponent()}</div>;
};

export default memo(CMSItemComponentView);
