import React, {FC} from "react";
import PropTypes from "prop-types";
import {View, Text, Image} from "@react-pdf/renderer";
import styles from "./styles";
import {Order} from "src/interfaces/Order";

interface HeaderProps {
  logo: string;
  name: string;
  order: Order;
}
const PLACEHOLDER = "/static/logo.png";

const Header: FC<HeaderProps> = ({logo, name, order}) => {
  return (
    <View>
      <View style={styles.store}>
        <Image source={logo || PLACEHOLDER} style={styles.brand} />
        <Text style={styles.h5}>{name}</Text>
      </View>
      <View style={styles.invoiceId}>
        <Text style={styles.h5}>Invoice #: {order.invoiceId}</Text>
        <Text style={styles.body2}>{new Date(order.createdAt!).toLocaleString()}</Text>
      </View>
    </View>
  );
};

Header.propTypes = {
  logo: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  order: PropTypes.any.isRequired,
};

export default Header;
