import { BaseDocument } from "./BaseDocument";
import { Country } from "./Country";

export interface User extends BaseDocument {
  email: string;
  emailVerified: boolean;
  phone: string;
  phoneVerified: boolean;
  firstName: string;
  lastName: string;
  disabled: boolean;
  country: Country;
  fcmTokens: string[];
}

export enum AuthModes {
  EMAIL_VERIFICATION = "verifyEmail",
  RESET_PASSWORD = "resetPassword",
}

export enum AuthEmail {
  EMAIL_VERIFICATION = "EMAIL_VERIFICATION",
  RESET_PASSWORD = "RESET_PASSWORD",
}
