import React, { FC } from "react";
import { IonBadge, IonButton, IonIcon } from "@ionic/react";
import { useAppSelector } from "src/hooks/useAppSelector";
import { cartOutline } from "ionicons/icons";
import "./styles.scss";
import getPagePath from "src/utils/getStoreIdCustomPath";

const CartButton: FC = () => {
  const cartItemsCount = useAppSelector(
    ({
      cartReducer: {
        cart: { products, services, servicesPackages },
      },
    }) => products.length + services.length + servicesPackages.length
  );

  const cartLink = getPagePath("cart");

  const getCartItemsCount = (): string => {
    return cartItemsCount > 99 ? "+99" : cartItemsCount.toString();
  };

  const renderItemsCount = (): JSX.Element | undefined => {
    if (!cartItemsCount) return;
    return (
      <IonBadge className="cartButton__itemsBadge" color="danger">
        {getCartItemsCount()}
      </IonBadge>
    );
  };

  return (
    <IonButton className="cartButton" routerLink={cartLink}>
      {renderItemsCount()}
      <IonIcon slot="icon-only" icon={cartOutline} />
    </IonButton>
  );
};

export default CartButton;
