import {IonBadge, IonButton, IonIcon} from "@ionic/react";
import {notificationsOutline} from "ionicons/icons";
import React, {FC} from "react";
import {auth} from "src/firebase";
import { useAppSelector } from "src/hooks/useAppSelector";
import getPagePath from "src/utils/getStoreIdCustomPath";
import classes from "./styles.module.scss";

const NotificationsButton: FC = () => {
  const notViewedNotifications = useAppSelector((state) =>
    state.notificationsReducer.notifications.filter(({views}) => !views.includes(auth.currentUser?.uid)),
  );
  const notifcationLink = getPagePath("notifications");

  const getNotificationsCount = (): string => {
    return notViewedNotifications.length > 99 ? "+99" : notViewedNotifications.length.toString();
  };

  const renderNoViewedBadge = (): JSX.Element | undefined => {
    if (!notViewedNotifications.length) return;
    return (
      <IonBadge className={classes.badge} color="danger">
        {getNotificationsCount()}
      </IonBadge>
    );
  };

  return (
    <IonButton className={classes.root} routerLink={notifcationLink}>
      {renderNoViewedBadge()}
      <IonIcon slot="icon-only" icon={notificationsOutline} />
    </IonButton>
  );
};

export default NotificationsButton;
