import { FC } from "react";
import { Attribute } from "src/interfaces/Attribute";
import { VariationAttribute } from "src/interfaces/VariationAttribute";
import AttributeItem from "./AttributeItem";

interface AttributesProps {
  variationAttributes: VariationAttribute[];
  attributes: Attribute[];
}

const Attributes: FC<AttributesProps> = ({
  variationAttributes,
  attributes,
}) => {
  const renderAttributes = (): JSX.Element[] => {
    return variationAttributes.map((variationAttribute) => {
      const attribute = attributes.find(
        ({ id }) => id === variationAttribute.id
      );
      const value = attribute?.values.find(
        ({ id }) => id === variationAttribute.value
      );
      if (!attribute || !value) return null;
      return (
        <AttributeItem
          key={variationAttribute.id}
          attribute={attribute}
          value={value}
        />
      );
    });
  };

  return <div className="ion-margin-bottom-small">{renderAttributes()}</div>;
};

export default Attributes;
