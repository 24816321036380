import { IonLabel } from "@ionic/react";
import { FC, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { OrderProductSerial } from "src/interfaces/OrderProductSerial";
import SerialItem from "./SerialItem";

interface SerialsProps {
  serials: OrderProductSerial[];
}

const Serials: FC<SerialsProps> = ({ serials }) => {
  const { t } = useTranslation();

  const renderSerials = (): JSX.Element[] =>
    serials.map((serial) => <SerialItem key={serial.id} serial={serial} />);

  if (!serials.length) return null;

  return (
    <Fragment>
      <IonLabel className="ion-margin-top ion-font-bold" color="medium">
        {t("SERIALS")}
      </IonLabel>
      {renderSerials()}
    </Fragment>
  );
};

export default Serials;
