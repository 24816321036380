
export enum FormFieldType {
  text = "TEXT",
  number = "NUMBER",
  date = "DATE",
  file = "FILE",
  location = "LOCATION",
  select = "SELECT",
  selectForm = "SELECT_FORM"
}
