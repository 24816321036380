import { IonList } from "@ionic/react";
import { FC, Fragment, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import AlertDialog from "src/components/AlertDialog";
import { useAppSelector } from "src/hooks/useAppSelector";
import { removeCartServicesPackage } from "src/utils/cart";
import ListHeader from "../ListHeader";
import PackageItem from "./PackageItem";
import useToast from "src/hooks/useToast";

const ServicesPackages: FC = () => {
  const { t } = useTranslation();
  const launchToast = useToast();

  const { servicesPackages } = useAppSelector(
    (state) => state.cartReducer.cart
  );

  const [pacakgeId, setPackageId] = useState("");

  const isDialogOpen = pacakgeId !== "";

  const handleOpenDialog = useCallback((id: string): void => {
    setPackageId(id);
  }, []);

  const handleCloseDialog = (): void => {
    setPackageId("");
  };

  const handleRemovePackage = async (): Promise<void> => {
    try {
      const id = pacakgeId;
      handleCloseDialog();
      await removeCartServicesPackage(id);
      launchToast(t("DONE"), "success");
    } catch {
      launchToast(t("FAILED"), "danger");
    }
  };
  const renderPackages = (): JSX.Element[] =>
    servicesPackages.map((servicesPackage) => (
      <PackageItem
        key={servicesPackage.id}
        cartPackage={servicesPackage}
        onRemovePackage={handleOpenDialog}
      />
    ));

  if (!servicesPackages.length) return null;

  return (
    <Fragment>
      <IonList>
        <ListHeader title={t("SERVICES_PACKAGES")} />
        {renderPackages()}
      </IonList>
      <AlertDialog
        message={t("REMOVE_CART_ITEM_MESSAGE")}
        onClose={handleCloseDialog}
        onConfirm={handleRemovePackage}
        open={isDialogOpen}
        title={t("WARNING")}
      />
    </Fragment>
  );
};

export default ServicesPackages;
