import {IonButton} from "@ionic/react";
import React, {FC} from "react";
import classes from "./SubmitButton.module.scss";
import {InputHelperText} from "src/components";
import {useTranslation} from "react-i18next";

interface SubmitButtonProps {
  isError: boolean;
}

const SubmitButton: FC<SubmitButtonProps> = ({isError}) => {
  const {t} = useTranslation();
  const renderSubmitError = (): JSX.Element | undefined => {
    if (!isError) return;
    return <InputHelperText color="danger" text={t("INVALID_EMAIL_ADDRESS_OR_PASSWORD")} />;
  };

  return (
    <div className={classes.root}>
      <IonButton className={classes.button} type="submit">
        {t("LOGIN.LOGIN_BUTTON")}
      </IonButton>
      <button hidden type="submit" />
      {renderSubmitError()}
    </div>
  );
};
export default SubmitButton;
