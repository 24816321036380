import React from 'react';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch } from 'react-instantsearch-hooks-web';
import ProductsListPage from './ProductsListPage';
const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_API_KEY
);

const Products = () => {
  return (
    <InstantSearch searchClient={searchClient} indexName='Products'>
      <ProductsListPage />
    </InstantSearch>
  );
};

export default Products;
