import { IonLabel } from "@ionic/react";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { CartRequirement } from "src/interfaces/CartRequirement";
import { RequiredField } from "src/interfaces/Requirement";
import RequirementItem from "./RequirementItem";

interface RequirementsProps {
  fields: RequiredField[];
  requirements: CartRequirement[];
  onChangeRequirement: (id: string, value: string) => void;
}

const Requirements: FC<RequirementsProps> = ({
  fields,
  requirements,
  onChangeRequirement,
}) => {
  const { t } = useTranslation();

  const renderRequirements = (): JSX.Element[] =>
    fields.map((field) => {
      const requirement = requirements.find(
        ({ requirementId }) => requirementId === field.id
      );
      const value = requirement?.value || "";
      return (
        <RequirementItem
          key={field.id}
          field={field}
          value={value}
          onChangeRequirement={onChangeRequirement}
        />
      );
    });

  return (
    <div className="ion-margin-bottom">
      <div className="ion-margin-bottom-small">
        <IonLabel className="ion-font-bold" color="medium">
          {t("REQUREMENTS")}
        </IonLabel>
      </div>
      {renderRequirements()}
    </div>
  );
};

export default Requirements;
