import {FC, useState, useEffect} from "react";
import classes from "./styles.module.scss";
import {IonCard, IonImg, IonSlide, IonSlides} from "@ionic/react";
import {CMSGallery, CMSItemShape} from "src/interfaces/CMS";
import getCarouselSlidePerView from "src/utils/getCarouselSlidePerView";
import { useAppSelector } from "src/hooks/useAppSelector";
import getCMSLinkFromLinkType from "src/utils/getCMSLinkFromLinkType";
import getCMSRouterLinkFromLinkType from "src/utils/getCMSRouterLinkFromLinkType";
import CMSFullscreenImage from "../CMSFullscreenImage";

interface CMSGalleryListProps {
  gallery: CMSGallery;
}

const CMSGalleryList: FC<CMSGalleryListProps> = ({gallery}) => {
  const logo = useAppSelector((state) => state.storeReducer.store.logo);
  const [slidesPerView, setSlidesPerView] = useState(
    getCarouselSlidePerView(
      gallery.itemOptions.isResponsive,
      gallery.itemOptions.itemPerView,
      gallery.itemOptions.width,
    ),
  );
  const [isListenerAttached, setIsListenerAttached] = useState(false);
  const [image, setImage] = useState("");
  const isCircleShape = gallery.itemOptions.shape === CMSItemShape.circle;
  const cardStyle = {borderRadius: isCircleShape && "50%"};
  const height = gallery.itemOptions.isResponsive
    ? (gallery.itemOptions.height * document.documentElement.clientHeight) / 100 - 64
    : gallery.itemOptions.height;
  const imageStyle = {height};
  const carouselOptions = {
    loop: false,
    slidesPerView,
  };
  const isOpen = image !== "";

  useEffect(() => {
    setSlidesPerView(
      getCarouselSlidePerView(
        gallery.itemOptions.isResponsive,
        gallery.itemOptions.itemPerView,
        gallery.itemOptions.width,
      ),
    );
    if (!isListenerAttached) {
      window.addEventListener("resize", () => {
        setSlidesPerView(
          getCarouselSlidePerView(
            gallery.itemOptions.isResponsive,
            gallery.itemOptions.itemPerView,
            gallery.itemOptions.width,
          ),
        );
      });
      setIsListenerAttached(true);
    }
  }, [isListenerAttached, gallery.itemOptions]);

  const handleImageError = (e: CustomEvent): void => {
    const target = e.target as HTMLIonImgElement;
    target.src = logo;
  };

  const handleClose = (): void => {
    setImage("");
  };

  const handleClickImage = (isFullScreen: boolean, image: string): (() => void) => {
    return (): void => {
      if (isFullScreen) {
        setImage(image);
      }
    };
  };

  const renderImages = (): JSX.Element[] =>
    gallery.images.map((image) => {
      const hrefLink = image.fullscreenImage ? undefined : getCMSLinkFromLinkType(image.linkType, image.link);
      const routerLink = image.fullscreenImage ? undefined : getCMSRouterLinkFromLinkType(image.linkType, image.link);
      return (
        <IonSlide key={image.id} className={classes.slide}>
          <div className={classes.imageContainer}>
            <IonCard
              routerLink={routerLink}
              target="_blank"
              href={hrefLink}
              onClick={handleClickImage(image.fullscreenImage, image.src)}
              className={classes.imageCard}
              style={cardStyle}
            >
              <IonImg onIonError={handleImageError} className={classes.image} style={imageStyle} src={image.src} />
            </IonCard>
          </div>
        </IonSlide>
      );
    });

  return (
    <IonSlides key={gallery.images.length} options={carouselOptions}>
      {renderImages()}
      <CMSFullscreenImage image={image} isOpen={isOpen} onClose={handleClose} />
    </IonSlides>
  );
};

export default CMSGalleryList;
