import React, { useState, FC } from "react";
import classes from "./AddressModal.module.scss";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { close, save } from "ionicons/icons";
import { CustomerAddress } from "src/interfaces/CustomerAddress";
import { InputHelperText } from "src/components";

interface AddressModalProps {
  isOpen: boolean;
  onSubmitAddress: (addres: CustomerAddress) => void;
  onClose: () => void;
}

const INITIAL_ADDRESS = {
  label: "",
  address: "",
  location: null,
  id: "",
};

const AddressModal: FC<AddressModalProps> = ({ isOpen, onSubmitAddress, onClose }) => {
  const { t } = useTranslation();
  const [address, setAddress] = useState<CustomerAddress>(INITIAL_ADDRESS);
  const [error, setError] = useState({ address: false, label: false });

  const handleCloseModal = (): void => {
    onClose();
  };

  const onChangeAddress = (e: CustomEvent): void => {
    const name = (e.target as HTMLIonInputElement).name;
    const value = e.detail.value;
    setError((prevState) => ({ ...prevState, [name]: false }));
    setAddress((prevState) => ({ ...prevState, [name]: value }));
  };

  const hasValidData = (): boolean => {
    const isValidLabel = !!address.label;
    const isValidAddress = !!address.address;
    setError({ label: !isValidLabel, address: !isValidAddress });
    return isValidAddress && isValidLabel;
  };

  const handleOnSubmitAddress = (): void => {
    if (!hasValidData()) return;
    onSubmitAddress(address);
    setAddress(INITIAL_ADDRESS);
  };

  const renderLabelError = (): JSX.Element | undefined => {
    if (!error.label) return;
    return <InputHelperText color="danger" text={t("PAGE.SETTINGS.NEW_ADDRESS_LABEL_REQUIRED_ERROR_MESSAGE")} />;
  };

  const renderAddressError = (): JSX.Element | undefined => {
    if (!error.address) return;
    return <InputHelperText color="danger" text={t("PAGE.SETTINGS.NEW_ADDRESS_DETAILS_REQUIRED_ERROR_MESSAGE")} />;
  };

  return (
    <IonModal isOpen={isOpen} onDidDismiss={handleCloseModal}>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>{t("PAGE.SETTINGS.ADD_ADDRESS_MODAL_HEADER_TITLE")}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={handleCloseModal}>
              <IonIcon icon={close} slot="icon-only" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className={classes.content}>
        <IonItem>
          <IonLabel position="stacked">{t("PAGE.SETTINGS.NEW_ADDRESS_LABEL_INPUT_LABEL")}</IonLabel>
          <IonInput
            name="label"
            onIonChange={onChangeAddress}
            value={address.label}
            placeholder={t("PAGE.SETTINGS.NEW_ADDRESS_LABEL_INPUT_PLACEHOLDER")}
          />
        </IonItem>
        {renderLabelError()}
        <IonItem>
          <IonLabel position="stacked">{t("PAGE.SETTINGS.NEW_ADDRESS_DETAILS_INPUT_LABEL")}</IonLabel>
          <IonTextarea
            name="address"
            onIonChange={onChangeAddress}
            value={address.address}
            rows={3}
            placeholder={t("PAGE.SETTINGS.NEW_ADDRESS_DETAILS_INPUT_PLACEHOLDER")}
          />
        </IonItem>
        {renderAddressError()}

        <IonButton className={classes.saveButton} onClick={handleOnSubmitAddress} expand="block">
          <IonIcon slot="start" icon={save} />
          {t("PAGE.SETTINGS.NEW_ADDRESS_SAVE_BUTTON")}
        </IonButton>
      </IonContent>
    </IonModal>
  );
};

export default AddressModal;
