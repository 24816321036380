import { IonLabel } from "@ionic/react";
import { FC } from "react";
import getLocalOrDefault from "src/utils/getLocalOrDefault";
import { Attribute } from "src/interfaces/Attribute";
import { AttributeValue } from "src/interfaces/AttributeValue";

interface AttributeItemProps {
  attribute: Attribute;
  value: AttributeValue;
}

const AttributeItem: FC<AttributeItemProps> = ({ attribute, value }) => {
  return (
    <div className="ion-display-flex ion-justify-content-between">
      <IonLabel>{getLocalOrDefault(attribute.name)}</IonLabel>
      <IonLabel className="ion-font-bold ion-overflow-visible">{getLocalOrDefault(value.name)}</IonLabel>
    </div>
  );
};

export default AttributeItem;
