import { IonLabel } from "@ionic/react";
import { FC, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { CartRequirementsGroup } from "src/interfaces/CartRequirementsGroup";
import { RequiredField } from "src/interfaces/Requirement";
import RequirementsGroupItem from "./RequirementsGroupItem";

interface RequirementsProps {
  fields: RequiredField[];
  groups: CartRequirementsGroup[];
}

const Requirements: FC<RequirementsProps> = ({ groups, fields }) => {
  const { t } = useTranslation();

  const renderGroups = (): JSX.Element[] =>
    groups.map((group) => (
      <RequirementsGroupItem key={group.id} group={group} fields={fields} />
    ));

  return (
    <Fragment>
      <IonLabel className="ion-margin-top ion-font-bold" color="medium">
        {t("REQUIREMENTS")}
      </IonLabel>
      {renderGroups()}
    </Fragment>
  );
};

export default Requirements;
