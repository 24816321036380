import {addDoc, collection, CollectionReference, doc, writeBatch} from "firebase/firestore";
import {getStoreDocRef} from "./store";
import {FormRequest} from "src/interfaces/FormRequest";
import {REQUESTS_STORAGE_KEY} from "src/constants/constants";
import {uploadRequestFileFB} from "./storage";
import convertBase64ToFile from "src/utils/convertBase64ToFile";
import {Storage} from "@capacitor/storage";
import {getFirestoreConverter} from "./converter";
import { FormFieldType } from "src/enums/FormFieldType";
import {firestore} from ".";

const FORMS_REQUESTS_COLLECTION_NAME = "requests";

const getFormsRequestsCollection = (storeId?: string): CollectionReference<FormRequest> =>
  collection(getStoreDocRef(storeId), FORMS_REQUESTS_COLLECTION_NAME).withConverter(getFirestoreConverter<FormRequest>());

export async function createRequestFB(request: FormRequest): Promise<void> {
  await addDoc(getFormsRequestsCollection(), request);
}

export async function syncSavedRequestsFB(): Promise<void> {
  const storedRequests = (await Storage.get({key: REQUESTS_STORAGE_KEY})).value;
  if (!storedRequests) return;
  const requests: FormRequest[] = JSON.parse(storedRequests);
  const convertedRequests = await Promise.all(
    requests.map(async (request) => {
      const fields = await Promise.all(
        request.fields.map(async (field) => {
          if (field.type === FormFieldType.file) {
            const file = convertBase64ToFile(field.value as string);
            if (!file) {
              return {...field, value: ""};
            }
            const link = await uploadRequestFileFB(file);
            return {...field, value: link};
          }
          return field;
        }),
      );
      return {...request, fields};
    }),
  );
  const batch = writeBatch(firestore);
  convertedRequests.forEach(async (request) => {
    batch.set(doc(getFormsRequestsCollection(request.storeId)), request);
  });
  await batch.commit();
  await Storage.remove({key: REQUESTS_STORAGE_KEY});
}
