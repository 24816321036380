import React, {Fragment, useEffect, useState, FC, useRef} from "react";
import {
  IonPage,
  IonHeader,
  IonButtons,
  IonToolbar,
  IonContent,
  IonTitle,
  IonSearchbar,
  IonButton,
  IonIcon,
  IonBackButton,
} from "@ionic/react";
import "./CategoryServicesList.scss";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { useAppSelector } from "src/hooks/useAppSelector";
import {subscribeToServicesCategories} from "src/slices/servicesCategories";
import {Category} from "src/interfaces/Category";
import {useTranslation} from "react-i18next";
import {closeOutline, searchOutline} from "ionicons/icons";
import {useLocation} from "react-router-dom";
import ServicesList from "src/components/ServicesList/ServicesList";
import {HorizontalCategoriesList} from "src/components";
import {subscribeToServices} from "src/slices/services";
import getPagePath from "src/utils/getStoreIdCustomPath";
import {sortServicesByName} from "../Services/Helpers";

const CategoryServicesList: FC = () => {
  const {t, i18n} = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const id = location.pathname.split("/").pop()!;
  const serviceCategory = useAppSelector((state) =>
    state.servicesCategoriesReducer.servicesCategories.find((category) => category.id === id),
  );
  const childCategories = useAppSelector((state) =>
    state.servicesCategoriesReducer.servicesCategories.filter((serviceCategory) => serviceCategory.parentId === id),
  );
  const childCategoriesIds = childCategories.map(({id}) => id);
  const services = useAppSelector((state) =>
    state.servicesReducer.services.filter(
      (service) => childCategoriesIds.includes(service.category) || service.category === id,
    ),
  );
  const [search, setSearch] = useState("");
  const searchRef = useRef<any>(null);
  const [isShowSearch, setIsShowSearch] = useState(false);

  const serviceCategoriesLink = getPagePath("service-categories");

  const sortedServices = sortServicesByName(services);

  useEffect(() => {
    dispatch(subscribeToServices());
  }, [dispatch]);

  useEffect(() => {
    dispatch(subscribeToServicesCategories());
  }, [dispatch]);

  useEffect(() => {
    if (isShowSearch) {
      searchRef.current.setFocus();
    }
  }, [isShowSearch]);

  const handleChangeSearch = (e: CustomEvent): void => {
    setSearch(e.detail.value);
  };

  const handleCloseSearch = (): void => {
    setIsShowSearch(false);
    setSearch("");
  };

  const handleOpenSearch = (): void => {
    setIsShowSearch(true);
  };

  const filterSearchedCategories = (categories: Category[]): Category[] => {
    if (!search) return categories;
    return categories.filter((category) => {
      return Object.values(category.name).some((name) => name!.toLowerCase().includes(search.toLowerCase()));
    });
  };

  const renderSearchIcon = (): JSX.Element | undefined => {
    if (childCategories.length) {
      return (
        <IonButtons slot="end">
          <IonButton ref={searchRef} onClick={handleOpenSearch}>
            <IonIcon icon={searchOutline} />
          </IonButton>
        </IonButtons>
      );
    }
  };

  const renderHeader = (): JSX.Element => {
    if (isShowSearch && childCategories.length) {
      return (
        <Fragment>
          <IonSearchbar
            placeholder={t("SERVICE_CATEGORIES.SEARCH")}
            value={search}
            ref={searchRef}
            onIonChange={handleChangeSearch}
            animated
          />
          <IonButtons slot="end">
            <IonButton onClick={handleCloseSearch}>
              <IonIcon icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </Fragment>
      );
    }
    return (
      <Fragment>
        <IonTitle>{serviceCategory?.name[i18n.language]}</IonTitle>
        {renderSearchIcon()}
      </Fragment>
    );
  };

  return (
    <IonPage id="category-services-page">
      <IonHeader color="primary">
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton text="" defaultHref={serviceCategoriesLink} />
          </IonButtons>
          {renderHeader()}
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <HorizontalCategoriesList
          isService
          categoriesIds={filterSearchedCategories(childCategories).map((category) => category.id)}
        />
        <ServicesList services={sortedServices} />
      </IonContent>
    </IonPage>
  );
};

export default CategoryServicesList;
