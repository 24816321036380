import { IonItem, IonLabel } from "@ionic/react";
import { FC, useMemo } from "react";
import getLocalOrDefault from "src/utils/getLocalOrDefault";
import Price from "src/components/Price";
import DetailsAccordion from "src/components/detailsAccordion";
import ServiceDetails from "./ServiceDetails";
import ValueUnit from "src/components/ValueUnit";
import { useTranslation } from "react-i18next";
import ItemThumbnail from "../../../components/ItemThumbnail";
import { OrderService } from "src/interfaces/OrderService";

interface ServiceItemProps {
  service: OrderService;
}

const ServiceItem: FC<ServiceItemProps> = ({ service }) => {
  const { t } = useTranslation();

  const isContainsDetails = useMemo(
    () => Boolean(service.addons.length || service.requirements.length),
    [service]
  );

  const name = getLocalOrDefault(service.name);

  const renderDetailsAccordion = (): JSX.Element => {
    if (!isContainsDetails) return;
    return (
      <DetailsAccordion>
        <ServiceDetails service={service} />
      </DetailsAccordion>
    );
  };

  const renderFees = (): JSX.Element => {
    if (!service.fees) return;
    return (
      <IonLabel className="ion-font-bold ion-margin-end">
        <Price price={service.fees} />
      </IonLabel>
    );
  };

  const renderDuration = (): JSX.Element => {
    if (!service.duration) return;
    return (
      <IonLabel className="ion-font-bold">
        <ValueUnit value={service.duration} unit={t(service.durationUnit)} />
      </IonLabel>
    );
  };

  return (
    <IonItem lines="full">
      <div className="ion-wrap ion-full-width ion-display-flex ion-padding-vertical-xsmall">
        <div className="ion-full-width ion-display-flex ion-align-items-center ion-margin-bottom-small">
          <ItemThumbnail src={service.image} alt={name} />
          <div className="ion-flex-grow  ion-overflow-hidden">
            <div className="ion-display-flex ion-wrap">
              <IonLabel>{name}</IonLabel>
              <IonLabel className="ion-margin-horizontal-small" color="medium">
                <sub>{getLocalOrDefault(service.worker.name)}</sub>
              </IonLabel>
            </div>
            <div className="ion-display-flex">
              {renderFees()}
              {renderDuration()}
            </div>
          </div>
        </div>
        {renderDetailsAccordion()}
      </div>
    </IonItem>
  );
};

export default ServiceItem;
