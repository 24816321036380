import { FC, Fragment } from "react";
import Attributes from "./Attributes";
import Addons from "./Addons";
import CustomVariations from "./CustomVariations";
import Requirements from "./Requirements";
import { OrderProduct } from "src/interfaces/OrderProduct";
import Serials from "./Serials";

interface ProductDetailsProps {
  product: OrderProduct;
}

const ProductDetails: FC<ProductDetailsProps> = ({ product }) => {
  return (
    <Fragment>
      <Attributes attributes={product.attributes} />
      <CustomVariations customVariations={product.customVariations} />
      <Addons addons={product.addons} addonsGroups={product.addonsGroups} />
      <Serials serials={product.serialsNumbers} />
      <Requirements requirements={product.requirementsGroups} />
    </Fragment>
  );
};

export default ProductDetails;
