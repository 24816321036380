import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Cart } from "src/interfaces/Cart";

interface CartState {
    cart: Cart;
    isLoaded: boolean;
    isSynced: boolean;
}

const initialState: CartState = {
    cart: { products: [], servicesPackages: [], services: [] },
    isSynced: false,
    isLoaded: false,
};

export const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        setCart: (state, action: PayloadAction<Cart>) => {
            state.cart = action.payload;
            state.isLoaded = true;
        },
        setIsSynced: (state, action: PayloadAction<boolean>) => {
            state.isSynced = action.payload;
        },
    },
});

export const { setCart, setIsSynced } = cartSlice.actions;

export default cartSlice.reducer;
