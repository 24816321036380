import { IonCol, IonGrid, IonRow, IonSkeletonText } from "@ionic/react";
import React, { FC } from "react";
import "./GridSkeleton.scss";

interface GridSkeletonProps {
    cols: number;
}

const GridSkeleton: FC<GridSkeletonProps> = ({ cols }) => {

    const renderCols = (): JSX.Element[] => {
        return Array.from(Array(cols).keys()).map((col) => {
            return (
                <IonCol key={col} sizeXs="6" sizeSm="4" sizeMd="3" sizeLg="2">
                    <IonSkeletonText className="gridSkeleton" animated />
                </IonCol>
            );
        });
    }

    return (
        <IonGrid>
            <IonRow>
                {renderCols()}
            </IonRow>
        </IonGrid>
    )
}

export default GridSkeleton;