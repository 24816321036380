import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getAttributesFB } from "src/firebase/attributes";
import { Attribute } from "src/interfaces/Attribute";
import { AppThunk } from "src/store";

interface AttributesState {
    attributes: Attribute[];
    isLoaded: boolean;
}

const initialState: AttributesState = {
    attributes: [],
    isLoaded: false,
};

export const attributesSlice = createSlice({
    name: "ATTRIBUTES",
    initialState,
    reducers: {
        setAttributes: (state: AttributesState, action: PayloadAction<Attribute[]>) => {
            state.attributes = action.payload;
            state.isLoaded = true;
        },
    },
});

export const { setAttributes } = attributesSlice.actions;

export const getAttributes = (): AppThunk => async (dispatch, getState): Promise<void> => {
    const isLoaded = getState().attributesReducer.isLoaded;
    if (isLoaded) return;
    const attributes = await getAttributesFB();
    dispatch(setAttributes(attributes));
};


export default attributesSlice.reducer;
