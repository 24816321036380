import {
  IonButton,
  IonButtons,
  IonIcon,
  IonItem,
  IonLabel,
} from "@ionic/react";
import { FC } from "react";
import getLocalOrDefault from "src/utils/getLocalOrDefault";
import Price from "src/components/Price";
import SessionsInDuration from "src/components/SessionsInDuration";
import ItemThumbnail from "../../../components/ItemThumbnail";
import { OrderServicesPackage } from "src/interfaces/OrderServicesPackage";
import { addCircleOutline } from "ionicons/icons";

interface PackageItemProps {
  servicesPackage: OrderServicesPackage;
  onBookSession: (id: string) => void;
}

const PackageItem: FC<PackageItemProps> = ({
  servicesPackage,
  onBookSession,
}) => {
  const name = getLocalOrDefault(servicesPackage.name);

  const handleBookSession = (): void => {
    onBookSession(servicesPackage.id);
  };

  return (
    <IonItem lines="full">
      <ItemThumbnail src={servicesPackage.image} alt={name} />
      <div className="ion-display-block ion-no-margin ion-overflow-hidden ion-padding-vertical">
        <div className="ion-wrap ion-no-margin">
          <IonLabel>{name}</IonLabel>
        </div>
        <div className="ion-display-flex">
          <IonLabel className="ion-font-bold ion-margin-end">
            <Price price={servicesPackage.fees} />
          </IonLabel>
          <IonLabel className="ion-font-small ion-font-bold ion-margin-top-auto">
            <SessionsInDuration
              sessions={servicesPackage.sessions}
              duration={servicesPackage.sessionsDuration}
              unit={servicesPackage.sessionsDurationUnit}
            />
          </IonLabel>
        </div>
      </div>
      <IonButtons slot="end">
        <IonButton onClick={handleBookSession}>
          <IonIcon slot="icon-only" icon={addCircleOutline} />
        </IonButton>
      </IonButtons>
    </IonItem>
  );
};

export default PackageItem;
