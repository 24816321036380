import {FC} from "react";
import classes from "./styles.module.scss";
import {IonButton} from "@ionic/react";
import {CMSLink, CMSLinkVariant} from "src/interfaces/CMS";
import getLocalOrDefault from "src/utils/getLocalOrDefault";
import getCMSLinkFromLinkType from "src/utils/getCMSLinkFromLinkType";
import getCMSRouterLinkFromLinkType from "src/utils/getCMSRouterLinkFromLinkType";

interface LinkProps {
  link: CMSLink;
}

const Link: FC<LinkProps> = ({link}) => {
  const hrefLink = getCMSLinkFromLinkType(link.type, link.value);
  const routerLink = getCMSRouterLinkFromLinkType(link.type, link.value);
  const fillVariant = link.variant === CMSLinkVariant.contained ? "solid" : "outline";

  const linkStyle =
    link.variant === CMSLinkVariant.contained
      ? {"--background": link.color}
      : {
          "--border-color": link.color,
          "color": link.color,
        };

  return (
    <IonButton
      className={classes.root}
      fill={fillVariant}
      href={hrefLink}
      target="_blank"
      routerLink={routerLink}
      style={linkStyle}
      expand="block"
    >
      {getLocalOrDefault(link.label)}
    </IonButton>
  );
};

export default Link;
