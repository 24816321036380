import React, {useEffect, FC, useState} from "react";
import classes from "./styles.module.scss";
import {IonSlide, IonSlides} from "@ionic/react";
import ProductView from "../ProductView";
import {Product} from "src/interfaces/Product";
import {useCallback} from "react";
import {getProductByIdFB} from "src/firebase/products";
import {CMSListItemOptions, CMSItemShape} from "src/interfaces/CMS";
import getCarouselSlidePerView from "src/utils/getCarouselSlidePerView";

interface HorizontalProductsListProps {
  productsIds: string[];
  options?: CMSListItemOptions;
}

const HorizontalProductsList: FC<HorizontalProductsListProps> = ({
  productsIds,
  options = {
    width: 175,
    shape: CMSItemShape.square,
    isImageOnly: false,
    height: 200,
    itemPerView: 3,
    isResponsive: false,
  },
}) => {
  const [products, setProducts] = useState<Product[]>([]);
  const [isListenerAttached, setIsListenerAttached] = useState(false);
  const [slidesPerView, setSlidesPerView] = useState(
    getCarouselSlidePerView(options.isResponsive, options.itemPerView, options.width),
  );
  const carouselOptions = {
    slidesPerView,
    loop: false,
  };

  useEffect(() => {
    setSlidesPerView(getCarouselSlidePerView(options.isResponsive, options.itemPerView, options.width));
    if (!isListenerAttached) {
      window.addEventListener("resize", () => {
        setSlidesPerView(getCarouselSlidePerView(options.isResponsive, options.itemPerView, options.width));
      });
      setIsListenerAttached(true);
    }
  }, [options, isListenerAttached]);

  const loadProducts = useCallback(async () => {
    const products = await Promise.all(
      productsIds.map(async (id) => {
        return (await getProductByIdFB(id))!;
      }),
    );
    setProducts(products);
  }, [productsIds]);

  useEffect(() => {
    loadProducts();
  }, [loadProducts]);

  const renderProducts = (): JSX.Element[] =>
    products.map((product) => (
      <IonSlide key={product.id} className={classes.slide}>
        <ProductView product={product} options={options} />
      </IonSlide>
    ));

  return (
    <IonSlides key={productsIds.length} options={carouselOptions}>
      {renderProducts()}
    </IonSlides>
  );
};

export default HorizontalProductsList;
