import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ServicesPackage} from "src/interfaces/ServicesPackage";

interface ServicesPackagesState {
  servicesPackages: ServicesPackage[];
  isLoaded: boolean;
}

const initialState: ServicesPackagesState = {
  servicesPackages: [],
  isLoaded: false,
};

export const servicesPackagesSlice = createSlice({
  name: "services-packages",
  initialState,
  reducers: {
    setServicesPackages: (state: ServicesPackagesState, action: PayloadAction<ServicesPackage[]>) => {
      state.servicesPackages = action.payload;
      state.isLoaded = true;
    },
  },
});

export const {setServicesPackages} = servicesPackagesSlice.actions;

export default servicesPackagesSlice.reducer;
