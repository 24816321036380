import { RGB } from "./types";

const componentToHex = (c: number): string => {
  const hex = c.toString(16);
  return hex.length === 1 ? `0${hex}` : hex;
};

const expandHex = (hex: string): string => {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, (_m, r, g, b) => {
    return r + r + g + g + b + b;
  });

  return `#${hex.replace("#", "")}`;
};

const hexToRGB = (hex: string): RGB => {
  hex = expandHex(hex);
  hex = hex.replace("#", "");
  const intValue: number = parseInt(hex, 16);

  return {
    r: (intValue >> 16) & 255,
    g: (intValue >> 8) & 255,
    b: intValue & 255
  };
};


const mixColors = (color: Color, mixColor: Color, weight = .5): RGB => {
  const colorRGB: RGB = color.rgb;
  const mixColorRGB: RGB = mixColor.rgb;
  const mixColorWeight = 1 - weight;

  return {
    r: Math.round(weight * mixColorRGB.r + mixColorWeight * colorRGB.r),
    g: Math.round(weight * mixColorRGB.g + mixColorWeight * colorRGB.g),
    b: Math.round(weight * mixColorRGB.b + mixColorWeight * colorRGB.b)
  };
};

const rgbToHex = ({ r, g, b }: RGB) => {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
};


const rgbToYIQ = ({ r, g, b }: RGB): number => {
  return ((r * 299) + (g * 587) + (b * 114)) / 1000;
};

export class Color {
  readonly hex: string;
  readonly rgb: RGB;
  readonly yiq: number;

  constructor(value: string | RGB) {
    if (typeof (value) === "string" && /rgb\(/.test(value)) {
      const matches = /rgb\((\d{1,3}), ?(\d{1,3}), ?(\d{1,3})\)/.exec(value) ?? [];
      value = { r: parseInt(matches[0], 10), g: parseInt(matches[1], 10), b: parseInt(matches[2], 10) };
    }

    if (typeof (value) === "string") {
      value = value.replace(/\s/g, "");
      this.hex = expandHex(value);
      this.rgb = hexToRGB(this.hex);
    } else if ("r" in value && "g" in value && "b" in value) {
      this.rgb = value as RGB;
      this.hex = rgbToHex(this.rgb);
    } else {
      throw new Error("Incorrect value passed.");
    }

    this.yiq = rgbToYIQ(this.rgb);

  }

  contrast(threshold = 200): Color {
    return new Color((this.yiq >= threshold ? "#000" : "#fff"));
  }

  mix(from: string | RGB | Color, amount = .5): Color {
    const base: Color = from instanceof Color ? from : new Color(from);
    return new Color(mixColors(this, base, amount));
  }

  shade(weight = .12): Color {
    return this.mix({ r: 0, g: 0, b: 0 }, weight);
  }

  tint(weight = .1): Color {
    return this.mix({ r: 255, g: 255, b: 255 }, weight);
  }

}