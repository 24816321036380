import { IonItem } from "@ionic/react";
import { FC, memo, useCallback, useEffect, useState } from "react";
import { Product } from "src/interfaces/Product";
import { Variation } from "src/interfaces/Variation";
import { CartProduct } from "src/interfaces/CartProduct";
import CartProductItem from "./CartProductItem";
import ItemPlaceholder from "../ItemPlaceholder";
import { Addon } from "src/interfaces/Addon";
import { AddonsGroup } from "src/interfaces/AddonsGroup";
import { Attribute } from "src/interfaces/Attribute";
import {
  fetchAddons,
  fetchAddonsGroups,
  fetchCartProduct,
  fetchAttributes,
  fetchGroupsAddons,
  checkProductAvailability,
} from "./helpers";

interface ProductItemProps {
  cartProduct: CartProduct;
  onRemoveProduct: (id: string) => void;
}

const ProductItem: FC<ProductItemProps> = ({
  cartProduct,
  onRemoveProduct,
}) => {
  const [product, setProduct] = useState<Product | Variation>();
  const [addons, setAddons] = useState<Addon[]>([]);
  const [addonsGroups, setAddonsGroups] = useState<AddonsGroup[]>([]);
  const [attributes, setAttributes] = useState<Attribute[]>([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const fetchAddonsAndGroups = useCallback(
    async (productAddons: string[], productGroups: string[]) => {
      const { addons, addonsGroups } = cartProduct;
      const fetchedGroups = await fetchAddonsGroups(
        addonsGroups,
        productGroups
      );
      const fetchedAddons = await fetchAddons(addons, productAddons, []);
      const fetchedGroupsAddons = await fetchGroupsAddons(
        addonsGroups,
        fetchedGroups,
        fetchedAddons
      );
      setAddonsGroups(fetchedGroups);
      setAddons(fetchedAddons.concat(fetchedGroupsAddons));
    },
    [cartProduct]
  );

  const fetchProduct = useCallback(async () => {
    if (product) return;
    const { productId, variationId, quantity } = cartProduct;
    const isVariation = !!variationId;
    const fetchedProduct = await fetchCartProduct(productId, variationId);
    const isAvailable =
      fetchedProduct && checkProductAvailability(fetchedProduct, quantity);
    if (isAvailable) {
      setProduct(fetchedProduct);
      await fetchAddonsAndGroups(
        fetchedProduct.addons,
        fetchedProduct.addonGroups
      );
      if (isVariation) {
        const attributes = (fetchedProduct as Variation).attributes;
        setAttributes(await fetchAttributes(attributes));
      }
    }
    setIsLoaded(true);
  }, [cartProduct, product, fetchAddonsAndGroups]);

  useEffect(() => {
    fetchProduct();
  }, [fetchProduct]);

  const renderContent = (): JSX.Element => {
    if (!product)
      return (
        <ItemPlaceholder
          item={cartProduct}
          isLoaded={isLoaded}
          onRemoveItem={onRemoveProduct}
        />
      );

    return (
      <CartProductItem
        onRemoveProduct={onRemoveProduct}
        cartProduct={cartProduct}
        product={product}
        addons={addons}
        attributes={attributes}
        addonsGroups={addonsGroups}
      />
    );
  };

  return <IonItem lines="full">{renderContent()}</IonItem>;
};

export default memo(ProductItem);
