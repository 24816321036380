import React, {FC} from "react";
import { useAppSelector } from "src/hooks/useAppSelector";
import {Redirect} from "react-router-dom";
import useAuth from "src/hooks/useAuth";
import getPagePath from "src/utils/getStoreIdCustomPath";

const DisabledGuard: FC = ({children}) => {
  const {isAuthenticated} = useAuth();

  const customer = useAppSelector((state) => state.customerReducer.customer);

  const homeLink = getPagePath("home");

  if (!isAuthenticated || !customer?.disabled) {
    return <Redirect to={homeLink} />;
  }

  return <>{children}</>;
};

export default DisabledGuard;
