import { FC, useState } from "react";
import {
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRadio,
  IonRadioGroup,
} from "@ionic/react";
import getLocalOrDefault from "src/utils/getLocalOrDefault";
import { RadioGroupChangeEventDetail } from "@ionic/core";
import { ServicesWorker } from "src/interfaces/ServicesWorker";
import { WorkerAvailability } from "src/interfaces/WorkerAvailability";
import { convert24To12Time } from "src/utils/convert24To12Time";
import ModalHeader from "src/components/ModalHeader";

interface WorkerSelectorModalProps {
  isOpen: boolean;
  isOptionalWorker: boolean;
  workers: ServicesWorker[];
  availableWorkers: string[];
  availabilities: WorkerAvailability[];
  onClose: () => void;
  onSelectWorker: (worker: string) => void;
  onSelectSlot: (slot: string) => void;
}

const SelectModal: FC<WorkerSelectorModalProps> = ({
  isOpen,
  workers,
  availableWorkers,
  availabilities,
  isOptionalWorker,
  onClose,
  onSelectWorker,
  onSelectSlot,
}) => {
  const [value, setValue] = useState("");

  const handleChangeValue = (
    e: CustomEvent<RadioGroupChangeEventDetail<string>>
  ): void => {
    const { value } = e.detail;
    setValue(value);
  };

  const handleSubmit = (): void => {
    if (availableWorkers.length) {
      onSelectWorker(value);
    } else {
      onSelectSlot(value);
    }
    setValue("");
    onClose();
  };

  const renderWorkers = (): JSX.Element[] => {
    return availableWorkers.map((workerId) => {
      const worker = workers.find(({ id }) => id === workerId);
      if (!worker) return null;
      return (
        <IonItem key={workerId}>
          <IonLabel>{getLocalOrDefault(worker.workerName)}</IonLabel>
          <IonRadio value={worker.id} />
        </IonItem>
      );
    });
  };

  const renderWorkerName = (name: string): JSX.Element | undefined => {
    if (!isOptionalWorker) return;
    return (
      <IonLabel color="medium" className="ion-margin-horizontal ion-font-bold">
        {name}
      </IonLabel>
    );
  };

  const renderSlots = (availability: WorkerAvailability): JSX.Element[] => {
    return availability.slots.map((slot) => {
      const value = availability.id.concat("-", slot);
      return (
        <IonItem key={slot}>
          <IonLabel>{convert24To12Time(slot)}</IonLabel>
          <IonRadio value={value} />
        </IonItem>
      );
    });
  };

  const renderAvailabilities = (): JSX.Element[] => {
    return availabilities.map((availability) => {
      const worker = workers.find(({ id }) => id === availability.id);
      if (!worker) return null;
      return (
        <div
          key={availability.id}
          className="ion-margin-bottom ion-display-block"
        >
          {renderWorkerName(getLocalOrDefault(worker.workerName))}
          {renderSlots(availability)}
        </div>
      );
    });
  };

  return (
    <IonModal isOpen={isOpen} onDidDismiss={onClose}>
      <ModalHeader onClose={onClose} onSubmit={handleSubmit} />
      <IonContent>
        <IonList lines="full">
          <IonRadioGroup onIonChange={handleChangeValue}>
            {renderWorkers()}
            {renderAvailabilities()}
          </IonRadioGroup>
        </IonList>
      </IonContent>
    </IonModal>
  );
};

export default SelectModal;
