import { FC } from "react";
import AddonItem from "./AddonItem";
import { useTranslation } from "react-i18next";
import { IonLabel } from "@ionic/react";
import { OrderServicesAddon } from "src/interfaces/OrderServicesAddon";

interface AddonsProps {
  addons: OrderServicesAddon[];
}

const Addons: FC<AddonsProps> = ({ addons }) => {
  const { t } = useTranslation();

  const renderAddons = (): JSX.Element[] =>
    addons.map((addon) => <AddonItem key={addon.id} addon={addon} />);

  if (!addons.length) return null;

  return (
    <div className="ion-margin-bottom-small">
      <IonLabel className="ion-margin-top ion-font-bold" color="medium">
        {t("ADDONS")}
      </IonLabel>
      {renderAddons()}
    </div>
  );
};

export default Addons;
