import {collection, CollectionReference, onSnapshot} from "firebase/firestore";
import {Form} from "src/interfaces/Form";
import {setForms} from "src/slices/forms";
import {store} from "src/store";
import {getFirestoreConverter} from "./converter";
import {getStoreDocRef} from "./store";

const FORMS_COLLECTION_NAME = "forms";

const getFormsCollection = (): CollectionReference<Form> =>
  collection(getStoreDocRef(), FORMS_COLLECTION_NAME).withConverter(getFirestoreConverter<Form>());

export const subscribeToFormsFB = (): void => {
  try {
    onSnapshot(
      getFormsCollection(),
      (snapshot) => {
        store.dispatch(setForms(snapshot.docs.map((doc) => doc.data())));
      },
      () => {
        store.dispatch(setForms([]));
      },
    );
  } catch {
    store.dispatch(setForms([]));
  }
};
