import { Clipboard } from "@capacitor/clipboard";
import { IonButton, IonButtons, IonIcon, IonLabel } from "@ionic/react";
import { copyOutline } from "ionicons/icons";
import { FC } from "react";
import { OrderProductSerial } from "src/interfaces/OrderProductSerial";

interface SerialItemProps {
  serial: OrderProductSerial;
}

const SerialItem: FC<SerialItemProps> = ({ serial }) => {
  const handleCopySerial = (): void => {
    Clipboard.write({ string: serial.serialNumber });
  };

  return (
    <div className="ion-display-flex ion-justify-content-between">
      <IonLabel className="ion-margin-vertical-auto">
        {serial.serialNumber}
      </IonLabel>
      <IonButtons slot="end">
        <IonButton size="small" className="ion-no-padding" onClick={handleCopySerial}>
          <IonIcon slot="icon-only" size="small" icon={copyOutline} />
        </IonButton>
      </IonButtons>
    </div>
  );
};

export default SerialItem;
