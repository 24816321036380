import React, { FC } from "react";
import PropTypes from "prop-types";
import { View, Text } from "@react-pdf/renderer";
import { OrderProduct } from "src/interfaces/OrderProduct";
import styles from "./styles";
import getLocalOrDefault from "src/utils/getLocalOrDefault";

interface OrderInvoiceProductProps {
  product: OrderProduct;
  index: number;
}

const OrderInvoiceProduct: FC<OrderInvoiceProductProps> = ({
  product,
  index,
}) => {
  function getProductSubtotal(): number {
    const addonPrices =
      product.addons.reduce((sum, addon) => sum + addon.price, 0) || 0;

    return (
      (addonPrices + (product.salePrice || product.price)) * product.quantity
    );
  }

  function renderPriceAndSalePrice(
    price: number,
    salePrice: number
  ): JSX.Element {
    return (
      <>
        <Text style={[styles.body1, salePrice ? styles.sale : {}]}>
          {price.toFixed(2)}
        </Text>
        <Text style={[styles.body1, styles.paddingLeft]}>
          {salePrice?.toFixed(2)}
        </Text>
        <Text style={[styles.body1, styles.paddingLeft]}>EGP</Text>
      </>
    );
  }

  function renderProductAddons(): JSX.Element[] {
    return product.addons.map((addon) => {
      return (
        <Text key={addon.id} style={styles.body1}>{`${getLocalOrDefault(
          addon.name
        )} ${addon.price.toFixed(2)} EGP`}</Text>
      );
    });
  }


  function renderProductAttributes(): JSX.Element[] {
    return product.attributes?.map((attribute) => {
      return (
        <Text key={attribute.id} style={styles.body1}>
          {getLocalOrDefault(attribute.name)}:&nbsp;
          {getLocalOrDefault(attribute.value.name)}
        </Text>
      );
    });
  }

  return (
    <View style={styles.tableRow}>
      <View style={styles.tableCellSmall}>
        <Text style={styles.body1}>{index + 1}</Text>
      </View>
      <View style={styles.tableNestedContainer}>
        <View style={styles.tableCellGrow}>
          <Text style={styles.body1}>{getLocalOrDefault(product.productName)}</Text>
        </View>
        <View style={styles.tableCellGrow}>{renderProductAttributes()}</View>
        <View style={styles.tableCellMedium}>
          <Text style={[styles.body1, styles.alignCenter]}>
            {product.quantity}
          </Text>
        </View>
        <View style={[styles.tableCellMedium, styles.priceAndSalePrice]}>
          {renderPriceAndSalePrice(product.price, product.salePrice!)}
        </View>
        <View style={styles.tableCellMedium}>
          {renderProductAddons()}
        </View>
        <View style={styles.tableCellMedium}>
          <Text style={[styles.body1]}>
            {getProductSubtotal().toFixed(2)} EGP
          </Text>
        </View>
      </View>
    </View>
  );
};

OrderInvoiceProduct.propTypes = {
  product: PropTypes.any.isRequired,
  index: PropTypes.number.isRequired,
};

export default OrderInvoiceProduct;
