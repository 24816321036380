import getPagePath from "src/utils/getStoreIdCustomPath";
import {CMSLinkType} from "src/interfaces/CMS";

export default function getCMSRouterLinkFromLinkType(type: CMSLinkType, path: string): string | undefined {
  const pageLink = getPagePath(`${path.toLowerCase().replace("_", "-")}`);
  const productPath = getPagePath(`product/${path}`);
  const servicesPath = getPagePath(`service/${path}`);
  const categoryPath = getPagePath(`category/${path}`);
  const serviceCategoryPath = getPagePath(`service-category/${path}`);
  const defaultPath = getPagePath(`${type.toLowerCase().replace("_", "-")}`);

  if (type === CMSLinkType.email || type === CMSLinkType.phone || type === CMSLinkType.externalLink) return;
  if (type === CMSLinkType.page) {
    if (!path) return;
    return pageLink;
  }
  if (type === CMSLinkType.product) {
    return productPath;
  }
  if (type === CMSLinkType.service) {
    return servicesPath;
  }
  if (type === CMSLinkType.category) {
    return categoryPath;
  }
  if (type === CMSLinkType.servicesCategory) {
    return serviceCategoryPath;
  }
  return defaultPath;
}
