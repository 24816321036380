import React, { useState, FC, memo } from "react";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonSearchbar,
  IonThumbnail,
  IonToolbar,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { close } from "ionicons/icons";
import { Country } from "src/interfaces/Country";
import useCountries from "src/hooks/useCountries";
import classes from "./CountriesModal.module.scss";

interface CountriesModalProps {
  isOpen: boolean;
  onSelectCountry: (country: Country) => void;
  onClose: () => void;
}

const CountriesModal: FC<CountriesModalProps> = ({ isOpen, onSelectCountry, onClose }) => {
  const { t } = useTranslation();
  const countries = useCountries();
  const [search, setSearch] = useState("");

  const handleCloseModal = (): void => {
    setSearch("");
    onClose();
  };

  const handleChangeSearch = (e: CustomEvent) => {
    const value = e.detail.value;
    setSearch(value);
  };

  const handleSelectCountry = (country: Country): (() => void) => {
    return () => {
      onSelectCountry(country);
    };
  };

  const filterCountries = (): Country[] => {
    return countries.filter((country) => country.name.toLowerCase().includes(search.toLowerCase()));
  };

  const renderCountriesList = (): JSX.Element[] => {
    return filterCountries().map((country) => (
      <IonItem button key={country.alpha2Code} onClick={handleSelectCountry(country)} lines="full">
        <IonThumbnail slot="start" className={classes.thumbnail}>
          <IonImg src={country.flag} className={classes.flag} />
        </IonThumbnail>
        <IonLabel>{country.name}</IonLabel>
      </IonItem>
    ));
  };

  return (
    <IonModal isOpen={isOpen} onDidDismiss={handleCloseModal}>
      <IonHeader>
        <IonToolbar color="primary">
          <IonSearchbar placeholder={t("SEARCH")} value={search} onIonChange={handleChangeSearch} />
          <IonButtons slot="end">
            <IonButton onClick={handleCloseModal}>
              <IonIcon icon={close} slot="icon-only" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>{renderCountriesList()}</IonList>
      </IonContent>
    </IonModal>
  );
};

export default memo(CountriesModal);
