import React, { FC } from "react";
import { Page, Document } from "@react-pdf/renderer";
import styles from "./styles";
import { Order } from "src/interfaces/Order";
import { Store } from "src/interfaces/Store";
import ReceiptHeader from "./ReceiptHeader";
import PersonalInfo from "./PersonalInfo";
import ProductsInfo from "./Product/ProductsInfo";
import Deliveryinfo from "./Deliveryinfo";
import OrderTotalInfo from "./OrderTotalInfo";
import BookingsInfo from "./BookingsInfo";
import getLocalOrDefault from "src/utils/getLocalOrDefault";

interface ReceiptPrinterPdfProps {
  order: Order;
  store: Store;
}

const ReceiptPrinterPdf: FC<ReceiptPrinterPdfProps> = ({
  order,
  store,
}) => {
  return (
    <Document>
      <Page size={[227]} style={styles.page}>
        <ReceiptHeader
          logo={store.logo!}
          name={getLocalOrDefault(store.storeName)}
          order={order}
        />
        <PersonalInfo order={order} />
        <ProductsInfo order={order} />
        <BookingsInfo order={order} />
        <Deliveryinfo order={order} />
        <OrderTotalInfo total={order.total} voucherCode={order.voucher?.code} />
      </Page>
    </Document>
  );
};

export default ReceiptPrinterPdf;
