import {FC, useState, useEffect, useCallback} from "react";
import classes from "./styles.module.scss";
import {IonSlide, IonSlides} from "@ionic/react";
import {Category} from "src/interfaces/Category";
import CategoryView from "../CategoryView/CategoryView";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { useAppSelector } from "src/hooks/useAppSelector";
import {getCategories} from "src/slices/categories";
import {subscribeToServicesCategories} from "src/slices/servicesCategories";
import {CMSListItemOptions, CMSItemShape} from "src/interfaces/CMS";
import getCarouselSlidePerView from "src/utils/getCarouselSlidePerView";

interface HorizontalCategoriesListProps {
  categoriesIds: string[];
  isService?: boolean;
  options?: CMSListItemOptions;
}

const HorizontalCategoriesList: FC<HorizontalCategoriesListProps> = ({
  categoriesIds,
  isService,
  options = {
    shape: CMSItemShape.square,
    width: 175,
    height: 200,
    isImageOnly: false,
    isResponsive: false,
    itemPerView: 3,
  },
}) => {
  const dispatch = useAppDispatch();
  const {isLoaded: isCategoriesLoaded, categories: allCategories} = useAppSelector((state) => state.categoriesReducer);
  const {isLoaded: isServicesCategoriesLoaded, servicesCategories: allServiceCategories} = useAppSelector(
    (state) => state.servicesCategoriesReducer,
  );
  const [slidesPerView, setSlidesPerView] = useState(
    getCarouselSlidePerView(options.isResponsive, options.itemPerView, options.width),
  );
  const [isListenerAttached, setIsListenerAttached] = useState(false);
  const [categories, setCategories] = useState<Category[]>([]);
  const sliderKey = categories.map((category) => category.id).join();

  const carouselOptions = {
    loop: false,
    slidesPerView,
  };

  useEffect(() => {
    setSlidesPerView(getCarouselSlidePerView(options.isResponsive, options.itemPerView, options.width));
    if (!isListenerAttached) {
      window.addEventListener("resize", () => {
        setSlidesPerView(getCarouselSlidePerView(options.isResponsive, options.itemPerView, options.width));
      });
      setIsListenerAttached(true);
    }
  }, [options, isListenerAttached]);

  const loadCategories = useCallback(async () => {
    const categories = categoriesIds.reduce((acc, id) => {
      const selectedCategory = allCategories.find((category) => category.id === id);
      if (selectedCategory) {
        acc.push(selectedCategory);
      }
      return acc;
    }, [] as Category[]);
    setCategories(categories);
  }, [allCategories, categoriesIds]);

  const loadServiceCategories = useCallback(async () => {
    const categories = categoriesIds.reduce((acc, id) => {
      const selectedCategory = allServiceCategories.find((category) => category.id === id)!;
      acc.push(selectedCategory);
      return acc;
    }, [] as Category[]);
    setCategories(categories);
  }, [allServiceCategories, categoriesIds]);

  useEffect(() => {
    if (isService) {
      if (isServicesCategoriesLoaded) {
        loadServiceCategories();
      } else {
        dispatch(subscribeToServicesCategories());
      }
    } else {
      if (isCategoriesLoaded) {
        loadCategories();
      } else {
        dispatch(getCategories());
      }
    }
  }, [dispatch, loadCategories, isCategoriesLoaded, isService, isServicesCategoriesLoaded, loadServiceCategories]);

  const renderCategories = (): JSX.Element[] =>
    categories.map((category) => (
      <IonSlide key={category.id} className={classes.slide}>
        <CategoryView category={category} isService={isService} options={options} />
      </IonSlide>
    ));
  return (
    <IonSlides key={sliderKey} options={carouselOptions}>
      {renderCategories()}
    </IonSlides>
  );
};

export default HorizontalCategoriesList;
