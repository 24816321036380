import { FC, Fragment, useCallback, useEffect, useMemo, useState } from "react";
import {
  DatetimeChangeEventDetail,
  IonContent,
  IonLoading,
} from "@ionic/react";
import { useHistory, useParams } from "react-router";
import { PageLoading } from "src/components";
import getLocalOrDefault from "src/utils/getLocalOrDefault";
import ServiceDetails from "./ServiceDetails";
import Page from "src/components/Page";
import Header from "./Header";
import { Service } from "src/interfaces/Service";
import { getServiceByIdFB } from "src/firebase/services";
import { Category } from "src/interfaces/Category";
import { getServicesCategoryByIdFB } from "src/firebase/servicesCategories";
import { ServicesWorker } from "src/interfaces/ServicesWorker";
import { WorkerAvailability } from "src/interfaces/WorkerAvailability";
import {
  fetchWorkers,
  generateBookingTime,
  updateRequirements,
} from "./helpers";
import { CartAddon } from "src/interfaces/CartAddon";
import { uuidv4 } from "src/utils/uuidv4";
import { CartRequirement } from "src/interfaces/CartRequirement";
import {
  bookServicesPackageSession,
  getAvailableBookings,
} from "src/firebase/functions";
import useToast from "src/hooks/useToast";
import { useTranslation } from "react-i18next";
import SelectModal from "./SelectModal";
import { CartService } from "src/interfaces/CartService";
import { addServiceToCart } from "src/utils/cart";
import getPagePath from "src/utils/getStoreIdCustomPath";
import { IonDatetimeCustomEvent } from "@ionic/core";

type Params = {
  serviceId: string;
};

type State = {
  orderId: string;
  packageId: string;
};

const ServiceDetailsPage: FC = () => {
  const { serviceId } = useParams<Params>();
  const { t } = useTranslation();
  const launchToast = useToast();
  const {
    replace,
    location: { state },
  } = useHistory<State>();

  const [service, setService] = useState<Service>();
  const [category, setCategory] = useState<Category>();
  const [workers, setWorkers] = useState<ServicesWorker[]>([]);
  const [addons, setAddons] = useState<CartAddon[]>([]);
  const [requirements, setRequirements] = useState<CartRequirement[]>([]);
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [availabilities, setAvailabilities] = useState<WorkerAvailability[]>(
    []
  );
  const [availableWorkers, setAvailableWorkers] = useState<string[]>([]);
  const [isSelectModalOpen, setIsSelectModalOpen] = useState(false);

  const name = useMemo(
    () => (service ? getLocalOrDefault(service.name) : ""),
    [service]
  );

  const fetchService = useCallback(async () => {
    const service = await getServiceByIdFB(serviceId);
    if(!service) return;
    const workers = await fetchWorkers(service.workers);
    if (!workers.length) return;
    setService(service);
    setWorkers(workers);
    setCategory(await getServicesCategoryByIdFB(service.category));
  }, [serviceId]);

  useEffect(() => {
    fetchService();
  }, [fetchService]);

  const handleClosePicker = (): void => {
    setIsPickerOpen(false);
  };

  const handleOpenPicker = (): void => {
    setIsPickerOpen(true);
  };

  const handleCloseSelectModal = (): void => {
    setIsSelectModalOpen(false);
  };

  const handleChangeDate = (
    e: IonDatetimeCustomEvent<DatetimeChangeEventDetail>
  ): void => {
    const value = e.detail.value as string;
    const date = new Date(value);
    setSelectedDate(date.toISOString());
  };

  const selectMultipleAddon = (addonId: string, checked: boolean): void => {
    setAddons((prevState) => {
      if (checked) return [...prevState, { id: uuidv4(), addonId }];
      return prevState.filter((addon) => addon.addonId !== addonId);
    });
  };

  const selectSingleAddon = (addonId: string, prevId: string): void => {
    setAddons((prevState) => {
      if (!prevId) return [...prevState, { id: uuidv4(), addonId }];
      return prevState.map((addon) => {
        if (addon.addonId !== prevId) return addon;
        return { ...addon, addonId };
      });
    });
  };

  const handleSelectSingleAddon = useCallback(
    (addonId: string, prevId: string): void => {
      selectSingleAddon(addonId, prevId);
    },
    []
  );

  const handleSelectMultipleAddon = useCallback(
    (addonId: string, checked: boolean): void => {
      selectMultipleAddon(addonId, checked);
    },
    []
  );

  const handleClearAddons = (): void => {
    setAddons([]);
  };

  const handleChangeRequirement = useCallback(
    (id: string, value: string): void => {
      setRequirements((prevState) => updateRequirements(prevState, id, value));
    },
    []
  );

  const handleClearAvailabilties = (): void => {
    setAvailabilities((prevState) => (prevState.length ? [] : prevState));
    setAvailableWorkers((prevState) => (prevState.length ? [] : prevState));
  };

  const handleBookPackageSession = async (
    worker: string,
    bookingTime: string
  ): Promise<void> => {
    const { orderId, packageId } = state;
    await bookServicesPackageSession(
      addons,
      service.id,
      worker,
      requirements,
      orderId,
      packageId,
      bookingTime
    );
    const path = getPagePath("successful-booking");
    replace(path);
  };

  const handleAddToCart = async (
    workerId: string,
    bookingTime: string
  ): Promise<void> => {
    const { name, id } = service;
    const cartService: CartService = {
      addons,
      id: uuidv4(),
      name,
      workerId,
      serviceId: id,
      requirements,
      bookingTime,
    };
    await addServiceToCart(cartService);
    launchToast(t("DONE"), "success");
  };

  const handleSubmitService = async (
    workerId: string,
    slot?: string
  ): Promise<void> => {
    try {
      const selectedWorker = workers.find((worker) => worker.id === workerId);
      const bookingTime = generateBookingTime(
        selectedDate,
        selectedWorker,
        slot
      );
      if (!state || !state.orderId || !state.packageId) {
        await handleAddToCart(workerId, bookingTime);
      } else {
        await handleBookPackageSession(workerId, slot);
      }
    } catch {
      launchToast(t("FAILED"), "danger");
    }
  };

  const handleSelectSlot = async (value: string): Promise<void> => {
    if (!value) return;
    const workerId = value.split("-")[0];
    const slot = value.split("-")[1];
    handleSubmitService(workerId, slot);
  };

  const handleSelectWorker = async (worker: string): Promise<void> => {
    if (!worker) return;
    handleSubmitService(worker);
  };

  const handleCheckAvailableBooking = async (): Promise<void> => {
    try {
      handleClearAvailabilties();
      setIsLoading(true);
      const { availabilities, availableWorkers, isDayOrMore } =
        await getAvailableBookings(selectedDate, addons, service.id);
      if (isDayOrMore) {
        if (!availableWorkers.length) {
          launchToast(t("NO_AVAILABLE_BOOKINGS"), "warning");
          return;
        }
        if (!service.isOptionalWorker || availableWorkers.length === 1) {
          handleSubmitService(availableWorkers[0]);
          return;
        }
        setAvailableWorkers(availableWorkers);
        setIsSelectModalOpen(true);
      } else {
        if (!availabilities.length) {
          launchToast(t("NO_AVAILABLE_BOOKINGS"), "warning");
          return;
        }
        setAvailabilities(availabilities);
        setIsSelectModalOpen(true);
      }
    } catch {
      launchToast(t("FAILED"), "danger");
    } finally {
      setIsLoading(false);
    }
  };

  const renderContent = (): JSX.Element => {
    if (!service) return <PageLoading />;
    return (
      <Fragment>
        <ServiceDetails
          service={service}
          name={name}
          category={category}
          workers={workers}
          isPickerOpen={isPickerOpen}
          selectedDate={selectedDate}
          selectedAddons={addons}
          requirements={requirements}
          onSubmitService={handleCheckAvailableBooking}
          onClosePicker={handleClosePicker}
          onOpenPicker={handleOpenPicker}
          onSelectDate={handleChangeDate}
          onClearAddons={handleClearAddons}
          onSelectMultipleAddon={handleSelectMultipleAddon}
          onSelectSingleAddon={handleSelectSingleAddon}
          onChangeRequirement={handleChangeRequirement}
        />
        <SelectModal
          isOpen={isSelectModalOpen}
          availableWorkers={availableWorkers}
          availabilities={availabilities}
          isOptionalWorker={service.isOptionalWorker}
          workers={workers}
          onClose={handleCloseSelectModal}
          onSelectSlot={handleSelectSlot}
          onSelectWorker={handleSelectWorker}
        />
      </Fragment>
    );
  };

  return (
    <Page title={name}>
      <Header title={name} />
      <IonContent>
        <IonLoading isOpen={isLoading} />
        {renderContent()}
      </IonContent>
    </Page>
  );
};

export default ServiceDetailsPage;
