import {FC, ReactNode, Fragment} from "react";
import {Helmet} from "react-helmet-async";
import PropTypes from "prop-types";
import {IonPage} from "@ionic/react";

interface PageProps {
  children?: ReactNode;
  title: string;
}

const Page: FC<PageProps> = ({children, title}) => {
  return (
    <Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <IonPage>{children}</IonPage>
    </Fragment>
  );
};

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default Page;
