import {
  IonBadge,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonProgressBar,
  IonText,
} from "@ionic/react";
import React, {useEffect, useState, FC} from "react";
import {useCallback} from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router";
import useAuth from "src/hooks/useAuth";
import useToast from "src/hooks/useToast";
import getPagePath from "src/utils/getStoreIdCustomPath";

interface ResetPasswordProps {
  oobCode: string;
}

const ResetPassword: FC<ResetPasswordProps> = ({ oobCode }) => {
  const { t } = useTranslation();
  const launchToast = useToast();
  const { verifyPasswordCode, resetPassword } = useAuth();
  const history = useHistory();
  const [codeError, setCodeError] = useState(false);
  const [form, setForm] = useState({password: "", confirmPassword: ""});
  const [isLoading, setIsLoading] = useState(false);

  const hasFormError = () => !form.password || hasPasswordError() || !form.confirmPassword || hasConfirmPasswordError();
  const hasPasswordError = () => form.password.length > 0 && form.password.length < 8;
  const hasConfirmPasswordError = () => form.confirmPassword.length > 0 && form.password !== form.confirmPassword;

  const verifyCode = useCallback(async () => {
    try {
      await verifyPasswordCode(oobCode);
    } catch {
      setCodeError(true);
    } finally {
      setIsLoading(false);
    }
  }, [oobCode, verifyPasswordCode])

  useEffect(() => {
    verifyCode();
  }, [verifyCode]);

  const onFormChange = (e: any) => {
    setForm({...form, [e.target.name]: e.detail.value});
  };

  const changePassword = async (): Promise<void> => {
    setIsLoading(true);
    try {
      await resetPassword(oobCode, form.password);
      launchToast("PAGE.RESET_PASSWORD.PASSWORD_CHANGE_SUCCESS_MESSAGE", "success");
      const loginLink = getPagePath("login");
      history.push(loginLink);
    } catch {
      launchToast("PAGE.RESET_PASSWORD.PASSWORD_CHANGE_ERROR_MESSAGE", "success");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <IonProgressBar type="indeterminate" />
      ) : codeError ? (
        <IonBadge className="authBadge" color="danger">
          {t("AUTH.INVALID_ACTION_CODE")}
        </IonBadge>
      ) : (
        <>
          <IonCard>
            <IonCardHeader>
              <IonText>{t("AUTH.RESET_PASSWORD_HEADER")}</IonText>
            </IonCardHeader>
            <IonCardContent>
              <IonItem>
                <IonLabel position="floating">{t("AUTH.FORM_PASSWORD")}</IonLabel>
                <IonInput
                  value={form.password}
                  name="password"
                  onIonChange={onFormChange}
                  color={hasPasswordError() ? "danger" : ""}
                  type="password"
                />
              </IonItem>
              {hasPasswordError() && <IonLabel color="danger">{t("AUTH.PASSWORD_ERROR")}</IonLabel>}

              <IonItem>
                <IonLabel position="floating">{t("AUTH.FORM_CONFIRM_PASSWORD")}</IonLabel>
                <IonInput
                  value={form.confirmPassword}
                  name="confirmPassword"
                  onIonChange={onFormChange}
                  color={hasConfirmPasswordError() ? "danger" : ""}
                  type="password"
                />
              </IonItem>
              {hasConfirmPasswordError() && <IonLabel color="danger">{t("AUTH.CONFIRM_PASSWORD_ERROR")}</IonLabel>}
            </IonCardContent>
            <IonButton
              disabled={hasFormError() || isLoading}
              onClick={changePassword}
              className="resetButton"
              color="primary"
              fill="solid"
              expand="block"
            >
              {t("AUTH.CHANGE_PASSWORD_BUTTON")}
            </IonButton>
            {isLoading && <IonProgressBar type="indeterminate" />}
          </IonCard>
        </>
      )}
    </>
  );
};

export default ResetPassword;
