import { AppThunk } from "src/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { City } from "src/interfaces/City";

interface CitiesState {
    cities: City[];
    isLoaded: boolean;
}

const initialState: CitiesState = {
    cities: [],
    isLoaded: false,
};

const slice = createSlice({
    name: "cities",
    initialState,
    reducers: {
        setCitites(state: CitiesState, action: PayloadAction<City[]>): void {
            state.cities = action.payload;
            state.isLoaded = true;
        },
    },
});

export const reducer = slice.reducer;

export const { setCitites } = slice.actions;

export const getStoreCities = (): AppThunk => async (dispatch, getState): Promise<void> => {

};

export default reducer;
