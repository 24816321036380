import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonList,
} from "@ionic/react";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { OrderProduct } from "src/interfaces/OrderProduct";
import ProductItem from "./ProductItem";

interface ProductsProps {
  products: OrderProduct[];
}

const Products: FC<ProductsProps> = ({ products }) => {
  const { t } = useTranslation();

  const renderProducts = (): JSX.Element[] =>
    products.map((product) => (
      <ProductItem key={product.id} product={product} />
    ));

  if (!products.length) return null;

  return (
    <IonCard>
      <IonCardHeader>
        <IonCardSubtitle className="ion-font-bold">
          {t("PRODUCTS")}
        </IonCardSubtitle>
      </IonCardHeader>
      <IonCardContent>
        <IonList>{renderProducts()}</IonList>
      </IonCardContent>
    </IonCard>
  );
};

export default Products;
