import {
  createContext,
  useEffect,
  useReducer,
  FC,
  ReactNode,
  useCallback,
} from "react";
import {
  signInWithEmailAndPassword,
  signOut,
  verifyPasswordResetCode,
  confirmPasswordReset,
  onAuthStateChanged,
  FacebookAuthProvider,
  signInWithCredential,
  signInWithPopup,
  OAuthProvider,
  GoogleAuthProvider,
} from "firebase/auth";
import { auth } from "src/firebase";
import SplashScreen from "src/components/SplashScreen";
import { FacebookLogin } from "@capacitor-community/facebook-login";
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import {
  SignInWithApple,
  SignInWithAppleOptions,
} from "@capacitor-community/apple-sign-in";
import { updatefirebaseApiAuthHeader } from "src/utils/updatefirebaseApiAuthHeader";
import { isNativePlatform } from "src/utils/isNativePlatform";
import {
  sendResetPasswordEmail,
  sendVerificationEmail,
} from "src/firebase/functions";
import { getUserFB } from "src/firebase/user";
import {
  checkCustomerAuthorizationFB,
  subscribeToCustomerFB,
  unsubscribeToCustomerFB,
} from "src/firebase/customer";
import useToast from "src/hooks/useToast";
import { store } from "src/store";
import { setUser } from "src/slices/user";
import { subscribeToNotificationsFB, unsubscribeToNotificationsFB } from "src/firebase/notifications";
import { restoreCart } from "src/utils/cart";

interface FirebaseAuthContextState {
  isInitialised: boolean;
  isAuthenticated: boolean;
}

interface FirebaseAuthContextValue extends FirebaseAuthContextState {
  loginWithEmailAndPassword: (email: string, password: string) => Promise<void>;
  sendResetPasswordEmail: (email: string) => Promise<void>;
  sendVerificationEmail: (email: string) => Promise<void>;
  verifyPasswordCode: (code: string) => Promise<void>;
  signInWithGoogle: () => Promise<void>;
  signInWithFacebook: () => Promise<void>;
  signInWithApple: () => Promise<void>;
  resetPassword: (code: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
}

enum ActionType {
  authStateChanged,
}

type AuthStateChangedAction = {
  type: ActionType.authStateChanged;
  payload: boolean;
};

const reducer = (
  state: FirebaseAuthContextState,
  action: AuthStateChangedAction
): FirebaseAuthContextState => {
  switch (action.type) {
    case ActionType.authStateChanged: {
      const isAuthenticated = action.payload;
      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const initialState: FirebaseAuthContextState = {
  isAuthenticated: false,
  isInitialised: false,
};

const FirebaseAuthContext = createContext<FirebaseAuthContextValue>({
  ...initialState,
  loginWithEmailAndPassword: () => Promise.resolve(),
  signInWithGoogle: () => Promise.resolve(),
  signInWithFacebook: () => Promise.resolve(),
  signInWithApple: () => Promise.resolve(),
  sendResetPasswordEmail: () => Promise.resolve(),
  sendVerificationEmail: () => Promise.resolve(),
  verifyPasswordCode: () => Promise.resolve(),
  resetPassword: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

interface FirebaseAuthProviderProps {
  children: ReactNode;
}

const FirebaseAuthProvider: FC<FirebaseAuthProviderProps> = ({ children }) => {
  const launchToast = useToast();
  const [state, dispatch] = useReducer(reducer, initialState);

  const logout = useCallback(async (): Promise<void> => {
    await signOut(auth);
    if (isNativePlatform) {
      await FacebookLogin.logout();
      await GoogleAuth.signOut();
    }
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        await getUserFB();
        const isAuthorized = await checkCustomerAuthorizationFB();
        if (!isAuthorized) {
          await logout();
          return;
        }
        subscribeToCustomerFB();
        subscribeToNotificationsFB();
        dispatch({ type: ActionType.authStateChanged, payload: true });
      } else {
        store.dispatch(setUser(null));
        unsubscribeToCustomerFB();
        unsubscribeToNotificationsFB();
        restoreCart();
        dispatch({ type: ActionType.authStateChanged, payload: false });
      }
      updatefirebaseApiAuthHeader();
    });
    return unsubscribe;
  }, [logout, launchToast]);

  const loginWithEmailAndPassword = async (
    email: string,
    password: string
  ): Promise<void> => {
    await signInWithEmailAndPassword(auth, email, password);
  };

  const signInWithGoogle = async (): Promise<void> => {
    if (isNativePlatform) {
      const { authentication } = await GoogleAuth.signIn();
      await signInWithCredential(
        auth,
        GoogleAuthProvider.credential(authentication.idToken)
      );
    } else {
      await signInWithPopup(auth, new GoogleAuthProvider());
    }
  };

  const signInWithFacebook = async (): Promise<void> => {
    if (isNativePlatform) {
      const { accessToken } = await FacebookLogin.login({
        permissions: ["email"],
      });
      await signInWithCredential(
        auth,
        FacebookAuthProvider.credential(accessToken.token)
      );
    } else {
      const provider = new FacebookAuthProvider();
      provider.addScope("email");
      await signInWithPopup(auth, provider);
    }
  };

  const signInWithApple = async (): Promise<void> => {
    if (isNativePlatform) {
      const options: SignInWithAppleOptions = {
        clientId: process.env.REACT_APP_APPLE_SIGN_IN_CLIENT_ID,
        redirectURI: process.env.REACT_APP_APPLE_SIGN_IN_REDIRECT_URL,
        scopes: "email name",
      };
      const { response } = await SignInWithApple.authorize(options);
      const provider = new OAuthProvider("apple.com");
      const credential = provider.credential({
        idToken: response.identityToken,
      });
      await signInWithCredential(auth, credential);
    } else {
      const provider = new OAuthProvider("apple.com");
      provider.addScope("email");
      provider.addScope("name");
      await signInWithPopup(auth, provider);
    }
  };

  const verifyPasswordCode = useCallback(
    async (code: string): Promise<void> => {
      await verifyPasswordResetCode(auth, code);
    },
    []
  );

  const resetPassword = async (
    code: string,
    password: string
  ): Promise<void> => {
    await confirmPasswordReset(auth, code, password);
  };

  if (!state.isInitialised) return <SplashScreen />;

  return (
    <FirebaseAuthContext.Provider
      value={{
        ...state,
        loginWithEmailAndPassword,
        signInWithApple,
        sendResetPasswordEmail,
        verifyPasswordCode,
        signInWithGoogle,
        resetPassword,
        signInWithFacebook,
        logout,
        sendVerificationEmail,
      }}
    >
      {children}
    </FirebaseAuthContext.Provider>
  );
};

export { FirebaseAuthContext, FirebaseAuthProvider };
