import {IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonLoading} from "@ionic/react";
import React, {useEffect, useState, FC} from "react";
import {useCallback} from "react";
import {useTranslation} from "react-i18next";
import {useHistory, useLocation} from "react-router";
import useAuth from "src/hooks/useAuth";
import useToast from "src/hooks/useToast";
import "./ResetPassword.scss";
import {ResetPasswordForm} from "./components";
import {InvalidOOBCode} from "src/components";
import getPagePath from "src/utils/getStoreIdCustomPath";

const ResetPassword: FC = () => {
  const {t} = useTranslation();
  const launchToast = useToast();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const oobCode = params.get("oobCode")!;
  const {verifyPasswordCode, resetPassword} = useAuth();
  const history = useHistory();
  const [isInvalidCode, setIsInvalidCode] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const loginPath = getPagePath("login");

  const verifyCode = useCallback(async () => {
    try {
      await verifyPasswordCode(oobCode);
    } catch {
      setIsInvalidCode(true);
    } finally {
      setIsLoading(false);
    }
  }, [oobCode, verifyPasswordCode]);

  useEffect(() => {
    verifyCode();
  }, [verifyCode]);

  const handleChangePassword = async (password: string): Promise<void> => {
    try {
      setIsSubmitting(true);
      await resetPassword(oobCode, password);
      launchToast("PAGE.RESET_PASSWORD.PASSWORD_CHANGE_SUCCESS_MESSAGE", "success");
      history.push(loginPath);
    } catch {
      launchToast("PAGE.RESET_PASSWORD.PASSWORD_CHANGE_ERROR_MESSAGE", "danger");
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderContent = (): JSX.Element | undefined => {
    if (isLoading) return;
    if (isInvalidCode) return <InvalidOOBCode />;
    return <ResetPasswordForm onChangePassword={handleChangePassword} />;
  };

  return (
    <IonPage className="resetPasswordPage">
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>{t(`PAGE.RESET_PASSWORD.PAGE_TITLE`)}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="resetPasswordPage__content">
        <IonLoading isOpen={isLoading || isSubmitting} message={t("PLEASE_WAIT")} />
        {renderContent()}
      </IonContent>
    </IonPage>
  );
};

export default ResetPassword;
