import { BaseDocument } from "./BaseDocument";
import { RawDraftTranslationMap, TranslationMap } from "./Translation";

export interface CMS extends BaseDocument {
  menu: CMSMenu;
  pages: CMSPage[];
  home: CMSItem[];
  homeBackground: string;
}

// Menu
export type CMSMenu = {
  items: CMSMenuItem[];
};

export type CMSMenuItem = {
  id: string;
  visibleOnGuest: boolean;
  visibleOnUser: boolean;
  path: string;
  icon: string;
};

// Pages
export type CMSPage = {
  id: string;
  name: TranslationMap;
  path: string;
  content: CMSItem[];
  background: string;
};

export enum CMSItemViewAlignment {
  left = "LEFT",
  center = "CENTER",
  right = "RIGHT",
}

export type CMSItemViewOptions = {
  width: number;
  alignment: CMSItemViewAlignment;
  isHidden: boolean;
};

export type CMSItemOptions = {
  mobileView: CMSItemViewOptions;
  tabletView: CMSItemViewOptions;
  desktopView: CMSItemViewOptions;
};

export enum CMSItemOptionsName {
  mobileView = "mobileView",
  tabletView = "tabletView",
  desktopView = "desktopView",
}

export type CMSItem = {
  id: string;
  type: CMSItemType;
  component: CMSItemComponent;
  options?: CMSItemOptions;
};

export enum CMSItemType {
  container = "CONTAINER",
  heading = "HEADING",
  paragraph = "PARAGRAPH",
  link = "LINK",
  banner = "BANNER",
  youtubeVideo = "YOUTUBE_VIDEO",
  productsList = "PRODUCTS_LIST",
  servicesList = "SERVICES_LIST",
  categoriesList = "CATEGORIES_LIST",
  servicesCategoriesList = "SERVICES_CATEGORIES_LIST",
  slider = "SLIDER",
  attachment = "ATTACHMENT",
  gallery = "GALLERY",
  form = "FORM",
  attendance = "ATTENDANCE",
}

export type CMSItemComponent =
  | CMSHeading
  | CMSParagraph
  | CMSBanner
  | CMSYoutubeVideo
  | CMSLink
  | CMSProductsList
  | CMSCategoriesList
  | CMSServicesList
  | CMSSlider
  | CMSGallery
  | CMSAttachment
  | CMSForm
  | CMSAttendance
  | CMSContainer;

// Heading
export type CMSHeading = {
  text: TranslationMap;
  type: CMSHeadingType;
  color: string;
  darkThemeColor: string;
  alignment: CMSHeadingAlignment;
  isLogo: boolean;
  logoAlignment: CMSHeadingLogoAlignment;
  isLogoStick: boolean;
  logoSize: number;
  logoShape: CMSItemShape;
};

export enum CMSHeadingType {
  h1 = "H1",
  h2 = "H2",
  h3 = "H3",
  h4 = "H4",
  h5 = "H5",
  h6 = "H6",
}

export enum CMSHeadingAlignment {
  left = "LEFT",
  center = "CENTER",
  right = "RIGHT",
}

export enum CMSHeadingLogoAlignment {
  left = "LEFT",
  right = "RIGHT",
}

// Paragraph
export type CMSParagraph = {
  state: RawDraftTranslationMap;
};

// Banner
export type CMSBanner = {
  src: string;
  maxHeight: number;
  isPercentage: boolean;
  isCustom: boolean;
  align: CMSBannerAlign;
};

export enum CMSBannerAlign {
  resize = "RESIZE",
  crop = "CROP",
  stretch = "STRETCH",
}

// Youtube video
export type CMSYoutubeVideo = {
  src: string;
};

// Link
export type CMSLink = {
  color: string;
  variant: CMSLinkVariant;
  label: TranslationMap;
  type: CMSLinkType;
  value: string;
};

export enum CMSLinkVariant {
  contained = "CONTAINED",
  outlined = "OUTLINED",
}

// Lists
export interface CMSList {
  itemOptions: CMSListItemOptions;
}

export type CMSListItemOptions = {
  width: number;
  height: number;
  isImageOnly: boolean;
  isResponsive: boolean;
  itemPerView: number;
  shape: CMSItemShape;
};

export enum CMSListItemOptionsName {
  width = "width",
  height = "height",
  isImageOnly = "isImageOnly",
  isResponsive = "isResponsive",
  itemPerView = "itemPerView",
  shape = "shape",
}

export interface CMSProductsList extends CMSList {
  products: string[];
}

export interface CMSServicesList extends CMSList {
  services: string[];
}

export interface CMSCategoriesList extends CMSList {
  categories: string[];
}

// Slider
export type CMSSlider = {
  slides: CMSSlide[];
};

export type CMSSlide = CMSItemSlide | CMSCustomSlide;

export type CMSItemSlide = {
  id: string;
  type: CMSSlideType.product | CMSSlideType.service;
  value: string;
};

export enum CMSSlideType {
  product = "PRODUCT",
  service = "SERVICE",
  custom = "CUSTOM",
}

export type CMSCustomSlide = {
  id: string;
  type: CMSSlideType.custom;
  image: string;
  title: string;
  description: string;
  link: string;
  linkType: CMSLinkType;
};

// Gallery
export interface CMSGallery extends CMSList {
  images: CMSGalleryImage[];
}

export type CMSGalleryImage = {
  id: string;
  src: string;
  link: string;
  linkType: CMSLinkType;
  fullscreenImage: boolean;
};

// Attachments
export type CMSAttachment = {
  link: string;
  label: TranslationMap;
};

// Form
export type CMSForm = {
  formId: string;
};

// Attendance
export type CMSAttendance = {
  attendanceId: string;
  label: TranslationMap;
  checkIn: CMSAttendanceCheck;
  checkOut: CMSAttendanceCheck;
  fields: string[];
};

export type CMSAttendanceCheck = {
  isIncludesLocation: boolean;
  formId: string;
};

// Container
export type CMSContainer = {
  items: CMSItem[];
};

// Shape
export enum CMSItemShape {
  square = "SQUARE",
  circle = "CIRCLE",
}

// Link type
export enum CMSLinkType {
  home = "HOME",
  products = "PRODUCTS",
  services = "SERVICES",
  categories = "CATEGORIES",
  servicesCategories = "SERVICE_CATEGORIES",
  product = "PRODUCT",
  category = "CATEGORY",
  service = "SERVICE",
  servicesCategory = "SERVICE_CATEGORY",
  externalLink = "EXTERNAL_LINK",
  phone = "PHONE",
  email = "EMAIL",
  page = "PAGE",
}

export enum CMSDefaultPage {
  home = "HOME",
  signup = "SIGNUP",
  login = "LOGIN",
  forgetPassword = "FORGET_PASSWORD",
  resetPassword = "RESET_PASSWORD",
  categories = "CATEGORIES",
  category = "CATEGORY",
  products = "PRODUCTS",
  product = "PRODUCT",
  serviceCategories = "SERVICE_CATEGORIES",
  serviceCategory = "SERVICE_CATEGORY",
  services = "SERVICES",
  servicesPackages = "SERVICES_PACKAGES",
  service = "SERVICE",
  cart = "CART",
  disabled = "DISABLED",
  inactive = "INACTIVE",
  checkout = "CHECKOUT",
  settings = "SETTINGS",
  orders = "ORDERS",
  order = "ORDER",
  bookings = "BOOKINGS",
  booking = "BOOKING",
  notifications = "NOTIFICATIONS",
  notification = "NOTIFICATION",
  storeWallet = "STORE_WALLET",
}
