import React, { FC } from "react";
import { IonItem, IonLabel, IonSelect, IonSelectOption } from "@ionic/react";
import LANGUAGES from "src/constants/languages";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "src/hooks/useAppSelector";
import { SelectChangeEventDetail } from "@ionic/core";
import classes from "./LanguageSelector.module.scss";

interface LanguageSelectorProps {
    language: string;
    onChangeLanguage: (e: CustomEvent<SelectChangeEventDetail>) => void;
}

const LanguageSelector: FC<LanguageSelectorProps> = ({ language, onChangeLanguage }) => {
    const { languages } = useAppSelector((state) => state.storeReducer.store);
    const { t } = useTranslation();

    const renderLanguages = (): JSX.Element[] => {
        return languages!.map((language) => {
            return (
                <IonSelectOption key={language} value={language}>
                    {LANGUAGES[language]}
                </IonSelectOption>
            );
        });
    };

    return (
        <IonItem className={classes.root}>
            <IonLabel>{t("SETTINGS.LANGUAGE_LABEL")}</IonLabel>
            <IonSelect
                cancelText={t("CANCEL")}
                onIonChange={onChangeLanguage}
                value={language}
                interface="action-sheet"
                interfaceOptions={{ header: t("SETTINGS.CHOOSE_LANGUAGE_TITLE") }}
            >
                {renderLanguages()}
            </IonSelect>
        </IonItem>
    );
};

export default LanguageSelector;
