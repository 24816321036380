import React, {FC} from "react";
import PropTypes from "prop-types";
import styles from "./styles";
import {View, Text} from "@react-pdf/renderer";
interface Props {
  total: number;
  voucherCode?: string;
}
const TotalInfo: FC<Props> = ({total, voucherCode}) => {
  function renderVoucherCode(): JSX.Element | undefined {
    if (!voucherCode) return;
    return (
      <View style={styles.tableRow}>
        <View style={styles.tableCellGrow}>
          <Text style={styles.body1}>
            Voucher code applied: <Text style={[styles.body1, styles.voucher]}>{voucherCode} </Text>
          </Text>
        </View>
      </View>
    );
  }
  return (
    <View>
      <View style={[styles.table, styles.marginTop]}>
        {renderVoucherCode()}
        <View style={styles.tableRow}>
          <View style={styles.tableCellGrow}>
            <Text style={styles.h6}>Total</Text>
          </View>
          <View style={styles.tableCell}>
            <Text style={[styles.h6]}>{total.toFixed(2)} EGP</Text>
          </View>
        </View>
      </View>
    </View>
  );
};

TotalInfo.propTypes = {
  total: PropTypes.number.isRequired,
  voucherCode: PropTypes.string,
};

export default TotalInfo;
