import  {FC} from "react";
import "./Auth.scss";
import {IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar} from "@ionic/react";
import {Redirect, useLocation} from "react-router";
import {AuthModes} from "src/interfaces/IUser";
import ResetPassword from "./ResetPassword";
import EmailVerification from "./EmailVerification";
import {useTranslation} from "react-i18next";
import getPagePath from "src/utils/getStoreIdCustomPath";

const Auth: FC = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const oobCode = params.get("oobCode");
  const mode = params.get("mode");
  const {t} = useTranslation();
  const homeLink = getPagePath("home");

  const renderAuthMode = () => {
    if (!oobCode) return <Redirect to={homeLink} />;
    switch (mode) {
      case AuthModes.RESET_PASSWORD:
        return <ResetPassword oobCode={oobCode} />;
      case AuthModes.EMAIL_VERIFICATION:
        return <EmailVerification oobCode={oobCode} />;
      default:
        return <Redirect to={homeLink} />;
    }
  };

  return (
    <IonPage className="authPage">
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{t(`AUTH.MODE_${mode?.toUpperCase()}`)}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="authMode">{renderAuthMode()}</div>
      </IonContent>
    </IonPage>
  );
};

export default Auth;
