import {IonButton, IonIcon} from "@ionic/react";
import {logoGoogle} from "ionicons/icons";
import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import useAuth from "src/hooks/useAuth";
import useToast from "src/hooks/useToast";
import classes from "./GoogleSignInButton.module.scss";

const GoogleSignInButton: FC = () => {
  const {t} = useTranslation();
  const {signInWithGoogle} = useAuth();
  const launchToast = useToast();

  const handleSigninWithGoogle = async (): Promise<void> => {
    try {
      await signInWithGoogle();
    } catch {
      launchToast(t("FAILED_TO_SIGNIN"), "danger");
    }
  };

  return (
    <div className={classes.root}>
      <IonButton className={classes.button} onClick={handleSigninWithGoogle}>
        <IonIcon icon={logoGoogle} slot="start" />
        {t("SIGN_IN_WITH_GOOGLE")}
      </IonButton>
    </div>
  );
};

export default GoogleSignInButton;
