import {Text, View} from "@react-pdf/renderer";
import React, {FC} from "react";
import {Order} from "src/interfaces/Order";
import ReceiptBooking from "./ReceiptBooking";
import styles from "./styles";
interface BookingInfoProps {
  order: Order;
}

const BookingInfo: FC<BookingInfoProps> = ({order}) => {
  function renderBookingsContent(): JSX.Element[] {
    return order.services.map((booking, i) => <ReceiptBooking index={i} booking={booking} key={booking.id! + i} />);
  }
  function renderBookings(): JSX.Element | undefined {
    if (order.services.length === 0) return;
    return (
      <View style={[styles.table]}>
        <View>
          <View style={styles.tableRow}>
            <View style={styles.tableCell}>
              <Text style={styles.contentHeader}>Booking details</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableNestedContainer}>
            </View>
          </View>
          {renderBookingsContent()}
        </View>
      </View>
    );
  }
  return <View>{renderBookings()}</View>;
};

BookingInfo.propTypes = {};
export default BookingInfo;
