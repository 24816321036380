import React, {FC} from "react";
import { useAppSelector } from "src/hooks/useAppSelector";
import {Redirect} from "react-router-dom";
import useAuth from "src/hooks/useAuth";
import getPagePath from "src/utils/getStoreIdCustomPath";

const PublicGuard: FC = ({children}) => {
  const {isAuthenticated} = useAuth();

  const customer = useAppSelector((state) => state.customerReducer.customer);
  const store = useAppSelector((state) => state.storeReducer.store);
  const inActiveLink = getPagePath("inactive");
  const disableLink = getPagePath("disabled");

  if (isAuthenticated && customer?.disabled) {
    return <Redirect to={disableLink} />;
  }

  if (!store.active) {
    return <Redirect to={inActiveLink} />;
  }

  return <>{children}</>;
};

export default PublicGuard;
