import { FC } from "react";
import { IonIcon, IonFab, IonFabButton } from "@ionic/react";
import { bagAdd } from "ionicons/icons";

interface CartAddButtonProps {
  onAddToCart: () => void;
  disabled?: boolean;
}

const CartAddButton: FC<CartAddButtonProps> = ({ onAddToCart, disabled }) => {
  return (
    <IonFab
      horizontal="end"
      vertical="bottom"
      className="ion-margin-bottom ion-margin-end"
      slot="fixed"
    >
      <IonFabButton disabled={disabled} onClick={onAddToCart}>
        <IonIcon icon={bagAdd} />
      </IonFabButton>
    </IonFab>
  );
};

export default CartAddButton;
