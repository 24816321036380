import { IonLabel } from "@ionic/react";
import { FC } from "react";
import { useTranslation } from "react-i18next";

interface StockProps {
  isShowQuantity: boolean;
  quantity: number;
  isUnlimitedQuantity: boolean;
}

const Stock: FC<StockProps> = ({
  isShowQuantity,
  isUnlimitedQuantity,
  quantity,
}) => {
  const { t } = useTranslation();

  const renderProductStock = (): JSX.Element => {
    if (isUnlimitedQuantity || !isShowQuantity) return;
    const isOutOfStock = !isUnlimitedQuantity && !quantity;
    const color = isOutOfStock ? "danger" : "success";
    const label = isOutOfStock
      ? t("OUT_OF_STOCK")
      : quantity.toString().concat(" ", t("IN_STOCK"));
    return (
      <IonLabel className="ion-font-bold" color={color}>
        {label}
      </IonLabel>
    );
  };

  return <div>{renderProductStock()}</div>;
};

export default Stock;
