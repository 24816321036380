import { FC } from "react";
import { OrderAttribute } from "src/interfaces/OrderAttribute";
import AttributeItem from "./AttributeItem";

interface AttributesProps {
  attributes: OrderAttribute[];
}

const Attributes: FC<AttributesProps> = ({ attributes }) => {
  const renderAttributes = (): JSX.Element[] => {
    return attributes.map((attribute) => {
      return <AttributeItem key={attribute.id} attribute={attribute} />;
    });
  };

  if (!attributes.length) return null;

  return <div className="ion-margin-bottom-small">{renderAttributes()}</div>;
};

export default Attributes;
