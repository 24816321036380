import {IonContent} from "@ionic/react";
import React, {FC} from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "src/hooks/useAppSelector";
import getLocalOrDefault from "src/utils/getLocalOrDefault";
import "./DisabledStore.scss";

const DisabledStore: FC = () => {
  const store = useAppSelector(state=> state.storeReducer.store);
  const {t} = useTranslation();

  const storeName = getLocalOrDefault(store.storeName);

  return (
    <IonContent>
      <div className="disabled-store">
        <div className="disabled-store__content">
          <img alt={storeName} src={store.logo} className="logo__image" />
          <h4>{t("PAGES.INACTIVE_STORE.HEADER")}</h4>
          <p>{t("PAGES.INACTIVE_STORE.CONTENT")}</p>
        </div>
      </div>
    </IonContent>
  );
};

export default DisabledStore;
