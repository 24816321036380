import { IonLabel } from "@ionic/react";
import { FC } from "react";

interface CartServiceItemProps {
  bookingTime: string;
  isAvailable: boolean;
}

const BookingTime: FC<CartServiceItemProps> = ({
  bookingTime,
  isAvailable,
}) => {
  const date = new Date(bookingTime);
  const color = isAvailable ? "success" : "danger";
  const Component = isAvailable ? "span" : "del";

  return (
    <IonLabel className="ion-font-bold ion-font-small" color={color}>
      <Component>{date.toLocaleString()}</Component>
    </IonLabel>
  );
};

export default BookingTime;
