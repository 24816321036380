import {
  collection,
  CollectionReference,
  DocumentData,
  onSnapshot,
  query,
  QueryDocumentSnapshot,
  where,
} from "firebase/firestore";
import {WalletTransaction} from "src/interfaces/WalletTransaction";
import {store} from "src/store";
import {getStoreDocRef} from "./store";
import {setStoreWalletTransactions} from "src/slices/storeWalletTransactions";
import { sortFirestoreDocuments } from "src/utils/sortFirestoreDocuments";

const STORE_WALLET_TRANSACTIONS_COLLECTION_NAME = "wallet-transactions";

const getStoreWalletTransactionsCollection = (): CollectionReference<DocumentData> => {
  return collection(getStoreDocRef(), STORE_WALLET_TRANSACTIONS_COLLECTION_NAME);
};

const toFirestore = (transaction: WalletTransaction): DocumentData => {
  delete transaction.id;
  return transaction;
};

const fromFirestore = (snapshot: QueryDocumentSnapshot): WalletTransaction => {
  const data = snapshot.data();
  const id = snapshot.id;
  const createdAt = data.createdAt || new Date().toISOString();
  return {id, createdAt, ...data} as WalletTransaction;
};

const converter = {
  toFirestore,
  fromFirestore,
};

export const subscribeToStoreWalletTransactionsFB = async (): Promise<void> => {
  try {
    const collection = getStoreWalletTransactionsCollection();
    const customerId = store.getState().customerReducer.customer!.id;
    const walletTransactionsQuery = query(collection, where("customerId", "==", customerId)).withConverter(converter);
    onSnapshot(
      walletTransactionsQuery,
      (snapshot) => {
        const transactions = sortFirestoreDocuments(snapshot.docs.map((doc) => doc.data()));
        store.dispatch(setStoreWalletTransactions(transactions));
      },
      () => {
        store.dispatch(setStoreWalletTransactions([]));
      },
    );
  } catch {
    store.dispatch(setStoreWalletTransactions([]));
  }
};
