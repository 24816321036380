import React, { FC } from "react";
import {
    IonToolbar,
    IonButtons,
    IonMenuButton,
    IonButton,
    IonIcon,
    IonSearchbar,
    IonHeader
} from "@ionic/react";
import { swapVertical, list } from "ionicons/icons";
import "./ProductsToolbar.scss";
import { useTranslation } from "react-i18next";
import {CartButton} from "src/components"


interface ProductsToolbarProps {
    searchTerm: string;
    onChangeSearch: (e: CustomEvent) => void;
    onOpenSortActionSheet: () => void;
}

const ProductsToolbar: FC<ProductsToolbarProps> = ({ onChangeSearch, onOpenSortActionSheet, searchTerm }) => {
    const { t } = useTranslation();

    return (
        <IonHeader>
            <IonToolbar color="primary">
                <IonButtons slot="start">
                    <IonMenuButton />
                </IonButtons>
                <IonSearchbar
                    className="productsToolbar__searchbar"
                    placeholder={t("PRODUCTS.SEARCH_PLACEHOLDER")}
                    value={searchTerm}
                    animated
                    onIonChange={onChangeSearch}
                    debounce={0}
                    type="search"
                    inputMode="search"
                    enterkeyhint="search"
                />
                <IonButtons slot="end">
                <CartButton />
                    <IonMenuButton menu="categories">
                        <IonButton>
                            <IonIcon icon={list} slot="icon-only" />
                        </IonButton>
                    </IonMenuButton>
                    <IonButton onClick={onOpenSortActionSheet}>
                        <IonIcon icon={swapVertical} slot="icon-only" />
                    </IonButton>
                </IonButtons>
            </IonToolbar>
        </IonHeader>
    );
};

export default ProductsToolbar;
