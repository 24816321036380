import {IonButton, IonContent, IonFooter, IonHeader, IonList, IonMenu, IonTitle, IonToolbar} from "@ionic/react";
import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import { useAppSelector } from "src/hooks/useAppSelector";
import {CategoriesFilterMenuItem} from "..";
import classes from "./CategoriesFilterMenu.module.scss";

interface CategoriesFilterMenuProps {
  selectedCategories: string[];
  onToggleSelectCategory: (id: string) => void;
  onClear: () => void;
}

const CategoriesFilterMenu: FC<CategoriesFilterMenuProps> = ({selectedCategories, onToggleSelectCategory, onClear}) => {
  const {t} = useTranslation();

  const {categories} = useAppSelector((state) => state.categoriesReducer);

  const baseCategories = categories.filter(({parentId}) => !parentId);

  const renderCategories = (): JSX.Element[] => {
    return baseCategories.map((category) => {
      return (
        <CategoriesFilterMenuItem
          key={category.id}
          selectedCategories={selectedCategories}
          category={category}
          onToggleSelectCategory={onToggleSelectCategory}
          padding={0}
        />
      );
    });
  };

  return (
    <IonMenu side="end" menuId="categories" contentId="products-page">
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>{t("PRODUCTS.FILTER_MENU_CATEGOREIS_TITLE")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList className={classes.list} lines="none">
          {renderCategories()}
        </IonList>
      </IonContent>
      <IonFooter className={classes.footer}>
        <IonButton size="small" fill="outline" onClick={onClear}>
          {t("PRODUCTS.FILTER_MENU_CLEAR_BUTTON")}
        </IonButton>
      </IonFooter>
    </IonMenu>
  );
};

export default CategoriesFilterMenu;
