import {Capacitor} from "@capacitor/core";

export default function hasCustomDomain(): boolean {
  const originName = window.location.origin;
  const storefrontDomain = process.env.REACT_APP_IONIC_APP_BASE_URL!;
  const localhostDomain = "http://localhost";
  return (
    !originName.startsWith(storefrontDomain) &&
    (!originName.startsWith(localhostDomain) || Capacitor.isNativePlatform())
  );
}
