import React, {FC} from "react";
import classes from "./Divider.module.scss";

type DividerOrientation = "horizontal" | "vertical";

interface DividerProps {
  orientation?: DividerOrientation;
  my?: number;
}

const Divider: FC<DividerProps> = ({orientation = "horizontal", my = 0}) => {
  return <div className={`${classes.root} ${classes[orientation]}`} style={{marginTop: my, marginBottom: my}} />;
};

export default Divider;
