import {
  DatetimeChangeEventDetail,
  IonBadge,
  IonCard,
  IonCardContent,
  IonCol,
  IonGrid,
  IonLabel,
  IonRow,
} from "@ionic/react";
import { FC, Fragment, useMemo } from "react";
import { useTranslation } from "react-i18next";
import CartAddButton from "src/components/CartAddButton";
import DraftEditorPreview from "src/components/DraftEditorPreview";
import Gallery from "src/components/Gallery";
import Price from "src/components/Price";
import ValueUnit from "src/components/ValueUnit";
import { CartAddon } from "src/interfaces/CartAddon";
import { CartRequirement } from "src/interfaces/CartRequirement";
import { Category } from "src/interfaces/Category";
import { Service } from "src/interfaces/Service";
import { ServicesWorker } from "src/interfaces/ServicesWorker";
import getLocalOrDefault from "src/utils/getLocalOrDefault";
import getLocalRawDrafOrDefault from "src/utils/getLocalRawDraftOrDefault";
import Addons from "./Addons";
import DatePicker from "./DatePicker";
import { getMinDate, validateService } from "./helpers";
import Requirements from "./Requirements";
import Workers from "./Workers";
import { IonDatetimeCustomEvent } from "@ionic/core";

interface ServiceDetailsProps {
  service: Service;
  name: string;
  category: Category;
  workers: ServicesWorker[];
  isPickerOpen: boolean;
  selectedDate: string;
  selectedAddons: CartAddon[];
  requirements: CartRequirement[];
  onSubmitService: () => void;
  onChangeRequirement: (id: string, value: string) => void;
  onSelectDate: (e: IonDatetimeCustomEvent<DatetimeChangeEventDetail>) => void;
  onClosePicker: () => void;
  onOpenPicker: () => void;
  onClearAddons: () => void;
  onSelectSingleAddon: (addonId: string, prevId: string) => void;
  onSelectMultipleAddon: (addonId: string, checked: boolean) => void;
}

const ServiceDetails: FC<ServiceDetailsProps> = ({
  service,
  name,
  category,
  workers,
  isPickerOpen,
  selectedDate,
  selectedAddons,
  requirements,
  onSubmitService,
  onChangeRequirement,
  onClearAddons,
  onSelectDate,
  onClosePicker,
  onOpenPicker,
  onSelectMultipleAddon,
  onSelectSingleAddon,
}) => {
  const { t } = useTranslation();

  const minDate = useMemo(
    () => getMinDate(service.minimumBookingDay || 0),
    [service]
  );

  const disabled = !validateService(
    service,
    selectedDate,
    selectedAddons,
    workers,
    requirements
  );

  const renderFees = (): JSX.Element => {
    if (!service.fees || service.isHideFees) return;
    return (
      <div>
        <IonLabel className="ion-font-bold ion-font-larger">
          <Price price={service.fees} />
        </IonLabel>
      </div>
    );
  };

  const renderCategory = (): JSX.Element => {
    if (!category) return;
    return (
      <IonBadge className="ion-margin-top">
        {getLocalOrDefault(category.name)}
      </IonBadge>
    );
  };

  const renderLocationType = (): JSX.Element => {
    if (service.isHideLocationType) return;
    return (
      <IonLabel color="medium" className="ion-display-block ion-font-bold">
        {t(service.locationType)}
      </IonLabel>
    );
  };

  const renderAddons = (): JSX.Element => {
    if (!service.addons.length) return;
    return (
      <Addons
        addons={service.addons}
        selectedAddons={selectedAddons}
        onSelectMultipleAddon={onSelectMultipleAddon}
        onSelectSingleAddon={onSelectSingleAddon}
        onClearAddons={onClearAddons}
      />
    );
  };
  const renderRequirements = (): JSX.Element => {
    if (!service.requiredFields.length) return;
    return (
      <Requirements
        fields={service.requiredFields}
        requirements={requirements}
        onChangeRequirement={onChangeRequirement}
      />
    );
  };

  return (
    <Fragment>
      <Gallery gallery={service.gallery} name={name} />
      <CartAddButton onAddToCart={onSubmitService} disabled={disabled} />
      <IonCard color="light">
        <IonCardContent>
          <IonGrid className="ion-no-padding">
            <IonRow>
              <IonCol>
                <IonLabel className="ion-font-xlarge">{name}</IonLabel>
                {renderLocationType()}
              </IonCol>
              <IonCol className="ion-text-end">
                {renderFees()}
                <IonLabel className="ion-font-bold">
                  <ValueUnit
                    unit={t(service.durationUnit)}
                    value={service.duration}
                  />
                </IonLabel>
              </IonCol>
            </IonRow>
          </IonGrid>
          {renderCategory()}
          <DraftEditorPreview
            state={getLocalRawDrafOrDefault(service.description)}
          />
          <Workers workers={workers} />
          <div className="ion-margin-bottom">
            <DatePicker
              onSelectDate={onSelectDate}
              isOpen={isPickerOpen}
              onClose={onClosePicker}
              onOpen={onOpenPicker}
              date={selectedDate}
              min={minDate}
              label={t("SERVICE_DETAILS.CHOOSE_DATE")}
            />
          </div>
          {renderAddons()}
          {renderRequirements()}
        </IonCardContent>
      </IonCard>
    </Fragment>
  );
};

export default ServiceDetails;
