import { IonLabel } from "@ionic/react";
import { FC, Fragment } from "react";
import { CartRequirementsGroup } from "src/interfaces/CartRequirementsGroup";
import { RequiredField } from "src/interfaces/Requirement";
import RequirementItem from "./RequirementItem";

interface RequirementsGroupItemProps {
  group: CartRequirementsGroup;
  fields: RequiredField[];
}

const RequirementsGroupItem: FC<RequirementsGroupItemProps> = ({
  group,
  fields,
}) => {
  const renderRequirements = (): JSX.Element[] => {
    return group.requirements.map(({ id, requirementId, value }) => {
      const field = fields.find(({ id }) => id === requirementId);
      if (!field) return null;
      return <RequirementItem key={id} field={field} value={value} />;
    });
  };
  return (
    <Fragment>
      {renderRequirements()}
      <IonLabel className="ion-font-light ion-text-end ion-font-large">
        &times;{group.quantity}
      </IonLabel>
    </Fragment>
  );
};

export default RequirementsGroupItem;
