import { store } from "src/store";

export const generateFawryIFrameLink = (): string => {
    const { fawryMerchantCode } = store.getState().storeReducer.store;
    const customerId = store.getState().customerReducer.customer.id;
    const returnUrl = "https://vendor.oppa.app./card-success"
    return "https://www.atfawry.com/atfawry/plugin/card-token?accNo=".concat(
        fawryMerchantCode,
        "&customerProfileId=",
        customerId,
        "&returnUrl=",
        returnUrl);
}