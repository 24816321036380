import { IonItem, IonLabel } from "@ionic/react";
import { FC, memo } from "react";
import { OrderProduct } from "src/interfaces/OrderProduct";
import ItemThumbnail from "src/components/ItemThumbnail";
import getLocalOrDefault from "src/utils/getLocalOrDefault";
import Price from "src/components/Price";
import DetailsAccordion from "src/components/detailsAccordion";
import ProductDetails from "./ProductDetails";

interface ProductItemProps {
  product: OrderProduct;
}

const ProductItem: FC<ProductItemProps> = ({ product }) => {
  const name = getLocalOrDefault(product.productName);

  const isContainsDetails = Boolean(
    product.addons.length ||
      product.addonsGroups.length ||
      product.requirementsGroups.length ||
      product.customVariations.length ||
      product.attributes.length ||
      product.serialsNumbers.length
  );

  const renderDetailsAccordion = (): JSX.Element => {
    if (!isContainsDetails) return;
    return (
      <DetailsAccordion>
        <ProductDetails product={product} />
      </DetailsAccordion>
    );
  };

  return (
    <IonItem lines="full">
      <div className="ion-wrap ion-full-width ion-display-flex ion-padding-vertical-xsmall">
        <div className="ion-full-width ion-display-flex ion-align-items-center ion-margin-bottom-small">
          <ItemThumbnail src={product.image} alt={name} />
          <div className="ion-flex-grow ion-overflow-hidden">
            <IonLabel>{name}</IonLabel>
            <IonLabel className="ion-font-bold">
              <Price
                price={product.price}
                hasSalePrice={product.hasSalePrice}
                salePrice={product.salePrice}
              />
            </IonLabel>
          </div>
          <div className="ion-text-end">
            <IonLabel>&times; {product.quantity}</IonLabel>
          </div>
        </div>
        {renderDetailsAccordion()}
      </div>
    </IonItem>
  );
};

export default memo(ProductItem);
