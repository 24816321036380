import {IonButton, IonIcon} from "@ionic/react";
import {logoFacebook} from "ionicons/icons";
import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import useAuth from "src/hooks/useAuth";
import useToast from "src/hooks/useToast";
import classes from "./FacebookSignInButton.module.scss";

const FacebookSignInButton: FC = () => {
  const {t} = useTranslation();
  const {signInWithFacebook} = useAuth();
  const launchToast = useToast();

  const handleSigninWithFacebook = async (): Promise<void> => {
    try {
      await signInWithFacebook();
    } catch {
      launchToast(t("FAILED_TO_SIGNIN"), "danger");
    }
  };

  return (
    <div className={classes.root}>
      <IonButton className={classes.button} onClick={handleSigninWithFacebook}>
        <IonIcon icon={logoFacebook} slot="start" />
        {t("SIGN_IN_WITH_FACEBOOK")}
      </IonButton>
    </div>
  );
};

export default FacebookSignInButton;
