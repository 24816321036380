import React, {FC} from "react";
import PropTypes from "prop-types";
import "./BookingItem.scss";
import {useTranslation} from "react-i18next";
import {
  IonCol,
  IonRow,
  IonCard,
  IonCardTitle,
  IonLabel,
  IonGrid,
  IonItem,
  IonCardSubtitle,
  IonBadge,
} from "@ionic/react";
import {getBookingStatusColor} from "src/utils";
import {Booking} from "src/interfaces/Booking";
import getLocalOrDefault from "src/utils/getLocalOrDefault";
import getPagePath from "src/utils/getStoreIdCustomPath";

interface BookingItemProps {
  booking: Booking;
}

const BookingItem: FC<BookingItemProps> = ({booking}) => {
  const {t} = useTranslation();
  const bookingDate = new Date(booking.createdAt || Date.now()).toLocaleString();
  const bookingTime = new Date(booking.bookingTime).toLocaleString();
  const bookingLink = getPagePath(`booking/${booking.id}`);

  return (
    <IonItem detail={false}>
      <IonCard className="bookingItem__card" routerLink={bookingLink}>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonCardTitle className="bookingItem__serviceName">
                {getLocalOrDefault(booking.service.name)}
              </IonCardTitle>
              <IonCardSubtitle className="bookingItem__bookingDate">{bookingDate}</IonCardSubtitle>
              <IonBadge color={getBookingStatusColor(booking.bookingStatus)}>{t(booking.bookingStatus)}</IonBadge>
            </IonCol>
            <IonCol className="bookingItem__detailsContainer">
              <IonLabel className="bookingItem__bookingTime">{bookingTime}</IonLabel>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCard>
    </IonItem>
  );
};

BookingItem.propTypes = {
  booking: PropTypes.any.isRequired,
};

export default React.memo(BookingItem);
