import { ChangeEvent, FC } from "react";
import {
  InputChangeEventDetail,
  TextareaChangeEventDetail,
  IonCard,
  IonCardContent,
  IonCol,
  IonGrid,
  IonRow,
} from "@ionic/react";
import { OrderUser, OrderUserKey } from "src/interfaces/OrderUser";
import { useTranslation } from "react-i18next";
import { IonInputCustomEvent, IonTextareaCustomEvent } from "@ionic/core";
import Address from "./Address";
import Notes from "./Notes";
import FloatingLabelInput from "src/components/FloatingLabelInput";
import { validateEmail, validateRequiredText } from "src/utils/validation";
import { CollectMethod } from "src/enums/CollectMethod";

interface UserFormProps {
  notes: string;
  user: OrderUser;
  isError: boolean;
  attachments: File[];
  collectMethod: CollectMethod;
  onChangeLocation: () => void;
  onRemoveAttachment: (index: number) => void;
  onAddAttachments: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeNotes: (e: IonTextareaCustomEvent<TextareaChangeEventDetail>) => void;
  onChangeOrderUser: (
    e:
      | IonInputCustomEvent<InputChangeEventDetail>
      | IonTextareaCustomEvent<TextareaChangeEventDetail>
  ) => void;
}

const UserForm: FC<UserFormProps> = ({
  user,
  notes,
  isError,
  attachments,
  collectMethod,
  onChangeNotes,
  onChangeLocation,
  onAddAttachments,
  onChangeOrderUser,
  onRemoveAttachment,
}) => {
  const { t } = useTranslation();

  const hasError = (isValid: boolean): boolean => {
    return isError && !isValid;
  };

  return (
    <IonCol size="12">
      <IonCard className="ion-no-margin">
        <IonCardContent>
          <IonGrid>
            <IonRow>
              <IonCol
                size="12"
                sizeSm="6"
                sizeLg="3"
                className="ion-padding-xsmall"
              >
                <FloatingLabelInput
                  placeholder={t("FIRST_NAME")}
                  value={user.firstname}
                  name={OrderUserKey.firstname}
                  onIonChange={onChangeOrderUser}
                  error={hasError(validateRequiredText(user.firstname))}
                  helperText={t("INVALID_FIELD")}
                />
              </IonCol>
              <IonCol
                size="12"
                sizeSm="6"
                sizeLg="3"
                className="ion-padding-xsmall"
              >
                <FloatingLabelInput
                  placeholder={t("LAST_NAME")}
                  value={user.lastname}
                  name={OrderUserKey.lastname}
                  onIonChange={onChangeOrderUser}
                  error={hasError(validateRequiredText(user.lastname))}
                  helperText={t("INVALID_FIELD")}
                />
              </IonCol>
              <IonCol
                size="12"
                sizeSm="6"
                sizeLg="3"
                className="ion-padding-xsmall"
              >
                <FloatingLabelInput
                  placeholder={t("EMAIL")}
                  value={user.email}
                  name={OrderUserKey.email}
                  onIonChange={onChangeOrderUser}
                  type="email"
                  inputmode="email"
                  error={hasError(validateEmail(user.email))}
                  helperText={t("INVALID_FIELD")}
                />
              </IonCol>
              <IonCol
                size="12"
                sizeSm="6"
                sizeLg="3"
                className="ion-padding-xsmall"
              >
                <FloatingLabelInput
                  placeholder={t("PHONE")}
                  value={user.phone}
                  name={OrderUserKey.phone}
                  onIonChange={onChangeOrderUser}
                  type="tel"
                  inputmode="tel"
                  error={hasError(validateRequiredText(user.phone))}
                  helperText={t("INVALID_FIELD")}
                />
              </IonCol>
              <Address
                isError={isError}
                address={user.address}
                location={user.location}
                collectMethod={collectMethod}
                onChangeAddress={onChangeOrderUser}
                onChangeLocation={onChangeLocation}
              />
              <Notes
                attachments={attachments}
                notes={notes}
                onAddAttachments={onAddAttachments}
                onChangeNotes={onChangeNotes}
                onRemoveAttachment={onRemoveAttachment}
              />
            </IonRow>
          </IonGrid>
        </IonCardContent>
      </IonCard>
    </IonCol>
  );
};

export default UserForm;
