import { IonLabel } from "@ionic/react";
import { FC } from "react";
import getLocalOrDefault from "src/utils/getLocalOrDefault";
import { OrderAttribute } from "src/interfaces/OrderAttribute";

interface AttributeItemProps {
  attribute: OrderAttribute;
}

const AttributeItem: FC<AttributeItemProps> = ({ attribute }) => {
  return (
    <div className="ion-display-flex ion-justify-content-between">
      <IonLabel>{getLocalOrDefault(attribute.name)}</IonLabel>
      <IonLabel className="ion-font-bold ion-overflow-visible">
        {getLocalOrDefault(attribute.value.name)}
      </IonLabel>
    </div>
  );
};

export default AttributeItem;
