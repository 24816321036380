import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {createRequestFB, syncSavedRequestsFB} from "src/firebase/formsRequests";
import {uploadRequestFileFB} from "src/firebase/storage";
import {FormRequest} from "src/interfaces/FormRequest";
import { FormRequestUser } from "src/interfaces/FormRequestUser";
import {AppThunk} from "src/store";

interface RequestsState {
  requests: FormRequest[];
  isLoaded: boolean;
}

const initialState: RequestsState = {
  requests: [],
  isLoaded: false,
};

export const bookingsSlice = createSlice({
  name: "REQUESTS",
  initialState,
  reducers: {
    setRequests: (state, action: PayloadAction<FormRequest[]>) => {
      state.requests = action.payload;
      state.isLoaded = true;
    },
  },
});

export const {setRequests} = bookingsSlice.actions;

export const createRequest =
  (request: FormRequest): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const customer = getState().customerReducer.customer;
    if (customer) {
      const phone = `+${customer.country.callingCodes[0]}${customer.phone}`;
      const requestUser: FormRequestUser = {
        id: customer.id!,
        firstName: customer.firstName,
        lastName: customer.lastName,
        email: customer.email,
        phone,
      };
      request.isGuest = false;
      request.user = requestUser;
    }
    await createRequestFB(request);
  };

export const syncSavedRequests = (): AppThunk => async (): Promise<void> => {
  await syncSavedRequestsFB();
};

export const uploadRequestFile =
  (file: File): AppThunk =>
  async (): Promise<string> => {
    return await uploadRequestFileFB(file);
  };

export default bookingsSlice.reducer;
