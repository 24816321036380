import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import { Service } from "src/interfaces/Service";
import {subscribeToServicesFB} from "src/firebase/services";
import {AppThunk} from "src/store";

interface ServicesState {
  services: Service[];
  isLoaded: boolean;
}

const initialState: ServicesState = {
  services: [],
  isLoaded: false,
};

export const servicesSlice = createSlice({
  name: "SERVICES",
  initialState,
  reducers: {
    setServices: (state: ServicesState, action: PayloadAction<Service[]>) => {
      const services = action.payload;
      state.services = services;
      state.isLoaded = true;
    },
  },
});

export const {setServices} = servicesSlice.actions;

export const subscribeToServices = (): AppThunk => async (dispatch, getState) => {
  const isLoaded = getState().servicesReducer.isLoaded;
  if (isLoaded) return;
  subscribeToServicesFB();
};

export default servicesSlice.reducer;
