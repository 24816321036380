import { FC } from "react";
import { View, Image, Text } from "@react-pdf/renderer";
import { Order } from "src/interfaces/Order";
import styles from "./styles";
interface ReceiptHeaderProps {
  logo: string;
  name: string;
  order: Order;
}

const ReceiptHeader: FC<ReceiptHeaderProps> = ({ logo, name, order }) => {
  return (
    <View style={styles.header}>
      <Image source={logo} style={styles.logo} />
      <Text style={styles.heading}>{name}</Text>
      <Text style={styles.heading}>Invoice #: {order.invoiceId}</Text>
      <Text style={styles.heading}>
        {new Date(order.createdAt!).toLocaleString()}
      </Text>
    </View>
  );
};

ReceiptHeader.propTypes = {};
export default ReceiptHeader;
