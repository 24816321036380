import { IonModal, IonContent } from "@ionic/react";
import { FC } from "react";
import ModalHeader from "src/components/ModalHeader";
import { useAppSelector } from "src/hooks/useAppSelector";
import getLocalOrDefault from "src/utils/getLocalOrDefault";

interface PaymobModalProps {
  link: string;
  onClose: () => void;
}

const PaymobModal: FC<PaymobModalProps> = ({ onClose, link }) => {
  const { storeName } = useAppSelector((store) => store.storeReducer.store);

  return (
    <IonModal isOpen={!!link} onDidDismiss={onClose}>
      <ModalHeader onClose={onClose} hideSubmit />
      <IonContent>
        <iframe
          width="100%"
          height="100%"
          className="ion-no-border"
          src={link}
          title={getLocalOrDefault(storeName)}
        />
      </IonContent>
    </IonModal>
  );
};

export default PaymobModal;
