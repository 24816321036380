import {
  collection,
  CollectionReference,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { Booking } from "src/interfaces/Booking";
import { setBookings } from "src/slices/bookings";
import { store } from "src/store";
import { getFirestoreConverter } from "./converter";
import { getStoreDocRef } from "./store";

const COLLECTION = "bookings";

const getCollection = (): CollectionReference<Booking> => {
  return collection(getStoreDocRef(), COLLECTION).withConverter(getFirestoreConverter<Booking>());
};

export function subscribeToBookingsFB(): void {
  const id = store.getState().customerReducer.customer.id;
  onSnapshot(query(getCollection(), where("customerId", "==", id)), (snapshot) => {
    const bookings = snapshot.docs.map((doc) => doc.data());
    store.dispatch(setBookings(bookings));
  });
}
