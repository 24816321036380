import { FC, SyntheticEvent } from "react";
import { useAppSelector } from "src/hooks/useAppSelector";

interface ItemThumbnailProps {
  src: string;
  alt: string;
}

const ItemThumbnail: FC<ItemThumbnailProps> = ({ src, alt }) => {
  const { logo } = useAppSelector((state) => state.storeReducer.store);

  const handleImageError  =  (e: SyntheticEvent< HTMLImageElement>): void=>{
    const target = e.target as HTMLImageElement;
    target.src = logo;
  }

  return (
    <img
      onError={handleImageError}
      src={src || logo}
      alt={alt}
      width={56}
      height={56}
      className="ion-margin-end ion-object-fit-cover ion-font-xsmall"
    />
  );
};

export default ItemThumbnail;
