import {collection, CollectionReference, DocumentData, getDocs, getFirestore} from "firebase/firestore";
import {TranslationMap} from "src/interfaces/Translation";
import { Unit } from "src/interfaces/Unit";

const UNITS_COLLECTION_NAME = "units";

const getUnitsCollection = (): CollectionReference<DocumentData> => {
  const db = getFirestore();
  return collection(db, UNITS_COLLECTION_NAME);
};

export const getUnitsFB = async (): Promise<Unit[]> => {
  try {
    const collection = getUnitsCollection();
    const unitsDocs = await getDocs(collection);
    return unitsDocs.docs.map((doc) => {
      const name: TranslationMap = {ar: doc.data().ar, en: doc.data().en};
      return {...doc.data(), id: doc.id, name} as Unit;
    });
  } catch {
    return [];
  }
};
