import { FC, Fragment } from "react";
import Addons from "./Addons";
import { CartService } from "src/interfaces/CartService";
import { Service } from "src/interfaces/Service";
import Requirements from "./Requirements";

interface ServiceDetailsProps {
  cartService: CartService;
  service: Service;
}

const ServiceDetails: FC<ServiceDetailsProps> = ({ cartService, service }) => {
  const renderAddons = (): JSX.Element => {
    if (!cartService.addons.length) return;
    return (
      <Addons addons={service.addons} cartServiceAddons={cartService.addons} />
    );
  };
  const renderRequirements = (): JSX.Element => {
    if (!cartService.requirements.length) return;
    return (
      <Requirements
        requirements={cartService.requirements}
        fields={service.requiredFields}
      />
    );
  };

  return (
    <Fragment>
      {renderAddons()}
      {renderRequirements()}
    </Fragment>
  );
};

export default ServiceDetails;
