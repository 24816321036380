import { IonLabel } from "@ionic/react";
import { FC, Fragment } from "react";
import getLocalOrDefault from "src/utils/getLocalOrDefault";
import Price from "src/components/Price";
import { CustomVariation } from "src/interfaces/CustomVariation";

interface CustomVariationItemProps {
  variation: CustomVariation;
  value: number;
}

const CustomVariationItem: FC<CustomVariationItemProps> = ({
  variation,
  value,
}) => {
  const renderPercentage = (): JSX.Element => {
    if (!variation.isPercentage) return;
    return <Fragment>&#x25;</Fragment>;
  };
  return (
    <div className="ion-display-flex ion-justify-content-between">
      <IonLabel>{getLocalOrDefault(variation.name)}</IonLabel>
      <div className="ion-display-flex">
        <IonLabel>
          ( {value} {renderPercentage()} )
        </IonLabel>
        &nbsp;
        <IonLabel className="ion-font-bold">
          <Price price={variation.price} />
        </IonLabel>
      </div>
    </div>
  );
};

export default CustomVariationItem;
