import { IonProgressBar, IonSpinner } from "@ionic/react";
import { FC } from "react";

const SplashScreen: FC = () => {
  return (
    <div>
      <IonProgressBar type="indeterminate" />
      <IonSpinner color="primary" className="ion-position-center" />
    </div>
  );
};

export default SplashScreen;
