
import storeReducer from "src/slices/store";
import productsReducer from "src/slices/products";
import servicesReducer from "src/slices/services";
import cmsReducer from "src/slices/cms";
import categoriesReducer from "src/slices/categories";
import userReducer from "src/slices/user";
import unitsReducer from "src/slices/units";
import servicesCategoriesReducer from "src/slices/servicesCategories";
import notificationsReducer from "src/slices/notifications";
import citiesReducer from "src/slices/cities";
import areasReducer from "src/slices/areas";
import ordersReducer from "src/slices/orders";
import bookingsReducer from "src/slices/bookings";
import requestsReducer from "src/slices/formsRequests";
import attributesReducer from "src/slices/attributes";
import addonsGroupsReducer from "src/slices/addonsGroups";
import addonsReducer from "src/slices/addons";
import customerReducer from "src/slices/customer";
import storeWalletTransactions from "src/slices/storeWalletTransactions";
import attendancesReducer from "src/slices/attendances";
import servicesWorkersReducer from "src/slices/servicesWorkers";
import servicesPackagesReducer from "src/slices/servicesPackages";
import formsReducer from "src/slices/forms";
import cartReducer from "src/slices/cart";

export const reducer = {
    storeReducer,
    productsReducer,
    servicesReducer,
    cmsReducer,
    categoriesReducer,
    userReducer,
    unitsReducer,
    servicesCategoriesReducer,
    notificationsReducer,
    citiesReducer,
    areasReducer,
    ordersReducer,
    bookingsReducer,
    requestsReducer,
    attributesReducer,
    addonsGroupsReducer,
    addonsReducer,
    customerReducer,
    attendancesReducer,
    storeWalletTransactions,
    servicesWorkersReducer,
    servicesPackagesReducer,
    formsReducer,
    cartReducer
};