
export enum NotificationType {
  home = "HOME",
  products = "PRODUCTS",
  categories = "CATEGORIES",
  services = "SERVICES",
  serviceCategories = "SERVICE_CATEGORIES",
  product = "PRODUCT",
  category = "CATEGORY",
  serviceCategory = "SERVICE_CATEGORY",
  service = "SERVICE",
  notification = "NOTIFICATION",
  order = "ORDER"
}
