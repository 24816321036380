import classes from "./styles.module.scss";
import { FC, Fragment, MouseEvent } from "react";
import {
  IonCardTitle,
  IonCard,
  IonCardHeader,
  IonImg,
  IonNote,
  IonButtons,
  IonButton,
  IonIcon,
} from "@ionic/react";
import { useAppSelector } from "src/hooks/useAppSelector";
import { Product } from "src/interfaces/Product";
import ProductPrice from "../ProductPrice";
import ProductOutOfStock from "../ProductOutOfStock";
import { useTranslation } from "react-i18next";
import CategoryBadge from "../CategoryBadge";
import getLocalOrDefault from "src/utils/getLocalOrDefault";
import { CMSListItemOptions, CMSItemShape } from "src/interfaces/CMS";
import getPagePath from "src/utils/getStoreIdCustomPath";
import { bagAddOutline } from "ionicons/icons";
import { CartProduct } from "src/interfaces/CartProduct";
import { uuidv4 } from "src/utils/uuidv4";
import { addProductToCart } from "src/utils/cart";
import useToast from "src/hooks/useToast";
import { Haptics } from "@capacitor/haptics";

interface ProductViewProps {
  product: Product;
  isShowCategories?: boolean;
  options?: CMSListItemOptions;
}

const ProductView: FC<ProductViewProps> = ({
  product,
  isShowCategories,
  options = {
    isImageOnly: false,
    height: 200,
    shape: CMSItemShape.square,
    isResponsive: false,
  },
}) => {
  const { t } = useTranslation();
  const launchToast = useToast();
  const { logo } = useAppSelector((state) => state.storeReducer.store);
  const categories = useAppSelector(
    (state) => state.categoriesReducer.categories
  );

  const isCircleShape = options.shape === CMSItemShape.circle;
  const cardStyle = { borderRadius: isCircleShape && "50%" };
  const height = options.isResponsive
    ? (options.height * document.documentElement.clientHeight) / 100 - 64
    : options.height;
  const imageStyle = { height };
  const isOutOfStock = !product.isUnlimitedQuantity && !product.quantity;
  const productLink = getPagePath(`product/${product.id}`);

  const image = product.gallery.find(({ isFeatured }) => isFeatured)?.src;

  const handleImageError = (e: CustomEvent): void => {
    const target = e.target as HTMLIonImgElement;
    target.src = logo;
  };

  const handleAddToCart = async (
    e: MouseEvent<HTMLIonButtonElement>
  ): Promise<void> => {
    e.stopPropagation();
    try {
      const cartProduct: CartProduct = {
        id: uuidv4(),
        addons: [],
        addonsGroups: [],
        customVariations: [],
        name: product.name,
        productId: product.id,
        quantity: 1,
        requirements: [],
        variationId: "",
      };
      await addProductToCart(cartProduct);
      launchToast(t("DONE"), "success");
      Haptics.vibrate();
    } catch {
      launchToast(t("FAILED"), "danger");
    }
  };

  const renderCategoriesBadges = (): (JSX.Element | null)[] | undefined => {
    if (!isShowCategories) return;
    return product.categories.map((categoryId) => {
      const category = categories.find(
        (category) => category.id === categoryId
      );
      if (!category) return null;
      return (
        <CategoryBadge
          key={categoryId}
          category={getLocalOrDefault(category.name)}
        />
      );
    });
  };

  const renderProductStock = (): JSX.Element | undefined => {
    if (isOutOfStock) return <ProductOutOfStock />;
    if (!product.isShowQuantity || product.isUnlimitedQuantity) return;
    return (
      <IonNote>
        {product.quantity} {t("IN_STOCK")}
      </IonNote>
    );
  };

  const renderAddToCartButton = (): JSX.Element | undefined => {
    if (
      product.attributes.length ||
      product.requiredFields.length ||
      product.customVariations.length ||
      product.hasMaxQuantityPerOrder ||
      isOutOfStock
    )
      return;
    return (
      <IonButtons className={classes.actions}>
        <IonButton routerLink="#" color="dark" onClick={handleAddToCart}>
          <IonIcon icon={bagAddOutline} slot="icon-only" />
        </IonButton>
      </IonButtons>
    );
  };

  const renderProductDetails = (
    shape: CMSItemShape
  ): JSX.Element | undefined => {
    if (shape !== options.shape || options.isImageOnly) return;
    return (
      <Fragment>
        <IonCardHeader className={classes.header}>
          {renderCategoriesBadges()}
          <IonCardTitle className={classes.title}>
            {getLocalOrDefault(product.name)}
          </IonCardTitle>
          <ProductPrice
            price={product.price}
            salePrice={product.salePrice}
            hasSalePrice={product.hasSalePrice}
          />
          {renderProductStock()}
        </IonCardHeader>
        {renderAddToCartButton()}
      </Fragment>
    );
  };

  return (
    <div className={classes.root}>
      <IonCard
        className={classes.card}
        routerLink={productLink}
        style={cardStyle}
      >
        <div className={classes.container}>
          <IonImg
            onIonError={handleImageError}
            style={imageStyle}
            className={classes.image}
            src={image}
          />
          {renderProductDetails(CMSItemShape.square)}
        </div>
      </IonCard>
      {renderProductDetails(CMSItemShape.circle)}
    </div>
  );
};

export default ProductView;
