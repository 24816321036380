import {IonBadge, IonProgressBar} from "@ionic/react";
import {applyActionCode} from "firebase/auth";
import {auth} from "src/firebase";
import React, {useState, useEffect, FC} from "react";
import {useTranslation} from "react-i18next";

interface EmailVerifictionProps {
  oobCode: string;
}

const EmailVerifiction: FC<EmailVerifictionProps> = ({oobCode}) => {
  const [emailVerified, setEmailVerified] = useState(false);
  const [codeError, setCodeError] = useState(false);
  const {t} = useTranslation();

  useEffect(() => {
    applyActionCode(auth, oobCode)
      .then(() => {
        setEmailVerified(true);
      })
      .catch(() => {
        setCodeError(true);
      });
  }, [oobCode]);

  return (
    <>
      {emailVerified && (
        <IonBadge className="authBadge" color="success">
          {t("AUTH.EMAIL_VERIFIED_MESSAGE")}
        </IonBadge>
      )}
      {codeError && (
        <IonBadge className="authBadge" color="danger">
          {t("AUTH.INVALID_ACTION_CODE")}
        </IonBadge>
      )}
      {!(emailVerified || codeError) && <IonProgressBar type="indeterminate" />}
    </>
  );
};

export default React.memo(EmailVerifiction);
