import React, {MouseEvent, FC} from "react";
import {IonItem, IonLabel, IonInput, IonButton, IonIcon, IonCard, IonCardContent} from "@ionic/react";
import {locate, save} from "ionicons/icons";
import {useTranslation} from "react-i18next";
import {CountrySelector, InputHelperText, RequirementsGroup} from "src/components";
import PhoneNumberInput from "src/components/PhoneNumberInput/PhoneNumberInput";
import {Country} from "src/interfaces/Country";
import { useAppSelector } from "src/hooks/useAppSelector";
import LanguageSelector from "../LanguageSelector";
import PaymentMethodSelector from "../PaymentMethodSelector";
import {Customer, CustomerKey} from "src/interfaces/Customer";
import classes from "./SettingsForm.module.scss";
import {ThemeSwitch, AddressesList} from "..";
import {validateEmail} from "src/utils/validation";
import {RequirementAttachmentFile} from "src/interfaces/Requirement";

interface SettingsFormProps {
  settings: Customer;
  isError: boolean;
  attachments: RequirementAttachmentFile[];
  onChangeSettings: (e: CustomEvent) => void;
  onChangeCountry: (country: Country) => void;
  onChangeLocation: () => void;
  onSaveSettings: () => void;
  onOpenAddressModal: () => void;
  onRemoveAddress: (label: string) => void;
  onChangeLanguage: (e: CustomEvent) => void;
  onChangeIsDarkTheme: (e: MouseEvent<HTMLIonToggleElement>) => void;
  onChangeRequirement: (id: string, value: string) => void;
  onRemoveAttachmentRequirement: (id: string) => void;
  onChangeAttachmentRequirement: (id: string, file: File) => void;
  onDeleteAccount: () => void;
}

const SettingsForm: FC<SettingsFormProps> = ({
  settings,
  isError,
  attachments,
  onChangeSettings,
  onChangeCountry,
  onChangeLocation,
  onSaveSettings,
  onChangeLanguage,
  onChangeIsDarkTheme,
  onOpenAddressModal,
  onDeleteAccount,
  onRemoveAddress,
  onChangeAttachmentRequirement,
  onChangeRequirement,
  onRemoveAttachmentRequirement,
}) => {
  const {languages, customersRequirements} = useAppSelector((state) => state.storeReducer.store);
  const {t} = useTranslation();

  const requirementsGroups = customersRequirements.filter(({fields}) => fields.length) || [];

  const renderLanguageSelector = (): JSX.Element | undefined => {
    if (languages!.length <= 1) return;
    return <LanguageSelector language={settings.language} onChangeLanguage={onChangeLanguage} />;
  };

  const renderInputError = (name: CustomerKey, message: string): JSX.Element | undefined => {
    if (!isError) return;
    switch (name) {
      case CustomerKey.firstName:
      case CustomerKey.lastName:
      case CustomerKey.phone:
        if (settings[name]) return;
        break;
      case CustomerKey.email:
        if (validateEmail(settings.email)) return;
        break;
      default:
        break;
    }
    return <InputHelperText color="danger" text={message} />;
  };

  const renderRequirementsGroups = (): JSX.Element[] => {
    return requirementsGroups.map((group) => {
      const requirements = settings.requirements.filter(({groupId}) => groupId === group.id);
      return (
        <RequirementsGroup
          group={group}
          key={group.id}
          requirements={requirements}
          attachments={attachments}
          isError={isError}
          onChangeRequirement={onChangeRequirement}
          onChangeAttachmentRequirement={onChangeAttachmentRequirement}
          onRemoveAttachmentRequirement={onRemoveAttachmentRequirement}
        />
      );
    });
  };

  return (
    <IonCard className={classes.root}>
      <IonCardContent>
        <ThemeSwitch isDarkTheme={settings.isDarkTheme} onChangeIsDarkTheme={onChangeIsDarkTheme} />

        {renderLanguageSelector()}

        <IonItem>
          <IonLabel position="floating">{t("FIRST_NAME")}</IonLabel>
          <IonInput
            name={CustomerKey.firstName}
            onIonChange={onChangeSettings}
            value={settings.firstName}
            placeholder={t("FIRST_NAME")}
          />
        </IonItem>
        {renderInputError(CustomerKey.firstName, t("REQUIRED_FIRST_NAME"))}

        <IonItem>
          <IonLabel position="floating">{t("LAST_NAME")}</IonLabel>
          <IonInput
            name={CustomerKey.lastName}
            onIonChange={onChangeSettings}
            value={settings.lastName}
            placeholder={t("LAST_NAME")}
          />
        </IonItem>
        {renderInputError(CustomerKey.lastName, t("REQUIRED_LAST_NAME"))}

        <IonItem>
          <IonLabel position="floating">{t("EMAIL_ADDRESS")}</IonLabel>
          <IonInput
            name={CustomerKey.email}
            onIonChange={onChangeSettings}
            value={settings.email}
            placeholder={t("EMAIL_ADDRESS")}
            inputmode="email"
            type="email"
            inputMode="email"
          />
        </IonItem>
        {renderInputError(CustomerKey.email, t("INVALID_EMAIL_ADDRESS"))}

        <CountrySelector country={settings.country} onChangeCountry={onChangeCountry} />

        <PhoneNumberInput
          code={settings.country.callingCodes[0]}
          phone={settings.phone}
          onChangePhoneNumber={onChangeSettings}
          name={CustomerKey.phone}
        />
        {renderInputError(CustomerKey.phone, t("INVALID_PHONE_NUMBER"))}

        <PaymentMethodSelector paymentMethod={settings.paymentMethod} onChangePaymentMethod={onChangeSettings} />

        <AddressesList
          addresses={settings.addresses}
          onOpenAddressModal={onOpenAddressModal}
          onRemoveAddress={onRemoveAddress}
        />

        <IonButton onClick={onChangeLocation} fill="outline">
          <IonIcon slot="start" icon={locate} />
          {t("GET_GPS_LOCATION")}
        </IonButton>

        {renderRequirementsGroups()}

        <IonButton className={classes.save} onClick={onSaveSettings} expand="block">
          <IonIcon slot="start" icon={save} />
          {t("SAVE")}
        </IonButton>
        <IonButton color="danger" onClick={onDeleteAccount} expand="block">
          {t("DELETE_ACCOUNT")}
        </IonButton>
      </IonCardContent>
    </IonCard>
  );
};

export default SettingsForm;
