import React, {MouseEvent, FC} from "react";
import {IonItem, IonLabel, IonToggle} from "@ionic/react";
import {useTranslation} from "react-i18next";

import classes from "./ThemeSwitch.module.scss";

interface ThemeSwitchProps {
  isDarkTheme: boolean;
  onChangeIsDarkTheme: (e: MouseEvent<HTMLIonToggleElement>) => void;
}

const ThemeSwitch: FC<ThemeSwitchProps> = ({isDarkTheme, onChangeIsDarkTheme}) => {
  const {t} = useTranslation();

  return (
    <IonItem lines="full" className={classes.root}>
      <IonLabel>{t("MENU.DARKMODE_TITLE")}</IonLabel>
      <IonToggle slot="end" checked={isDarkTheme} onClick={onChangeIsDarkTheme} />
    </IonItem>
  );
};

export default ThemeSwitch;
