import React, {FC} from "react";
import { useAppSelector } from "src/hooks/useAppSelector";
import {Helmet} from "react-helmet-async";
import getLocalOrDefault from "src/utils/getLocalOrDefault";

interface AppHelmetProps {
  pageTitle?: string;
  pageDescription?: string;
  keyWords?: any;
  pageType?: any;
}

const AppHelmet: FC<AppHelmetProps> = ({pageTitle, pageDescription, keyWords, pageType}) => {
  const store = useAppSelector((state) => state.storeReducer.store);

  const storeName = getLocalOrDefault(store.storeName);
  const storeDescription = getLocalOrDefault(store.description);

  const renderGoogleVerificationMetaTag = (): JSX.Element | undefined => {
    if (!store.googleShoppingVerificationCode) return;
    return <meta name="google-site-verification" content={store.googleShoppingVerificationCode} />;
  };

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{pageTitle || storeName}</title>

      <meta name="color-scheme" content={store.primaryColor} />
      <link rel="shortcut icon" href={store.logo} />
      <meta name="keywords" content={keyWords} />
      <meta name="author" content={storeName} />
      <meta name="description" content={pageDescription || storeDescription} />

      <meta name="apple-mobile-web-app-title" content={pageTitle} />
      <meta name="apple-mobile-web-app-status-bar-style" content={store.primaryColor} />

      <meta property="og:title" content={pageTitle || storeName} />
      <meta property="og:type" content={pageType} />
      <meta property="og:image" content={store.logo} />
      <meta property="og:url" content={"https://store.oppa.app/" + store.id} />

      <meta property="og:site_name" content={pageTitle || storeName} />
      <meta name="twitter:image:alt" content={store.logo} />
      <meta property="fb:app_id" content={store.pixelId} />

      {renderGoogleVerificationMetaTag()}
    </Helmet>
  );
};

export default AppHelmet;
