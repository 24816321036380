import {
  InputChangeEventDetail,
  IonInputCustomEvent,
  IonRadioGroupCustomEvent,
  RadioGroupChangeEventDetail,
} from "@ionic/core";
import {
  IonModal,
  IonContent,
  IonList,
  IonRadioGroup,
  IonRadio,
  IonItem,
  IonLabel,
  IonButton,
  IonIcon,
  IonButtons,
  IonInput,
} from "@ionic/react";
import { add, cardOutline } from "ionicons/icons";
import { FC, Fragment, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ModalHeader from "src/components/ModalHeader";
import PagePlaceholder from "src/components/PagePlaceholder";
import { getFawryCards } from "src/firebase/functions";
import { useAppSelector } from "src/hooks/useAppSelector";
import useIsMountedRef from "src/hooks/useIsMountedRef";
import { FawryCard } from "src/interfaces/FawryCard";
import { generateFawryIFrameLink } from "src/utils/generateFawryIFrameLink";
import getLocalOrDefault from "src/utils/getLocalOrDefault";

interface FawryModalProps {
  isOpen: boolean;
  cardLink: string;
  onClose: () => void;
  onSubmit: (token: string, cvv: string) => void;
}

const FawryModal: FC<FawryModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  cardLink,
}) => {
  const isMountedRef = useIsMountedRef();
  const { t } = useTranslation();

  const { storeName } = useAppSelector((store) => store.storeReducer.store);

  const [showIFrame, setShowIFrame] = useState(false);
  const [cvv, setCvv] = useState("");
  const [selectedCard, setSelectedCard] = useState("");
  const [cards, setCards] = useState<FawryCard[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchCards = useCallback(async () => {
    try {
      setIsLoading(true);
      if (!showIFrame) {
        const cards = await getFawryCards();
        setCards((prevState) => [
          ...prevState,
          ...cards.filter(
            (card) => !prevState.some(({ token }) => card.token === token)
          ),
        ]);
      }
    } catch {
    } finally {
      if (isMountedRef.current) setIsLoading(false);
    }
  }, [showIFrame, isMountedRef]);

  useEffect(() => {
    fetchCards();
  }, [fetchCards]);

  const handleSubmit = (): void => {
    if (showIFrame) {
      setShowIFrame(false);
    } else {
      if (!selectedCard || cvv.length < 3) return;
      onSubmit(selectedCard, cvv);
    }
  };

  const handleAddCard = (): void => {
    setShowIFrame(true);
    setSelectedCard("");
    setCvv("");
  };

  const handleChangeCvv = (
    e: IonInputCustomEvent<InputChangeEventDetail>
  ): void => {
    const { value } = e.detail;
    setCvv(value);
  };

  const handleSelectCard = (
    e: IonRadioGroupCustomEvent<RadioGroupChangeEventDetail<string>>
  ): void => {
    const { value } = e.detail;
    setSelectedCard(value);
  };

  const renderCards = (): JSX.Element[] => {
    return cards.map((card) => {
      return (
        <IonItem lines="full" key={card.token}>
          <div className="ion-padding-vertical-xsmall">
            <IonLabel>
              {card.firstSixDigits}xx-xxxx-{card.lastFourDigits}
            </IonLabel>
            <IonLabel className="ion-font-small ion-font-light" color="medium">
              {card.brand}
            </IonLabel>
          </div>
          <IonRadio value={card.token} slot="start" />
        </IonItem>
      );
    });
  };

  const renderCVV = (): JSX.Element => {
    if (!selectedCard) return;
    return (
      <IonItem
        lines="none"
        className="ion-width-fit-content ion-margin-horizontal-auto ion-no-padding ion-no-inner-padding-end"
      >
        <IonInput
          placeholder={t("CVV")}
          value={cvv}
          disabled={!selectedCard}
          maxlength={3}
          className="ion-text-center"
          onIonChange={handleChangeCvv}
        />
      </IonItem>
    );
  };

  const renderContent = (): JSX.Element => {
    if (showIFrame || cardLink) {
      return (
        <iframe
          width="100%"
          height="100%"
          className="ion-no-border"
          src={cardLink || generateFawryIFrameLink()}
          title={getLocalOrDefault(storeName)}
        />
      );
    } else {
      return (
        <Fragment>
          <PagePlaceholder
            icon={cardOutline}
            isLoading={isLoading}
            isEmpty={!cards.length}
            message={t("NO_SAVED_CARDS")}
          />
          <IonList>
            <IonItem lines="none">
              <IonButtons slot="end">
                <IonButton onClick={handleAddCard}>
                  <IonIcon icon={add} slot="icon-only" />
                </IonButton>
              </IonButtons>
            </IonItem>
            <IonRadioGroup onIonChange={handleSelectCard} value={selectedCard}>
              {renderCards()}
            </IonRadioGroup>
          </IonList>
          {renderCVV()}
        </Fragment>
      );
    }
  };

  return (
    <IonModal isOpen={isOpen} onDidDismiss={onClose}>
      <ModalHeader
        onClose={onClose}
        onSubmit={handleSubmit}
        hideSubmit={!!cardLink}
      />
      <IonContent>{renderContent()}</IonContent>
    </IonModal>
  );
};

export default FawryModal;
