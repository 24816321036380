import {CMSItemOptions, CMSItemViewAlignment, CMSItemViewOptions} from "src/interfaces/CMS";

const MOBILE_MAX_SCREEN_WIDTH = 768;
const TABLET_MAX_SCREEN_WIDTH = 992;

export default function getCMSItemViewOptions(screenWidth: number, options?: CMSItemOptions): CMSItemViewOptions {
  return (
    (screenWidth < MOBILE_MAX_SCREEN_WIDTH
      ? options?.mobileView
      : screenWidth < TABLET_MAX_SCREEN_WIDTH
      ? options?.tabletView
      : options?.desktopView) || {width: 100, alignment: CMSItemViewAlignment.left, isHidden: false}
  );
}
