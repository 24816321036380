import { FC } from "react";
import {
  IonToolbar,
  IonHeader,
  IonButtons,
  IonBackButton,
  IonTitle,
} from "@ionic/react";
import { CartButton } from "src/components";
import getPagePath from "src/utils/getStoreIdCustomPath";

interface HeaderProps {
  title: string;
}

const Header: FC<HeaderProps> = ({ title }) => {
  const link = getPagePath("services");

  return (
    <IonHeader>
      <IonToolbar color="primary">
        <IonButtons slot="start">
          <IonBackButton text="" defaultHref={link} />
        </IonButtons>
        <IonTitle>{title}</IonTitle>
        <IonButtons slot="end">
          <CartButton />
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  );
};

export default Header;
