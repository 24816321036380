import { ChangeEvent, FC, Fragment } from "react";
import { TextareaChangeEventDetail, IonCol } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { IonTextareaCustomEvent } from "@ionic/core";
import AttachmentButton from "./AttachmentButton";
import AttachmentItem from "./AttachmentItem";
import FloatingLabelTextarea from "src/components/FloatingLabelTextarea";

interface NotesProps {
  notes: string;
  attachments: File[];
  onChangeNotes: (e: IonTextareaCustomEvent<TextareaChangeEventDetail>) => void;
  onAddAttachments: (e: ChangeEvent<HTMLInputElement>) => void;
  onRemoveAttachment: (index: number) => void;
}

const Notes: FC<NotesProps> = ({
  notes,
  attachments,
  onChangeNotes,
  onAddAttachments,
  onRemoveAttachment,
}) => {
  const { t } = useTranslation();
  const renderAttachments = (): JSX.Element[] => {
    return attachments.map((attachment, index) => {
      const key = attachment.name
        .concat(attachment.size.toString())
        .concat(attachment.type);
      return (
        <AttachmentItem
          key={key}
          attachment={attachment}
          index={index}
          onRemoveAttachment={onRemoveAttachment}
        />
      );
    });
  };
  return (
    <Fragment>
      <IonCol size="12" sizeSm="6" className="ion-padding-xsmall">
        <div className="ion-display-flex ion-justify-content-end ion-wrap">
          <FloatingLabelTextarea
            placeholder={t("NOTES")}
            value={notes}
            onIonChange={onChangeNotes}
            rows={3}
            className="ion-flex-grow ion-full-width"
          />
          <AttachmentButton onAddAttachments={onAddAttachments} />
        </div>
      </IonCol>
      {renderAttachments()}
    </Fragment>
  );
};

export default Notes;
