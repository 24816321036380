import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "src/interfaces/IUser";

interface UserState {
  user: User | null;
  isLoaded: boolean;
}

const initialState: UserState = {
  user: null,
  isLoaded: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User | null>) => {
      state.user = action.payload;
      state.isLoaded = true;
    },
  },
});

export const { setUser } = userSlice.actions;


export default userSlice.reducer;
