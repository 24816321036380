import { IonList } from "@ionic/react";
import { FC, Fragment, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import AlertDialog from "src/components/AlertDialog";
import { useAppSelector } from "src/hooks/useAppSelector";
import { removeCartService } from "src/utils/cart";
import ListHeader from "../ListHeader";
import ServiceItem from "./ServiceItem";
import useToast from "src/hooks/useToast";

const Services: FC = () => {
  const { t } = useTranslation();
  const launchToast = useToast();

  const { services } = useAppSelector((state) => state.cartReducer.cart);

  const [serviceId, setServiceId] = useState("");

  const isDialogOpen = serviceId !== "";

  const handleOpenDialog = useCallback((id: string): void => {
    setServiceId(id);
  }, []);

  const handleCloseDialog = (): void => {
    setServiceId("");
  };

  const handleRemoveService = async (): Promise<void> => {
    try {
      const id = serviceId;
      handleCloseDialog();
      await removeCartService(id);
      launchToast(t("DONE"), "success");
    } catch {
      launchToast(t("FAILED"), "danger");
    }
  };

  const renderServices = (): JSX.Element[] =>
    services.map((service) => (
      <ServiceItem
        key={service.id}
        cartService={service}
        onRemoveService={handleOpenDialog}
      />
    ));

  if (!services.length) return null;

  return (
    <Fragment>
      <IonList>
        <ListHeader title={t("SERVICES")} />
        {renderServices()}
      </IonList>
      <AlertDialog
        message={t("REMOVE_CART_ITEM_MESSAGE")}
        onClose={handleCloseDialog}
        onConfirm={handleRemoveService}
        open={isDialogOpen}
        title={t("WARNING")}
      />
    </Fragment>
  );
};

export default Services;
