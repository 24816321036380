import {
  CheckboxChangeEventDetail,
  IonCheckbox,
  IonItem,
  IonLabel,
  IonRadio,
} from "@ionic/react";
import { IonCheckboxCustomEvent } from "@ionic/core";
import { FC, memo } from "react";
import Price from "src/components/Price";
import { ServicesAddon } from "src/interfaces/ServicesAddon";
import getLocalOrDefault from "src/utils/getLocalOrDefault";
import ValueUnit from "src/components/ValueUnit";
import { useTranslation } from "react-i18next";

interface AddonItemProps {
  addon: ServicesAddon;
  checked: boolean;
  onSelectAddon: (id: string, checked: boolean) => void;
}

const AddonItem: FC<AddonItemProps> = ({ addon, checked, onSelectAddon }) => {
  const { t } = useTranslation();
  const handleSelectAddon = (
    e: IonCheckboxCustomEvent<CheckboxChangeEventDetail>
  ): void => {
    onSelectAddon(addon.id, e.detail.checked);
  };

  const renderInput = (): JSX.Element => {
    if (addon.isSingle) return <IonRadio value={addon.id} slot="start" />;
    return (
      <IonCheckbox
        slot="start"
        checked={checked}
        onIonChange={handleSelectAddon}
      />
    );
  };
  return (
    <IonItem lines="full">
      <div className="ion-display-flex ion-full-width ion-justify-content-between">
        <IonLabel>{getLocalOrDefault(addon.name)}</IonLabel>
        <IonLabel className="ion-font-bold ion-font-small">
          <ValueUnit unit={t(addon.durationUnit)} value={addon.duration} />
          &nbsp;&nbsp;
          <Price price={addon.price} />
        </IonLabel>
      </div>
      {renderInput()}
    </IonItem>
  );
};

export default memo(AddonItem);
