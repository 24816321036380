import { IonChip } from "@ionic/react";
import React, { FC } from "react";
import { selectAllCategories } from "src/slices/categories";
import { useAppSelector } from "src/hooks/useAppSelector";
import getLocalOrDefault from "src/utils/getLocalOrDefault";
import "./ProductsCategoriesChips.scss"

interface ProductsCategoriesChipsProps {
    filteredCategories: string[];
}

const ProductsCategoriesChips: FC<ProductsCategoriesChipsProps> = ({ filteredCategories }) => {

    const categories = useAppSelector(selectAllCategories);

    const renderCategoriesChips = (): JSX.Element[] => {
        return filteredCategories.map((categoryId) => {
            const selectedCategory = categories.find((category) => category.id === categoryId)!;
            return (
                <IonChip color="primary" key={categoryId}>
                    {getLocalOrDefault(selectedCategory.name)}
                </IonChip>
            )
        });
    };

    return <div className="productsCategoriesChips">{renderCategoriesChips()}</div>
}

export default ProductsCategoriesChips;