import { FC } from "react";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonBackButton,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import getPagePath from "src/utils/getStoreIdCustomPath";

const Header: FC = () => {
  const { t } = useTranslation();

  const defaultPath = getPagePath("home");

  return (
    <IonHeader>
      <IonToolbar color="primary">
        <IonButtons slot="start">
          <IonBackButton defaultHref={defaultPath} text=""/>
        </IonButtons>
        <IonTitle>{t("CART")}</IonTitle>
      </IonToolbar>
    </IonHeader>
  );
};

export default Header;
