import { IonLabel } from "@ionic/react";
import { FC, useMemo } from "react";
import getLocalOrDefault from "src/utils/getLocalOrDefault";
import { getFeaturedImage } from "src/utils/getFeaturedImage";
import DeleteButton from "../deleteButton";
import Price from "src/components/Price";
import { Service } from "src/interfaces/Service";
import { CartService } from "src/interfaces/CartService";
import { ServicesWorker } from "src/interfaces/ServicesWorker";
import DetailsAccordion from "src/components/detailsAccordion";
import ServiceDetails from "./ServiceDetails";
import ValueUnit from "src/components/ValueUnit";
import { useTranslation } from "react-i18next";
import ItemThumbnail from "../../../components/ItemThumbnail";
import BookingTime from "./BookingTime";

interface CartServiceItemProps {
  cartService: CartService;
  isAvailable: boolean;
  service: Service;
  worker: ServicesWorker;
  onRemoveService: (id: string) => void;
}

const CartServiceItem: FC<CartServiceItemProps> = ({
  cartService,
  service,
  isAvailable,
  worker,
  onRemoveService,
}) => {
  const { t } = useTranslation();

  const isContainsDetails = useMemo(
    () => Boolean(cartService.addons.length || cartService.requirements.length),
    [cartService]
  );

  const image = getFeaturedImage(service.gallery);
  const name = getLocalOrDefault(service.name);

  const renderDetailsAccordion = (): JSX.Element => {
    if (!isContainsDetails) return;
    return (
      <DetailsAccordion>
        <ServiceDetails service={service} cartService={cartService} />
      </DetailsAccordion>
    );
  };

  const renderFees = (): JSX.Element => {
    if (!service.fees) return;
    return (
      <IonLabel className="ion-font-bold">
        <Price price={service.fees} />
      </IonLabel>
    );
  };

  const renderDuration = (): JSX.Element => {
    if (!service.duration) return;
    return (
      <IonLabel className="ion-font-small ion-font-bold">
        <ValueUnit value={service.duration} unit={t(service.durationUnit)} />
      </IonLabel>
    );
  };

  return (
    <div className="ion-wrap ion-full-width ion-display-flex">
      <div className="ion-full-width ion-display-flex ion-align-items-center ion-margin-bottom-small">
        <ItemThumbnail src={image.src} alt={name} />
        <div className="ion-flex-grow  ion-overflow-hidden">
          <div className="ion-display-flex ion-wrap">
            <IonLabel>{name}</IonLabel>
            <IonLabel className="ion-margin-horizontal-small" color="medium">
              <sub>{getLocalOrDefault(worker.workerName)}</sub>
            </IonLabel>
          </div>
          {renderFees()}
          {renderDuration()}
          <BookingTime
            bookingTime={cartService.bookingTime}
            isAvailable={isAvailable}
          />
        </div>
        <DeleteButton id={cartService.id} onRemoveItem={onRemoveService} />
      </div>
      {renderDetailsAccordion()}
    </div>
  );
};

export default CartServiceItem;
