import React, {FC, Fragment, ChangeEvent} from "react";
import {IonLabel, IonList, IonListHeader} from "@ionic/react";
import {CustomersRequirementsGroup} from "src/interfaces/Store";
import getLocalOrDefault from "src/utils/getLocalOrDefault";
import { CustomerRequirement } from "src/interfaces/CustomerRequirement";
import {InputChangeEventDetail} from "@ionic/core";
import {RequirementAttachmentFile} from "src/interfaces/Requirement";
import { RequirementType } from "src/enums/RequirementType";
import {InputHelperText} from "src/components";
import {useTranslation} from "react-i18next";
import {TextRequirement, NumberRequirement, DateRequirement} from "..";
import AttachmentRequirement from "../AttachmentRequirement";

interface RequirementsGroupProps {
  isError: boolean;
  group: CustomersRequirementsGroup;
  requirements: CustomerRequirement[];
  attachments: RequirementAttachmentFile[];
  onChangeRequirement: (id: string, value: string) => void;
  onChangeAttachmentRequirement: (id: string, file: File) => void;
  onRemoveAttachmentRequirement: (id: string) => void;
}

const RequirementsGroup: FC<RequirementsGroupProps> = ({
  group,
  requirements,
  attachments,
  onChangeRequirement,
  isError,
  onChangeAttachmentRequirement,
  onRemoveAttachmentRequirement,
}) => {
  const {t} = useTranslation();

  const handleChangeRequirement = (id: string): ((e: CustomEvent<InputChangeEventDetail>) => void) => {
    return (e: CustomEvent<InputChangeEventDetail>): void => {
      const value = e.detail.value || "";
      onChangeRequirement(id, value);
    };
  };

  const handleChangeAttchmentRequirement = (id: string): ((e: ChangeEvent<HTMLInputElement>) => void) => {
    return (e: ChangeEvent<HTMLInputElement>): void => {
      const file = e.target.files?.[0];
      if (!file) return;
      onChangeAttachmentRequirement(id, file);
    };
  };

  const renderInputError = (isRequired: boolean, name: string, value: string): JSX.Element | undefined => {
    if (!isError) return;
    const message = `${name} ${t("REQUIRED")}`;
    if (isRequired && !value) {
      return <InputHelperText color="danger" text={message} />;
    }
  };

  const renderRequirementsFields = (): (JSX.Element | null)[] => {
    return group.fields.map((field) => {
      const label = getLocalOrDefault(field.label);
      const requirement = requirements.find(({fieldId}) => fieldId === field.id);
      if (!requirement) return null;
      switch (requirement.type) {
        case RequirementType.text:
          return (
            <Fragment key={requirement.fieldId}>
              <TextRequirement
                onChangeRequirement={handleChangeRequirement(requirement.fieldId)}
                value={requirement.value}
                label={label}
              />
              {renderInputError(field.isRequired, label, requirement.value)}
            </Fragment>
          );
        case RequirementType.number:
          return (
            <Fragment key={requirement.fieldId}>
              <NumberRequirement
                onChangeRequirement={handleChangeRequirement(requirement.fieldId)}
                value={requirement.value}
                label={label}
                field={field}
              />
              {renderInputError(field.isRequired, label, requirement.value)}
            </Fragment>
          );
        case RequirementType.date:
          return (
            <Fragment key={requirement.fieldId}>
              <DateRequirement
                label={label}
                value={requirement.value}
                field={field}
                onChangeRequirement={handleChangeRequirement(requirement.fieldId)}
              />
              {renderInputError(field.isRequired, label, requirement.value)}
            </Fragment>
          );

        case RequirementType.file:
        case RequirementType.image:
          const attachment = attachments.find(({id}) => id === requirement.fieldId)?.file;
          const value = attachment?.name || "";
          return (
            <Fragment key={requirement.fieldId}>
              <AttachmentRequirement
                label={label}
                type={requirement.type}
                id={requirement.fieldId}
                onChangeAttachment={handleChangeAttchmentRequirement(requirement.fieldId)}
                onRemoveAttchment={onRemoveAttachmentRequirement}
                attachment={attachment}
              />
              {renderInputError(field.isRequired, label, value)}
            </Fragment>
          );
        default:
          return null;
      }
    });
  };

  return (
    <IonList>
      <IonListHeader>
        <IonLabel>{getLocalOrDefault(group.name)}</IonLabel>
      </IonListHeader>
      {renderRequirementsFields()}
    </IonList>
  );
};

export default RequirementsGroup;
