import {IonSelect, IonSelectOption, IonItem, IonLabel} from "@ionic/react";
import React, {FC} from "react";
import { FormRequestSelectFormItem } from "src/interfaces/FormRequestSelectFormItem";
import getLocalOrDefault from "src/utils/getLocalOrDefault";
import {SelectChangeEventDetail} from "@ionic/core";
import {CMSFormFieldsView} from "..";
import {RequirementAttachmentFiles} from "src/interfaces/Requirement";
import classes from "./styles.module.scss";
import {useTranslation} from "react-i18next";
import { SelectFormField } from "src/interfaces/SelectFormField";
import { FormRequestField } from "src/interfaces/FormRequestField";

interface CMSFormSelectFormProps {
  items: SelectFormField[];
  attachments: RequirementAttachmentFiles[];
  label: string;
  fieldId: string;
  isError: boolean;
  isSubmitting: boolean;
  itemsRequirements: FormRequestSelectFormItem[];
  onChangeSelectedForms: (selectedItems: string[]) => void;
  onChangeRequirements: (id: string, requirements: FormRequestSelectFormItem[]) => void;
  onChangeAttachments: (attachments: RequirementAttachmentFiles[]) => void;
}

const CMSFormSelectFormField: FC<CMSFormSelectFormProps> = ({
  items,
  label,
  itemsRequirements,
  attachments,
  onChangeSelectedForms,
  onChangeRequirements,
  onChangeAttachments,
  isError,
  isSubmitting,
  fieldId,
}) => {
  const {t} = useTranslation();

  const loadImages = (): void => {
    setTimeout(() => {
      const checkboxes = Object.values(document.getElementsByClassName("alert-checkbox-label"));
      checkboxes.forEach((element, index) => {
        const image = items[index].image;
        if (!image) return;
        const imageHTML = '<div><img width="50" style="display: block;"  src="' + image + '" /></div>';
        element.innerHTML = imageHTML + element.innerHTML;
      });
    }, 100);
  };

  const handleChangeSelectedItems = (e: CustomEvent<SelectChangeEventDetail>): void => {
    const value = e.detail.value || [];
    onChangeSelectedForms(value);
  };

  const handleChangeRequirements = (id: string): ((fields: FormRequestField[]) => void) => {
    return (fields: FormRequestField[]): void => {
      const updatedRequirements = itemsRequirements.map((requirement) =>
        id === requirement.id ? {...requirement, fields} : requirement,
      );
      onChangeRequirements(fieldId, updatedRequirements);
    };
  };
  const handleChangeAttachments = (attachments: RequirementAttachmentFiles[]): void => {
    onChangeAttachments(attachments);
  };

  const renderItems = (): JSX.Element[] => {
    return items.map((item) => {
      return (
        <IonSelectOption value={item.id} key={item.id}>
          {getLocalOrDefault(item.name)}
        </IonSelectOption>
      );
    });
  };

  const renderForms = (): (JSX.Element | null)[] => {
    return itemsRequirements.map((requirement) => {
      const item = items.find(({id}) => requirement.id === id);
      if (!item || !item.fields.length) return null;
      return (
        <div className={classes.item} key={requirement.id}>
          <IonLabel>{getLocalOrDefault(item.name)}</IonLabel>
          <CMSFormFieldsView
            fields={item.fields}
            isError={isError}
            isSubmitting={isSubmitting}
            requirements={requirement.fields}
            onChangeRequirements={handleChangeRequirements(requirement.id)}
            onChangeAttachments={handleChangeAttachments}
            attachments={attachments}
          />
        </div>
      );
    });
  };

  return (
    <div>
      <IonItem>
        <IonLabel className={classes.label}>{label}</IonLabel>
        <IonSelect
          interfaceOptions={{cssClass: classes.alert}}
          className={classes.select}
          interface="alert"
          multiple
          onClick={loadImages}
          okText={t("OK")}
          cancelText={t("CANCEL")}
          onIonChange={handleChangeSelectedItems}
        >
          {renderItems()}
        </IonSelect>
      </IonItem>
      {renderForms()}
    </div>
  );
};
export default CMSFormSelectFormField;
