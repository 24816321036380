import React, { FC } from "react";
import { Order } from "src/interfaces/Order";
import styles from "../styles";
import { View, Text } from "@react-pdf/renderer";
import ReceiptProduct from "./ReceiptProduct";

interface ProductsInfoProps {
  order: Order;
}

const ProductsInfo: FC<ProductsInfoProps> = ({ order }) => {
  function renderProducts(): JSX.Element[] {
    return order.products.map((product, index) => (
      <ReceiptProduct index={index} key={order.id! + index} product={product} />
    ));
  }
  function renderProductsTable(): JSX.Element | undefined {
    if (order.products?.length === 0) return;
    return (
      <View style={styles.table}>
        <View>
          <View style={styles.tableRow}>
            <View style={styles.tableCell}>
              <Text style={styles.contentText}>Order Products</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableNestedContainer}></View>
          </View>
        </View>
        {renderProducts()}
      </View>
    );
  }
  return <View style={styles.section}>{renderProductsTable()}</View>;
};

ProductsInfo.propTypes = {};
export default ProductsInfo;
