import {useEffect, memo, FC} from "react";
import {IonText, IonButton, IonCard} from "@ionic/react";
import "./DefaultHome.scss";
import {Product} from "src/interfaces/Product";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { useAppSelector } from "src/hooks/useAppSelector";
import {
  selectOnSaleProducts,
  selectFeaturedProducts,
  selectLatestProducts,
  getRemoteProducts,
} from "src/slices/products";
import {CarouselOptions} from "../Carousel";
import {Store, StoreSpecialization} from "src/interfaces/Store";
import {
  HorizontalProductsList,
  HorizontalCategoriesList,
  HorizontalServicesList,
  Carousel,
  CategoryBadge,
  ProductPrice,
} from "src/components";
import {getCategories, selectAllCategories, selectLatestCategories} from "src/slices/categories";
import {useTranslation} from "react-i18next";
import {subscribeToServicesCategories, selectAllServiceCategories} from "src/slices/servicesCategories";
import AppHelmet from "src/components/AppHelmet/AppHelmet";
import getLocalOrDefault from "src/utils/getLocalOrDefault";
import getPagePath from "src/utils/getStoreIdCustomPath";

interface DefaultHomeProps {
  store: Store;
}

const DefaultHome: FC<DefaultHomeProps> = ({store}) => {
  const onSaleProducts = useAppSelector(selectOnSaleProducts);
  const dispatch = useAppDispatch();
  const featuredProducts = useAppSelector(selectFeaturedProducts);
  const latestProducts = useAppSelector(selectLatestProducts)(5);
  const latestCategories = useAppSelector(selectLatestCategories)(5);
  const latestServices = useAppSelector(({servicesReducer}) => servicesReducer.services);
  const latestServiceCategories = useAppSelector(selectAllServiceCategories);
  const categories = useAppSelector(selectAllCategories);
  const {t, i18n} = useTranslation();
  const mainCarouselOptions: CarouselOptions = {
    loop: true,
  };

  const storeName = getLocalOrDefault(store.storeName);
  const storeDescription = getLocalOrDefault(store.description);
  const servicesLink = getPagePath("services");

  useEffect(() => {
    dispatch(getCategories());
    dispatch(subscribeToServicesCategories());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getRemoteProducts());
  }, [dispatch]);

  const renderCategoriesBadges = (categoryIds: string[]): JSX.Element[] => {
    return categoryIds.map((id) => {
      return (
        <CategoryBadge key={id} category={categories.find((category) => category.id === id)?.name[i18n.language]!} />
      );
    });
  };

  const _renderMainCarouselSlides = (): JSX.Element[] => {
    const prods = onSaleProducts.length ? onSaleProducts : featuredProducts.length ? featuredProducts : latestProducts;
    return prods.slice(0, 5).map((product: Product) => {
      const productLink = getPagePath(`product/${product.id}`);
      return (
        <div className="slide">
          <div className="slide__image">
            <img
              src={`${product.gallery.find((img) => img.isFeatured)?.src}`}
              onError={(event) => {
                event.currentTarget.src = "/assets/icon/icon.png";
              }}
              alt="slide_image"
            />
          </div>
          <div className="slide__info">
            <IonText color="primary">
              {renderCategoriesBadges(product.categories)}
              <h4>{product.name[i18n.language]}</h4>
            </IonText>
            <ProductPrice
              price={product.price}
              salePrice={product.salePrice}
              showSaving
              hasSalePrice={product.hasSalePrice}
            />
            <IonButton color="primary" routerLink={productLink}>
              {t("VIEW_DEAL")}
            </IonButton>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="default-home">
      <AppHelmet pageTitle={storeName} pageDescription={storeDescription} pageType="store" />
      {store.storeSpecializations.includes(StoreSpecialization.ecommerce) && (
        <IonCard className="carousel-container">
          <div className="header"></div>
          <Carousel
            pager={true}
            slides={_renderMainCarouselSlides()}
            options={mainCarouselOptions}
            className="default-home__carousel"
          />
        </IonCard>
      )}

      {store.storeSpecializations.includes(StoreSpecialization.services) &&
        !store.storeSpecializations.includes(StoreSpecialization.ecommerce) && (
          <>
            <div className="default-home__featured">
              <div className="header">
                <IonText color="primary">
                  <h4>{featuredProducts.length > 0 ? t("SERVICES.FEATURED") : t("SERVICES.LATEST")}</h4>
                </IonText>
                <IonButton routerLink={servicesLink} fill="outline" color="primary" size="small">
                  {t("VIEW_ALL")}
                </IonButton>
              </div>
            </div>
            <HorizontalServicesList servicesIds={latestServices.map((service) => service.id)} />
            <div className="default-home__featured">
              <div className="header">
                <IonText color="primary">
                  <h4>{t("SERVICE_CATEGORIES.LATEST")}</h4>
                </IonText>
                <IonButton routerLink="categories" fill="outline" color="primary" size="small">
                  {t("VIEW_ALL")}
                </IonButton>
              </div>
            </div>
            <HorizontalCategoriesList
              isService
              categoriesIds={latestServiceCategories.map((category) => category.id)}
            />
          </>
        )}
      {store.storeSpecializations.includes(StoreSpecialization.ecommerce) && (
        <>
          <div className="default-home__featured">
            <div className="header">
              <IonText color="primary">
                <h4>{featuredProducts.length > 0 ? t("PRODUCTS.FEATURED") : t("PRODUCTS.LATEST")}</h4>
              </IonText>
              <IonButton routerLink="products" fill="outline" color="primary" size="small">
                {t("VIEW_ALL")}
              </IonButton>
            </div>
          </div>
          <HorizontalProductsList
            productsIds={
              featuredProducts.length
                ? featuredProducts.slice(0, 5).map((product) => product.id)
                : latestProducts.slice(0, 5).map((product) => product.id)
            }
          />
          <div className="default-home__featured">
            <div className="header">
              <IonText color="primary">
                <h4>{t("CATEGORIES.LATEST")}</h4>
              </IonText>
              <IonButton routerLink="categories" fill="outline" color="primary" size="small">
                {t("VIEW_ALL")}
              </IonButton>
            </div>
          </div>
          <HorizontalCategoriesList categoriesIds={latestCategories.map((category) => category.id)} />
        </>
      )}
    </div>
  );
};

export default memo(DefaultHome);
