import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { updateOrderAttachmentsFB } from "src/firebase/orders";
import { Order } from "src/interfaces/Order";
import { AppThunk } from "../store";

interface OrdersSlice {
  orders: Order[];
  isLoaded: boolean;
}

const initialState: OrdersSlice = {
  orders: [],
  isLoaded: false,
};

export const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    setOrders: (state, action: PayloadAction<Order[]>) => {
      state.orders = action.payload;
      state.isLoaded = true;
    },
  },
});

export const { setOrders } = ordersSlice.actions;


export const updateOrderAttachments = (attachments: string[], id: string): AppThunk => async (): Promise<void> => {
  await updateOrderAttachmentsFB(attachments, id);
};


export default ordersSlice.reducer;
