import React, { FC } from "react";
import { View, Text } from "@react-pdf/renderer";
import styles from "./styles";
import getLocalOrDefault from "src/utils/getLocalOrDefault";
import { OrderService } from "src/interfaces/OrderService";

interface ReceiptBookingProps {
  booking: OrderService;
  index: number;
}

const ReceiptBooking: FC<ReceiptBookingProps> = ({ booking, index }) => {
  function renderBookingAddons(): JSX.Element[] {
    return booking.addons.map((addon) => (
      <View
        key={addon.id}
        style={[styles.sideContent, styles.sideContentItemPadding]}
      >
        <View>
          <Text style={[styles.contentText, styles.sideContentText]}>
            Name: {getLocalOrDefault(addon.name)}
          </Text>
        </View>
        <View>
          <Text style={[styles.contentText, styles.sideContentText]}>
            Duration: {addon.duration}-{addon.durationUnit}
          </Text>
        </View>
        <View>
          <Text style={[styles.contentText, styles.sideContentText]}>
            Price: {addon.price}
          </Text>
        </View>
      </View>
    ));
  }
  function renderAddonsHeader(): JSX.Element | undefined {
    if (booking.addons.length === 0) return;
    return (
      <View style={styles.contentText}>
        <Text>Addons</Text>
      </View>
    );
  }
  return (
    <>
      <View style={styles.tableRow}>
        <View style={styles.tableNestedContainer}>
          <View style={styles.tableCellSmall}>
            <Text style={styles.contentText}>{index + 1}</Text>
          </View>
          <View style={[styles.sideContent]}>
            <View>
              <Text style={styles.contentText}>
                Service Name:{getLocalOrDefault(booking.name)}
              </Text>
            </View>
            <View>
              <Text style={[styles.contentText]}>
                Price: {`${booking.fees} EGP`}
              </Text>
            </View>
          </View>
        </View>
      </View>
      <View>
        {renderAddonsHeader()}
        {renderBookingAddons()}
      </View>
    </>
  );
};

ReceiptBooking.propTypes = {};
export default ReceiptBooking;
