import { IonText } from "@ionic/react";
import React, { FC } from "react";
import "./InputHelperText.scss"

interface InputHelperTextProps {
    text: string;
    color?: "primary" | "secondary" | "tertiary" | "success" | "warning" | "danger" | "light" | "medium" | "dark";
}

const InputHelperText: FC<InputHelperTextProps> = ({ text, color }) => {
    return (
        <IonText className="inputHelperText" color={color}>
            <p>{text}</p>
        </IonText>
    );
}

export default InputHelperText;