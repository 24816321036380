import {StyleSheet, Font} from "@react-pdf/renderer";

Font.register({
  family: "Cairo",
  fonts: [
    {
      src: `${window.location.origin}/assets/fonts/Cairo-Regular.ttf`,
      family: "Cairo",
    },
    {
      src: `${window.location.origin}/assets/fonts/Cairo-Bold.ttf`,
      family: "Cairo",
      fontWeight: 700,
    },
  ],
});

const orderInvoiceStyles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    padding: 24,
    fontFamily: "Cairo",
  },
  header: {},
  h5: {
    fontSize: 10,
    fontWeight: 700,
  },
  h6: {
    fontSize: 8,
    fontWeight: 700,
  },
  body1: {
    fontSize: 7,
    lineHeight: 1.5,
  },
  body2: {
    fontSize: 6,
    lineHeight: 1.5,
  },
  invoiceId: {
    marginBottom: 14,
    border: 1,
    width: "60%",
    marginLeft: "auto",
    marginRight: "auto",
    padding: 5,
    textAlign: "center",
  },
  brand: {
    width: 100,
    padding: 10,
  },
  store: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  references: {
    marginTop: 32,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  items: {
    marginTop: 16,
  },
  table: {
    display: "flex",
    width: "100%",
    border: 1,
  },
  tableHeader: {},
  tableBody: {},
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderColor: "#CCCCCC",
    justifyContent: "space-between",
    borderStyle: "solid",
    width: "100%",
  },
  tableCell: {
    padding: 6,
  },
  tableCellSmall: {
    padding: 6,
    width: 20,
  },
  tableCellMedium: {
    padding: 6,
    width: 78,
  },
  tableCellGrow: {
    padding: 6,
    flexGrow: 1,
    width: 80,
    maxWidth: 120,
  },
  alignRight: {
    textAlign: "right",
  },
  alignCenter: {
    textAlign: "center",
  },
  priceAndSalePrice: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  paddingLeft: {
    paddingLeft: 1,
  },
  sale: {
    textDecoration: "line-through",
    color: "red",
  },
  footerText: {
    marginTop: 8,
    fontSize: 10,
    color: "#555",
  },
  marginTop: {
    marginTop: "10px",
  },
  tableNestedContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
  },
  voucher: {
    color: "#4caf50",
  },
});

export default orderInvoiceStyles;
