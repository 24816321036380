import { FC } from "react";
import PropTypes from "prop-types";
import { View, Text } from "@react-pdf/renderer";
import styles from "./styles";
import getLocalOrDefault from "src/utils/getLocalOrDefault";
import { OrderService } from "src/interfaces/OrderService";

interface OrderInvoiceBookingProps {
  service: OrderService;
  index: number;
}

const OrderInvoiceBooking: FC<OrderInvoiceBookingProps> = ({
  service,
  index,
}) => {
  function renderBookingAddons(): JSX.Element[] {
    return service.addons.map((addon) => (
      <Text key={addon.id} style={styles.body1}>
        {getLocalOrDefault(addon.name)}
      </Text>
    ));
  }
  return (
    <View key={service.id} style={styles.tableRow}>
      <View style={styles.tableCellSmall}>
        <Text style={styles.body1}>{index + 1}</Text>
      </View>

      <View style={styles.tableNestedContainer}>
        <View style={styles.tableCellGrow}>
          <Text style={styles.body1}>
            {getLocalOrDefault(service.name)}
          </Text>
        </View>

        <View style={[styles.tableCellMedium]}>
          <Text style={[styles.body1]}>{`${service.fees} EGP`}</Text>
        </View>

        <View style={styles.tableCellMedium}>{renderBookingAddons()}</View>

        <View style={styles.tableCellMedium}>
          <Text style={styles.body1}></Text>
        </View>

        <View style={styles.tableCellMedium}>
          <Text style={styles.body1}></Text>
        </View>
        <View style={styles.tableCellMedium}>
          <Text style={[styles.body1, styles.alignCenter]}>
            {service.total.toFixed(2)} EGP
          </Text>
        </View>
      </View>
    </View>
  );
};

OrderInvoiceBooking.propTypes = {
  service: PropTypes.any.isRequired,
  index: PropTypes.number.isRequired,
};

export default OrderInvoiceBooking;
