import React, {MouseEvent, useEffect, useState, FC} from "react";
import {
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonTitle,
  IonLoading,
} from "@ionic/react";
import {useTranslation} from "react-i18next";
import {updateCustomerIsDarkTheme, changeCustomerLanguage, updateCustomer} from "src/slices/customer";
import {Country} from "src/interfaces/Country";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { useAppSelector } from "src/hooks/useAppSelector";
import { getGPSLocation } from "src/utils/getGPSLocation";
import useToast from "src/hooks/useToast";
import {AddressModal, SettingsForm} from "./components";
import { CustomerAddress } from "src/interfaces/CustomerAddress";
import { CustomerRequirement } from "src/interfaces/CustomerRequirement";
import {PageLoading} from "src/components";
import classes from "./styles.module.scss";
import {v4 as uuidv4} from "uuid";
import {RequirementAttachmentFile} from "src/interfaces/Requirement";
import {validateSettings} from "./Validations";
import {updateRequirementsWithAttachmentsLinks, uploadRequirementsAttachments} from "./Helpers";
import AlertDialog from "src/components/AlertDialog";
import {deleteCustomerFB} from "src/firebase/customer";

const Settings: FC = () => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const launchToast = useToast();

  const customer = useAppSelector((state) => state.customerReducer.customer);
  const {store} = useAppSelector((state) => state.storeReducer);

  const [isLoading, setIsLoading] = useState(false);
  const [isOpenAddressModal, setIsOpenAddressModal] = useState(false);
  const [settings, setSettings] = useState(customer);
  const [isError, setIsError] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [attachments, setAttachments] = useState<RequirementAttachmentFile[]>([]);

  useEffect(() => {
    if (customer) {
      const requirements: CustomerRequirement[] =
        store.customersRequirements.reduce((requirements, group) => {
          group.fields.forEach((field) => {
            const customerRequirement = customer.requirements.find(({fieldId}) => fieldId === field.id);
            const requirement: CustomerRequirement = {
              fieldId: field.id,
              groupId: group.id,
              type: field.type,
              isRequired: field.isRequired,
              value: "",
            };
            requirements.push(customerRequirement || requirement);
          });
          return requirements;
        }, [] as CustomerRequirement[]) || [];
      setSettings({...customer, requirements});
    }
  }, [customer, store]);

  const handleCloseDialog = (): void => {
    setIsDialogOpen(false);
  };

  const handleOpenDialog = (): void => {
    setIsDialogOpen(true);
  };

  const handleChangeIsDarkTheme = (event: MouseEvent<HTMLIonToggleElement>) => {
    const isDarkTheme = (event.target as HTMLIonToggleElement).checked;
    dispatch(updateCustomerIsDarkTheme(isDarkTheme));
  };

  const handleChangeLanguage = (event: CustomEvent) => {
    const language = event.detail.value;
    dispatch(changeCustomerLanguage(language));
  };

  const handleChangeSettings = (e: CustomEvent): void => {
    const name = (e.target as HTMLIonInputElement).name;
    const value = e.detail.value;
    setSettings((prevState) => ({...prevState!, [name]: value}));
  };

  const handleChangeCountry = (country: Country): void => {
    setSettings((prevState) => ({...prevState!, country}));
  };

  const handleChangeRequirement = (id: string, value: string): void => {
    setSettings((prevState) => ({
      ...prevState!,
      requirements: prevState!.requirements.map((field) => (field.fieldId === id ? {...field, value} : field)),
    }));
  };

  const handleChangeAttachmentRequirement = (id: string, file: File): void => {
    setAttachments((prevState) => {
      const isAdded = prevState.some((attachment) => attachment.id === id);
      const updatedAttachment: RequirementAttachmentFile = {id, file};
      if (isAdded) return prevState.map((attachment) => (attachment.id === id ? updatedAttachment : attachment));
      return [...prevState, updatedAttachment];
    });
  };

  const handleRemoveAttachmentRequirement = (id: string): void => {
    setAttachments((prevState) => prevState.filter((attachment) => attachment.id !== id));
  };

  const handleSaveSettings = async (): Promise<void> => {
    const isValidSettings = validateSettings(settings!, attachments);
    if (!isValidSettings) {
      setIsError(true);
      return;
    }
    try {
      setIsLoading(true);
      const attachmentsLinks = await uploadRequirementsAttachments(attachments, settings!.requirements);
      const requirements = updateRequirementsWithAttachmentsLinks(attachmentsLinks, settings!.requirements);
      setSettings((prevState) => ({...prevState!, requirements}));
      await dispatch(updateCustomer({...settings!, requirements}));
      launchToast(t("SETTINGS.SAVE_MESSAGE"), "success");
    } catch (err) {
      launchToast(t("SETTINGS.SAVE_ERROR_MESSAGE"), "danger");
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseAddressModal = (): void => {
    setIsOpenAddressModal(false);
  };

  const handleOpenAddressModal = (): void => {
    setIsOpenAddressModal(true);
  };

  const handleAddAddress = (newAddress: CustomerAddress): void => {
    const id = uuidv4();
    newAddress.id = id;
    setSettings((prevState) => {
      const isFoundAddress = prevState?.addresses.some(({label}) => label === newAddress.label);
      if (isFoundAddress) {
        return {
          ...prevState!,
          addresses: prevState!.addresses.map((address) => (address.label === newAddress.label ? newAddress : address)),
        };
      }
      return {...prevState!, addresses: [...prevState!.addresses, newAddress]};
    });
    handleCloseAddressModal();
  };

  const handleRemoveAddress = (label: string): void => {
    setSettings((prevState) => ({
      ...prevState!,
      addresses: prevState!.addresses.filter((address) => address.label !== label),
    }));
  };

  const handleDeleteAccount = async (): Promise<void> => {
    try {
      setIsDialogOpen(false);
      await deleteCustomerFB();
      launchToast(t("ACCOUNT_DELETE_SUCCESS_MESSAGE"), "success");
    } catch (e) {}
  };

  const handleChangeLocation = async (): Promise<void> => {
    try {
      const location = await getGPSLocation();
      launchToast(t("GPS_LOCATION_SUCCESS_MESSAGE"), "success");
      setSettings((prevState) => ({...prevState!, location}));
    } catch {
      launchToast(t("GPS_LOCATION_FAILED_MESSAGE"), "danger");
    }
  };

  const renderSettingsForm = (): JSX.Element => {
    if (!settings) return <PageLoading />;
    return (
      <SettingsForm
        settings={settings}
        attachments={attachments}
        isError={isError}
        onDeleteAccount={handleOpenDialog}
        onChangeCountry={handleChangeCountry}
        onChangeIsDarkTheme={handleChangeIsDarkTheme}
        onChangeLanguage={handleChangeLanguage}
        onChangeLocation={handleChangeLocation}
        onChangeSettings={handleChangeSettings}
        onSaveSettings={handleSaveSettings}
        onOpenAddressModal={handleOpenAddressModal}
        onRemoveAddress={handleRemoveAddress}
        onChangeAttachmentRequirement={handleChangeAttachmentRequirement}
        onChangeRequirement={handleChangeRequirement}
        onRemoveAttachmentRequirement={handleRemoveAttachmentRequirement}
      />
    );
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{t("SETTINGS.HEADER_TITLE")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={classes.root}>
        <AddressModal
          isOpen={isOpenAddressModal}
          onClose={handleCloseAddressModal}
          onSubmitAddress={handleAddAddress}
        />
        {renderSettingsForm()}

        <AlertDialog
          message={t("DELETE_ACCOUNT_MESSAGE")}
          onClose={handleCloseDialog}
          onConfirm={handleDeleteAccount}
          open={isDialogOpen}
          title={t("DELETE_ACCOUNT")}
        />
        <IonLoading message={t("PLEASE_WAIT")} isOpen={isLoading} />
      </IonContent>
    </IonPage>
  );
};

export default Settings;
