import { IonText } from "@ionic/react";
import React, { FC } from "react";
import "./EmptyView.scss";

interface EmptyViewProps {
    message: string;
}

const EmptyView: FC<EmptyViewProps> = ({ message }) => {
    return (
        <IonText className="emptyView">
            <h5 className="emptyView__message">{message}</h5>
        </IonText>
    );
}

export default EmptyView;