import {IonInput, IonItem, IonLabel} from "@ionic/react";
import React, {FC} from "react";
import classes from "./styles.module.scss";
import {InputChangeEventDetail} from "@ionic/core";
import { FormFieldType } from "src/enums/FormFieldType";

interface CMSFormTextNumberFieldProps {
  label: string;
  type: FormFieldType;
  disabled: boolean;
  value: string;
  onChangeField: (e: CustomEvent<InputChangeEventDetail>) => void;
}

const CMSFormTextNumberField: FC<CMSFormTextNumberFieldProps> = ({label, disabled, type, value, onChangeField}) => {
  const inputType = type === FormFieldType.number ? "number" : "text";
  const inputMode = type === FormFieldType.number ? "numeric" : "text";

  return (
    <IonItem>
      <IonLabel mode="ios" className={classes.label} position="floating">
        {label}
      </IonLabel>
      <IonInput
        disabled={disabled}
        type={inputType}
        value={value}
        inputmode={inputMode}
        inputMode={inputMode}
        onIonChange={onChangeField}
      />
    </IonItem>
  );
};

export default CMSFormTextNumberField;
