import { CartAddonsGroup } from "src/interfaces/CartAddonsGroup";
import { CartCustomVariation } from "src/interfaces/CartCustomVariation";
import { CartRequirement } from "src/interfaces/CartRequirement";
import { Product } from "src/interfaces/Product";
import { VariationAttribute } from "src/interfaces/VariationAttribute";
import { Variation } from "src/interfaces/Variation";
import { CustomVariation } from "src/interfaces/CustomVariation";
import { RequiredField } from "src/interfaces/Requirement";
import { RequirementType } from "src/enums/RequirementType";
import { uuidv4 } from "src/utils/uuidv4";

export const hasValidAttributeOption = (
    value: string,
    attributeId: string,
    values: string[],
    variations: Variation[],
    selectedAttributes: VariationAttribute[]
): boolean => {
    return values.filter((productValue) => {
        return variations.some((variation) => {
            const isIncludesValue = variation.attributes.some(({ id, value }) =>
                attributeId === id && value === productValue
            );

            const isIncludesSelected = selectedAttributes.every((attribute) => {
                if (attributeId === attribute.id) return true;
                const variationAttribute = variation.attributes.find(({ id }) => id === attribute.id);
                return (variationAttribute && variationAttribute.value === attribute.value);
            });

            return isIncludesValue && isIncludesSelected;
        });
    }).includes(value);
}

export const updateRequirements = (
    requirements: CartRequirement[],
    id: string,
    value: string
): CartRequirement[] => {
    const isFound = requirements.some(({ requirementId }) => requirementId === id);
    return !isFound ? [...requirements, { id: uuidv4(), requirementId: id, value }] :
        requirements.map((requirement) => requirement.requirementId !== id ? requirement : { ...requirement, value });
}

export const updateCustomVariations = (
    variations: CartCustomVariation[],
    id: string,
    value: number
): CartCustomVariation[] => {
    if (!value) return variations.filter(({ variationId }) => variationId !== id)
    const isFound = variations.some(({ variationId }) => variationId === id);
    return !isFound ? [...variations, { id: uuidv4(), variationId: id, value }] :
        variations.map((variation) => variation.variationId !== id ? variation : { ...variation, value });
}


export const updateMultipleAddonsGroups = (groups: CartAddonsGroup[], groupId: string, addonId: string, checked: boolean): CartAddonsGroup[] => {
    const isGroupFound = groups.some((group) => group.groupId === groupId);
    const addon = { id: uuidv4(), addonId };
    return (!isGroupFound && checked) ? [...groups, { id: uuidv4(), groupId, addons: [addon] }] :
        groups.map((group) => {
            if (group.groupId !== groupId) return group;
            if (checked) return { ...group, addons: [...group.addons, addon] };
            const addons = group.addons.filter((addon) => addon.addonId !== addonId);
            return { ...group, addons };
        }).filter(({ addons }) => addons.length);
}

export const updateSingleAddonsGroups = (groups: CartAddonsGroup[], groupId: string, addonId: string, prevId: string): CartAddonsGroup[] => {
    const isGroupFound = groups.some((group) => group.groupId === groupId);
    const addon = { id: uuidv4(), addonId };
    return !isGroupFound ? [...groups, { id: uuidv4(), groupId, addons: [addon] }] :
        groups.map((group) => {
            if (group.groupId !== groupId) return group;
            if (!prevId) return { ...group, addons: [...group.addons, addon] };
            const addons = group.addons.map((addon) => addon.addonId !== prevId ? addon : { ...addon, addonId });
            return { ...group, addons };
        }).filter(({ addons }) => addons.length);
}

export const getSelectedVariation = (variations: Variation[], attributes: VariationAttribute[]): Variation => {
    return variations.find((variation) =>
        variation.attributes.every((attribute) =>
            attributes.some(({ id, value }) =>
                id === attribute.id && value === attribute.value)))
}

const validateCustomVariations = (
    customVariations: CustomVariation[],
    variations: CartCustomVariation[]
): boolean => {
    return customVariations.every((customVariation) => {
        const variation = variations.find(({ variationId }) => variationId === customVariation.id);
        const value = variation?.value || 0;
        return (!customVariation.hasMax || value <= customVariation.max)
            && (!customVariation.hasMin || value >= customVariation.min)
    })
}

const validateRequirements = (
    fields: RequiredField[],
    requirements: CartRequirement[]
): boolean => {
    return fields.every((field) => {
        const reqirement = requirements.find(({ requirementId }) => requirementId === field.id);
        const value = reqirement?.value || "";
        if (field.isRequired && !value) return false
        if (field.type === RequirementType.number) {
            const numberValue = +value;
            return (!field.hasMaxValue || numberValue <= field.max)
                && (!field.hasMinValue || numberValue >= field.min)
        }
        return true;
    })
}

export const validateProduct = (product: Product,
    selectedProduct: Product | Variation,
    attributes: VariationAttribute[],
    customVariations: CartCustomVariation[],
    requirements: CartRequirement[],
    variations: Variation[],
    quantity: number,
): boolean => {
    if (!selectedProduct) return false;
    const isValidQuantity = (selectedProduct.isUnlimitedQuantity || selectedProduct.quantity - quantity > 0)
        && (!selectedProduct.hasMaxQuantityPerOrder || quantity < selectedProduct.maxQuantityPerOrder);
    const isValidAttributes = attributes.length === product.attributes.length || !variations.length;
    const isValidCustomVariations = validateCustomVariations(selectedProduct.customVariations, customVariations);
    const isValidRequiremnts = validateRequirements(selectedProduct.requiredFields, requirements)
    return isValidCustomVariations && isValidRequiremnts && isValidQuantity && isValidAttributes;
}