import {auth} from "src/firebase";
import React, {FC, Fragment} from "react";
import { useAppSelector } from "src/hooks/useAppSelector";
import {Redirect} from "react-router-dom";
import getPagePath from "src/utils/getStoreIdCustomPath";

const ProtectedGuard: FC = ({children}) => {
  const store = useAppSelector((state) => state.storeReducer.store);
  const user = useAppSelector((state) => state.userReducer.user);
  const loginLink = getPagePath("login");
  const inActiveLink = getPagePath("inactive");
  const disableLink = getPagePath("disabled");

  if (!store.guestBrowsing && !auth.currentUser) {
    return <Redirect to={loginLink} />;
  }
  if (!store.active) {
    return <Redirect to={inActiveLink} />;
  }

  if (auth.currentUser && user?.disabled) {
    return <Redirect to={disableLink} />;
  }

  return <Fragment>{children}</Fragment>;
};

export default ProtectedGuard;
