import React, { Fragment, useEffect, useState, FC, useRef } from "react";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonPage,
  IonHeader,
  IonButtons,
  IonToolbar,
  IonContent,
  IonTitle,
  IonMenuButton,
  IonSearchbar,
  IonButton,
  IonIcon,
} from "@ionic/react";
import "./Categories.scss";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { useAppSelector } from "src/hooks/useAppSelector";
import { getCategories, selectCategoriesState } from "src/slices/categories";
import { Category } from "src/interfaces/Category";
import { useTranslation } from "react-i18next";
import GridSkeleton from "src/components/GridSkeleton/GridSkeleton";
import EmptyView from "src/components/EmptyView/EmptyView";
import { closeOutline, searchOutline } from "ionicons/icons";
import CategoryView from "src/components/CategoryView/CategoryView";

const Categories: FC = () => {
  const { t } = useTranslation(); 
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState("");
  const searchRef = useRef<any>(null)
  const [isShowSearch, setIsShowSearch] = useState(false);
  const categoriesState = useAppSelector(selectCategoriesState);

  useEffect(() => {
    dispatch(getCategories())
  }, [dispatch]);

  useEffect(() => {
    if (isShowSearch) {
      searchRef.current.setFocus();
    }
  }, [isShowSearch])

  const handleChangeSearch = (e: CustomEvent): void => {
    setSearch(e.detail.value);
  }

  const handleCloseSearch = (): void => {
    setIsShowSearch(false);
    setSearch("");
  }

  const handleOpenSearch = (): void => {
    setIsShowSearch(true);
  }

  const filterParentCategories = (categories: Category[]): Category[] => {
    return categories.filter((category) => !category.parentId);
  }

  const filterSearchedCategories = (categories: Category[]): Category[] => {
    if (!search) return categories;
    return categories.filter((category) => {
      return Object.values(category.name).some((name) => name!.toLowerCase().includes(search.toLowerCase()))
    })
  }

  const renderCategoriesList = (): JSX.Element[] => {
    return filterSearchedCategories(filterParentCategories(categoriesState.categories))
      .map((category) => {
        return (
          <IonCol key={category.id} sizeXs="6" sizeSm="4" sizeMd="3" sizeLg="2">
            <CategoryView category={category} />
          </IonCol>
        );
      });
  };

  const renderContent = (): JSX.Element => {
    if (!categoriesState.isLoaded) return <GridSkeleton cols={8} />;

    if (!categoriesState.categories.length) return <EmptyView message={t("CATEGORIES.EMPTY")} />;

    return (
      <IonGrid>
        <IonRow>{renderCategoriesList()}</IonRow>
      </IonGrid>
    );
  };


  const renderHeader = (): JSX.Element => {
    if (isShowSearch) {
      return (
        <Fragment>
          <IonSearchbar
            placeholder={t("CATEGORIES.SEARCH")}
            value={search}
            ref={searchRef}
            onIonChange={handleChangeSearch}
            animated
          />
          <IonButtons slot="end">
            <IonButton onClick={handleCloseSearch}>
              <IonIcon icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </Fragment>
      )
    }
    return (
      <Fragment>
        <IonTitle>{t("MENU.CATEGORIES_TITLE")}</IonTitle>
        <IonButtons slot="end">
          <IonButton ref={searchRef}
            onClick={handleOpenSearch}>
            <IonIcon icon={searchOutline} />
          </IonButton>
        </IonButtons>
      </Fragment>
    )
  }

  return (
    <IonPage id="categories-page">
      <IonHeader color="primary">
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          {renderHeader()}
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {renderContent()}
      </IonContent>
    </IonPage>
  );
};

export default Categories;
