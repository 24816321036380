import { RawDraftContentState } from "draft-js";
import { PaymentMethod } from "src/enums/PaymentMethod";
import { Country } from "src/interfaces/Country";
import { TranslationMap } from "./Translation";
import { RequiredField } from "src/interfaces/Requirement";
import { BaseDocument } from "./BaseDocument";
import { CollectMethod } from "src/enums/CollectMethod";

export type StoreType = {
  id: string;
  name: string;
  createdAt?: string;
  updatedAt?: string;
};

export enum StoreReferralType {
  googleSearch = "GOOGLE_SEARCH",
  facebook = "FACEBOOK",
  instagram = "INSTAGRAM",
  salesTeam = "SALES_TEAM",
  referClient = "REFER_CLIENT",
  others = "OTHERS",
}

export type StoreReferral = {
  type: StoreReferralType | "";
  value: string;
};

export enum StoreSpecialization {
  ecommerce = "ECOMMERCE",
  services = "SERVICES",
  informative = "INFORMATIVE",
}

export type CustomersRequirementsGroup = {
  id: string;
  name: TranslationMap;
  inRegistration: boolean;
  fields: RequiredField[];
};

export enum OrderInvoiceSize {
  a4 = "A4",
  a7 = "A7",
}

export interface Store extends BaseDocument {
  storeName: TranslationMap;
  primaryColor: string;
  secondaryColor: string;
  description: TranslationMap;
  country: Country;
  languages: string[];
  logo: string;
  type: string;
  storeSpecializations: StoreSpecialization[];

  email: string;
  phone: string;
  landline: string;
  location: string;
  address: TranslationMap;
  branches: StoreBranch[];

  policy: RawDraftContentState;
  terms: RawDraftContentState;

  facebook: string;
  instagram: string;
  twitter: string;
  linkedIn: string;
  tiktok: string;
  youtube: string;
  pinterest: string;

  paymentMethods: PaymentMethod[];
  
  pixelId: string;
  googleShoppingVerificationCode: string;
  facebookAppId: string;
  enableHRSolutions: boolean;

  guestBrowsing: boolean;
  privateRegisteration: boolean;
  customersRequirements: CustomersRequirementsGroup[];
  minimumOrderTotalValue: number;
  orderCollectMethods: CollectMethod[];
  orderInvoiceSize: OrderInvoiceSize;
  isLocationMandatoryInOrder: boolean;

  commercialId: string;
  taxId: string;
  isCommercialIdInInvoice: boolean;
  isTaxIdInInvoice: boolean;
  invoiceDisclaimer: string;
  isDarkTheme: boolean;
  hideSubcategories: boolean;

  //should be removed
  isPaymentLive?: boolean;
  showWelcome: boolean;
  active?: boolean;
  referral?: StoreReferral;
  fawryMerchantCode: string;

}

export type StoreBranch = {
  id: string;
  name: TranslationMap;
  address: TranslationMap;
  location: string;
  phone: string;
  email: string;
  landline: string;
  isPickup: boolean;
};
