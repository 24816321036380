import { IonLabel } from "@ionic/react";
import { FC } from "react";
import { RequirementType } from "src/enums/RequirementType";
import { RequiredField } from "src/interfaces/Requirement";
import getLocalOrDefault from "src/utils/getLocalOrDefault";

interface RequirementItemProps {
  field: RequiredField;
  value: string;
}

const RequirementItem: FC<RequirementItemProps> = ({ field, value }) => {
  const getValue = (): string => {
    switch (field.type) {
      case RequirementType.date:
        return new Date(value).toLocaleDateString();
      default:
        return value;
    }
  };

  return (
    <div className="ion-display-flex ion-justify-content-between">
      <IonLabel>{getLocalOrDefault(field.label)}</IonLabel>
      <IonLabel className="ion-font-bold ion-overflow-visible ion-text-wrap ion-max-width-half">
        {getValue()}
      </IonLabel>
    </div>
  );
};

export default RequirementItem;
