import { ChangeEvent, FC } from "react";
import { IonButton, IonIcon } from "@ionic/react";
import { attach } from "ionicons/icons";

interface AttachmentButtonProps {
  onAddAttachments: (e: ChangeEvent<HTMLInputElement>) => void;
}

const AttachmentButton: FC<AttachmentButtonProps> = ({ onAddAttachments }) => {
  return (
    <IonButton className="ion-no-padding" fill="clear" color="dark">
      <IonIcon
        icon={attach}
        slot="icon-only"
        className="ion-padding-horizontal-small"
      />
      <input
        type="file"
        multiple
        className="ion-full-width ion-position-absolute ion-no-opacity"
        onChange={onAddAttachments}
      />
    </IonButton>
  );
};

export default AttachmentButton;
