import { FormField } from "src/interfaces/FormField";
import { FormRequestField } from "src/interfaces/FormRequestField";

export default function initCMSFormRequirements(fields: FormField[]): FormRequestField[] {
  return fields.map((field) => {
    const value = "";
    const requirement: FormRequestField = {
      id: field.id,
      type: field.type,
      name: field.name,
      value,
      attachments: [],
      selectValue: null,
      selectFormItemsRequirements: [],
    };
    return requirement;
  });
}
