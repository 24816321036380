export interface ColorVariable {
    property?: string;
    value?: string;
    valueRgb?: string;
    contrast?: string;
    contrastRgb?: string;
    tint?: string;
    shade?: string;
}

export interface RGB {
    b: number;
    g: number;
    r: number;
}

export enum ColorName {
    primary = "primary",
    secondary = "secondary",
    tertiary = "tertiary",
}