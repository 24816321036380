import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {subscribeToServicesWorkersFB} from "src/firebase/servicesWorkers";
import { ServicesWorker } from "src/interfaces/ServicesWorker";
import {AppThunk} from "src/store";

interface ServicesWorkersState {
  workers: ServicesWorker[];
  isLoaded: boolean;
}

const initialState: ServicesWorkersState = {
  workers: [],
  isLoaded: false,
};

export const servicesWorkersSlice = createSlice({
  name: "SERVICES_WORKERS",
  initialState,
  reducers: {
    setServicesWorkers: (state: ServicesWorkersState, action: PayloadAction<ServicesWorker[]>) => {
      state.workers = action.payload;
      state.isLoaded = true;
    },
  },
});

export const {setServicesWorkers} = servicesWorkersSlice.actions;

export const subscribeToServicesWorkers =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const isLoaded = getState().servicesWorkersReducer.isLoaded;
    if (isLoaded) return;
    subscribeToServicesWorkersFB();
  };

export default servicesWorkersSlice.reducer;
