import { collection, CollectionReference, doc, FirestoreError, getDoc, getDocFromCache, getDocs } from "firebase/firestore";
import { Category } from "src/interfaces/Category";
import { getFirestoreConverter } from "./converter";
import { getStoreDocRef } from "./store";

const COLLECTION = "categories";

const getCollection = (): CollectionReference<Category> => {
  return collection(getStoreDocRef(), COLLECTION).withConverter(getFirestoreConverter<Category>());
};

export const getCategoriesFB = async (): Promise<Category[]> => {
  try {
    const snapshot = await getDocs(getCollection());
    return snapshot.docs.map((doc) => doc.data());
  } catch {
    return []
  }
};


export const getCategoryByIdFB = async (id: string, fromCache = true): Promise<Category | undefined> => {
  try {
    const document = doc(getCollection(), id);
    const snapshot = fromCache ? await getDocFromCache(document) : await getDoc(document);
    return snapshot.data();
  } catch (err) {
    const error = err as FirestoreError;
    if (error.code === "unavailable" && fromCache) {
      return await getCategoryByIdFB(id, false);
    }
  }
};
