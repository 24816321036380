import { FC } from "react";
import {
  IonContent,
  IonFab,
  IonFabButton,
  IonFabList,
  IonIcon,
  IonLoading,
} from "@ionic/react";
import { Order } from "src/interfaces/Order";
import OrderDetails from "./OrderDetails";
import OrderNotes from "./OrderNotes";
import { filterNotes } from "src/utils/filterNotes";
import UserDetails from "./UserDetails";
import Products from "./products";
import Services from "./services";
import ServicesPackages from "./servicesPackages";
import {
  cloudDownloadOutline,
  documentTextOutline,
  ellipsisVerticalOutline,
  trashBinOutline,
} from "ionicons/icons";
import { OrderStatus } from "src/enums/OrderStatus";

interface ContentProps {
  order: Order;
  isLoading: boolean;
  onCancelOrder: () => void;
  onAddNote: () => void;
  onDownloadInvoice: () => void;
}

const Content: FC<ContentProps> = ({
  order,
  isLoading,
  onCancelOrder,
  onAddNote,
  onDownloadInvoice,
}) => {
  const renderCancelButton = (): JSX.Element => {
    if (order.orderStatus !== OrderStatus.received) return;
    return (
      <IonFabButton color="danger" onClick={onCancelOrder}>
        <IonIcon icon={trashBinOutline} />
      </IonFabButton>
    );
  };

  return (
    <IonContent>
      <OrderDetails order={order} />
      <UserDetails user={order.user} />
      <OrderNotes
        userNotes={filterNotes(order.userNotes)}
        vendorNotes={filterNotes(order.vendorNotes)}
      />
      <Products products={order.products} />
      <Services services={order.services} />
      <ServicesPackages servicesPackages={order.servicesPackages} />
      <IonFab
        vertical="bottom"
        horizontal="end"
        className="ion-margin-bottom ion-margin-end"
      >
        <IonFabButton>
          <IonIcon icon={ellipsisVerticalOutline} />
        </IonFabButton>
        <IonFabList side="top">
          {renderCancelButton()}
          <IonFabButton color="success" onClick={onAddNote}>
            <IonIcon icon={documentTextOutline} />
          </IonFabButton>
          <IonFabButton color="primary" onClick={onDownloadInvoice}>
            <IonIcon icon={cloudDownloadOutline} />
          </IonFabButton>
        </IonFabList>
      </IonFab>
      <IonLoading isOpen={isLoading} />
    </IonContent>
  );
};

export default Content;
