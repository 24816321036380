import { IonButton, IonIcon } from "@ionic/react";
import { downloadOutline } from "ionicons/icons";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { CMSAttachment } from "src/interfaces/CMS";
import getFileNameFromUrl from "src/utils/getFileNameFromUrl";
import getLocalOrDefault from "src/utils/getLocalOrDefault";

interface CMSAtatchmentViewProps {
    attachment: CMSAttachment;
}

const CMSAttachmentView: FC<CMSAtatchmentViewProps> = ({ attachment }) => {
    const { t } = useTranslation();
    const fileName = getFileNameFromUrl(attachment.link);
    return (
        <IonButton color="primary" fill="clear" expand="full" target="_blank" href={attachment.link} download={fileName}>
            {getLocalOrDefault(attachment.label) || t("PAGE.HOME.CMS_ATTACHMENT_DOWNLOAD_BUTTON_LABEL")}
            <IonIcon slot="start" icon={downloadOutline} />
        </IonButton>
    )
}
export default CMSAttachmentView;