import { IonButton, IonIcon } from "@ionic/react";
import { add, remove } from "ionicons/icons";
import { ChangeEvent, FC, useEffect, useMemo, useState } from "react";
import useDebounce from "src/hooks/useDebounce";
import { concClasses } from "src/utils/concClasses";
import classes from "./styles.module.scss";

interface QuantityProps {
  quantity: number;
  productQuantity: number;
  isUnlimitedQuantity: boolean;
  maxQuantity: number;
  hasMaxQuantity: boolean;
  onChangeQuantity: (quantity: number) => void;
}

const Quantity: FC<QuantityProps> = ({
  quantity,
  maxQuantity,
  hasMaxQuantity,
  isUnlimitedQuantity,
  productQuantity,
  onChangeQuantity,
}) => {
  const [quantityValue, setQuantityValue] = useState(quantity.toString());
  const debouncedValue = useDebounce<string>(quantityValue, 1000);

  useEffect(() => {
    const quantity = Math.max(+debouncedValue, 1);
    onChangeQuantity(quantity);
  }, [debouncedValue, onChangeQuantity]);

  const { width, isDecreaseDisabled, isIncreaseDisabled } = useMemo(
    () => ({
      width: +quantityValue.toString().length * 10 + 24,
      isDecreaseDisabled: +quantityValue <= 1,
      isIncreaseDisabled:
        (hasMaxQuantity && +quantityValue >= maxQuantity) ||
        (!isUnlimitedQuantity && +quantityValue >= productQuantity),
    }),
    [
      quantityValue,
      maxQuantity,
      hasMaxQuantity,
      productQuantity,
      isUnlimitedQuantity,
    ]
  );

  const handleDecreaseQuantity = (): void => {
    const value = +quantityValue - 1;
    if (isDecreaseDisabled) return;
    setQuantityValue(value.toString());
  };

  const handleIncreaseQuantity = (): void => {
    const value = +quantityValue + 1;
    if (isIncreaseDisabled) return;
    setQuantityValue(value.toString());
  };

  const handleChangeQuantity = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    const quantity = +value;
    if (
      (hasMaxQuantity && quantity > maxQuantity) ||
      (!isUnlimitedQuantity && quantity > productQuantity) ||
      value === "0"
    )
      return;
    setQuantityValue(value);
  };

  const handleBlur = (): void => {
    setQuantityValue(Math.max(+quantityValue, 1).toString());
  };

  return (
    <div className="ion-display-flex ion-margin-start">
      <IonButton
        className={concClasses(
          classes.button,
          "ion-height-auto ion-no-padding"
        )}
        shape="round"
        onClick={handleDecreaseQuantity}
        disabled={isDecreaseDisabled}
      >
        <IonIcon icon={remove} className="ion-icon-stroke-bold" />
      </IonButton>
      <input
        className="ion-text-center ion-no-border ion-input-no-spin ion-no-background ion-margin-horizontal-xsmall"
        value={quantityValue}
        type="number"
        inputMode="numeric"
        contentEditable
        onBlur={handleBlur}
        style={{ width }}
        onChange={handleChangeQuantity}
      />
      <IonButton
        className={concClasses(
          classes.button,
          "ion-height-auto ion-no-padding"
        )}
        shape="round"
        onClick={handleIncreaseQuantity}
        disabled={isIncreaseDisabled}
      >
        <IonIcon icon={add} className="ion-icon-stroke-bold" />
      </IonButton>
    </div>
  );
};

export default Quantity;
