import {
  CheckboxChangeEventDetail,
  IonCheckbox,
  IonItem,
  IonLabel,
  IonRadio,
} from "@ionic/react";
import { IonCheckboxCustomEvent } from "@ionic/core";
import { FC, memo } from "react";
import Price from "src/components/Price";
import { Addon } from "src/interfaces/Addon";
import getLocalOrDefault from "src/utils/getLocalOrDefault";

interface AddonItemProps {
  addon: Addon;
  checked: boolean;
  groupId?: string;
  onSelectAddon: (id: string, checked: boolean, groupId?: string) => void;
}

const AddonItem: FC<AddonItemProps> = ({
  addon,
  checked,
  groupId,
  onSelectAddon,
}) => {
  const handleSelectAddon = (
    e: IonCheckboxCustomEvent<CheckboxChangeEventDetail>
  ): void => {
    onSelectAddon(addon.id, e.detail.checked, groupId);
  };

  const renderInput = (): JSX.Element => {
    if (addon.isSingle) return <IonRadio value={addon.id} slot="start" />;
    return (
      <IonCheckbox
        slot="start"
        checked={checked}
        onIonChange={handleSelectAddon}
      />
    );
  };
  return (
    <IonItem lines="full">
      <div className="ion-display-flex ion-full-width ion-justify-content-between">
        <IonLabel>{getLocalOrDefault(addon.name)}</IonLabel>
        <IonLabel className="ion-font-bold ion-font-small">
          <Price price={addon.price} />
        </IonLabel>
      </div>
      {renderInput()}
    </IonItem>
  );
};

export default memo(AddonItem);
