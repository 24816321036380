import {DatetimeChangeEventDetail, IonDatetimeCustomEvent} from "@ionic/core";
import {IonButton, IonButtons, IonContent, IonDatetime, IonHeader, IonIcon, IonModal, IonToolbar} from "@ionic/react";
import {close} from "ionicons/icons";
import {FC} from "react";

interface CalenderModalProps {
  onClose: () => void;
  onSubmit: (e: IonDatetimeCustomEvent<DatetimeChangeEventDetail>) => void;
  open: boolean;
  value: string;
  min: string;
  max: string;
  name: string;
}

const CalenderModal: FC<CalenderModalProps> = ({open, onClose, value, onSubmit, min, max, name}) => {
  return (
    <IonModal isOpen={open} onWillDismiss={onClose}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="end">
            <IonButton onClick={onClose}>
              <IonIcon icon={close} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonDatetime name={name} min={min} max={max} size="cover" presentation="date" value={value} onIonChange={onSubmit} />
      </IonContent>
    </IonModal>
  );
};

export default CalenderModal;
