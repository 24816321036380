import { FC, Fragment, useCallback, useEffect, useState } from "react";
import { updateCustomerLanguageFB } from "src/firebase/customer";
import { useAppSelector } from "src/hooks/useAppSelector";
import useAuth from "src/hooks/useAuth";
import useSettings from "src/hooks/useSettings";
import { restoreSettingsLS } from "src/storage/settings";
import SplashScreen from "./SplashScreen";

const SettingsGuard: FC = ({ children }) => {
  const { updateSettings, language } = useSettings();
  const { isAuthenticated } = useAuth();

  const { customer } = useAppSelector((state) => state.customerReducer);
  const { store } = useAppSelector((state) => state.storeReducer);

  const [isSynced, setIsSynced] = useState(false);
  const [isCustomerSynced, setIsCustomerSynced] = useState(false);
  const [isSwitchingDirection, setIsSwitchingDirection] = useState(false);

  useEffect(() => {
    setIsSwitchingDirection(true);
    setTimeout(() => {
      setIsSwitchingDirection(false);
    }, 500);
  }, [language]);

  const syncSettings = useCallback(async () => {
    if (!store || isSynced || isAuthenticated) return;
    const settings = await restoreSettingsLS();
    const language = store.languages[0];
    setIsSynced(true);
    if (!settings) {
      updateSettings({ isDarkTheme: store.isDarkTheme, language });
      return;
    }
    const isStoreLanguage = store.languages.includes(settings.language);
    if (!isStoreLanguage) {
      updateSettings({ isDarkTheme: settings.isDarkTheme, language });
    }
  }, [store, updateSettings, isSynced, isAuthenticated]);

  useEffect(() => {
    if (!customer) return;
    if (!isCustomerSynced) {
      const language = store.languages.includes(customer.language)
        ? customer.language
        : store.languages[0];
      setIsCustomerSynced(true);
      updateSettings({ isDarkTheme: customer.isDarkTheme, language });
      updateCustomerLanguageFB(language);
      return;
    }
    updateSettings({
      isDarkTheme: customer.isDarkTheme,
      language: customer.language,
    });
  }, [customer, updateSettings, store, isCustomerSynced]);

  useEffect(() => {
    syncSettings();
  }, [syncSettings]);

  if (isSwitchingDirection) return <SplashScreen />;

  return <Fragment>{children}</Fragment>;
};

export default SettingsGuard;
