import {
  collection,
  CollectionReference,
  doc,
  getDoc,
  setDoc,
} from "firebase/firestore";
import { auth, firestore } from "src/firebase";
import { EGYPT_COUNTRY } from "src/constants/constants";
import { User } from "src/interfaces/IUser";
import { getFirestoreConverter } from "./converter";
import { splitFullname } from "src/utils/splitFullname";
import { store } from "src/store";
import { setUser } from "src/slices/user";

const COLLECTION = "users";

const getCollection = (): CollectionReference<User> => {
  return collection(firestore, COLLECTION).withConverter(getFirestoreConverter<User>());
};


const createUserFB = async (): Promise<void> => {
  const user = auth.currentUser!;
  const { displayName, email, emailVerified, phoneNumber } = user;
  const { firstname, lastname } = splitFullname(displayName)
  const mainUser: User = {
    id: user.uid,
    country: EGYPT_COUNTRY,
    disabled: false,
    email: email || "",
    emailVerified,
    firstName: firstname,
    lastName: lastname,
    phone: phoneNumber || "",
    phoneVerified: false,
    fcmTokens: [],
  };
  await setDoc(doc(getCollection(), user.uid), mainUser);
}

export const getUserFB = async (): Promise<void> => {
  const user = auth.currentUser;
  const snapshot = await getDoc(doc(getCollection(), user.uid));
  if (!snapshot.exists()) {
    await createUserFB();
    const snapshot = await getDoc(doc(getCollection(), user.uid));
    store.dispatch(setUser(snapshot.data()));
  }
  store.dispatch(setUser(snapshot.data()));
};
