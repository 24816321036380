import { FC, Fragment } from "react";
import { IonCard, IonCardContent, IonLabel } from "@ionic/react";
import { BaseNote } from "src/interfaces/BaseNote";
import { OrderVendorNote } from "src/interfaces/OrderVendorNote";
import { useTranslation } from "react-i18next";

interface OrderNotesProps {
  userNotes: BaseNote[];
  vendorNotes: OrderVendorNote[];
}

const OrderNotes: FC<OrderNotesProps> = ({ userNotes, vendorNotes }) => {
  const { t } = useTranslation();

  const renderNotes = (notes: BaseNote[]): JSX.Element[] => {
    return notes.map((note) => {
      return (
        <IonLabel key={note.id} color="dark" className="ion-display-block ion-margin-small">
          {note.note}
        </IonLabel>
      );
    });
  };

  const renderNotesView = (label: string, notes: BaseNote[]): JSX.Element => {
    if (!notes.length) return;
    return (
      <Fragment>
        <IonLabel
          className="ion-font-bold ion-display-block ion-margin-bottom-small"
          color="medium"
        >
          {label}
        </IonLabel>
        {renderNotes(notes)}
      </Fragment>
    );
  };

  if (!userNotes.concat(vendorNotes).length) return null;

  return (
    <IonCard>
      <IonCardContent>
        {renderNotesView(t("NOTES"), userNotes)}
        {renderNotesView(t("STORE_NOTES"), vendorNotes)}
      </IonCardContent>
    </IonCard>
  );
};

export default OrderNotes;
