import { FC } from "react";
import { CartAddon } from "src/interfaces/CartAddon";
import AddonItem from "./AddonItem";
import { useTranslation } from "react-i18next";
import { IonLabel } from "@ionic/react";
import { ServicesAddon } from "src/interfaces/ServicesAddon";

interface AddonsProps {
  cartServiceAddons: CartAddon[];
  addons: ServicesAddon[];
}

const Addons: FC<AddonsProps> = ({ cartServiceAddons, addons }) => {
  const { t } = useTranslation();

  const renderAddons = (): JSX.Element[] => {
    return cartServiceAddons.map((cartServiceAddon) => {
      const addon = addons.find(({ id: key }) => key === cartServiceAddon.addonId);
      if (!addon) return null;
      return <AddonItem key={addon.id} addon={addon} />;
    });
  };

  return (
    <div className="ion-margin-bottom-small">
      <IonLabel className="ion-margin-top ion-font-bold" color="medium">
        {t("ADDONS")}
      </IonLabel>
      {renderAddons()}
    </div>
  );
};

export default Addons;
