import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "src/hooks/useAppSelector";
import { BaseCartItem } from "src/interfaces/BaseCartItem";
import PagePlaceholder from "src/components/PagePlaceholder";
import { cartOutline } from "ionicons/icons";

const Placeholder: FC = () => {
  const { t } = useTranslation();
  const { isLoaded, cart } = useAppSelector((state) => state.cartReducer);
  const isEmpty = (Object.values(cart) as BaseCartItem[][]).every(
    (list) => !list.length
  );

  return (
    <PagePlaceholder
      icon={cartOutline}
      isLoading={!isLoaded}
      isEmpty={isEmpty}
      message={t("NO_CART_ITEMS")}
    />
  );
};

export default Placeholder;
