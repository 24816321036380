import { IonButton, IonLabel } from "@ionic/react";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import AddonsList from "./AddonsList";
import AddonsGroupItem from "./AddonsGroupItem";
import { CartAddon } from "src/interfaces/CartAddon";
import { CartAddonsGroup } from "src/interfaces/CartAddonsGroup";

interface AddonsProps {
  productAddons: string[];
  productGroups: string[];
  addons: CartAddon[];
  selectedAddonsGroups: CartAddonsGroup[];
  onClearAddons: () => void;
  onSelectSingleAddon: (
    addonId: string,
    prevId: string,
    groupId: string
  ) => void;
  onSelectMultipleAddon: (
    addonId: string,
    checked: boolean,
    groupId: string
  ) => void;
}

const Addons: FC<AddonsProps> = ({
  productAddons,
  productGroups,
  addons,
  selectedAddonsGroups,
  onClearAddons,
  onSelectMultipleAddon,
  onSelectSingleAddon,
}) => {
  const { t } = useTranslation();

  const disabled = !addons.length && !selectedAddonsGroups.length;

  const renderAddonsGroups = (): JSX.Element[] => {
    return productGroups.map((groupId) => {
      const selectedGroup = selectedAddonsGroups.find(
        (group) => group.groupId === groupId
      );
      const selectedAddons = selectedGroup ? selectedGroup.addons : [];
      return (
        <AddonsGroupItem
          key={groupId}
          groupId={groupId}
          selectedAddons={selectedAddons}
          onSelectMultipleAddon={onSelectMultipleAddon}
          onSelectSingleAddon={onSelectSingleAddon}
        />
      );
    });
  };

  return (
    <div className="ion-margin-bottom">
      <div className="ion-display-flex ion-justify-content-between">
        <IonLabel
          className="ion-font-bold ion-margin-vertical-auto"
          color="medium"
        >
          {t("ADDONS")}
        </IonLabel>
        <IonButton
          disabled={disabled}
          onClick={onClearAddons}
          fill="clear"
          color="secondary"
          size="small"
        >
          {t("CLEAR")}
        </IonButton>
      </div>
      <AddonsList
        productAddons={productAddons}
        selectedAddons={addons}
        onSelectMultipleAddon={onSelectMultipleAddon}
        onSelectSingleAddon={onSelectSingleAddon}
      />
      {renderAddonsGroups()}
    </div>
  );
};

export default Addons;
