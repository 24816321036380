import {IonLabel, IonSpinner} from "@ionic/react";
import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import classes from "./EmptyLoadingList.module.scss";

interface EmptyLoadingListProps {
  isLoading: boolean;
}

const EmptyLoadingList: FC<EmptyLoadingListProps> = ({isLoading}) => {
  const {t} = useTranslation();

  const renderContent = (): JSX.Element => {
    if (isLoading) return <IonSpinner color="primary" />;
    return <IonLabel>{t("NO_TRANSACTIONS")}</IonLabel>
  };

  return <div className={classes.root}>{renderContent()}</div>;
};
export default EmptyLoadingList;
