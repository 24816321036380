import React, { FC, Fragment } from "react";
import { useLocation } from "react-router";
import {
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonRouterLink,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToggle,
  IonToolbar,
} from "@ionic/react";
import {
  logOutOutline,
  globeOutline,
  moonOutline,
  sunnyOutline,
} from "ionicons/icons";
import classes from "./Menu.module.scss";
import { useAppSelector } from "src/hooks/useAppSelector";
import { useTranslation } from "react-i18next";
import LANGUAGES from "src/constants/languages";
import useAuth from "src/hooks/useAuth";
import useSettings from "src/hooks/useSettings";
import { OPPA_WEBSITE_URL } from "src/constants/constants";
import getLocalOrDefault from "src/utils/getLocalOrDefault";
import { CMSMenuItem } from "src/interfaces/CMS";
import { hasMenuItemsProductsOrServiceSpec } from "src/utils/hasMenuItemProductsOrServicesSpec";
import { hasMenuItemsProductsSpec } from "src/utils/hasMenuItemProductsSpec";
import { hasMenuItemsServicesSpec } from "src/utils/hasMenuItemServicesSpec";
import { hasDefaultMenuItem } from "src/utils/hasDefaultMenuItem";
import * as Icons from "ionicons/icons";
import { StoreSpecialization } from "src/interfaces/Store";
import getPagePath from "src/utils/getStoreIdCustomPath";

const Menu: FC = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { isAuthenticated, logout } = useAuth();
  const { updateSettings, isDarkTheme, language } = useSettings();

  const store = useAppSelector((state) => state.storeReducer.store);
  const user = useAppSelector((state) => state.userReducer.user);
  const { menu, pages } = useAppSelector(({ cmsReducer }) => cmsReducer.cms);

  if (user?.disabled || !store.active) return null;

  const hasItemSelected = (path: string): boolean => {
    return location.pathname.split("/").pop() === path;
  };

  const getItemClassName = (path: string): string => {
    return hasItemSelected(path) ? classes.itemSelected : "";
  };

  const handleChangeLanguage = (e: CustomEvent): void => {
    updateSettings({ isDarkTheme, language: e.detail.value });
  };

  const handleChangeIsDarkTheme = (): void => {
    updateSettings({ language, isDarkTheme: !isDarkTheme });
  };

  const getThemeIcon = (): string => {
    return isDarkTheme ? moonOutline : sunnyOutline;
  };

  const handleSignout = async (): Promise<void> => {
    await logout();
  };

  const renderLanguages = () => {
    return store.languages!.map((language) => {
      return (
        <IonSelectOption key={language} value={language}>
          {LANGUAGES[language]}
        </IonSelectOption>
      );
    });
  };

  const filterMenuItems = (): CMSMenuItem[] => {
    return menu.items.filter((item) => {
      if (isAuthenticated && !item.visibleOnUser) return false;
      if (!isAuthenticated && !item.visibleOnGuest) return false;
      if (hasMenuItemsProductsOrServiceSpec(item.id))
        return (
          store.storeSpecializations.includes(StoreSpecialization.ecommerce) ||
          store.storeSpecializations.includes(StoreSpecialization.services)
        );
      if (hasMenuItemsProductsSpec(item.id))
        return store.storeSpecializations.includes(
          StoreSpecialization.ecommerce
        );
      if (hasMenuItemsServicesSpec(item.id))
        return store.storeSpecializations.includes(
          StoreSpecialization.services
        );
      return true;
    });
  };

  const renderItemIcon = (iconKey: string): JSX.Element | undefined => {
    if (!iconKey) return;
    const IonIcons = Icons as Record<string, string>;
    const icon = IonIcons[iconKey];
    return <IonIcon slot="start" icon={icon} />;
  };

  const renderMenuItems = (): JSX.Element[] => {
    return filterMenuItems().map((item) => {
      const isDefault = hasDefaultMenuItem(item.id);
      const page = pages.find(({ id }) => id === item.id);
      const pageLabel = page
        ? getLocalOrDefault(page.name) || page.path
        : item.id;
      const label = isDefault ? t(`MENU.${item.id}_TITLE`) : pageLabel;
      const itemPath = getPagePath(item.path);
      return (
        <IonMenuToggle key={item.id} className={getItemClassName(item.path)}>
          <IonItem detail={false} routerLink={itemPath}>
            {renderItemIcon(item.icon)}
            <IonLabel>{label}</IonLabel>
          </IonItem>
        </IonMenuToggle>
      );
    });
  };

  const renderLanguageItem = (): JSX.Element | undefined => {
    if (store.languages!.length <= 1) return;
    return (
      <IonItem>
        <IonIcon slot="start" icon={globeOutline} />
        <IonSelect
          className={classes.languageSelector}
          cancelText={t("CANCEL")}
          onIonChange={handleChangeLanguage}
          value={language}
          interface="action-sheet"
          interfaceOptions={{ header: t("SETTINGS.CHOOSE_LANGUAGE_TITLE") }}
        >
          {renderLanguages()}
        </IonSelect>
      </IonItem>
    );
  };

  const renderAddationalMenuItems = (): JSX.Element | undefined => {
    const loginLink = getPagePath("login");
    if (isAuthenticated) {
      return (
        <IonMenuToggle>
          <IonItem
            detail={false}
            routerLink={loginLink}
            onClick={handleSignout}
          >
            <IonIcon slot="start" icon={logOutOutline} />
            <IonLabel>{t(`MENU.SIGNOUT_TITLE`)}</IonLabel>
          </IonItem>
        </IonMenuToggle>
      );
    } else {
      return (
        <Fragment>
          {renderLanguageItem()}
          <IonItem>
            <IonIcon slot="start" icon={getThemeIcon()} />
            <IonLabel>{t("MENU.DARKMODE_TITLE")}</IonLabel>
            <IonToggle
              slot="end"
              color="primary"
              checked={isDarkTheme}
              onClick={handleChangeIsDarkTheme}
            />
          </IonItem>
        </Fragment>
      );
    }
  };

  return (
    <IonMenu menuId="main" contentId="main" side="start">
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>{getLocalOrDefault(store.storeName)}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className={classes.content}>
          <IonList lines="none" className={classes.itemsList}>
            {renderMenuItems()}
            {renderAddationalMenuItems()}
          </IonList>
          <IonFooter className={classes.footer}>
            <IonRouterLink href={OPPA_WEBSITE_URL} target="_blank">
              <IonImg src="/assets/images/brand.png" />
            </IonRouterLink>
          </IonFooter>
        </div>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
