import { IonItem} from "@ionic/react";
import {FC} from "react";
import {DatetimeChangeEventDetail} from "@ionic/core";
import DateTimeInput from "../DateTimeInput";

interface CMSFormDateFieldProps {
  value: string;
  label: string;
  disabled: boolean;
  onChangeField: (e: CustomEvent<DatetimeChangeEventDetail>) => void;
}

const CMSFormDateField: FC<CMSFormDateFieldProps> = ({value, label, disabled, onChangeField}) => {

  return (
    <IonItem>
      <DateTimeInput label={label} value={value} onSubmit={onChangeField} disabled={disabled}/>
    </IonItem>
  );
};

export default CMSFormDateField;
