import React, { useState, FC } from 'react';
import { IonGrid, IonRow, IonCol, IonButton, IonButtons, IonIcon, IonLabel } from '@ionic/react';
import './ProductsList.scss';
import { Product } from 'src/interfaces/Product';
import { gridOutline, listOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import ProductView from '../ProductView';
import ProductListView from '../ProductListView';
import { useInfiniteHits } from 'react-instantsearch-hooks-web';

enum ViewType {
  list,
  grid,
}

interface ProductsListProps {
  numFound: number;
}

const ProductsList: FC<ProductsListProps> = ({ numFound }) => {
  const { t } = useTranslation();
  const [viewType, setViewType] = useState(ViewType.grid);
  const { hits } = useInfiniteHits();
  const products = hits as unknown as Product[];
  const renderList = (): JSX.Element[] =>
    products.map((product) => {
      return (
        <IonCol
          key={product.id}
          className='product__list'
          sizeXs='12'
          sizeSm='12'
          sizeMd='12'
          sizeLg='12'
        >
          <ProductListView product={product} isShowCategories />
        </IonCol>
      );
    });
  const renderGrid = (): JSX.Element[] =>
    products.map((product) => {
      return (
        <IonCol key={product.id} sizeXs='6' sizeSm='4' sizeMd='4' sizeLg='3'>
          <ProductView product={product} isShowCategories />
        </IonCol>
      );
    });

  const getViewTypeIconColor = (type: ViewType): string => {
    return type === viewType ? 'primary' : 'medium';
  };

  const handleSwitchListView = (): void => {
    setViewType(ViewType.list);
  };

  const handleSwitchGridView = (): void => {
    setViewType(ViewType.grid);
  };

  return (
    <IonGrid className='productsList'>
      <IonRow>
        <IonCol className='productsList__itemCount'>
          <IonLabel>{`${numFound || products.length} ${t('ITEMS')}`}</IonLabel>
        </IonCol>
        <IonCol className='productsList__viewSwitch'>
          <IonButtons>
            <IonButton color={getViewTypeIconColor(ViewType.grid)} onClick={handleSwitchGridView}>
              <IonIcon slot='icon-only' icon={gridOutline} />
            </IonButton>
            <IonButton color={getViewTypeIconColor(ViewType.list)} onClick={handleSwitchListView}>
              <IonIcon slot='icon-only' icon={listOutline} />
            </IonButton>
          </IonButtons>
        </IonCol>
      </IonRow>
      <IonRow>{viewType === ViewType.list ? renderList() : renderGrid()}</IonRow>
    </IonGrid>
  );
};

export default ProductsList;
