import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getAddonsGroupsFB } from "src/firebase/addonsGroups";
import { AddonsGroup } from "src/interfaces/AddonsGroup";
import { AppThunk } from "../store";

interface AddonsGroupsState {
    addonsGroups: AddonsGroup[];
    isLoaded: boolean;
}

const initialState: AddonsGroupsState = {
    addonsGroups: [],
    isLoaded: false,
};

export const addonsGroupsSlice = createSlice({
    name: "ADDONS_GROUPS",
    initialState,
    reducers: {
        setAddonsGroups: (state: AddonsGroupsState, action: PayloadAction<AddonsGroup[]>) => {
            state.addonsGroups = action.payload;
            state.isLoaded = true;
        },
    },
});

export const { setAddonsGroups } = addonsGroupsSlice.actions;

export const getAddonsGroups = (): AppThunk => async (dispatch, getState): Promise<void> => {
    const isLoaded = getState().addonsGroupsReducer.isLoaded;
    if (isLoaded) return;
    const addonsGroups = await getAddonsGroupsFB();
    dispatch(setAddonsGroups(addonsGroups));
};


export default addonsGroupsSlice.reducer;
