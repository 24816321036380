import {
  collection,
  CollectionReference,
  doc,
  FirestoreError,
  getDoc,
  getDocFromCache,
  onSnapshot,
} from "firebase/firestore";
import { ServicesWorker } from "src/interfaces/ServicesWorker";
import { setServicesWorkers } from "src/slices/servicesWorkers";
import { store } from "src/store";
import { getFirestoreConverter } from "./converter";
import { getStoreDocRef } from "./store";

const COLLECTION = "service-workers";

const getCollection = (): CollectionReference<ServicesWorker> => {
  return collection(getStoreDocRef(), COLLECTION).withConverter(getFirestoreConverter<ServicesWorker>());
};

export const subscribeToServicesWorkersFB = async (): Promise<void> => {
  try {
    onSnapshot(
      getCollection(),
      (snapshot) => {
        store.dispatch(setServicesWorkers(snapshot.docs.map((doc) => doc.data())));
      },
      () => {
        store.dispatch(setServicesWorkers([]));
      },
    );
  } catch {
    store.dispatch(setServicesWorkers([]));
  }
};

export const getServicesWorkerByIdFB = async (id: string, fromCache = true): Promise<ServicesWorker | undefined> => {
  try {
    const document = doc(getCollection(), id);
    const snapshot = fromCache ? await getDocFromCache(document) : await getDoc(document);
    return snapshot.data();
  } catch (err) {
    const error = err as FirestoreError;
    if (error.code === "unavailable" && fromCache) {
      return await getServicesWorkerByIdFB(id, false);
    }
  }
};
