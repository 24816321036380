import { FC, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { IonIcon, IonLabel } from "@ionic/react";
import { PaymentMethod } from "src/enums/PaymentMethod";
import {
  cardOutline,
  cashOutline,
  phonePortraitOutline,
  storefrontOutline,
  walletOutline,
} from "ionicons/icons";

interface PaymentMethodViewProps {
  method: PaymentMethod;
}

const PaymentMethodView: FC<PaymentMethodViewProps> = ({ method }) => {
  const { t } = useTranslation();

  const getMethodIcon = (): string => {
    switch (method) {
      case PaymentMethod.card:
        return cardOutline;
      case PaymentMethod.cash:
        return cashOutline;
      case PaymentMethod.kiosk:
      case PaymentMethod.payAtFawry:
        return storefrontOutline;
      case PaymentMethod.customerWallet:
        return walletOutline;
      case PaymentMethod.wallet:
        return phonePortraitOutline;
      default:
        return "";
    }
  };

  const renderPaymentMethod = (): JSX.Element => {
    const icon = getMethodIcon();
    if (!icon) return <IonLabel>{t(method)}</IonLabel>;
    return <IonIcon size="small" color="dark" icon={icon} />;
  };

  return <Fragment>{renderPaymentMethod()}</Fragment>;
};

export default PaymentMethodView;
