import {FC, Fragment} from "react";
import {IonText} from "@ionic/react";
import classes from "./styles.module.scss";
import {useTranslation} from "react-i18next";

interface ProductPriceProps {
  price: number;
  salePrice: number;
  hasSalePrice: boolean;
  showSaving?: boolean;
}

const ProductPrice: FC<ProductPriceProps> = ({price, salePrice, hasSalePrice, showSaving}) => {
  const {t} = useTranslation();

  const priceDifference = price - +salePrice;
  const salePercentage = (priceDifference * 100) / price;
  const productPrice = hasSalePrice ? salePrice : price;

  const renderProductPrice = (): JSX.Element => {
    const renderPrevPrice = (): JSX.Element | undefined => {
      if (hasSalePrice) {
        return (
          <IonText className={classes.oldPrice} color="danger">
            <s>{price}</s>
          </IonText>
        );
      }
    };

    const renderSaving = (): JSX.Element | undefined => {
      if (showSaving && hasSalePrice) {
        return (
          <IonText color="success">
            <p className={classes.saving}>
              {`${t("PRODUCT_PRICE.SAVING_TEXT")} ${priceDifference.toFixed(2)} ${t("EGP")} (${salePercentage.toFixed(
                2,
              )}%)`}
            </p>
          </IonText>
        );
      }
    };

    return (
      <Fragment>
        {renderPrevPrice()}
        <IonText className={classes.currentPrice}>
          <span>{`${productPrice} ${t("EGP")}`}</span>
        </IonText>
        {renderSaving()}
      </Fragment>
    );
  };

  return <div>{renderProductPrice()}</div>;
};

export default ProductPrice;
