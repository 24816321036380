import {IonInput, IonItem, IonLabel} from "@ionic/react";
import React, {useState, FC, FormEvent} from "react";
import {useTranslation} from "react-i18next";
import {AppleSignInButton, CountrySelector, GoogleSignInButton, InputHelperText} from "src/components";
import PhoneNumberInput from "src/components/PhoneNumberInput/PhoneNumberInput";
import {Country} from "src/interfaces/Country";
import {validateEmail, validateIdenticalTexts, validatePassword} from "src/utils/validation";
import {SubmitButton} from "..";
import {SignupError, SignupInfo, SignupInfoName} from "src/interfaces/Authentication";
import {InputChangeEventDetail} from "@ionic/core";
import {ShowPasswordToggler, LoginLink, RequirementsGroup, Divider, FacebookSignInButton} from "src/components";
import { useAppSelector } from "src/hooks/useAppSelector";
import {RequirementAttachmentFile} from "src/interfaces/Requirement";
import {Capacitor} from "@capacitor/core";

interface SignupFormProps {
  signupInfo: SignupInfo;
  isError: boolean;
  submitError: SignupError | "";
  attachments: RequirementAttachmentFile[];
  onSubmit: (e: FormEvent<HTMLFormElement>) => Promise<void>;
  onChangeSignupInfo: (e: CustomEvent<InputChangeEventDetail>) => void;
  onChangeCountry: (country: Country) => void;
  onChangeRequirement: (id: string, value: string) => void;
  onChangeAttachmentRequirement: (id: string, file: File) => void;
  onRemoveAttachmentRequirement: (id: string) => void;
}

const SignupForm: FC<SignupFormProps> = ({
  onSubmit,
  signupInfo,
  onChangeSignupInfo,
  onChangeCountry,
  isError,
  submitError,
  attachments,
  onChangeRequirement,
  onChangeAttachmentRequirement,
  onRemoveAttachmentRequirement,
}) => {
  const {t} = useTranslation();

  const {store} = useAppSelector((state) => state.storeReducer);

  const [isShowPassword, setIsShowPassword] = useState(false);

  const passwordType = isShowPassword ? "text" : "password";
  const registerationRequirements =
    store.customersRequirements.filter(({inRegistration, fields}) => inRegistration && fields.length) || [];

  const handleToggleShowPassword = (): void => {
    setIsShowPassword((prevState) => !prevState);
  };

  const renderRequirementsGroups = (): JSX.Element[] => {
    return registerationRequirements.map((group) => {
      const groupRequirements = signupInfo.requirements.filter(({groupId}) => groupId === group.id);
      return (
        <RequirementsGroup
          group={group}
          key={group.id}
          requirements={groupRequirements}
          attachments={attachments}
          isError={isError}
          onChangeRequirement={onChangeRequirement}
          onChangeAttachmentRequirement={onChangeAttachmentRequirement}
          onRemoveAttachmentRequirement={onRemoveAttachmentRequirement}
        />
      );
    });
  };

  const renderInputError = (name: SignupInfoName, message: string): JSX.Element | undefined => {
    if (!isError) return;
    switch (name) {
      case SignupInfoName.firstName:
      case SignupInfoName.lastName:
      case SignupInfoName.phone:
        if (signupInfo[name]) return;
        break;
      case SignupInfoName.email:
        if (validateEmail(signupInfo.email)) return;
        break;
      case SignupInfoName.password:
        if (validatePassword(signupInfo.password)) return;
        break;
      case SignupInfoName.confirmPassword:
        if (validateIdenticalTexts(signupInfo.password, signupInfo.confirmPassword)) return;
        break;
      default:
        break;
    }
    return <InputHelperText color="danger" text={message} />;
  };

  const renderAppleSignInButton = (): JSX.Element | undefined => {
    const isAndroid = Capacitor.getPlatform() === "android";
    if (isAndroid) return;
    return <AppleSignInButton />;
  };

  return (
    <form noValidate onSubmit={onSubmit}>
      <IonItem>
        <IonLabel position="floating">{t("FIRST_NAME")}</IonLabel>
        <IonInput
          onIonChange={onChangeSignupInfo}
          placeholder={t("FIRST_NAME")}
          value={signupInfo.firstName}
          name={SignupInfoName.firstName}
        />
      </IonItem>
      {renderInputError(SignupInfoName.firstName, t("REQUIRED_FIRST_NAME"))}

      <IonItem>
        <IonLabel position="floating">{t("LAST_NAME")}</IonLabel>
        <IonInput
          onIonChange={onChangeSignupInfo}
          placeholder={t("LAST_NAME")}
          value={signupInfo.lastName}
          name={SignupInfoName.lastName}
        />
      </IonItem>
      {renderInputError(SignupInfoName.lastName, t("REQUIRED_LAST_NAME"))}

      <IonItem>
        <IonLabel position="floating">{t("EMAIL_ADDRESS")}</IonLabel>
        <IonInput
          onIonChange={onChangeSignupInfo}
          placeholder={t("EMAIL_ADDRESS")}
          type="email"
          value={signupInfo.email}
          inputMode="email"
          inputmode="email"
          name={SignupInfoName.email}
        />
      </IonItem>
      {renderInputError(SignupInfoName.email, t("INVALID_EMAIL_ADDRESS"))}

      <CountrySelector country={signupInfo.country} onChangeCountry={onChangeCountry} />

      <PhoneNumberInput
        phone={signupInfo.phone}
        code={signupInfo.country.callingCodes[0]}
        onChangePhoneNumber={onChangeSignupInfo}
        name={SignupInfoName.phone}
      />
      {renderInputError(SignupInfoName.phone, t("INVALID_PHONE_NUMBER"))}

      <IonItem>
        <IonLabel position="floating">{t("PASSWORD")}</IonLabel>
        <IonInput
          placeholder={t("PASSWORD")}
          onIonChange={onChangeSignupInfo}
          type={passwordType}
          value={signupInfo.password}
          name={SignupInfoName.password}
        />
        <ShowPasswordToggler onToggleShowPassword={handleToggleShowPassword} isShowPassword={isShowPassword} />
      </IonItem>
      {renderInputError(SignupInfoName.password, t("INVALID_PASSWORD"))}

      <IonItem>
        <IonLabel position="floating">{t("CONFIRM_PASSWORD")}</IonLabel>
        <IonInput
          placeholder={t("CONFIRM_PASSWORD")}
          onIonChange={onChangeSignupInfo}
          type={passwordType}
          value={signupInfo.confirmPassword}
          name={SignupInfoName.confirmPassword}
        />
        <ShowPasswordToggler onToggleShowPassword={handleToggleShowPassword} isShowPassword={isShowPassword} />
      </IonItem>
      {renderInputError(SignupInfoName.confirmPassword, t("PASSWORD_MISMATCH"))}
      {renderRequirementsGroups()}
      <SubmitButton error={submitError} />
      <Divider my={8} />
      <GoogleSignInButton />
      <FacebookSignInButton />
      {renderAppleSignInButton()}
      <LoginLink />
    </form>
  );
};

export default SignupForm;
