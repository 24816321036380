import React, {useState, useEffect} from "react";
import {IonImg} from "@ionic/react";
import {useAppSelector} from "src/hooks/useAppSelector";
import {selectStore} from "src/slices/store";

interface LazyImageProps {
  src: string;
  className?: string;
  isLoaded?: (loaded: boolean) => void;
  onLoaded?(): void;
}

const LazyImage: React.FC<LazyImageProps> = ({src, className, isLoaded}) => {
  const [imgSrc, setImgSrc] = useState(src);
  const [loading, setLoading] = useState(true);
  const store = useAppSelector(selectStore);

  const onImageError = () => setImgSrc(store.logo!);
  const onImageLoading = () => setLoading(true);
  const onImageLoaded = () => setLoading(false);

  useEffect(() => {
    if (!loading && isLoaded) {
      isLoaded(true);
    }
    if (loading && isLoaded) {
      isLoaded(false);
    }
  }, [loading, isLoaded]);

  return (
    <div className={`image ${className}`}>
      <IonImg
        src={imgSrc}
        onIonError={onImageError}
        onIonImgDidLoad={onImageLoaded}
        onIonImgWillLoad={onImageLoading}
      />
    </div>
  );
};

export default React.memo(LazyImage);
