import React, {FC} from "react";
import {IonItem, IonLabel, IonSelect, IonSelectOption} from "@ionic/react";
import {useTranslation} from "react-i18next";
import { useAppSelector } from "src/hooks/useAppSelector";
import { PaymentMethod } from "src/enums/PaymentMethod";
import {CustomerKey} from "src/interfaces/Customer";
import classes from "./PaymentMethodSelector.module.scss";

interface PaymentMethodSelectorProps {
  paymentMethod: PaymentMethod;
  onChangePaymentMethod: (e: CustomEvent) => void;
}

const PaymentMethodSelector: FC<PaymentMethodSelectorProps> = ({paymentMethod, onChangePaymentMethod}) => {
  const {paymentMethods} = useAppSelector((state) => state.storeReducer.store);
  const {t} = useTranslation();

  const renderPaymentMethods = (): JSX.Element[] => {
    return paymentMethods!.map((method) => {
      return (
        <IonSelectOption key={method} value={method}>
          {t(`PAYMENT_METHOD_${method}`)}
        </IonSelectOption>
      );
    });
  };

  return (
    <IonItem className={classes.root}>
      <IonLabel>{t("SETTINGS.PAYMENT_METHOD_LABEL")}</IonLabel>
      <IonSelect
        cancelText={t("CANCEL")}
        name={CustomerKey.paymentMethod}
        onIonChange={onChangePaymentMethod}
        value={paymentMethod}
        interface="action-sheet"
        interfaceOptions={{header: t("SETTINGS.PAYEMENT_METHOD_HEADER_TITLE")}}
        placeholder={t("PAYMENT_METHOD")}
      >
        {renderPaymentMethods()}
      </IonSelect>
    </IonItem>
  );
};

export default PaymentMethodSelector;
