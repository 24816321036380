import { FC } from "react";
import { IonAccordionGroup, IonLabel } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { ServicesWorker } from "src/interfaces/ServicesWorker";
import WorkerItem from "./WorkerItem";

interface WorkersProps {
  workers: ServicesWorker[];
}

const Workers: FC<WorkersProps> = ({ workers }) => {
  const { t } = useTranslation();

  const renderWorkers = (): JSX.Element[] =>
    workers.map((worker) => <WorkerItem key={worker.id} worker={worker} />);

  return (
    <div className="ion-margin-bottom">
      <IonLabel className="ion-font-bold ion-display-block ion-margin-bottom-small" color="medium">
        {t("SERVICE_DETAILS.WE_ARE_WORKING_ON")}
      </IonLabel>
      <IonAccordionGroup multiple>{renderWorkers()}</IonAccordionGroup>
    </div>
  );
};

export default Workers;
