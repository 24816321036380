import { collection, CollectionReference, onSnapshot } from "firebase/firestore";
import { Area } from "src/interfaces/Area";
import { setAreas } from "src/slices/areas";
import { store } from "src/store";
import { getFirestoreConverter } from "./converter";
import { getStoreDocRef } from "./store";

const COLLECTION = "cities-areas";

const getCollection = (): CollectionReference<Area> =>
  collection(getStoreDocRef(), COLLECTION).withConverter(getFirestoreConverter<Area>());

export const subscribeToAreasFB = (): void => {
  if (store.getState().areasReducer.isLoaded) return;
  onSnapshot(getCollection(), (snapshot) => {
    store.dispatch(setAreas(snapshot.docs.map(doc => doc.data())));
  })
}
