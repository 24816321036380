import { getServicesWorkerByIdFB } from "src/firebase/servicesWorkers";
import { ServicesAddon } from "src/interfaces/ServicesAddon";
import { CartAddon } from "src/interfaces/CartAddon";
import { CartRequirement } from "src/interfaces/CartRequirement";
import { WeekDay } from "src/enums/WeekDay";
import { Service } from "src/interfaces/Service";
import { ServicesWorkerWorkingDays, ServicesWorker } from "src/interfaces/ServicesWorker";
import { uuidv4 } from "src/utils/uuidv4";
import { RequiredField } from "src/interfaces/Requirement";
import { RequirementType } from "src/enums/RequirementType";

export const fetchWorkers = async (workersIds: string[]): Promise<ServicesWorker[]> => {
    return await Promise.all(
        await workersIds.reduce(async (workers, id) => {
            const worker = await getServicesWorkerByIdFB(id);
            if (worker) (await workers).push(worker);
            return workers;
        }, Promise.resolve([] as ServicesWorker[]))
    );
}

export const checkFullWeekWorking = (days: ServicesWorkerWorkingDays): boolean => {
    const initialSlots = Object.values(days)[0];
    return Object.values(days).every((slots) => {
        if (slots.length !== initialSlots.length) return false;
        return slots.every(({ from, to }, index) =>
            from === initialSlots[index].from
            && to === initialSlots[index].to
        );
    });
}

export const getMinDate = (minDays: number): string => {
    const now = new Date();
    now.setDate(now.getDate() + minDays);
    return now.toISOString()
}


export const updateRequirements = (
    requirements: CartRequirement[],
    id: string,
    value: string
): CartRequirement[] => {
    const isFound = requirements.some(({ requirementId }) => requirementId === id);
    return !isFound ? [...requirements, { id: uuidv4(), requirementId: id, value }] :
        requirements.map((requirement) => requirement.requirementId !== id ? requirement : { ...requirement, value });
}


const validateRequirements = (
    fields: RequiredField[],
    requirements: CartRequirement[]
): boolean => {
    return fields.every((field) => {
        const reqirement = requirements.find(({ requirementId }) => requirementId === field.id);
        const value = reqirement?.value || "";
        if (field.isRequired && !value) return false
        if (field.type === RequirementType.number) {
            const numberValue = +value;
            return (!field.hasMaxValue || numberValue <= field.max)
                && (!field.hasMinValue || numberValue >= field.min)
        }
        return true;
    })
}


const validateDuration = (duration: number, addons: ServicesAddon[], selectedAddons: CartAddon[]): boolean => {
    if (duration) return true;
    return selectedAddons.some(({ addonId }) => {
        const addon = addons.find(({ id: key }) => key === addonId);
        return addon.duration;
    })
}

const validateDate = (selectedDate: string, workers: ServicesWorker[]): boolean => {
    return workers.some((worker) => worker.workingDays[Object.values(WeekDay)[new Date(selectedDate).getDay()]].length)
}

export const validateService = (
    service: Service,
    selectedDate: string,
    addons: CartAddon[],
    workers: ServicesWorker[],
    requirements: CartRequirement[],
): boolean => {
    if (!selectedDate) return false;
    const isValidDate = validateDate(selectedDate, workers)
    const isValidDuration = validateDuration(service.duration, service.addons, addons)
    const isValidRequiremnts = validateRequirements(service.requiredFields, requirements)
    return isValidDate && isValidRequiremnts && isValidDuration;
}

export const generateBookingTime = (selectedDate: string, worker: ServicesWorker, slot: string): string => {
    const date = new Date(selectedDate);
    const selectedSlot = slot ? slot : worker.workingDays[Object.values(WeekDay)[date.getDay()]][0].from;
    const hoursAndMinutes = selectedSlot.split(":");
    date.setHours(+hoursAndMinutes[0]);
    date.setMinutes(+hoursAndMinutes[1]);
    date.setSeconds(0);
    return date.toISOString();

}