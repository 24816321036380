import { Color } from "@ionic/core";
import { OrderStatus } from "src/enums/OrderStatus";

export const getOrderStatusColor = (status: OrderStatus): Color => {
  switch (status) {
    case OrderStatus.notCompleted:
      case OrderStatus.returned:
    case OrderStatus.canceled:
      return "danger";
    case OrderStatus.delivered:
      return "success";
    case OrderStatus.outForDelivery:
    case OrderStatus.processing:
      return "warning";
    default:
      return "primary";
  }
}