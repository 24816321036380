import { FC } from "react";
import { IonContent, IonItem, IonLabel, IonList, IonModal } from "@ionic/react";
import { OrderServicesPackagesService } from "src/interfaces/OrderServicesPackagesService";
import ModalHeader from "src/components/ModalHeader";
import getPagePath from "src/utils/getStoreIdCustomPath";
import ItemThumbnail from "src/components/ItemThumbnail";
import getLocalOrDefault from "src/utils/getLocalOrDefault";

interface ServicesModalProps {
  isOpen: boolean;
  services: OrderServicesPackagesService[];
  onClose: () => void;
}

const ServicesModal: FC<ServicesModalProps> = ({
  isOpen,
  services,
  onClose,
}) => {
  const renderServices = (): JSX.Element[] => {
    return services.concat(services).map((service) => {
      const link = getPagePath("service/".concat(service.serviceId));
      return (
        <IonItem
          onClick={onClose}
          routerLink={link}
          lines="full"
        >
          <ItemThumbnail
            src={service.image}
            alt={getLocalOrDefault(service.name)}
          />
          <IonLabel className="ion-padding-vertical">
            {getLocalOrDefault(service.name)}
          </IonLabel>
        </IonItem>
      );
    });
  };

  return (
    <IonModal isOpen={isOpen} onDidDismiss={onClose}>
      <ModalHeader onClose={onClose} hideSubmit />
      <IonContent>
        <IonList>{renderServices()}</IonList>
      </IonContent>
    </IonModal>
  );
};

export default ServicesModal;
