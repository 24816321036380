import { FC, Fragment } from "react";
import { IonModal, IonContent } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { OrderReport } from "src/interfaces/OrderReport";
import { useAppSelector } from "src/hooks/useAppSelector";
import ReportItem from "./ReportItem";
import { CollectMethod } from "src/enums/CollectMethod";
import { PaymentMethod } from "src/enums/PaymentMethod";
import ModalHeader from "src/components/ModalHeader";

interface ReportModalProps {
  report: OrderReport;
  collectMethod: CollectMethod;
  paymentMethod: PaymentMethod;
  isOpen: boolean;
  onClose: () => void;
  onSumbit: () => void;
}

const ReportModal: FC<ReportModalProps> = ({
  report,
  collectMethod,
  paymentMethod,
  isOpen,
  onClose,
  onSumbit,
}) => {
  const { t } = useTranslation();

  const { minimumOrderTotalValue = 0 } = useAppSelector(
    (state) => state.storeReducer.store
  );
  const { walletCredit } = useAppSelector(
    (state) => state.customerReducer.customer
  );
  const { products, services, servicesPackages } = useAppSelector(
    (state) => state.cartReducer.cart
  );

  const handleSubmit = (): void => {
    if (
      report.total < minimumOrderTotalValue ||
      (paymentMethod === PaymentMethod.customerWallet &&
        walletCredit < report.total)
    )
      return;
    onSumbit();
  };

  const renderProducts = (): JSX.Element => {
    if (!products.length) return;
    return <ReportItem label={t("PRODUCTS")} price={report.products} />;
  };
  const renderServices = (): JSX.Element => {
    if (!services.length) return;
    return <ReportItem label={t("SERVICES")} price={report.services} />;
  };
  const renderServicesPackages = (): JSX.Element => {
    if (!servicesPackages.length) return;
    return (
      <ReportItem
        label={t("SERVICES_PACKAGES")}
        price={report.servicesPackages}
      />
    );
  };

  const renderDeliveryFees = (): JSX.Element => {
    if (collectMethod !== CollectMethod.delivery) return;
    return (
      <ReportItem label={t("DELIVERY_FEES")} price={report.deliveryFees} />
    );
  };

  const renderDigitalFees = (): JSX.Element => {
    if (paymentMethod === PaymentMethod.cash) return;
    return <ReportItem label={t("DIGITAL_FEES")} price={report.digitalFees} />;
  };

  const renderDiscount = (): JSX.Element => {
    if (!report.discount) return;
    return <ReportItem label={t("DISCOUNT")} price={-report.discount} />;
  };

  const renderMinimumTotal = (): JSX.Element => {
    if (report.total >= minimumOrderTotalValue) return;
    return (
      <ReportItem
        color="danger"
        label={t("MINMUM_ORDER_TOTAL")}
        price={minimumOrderTotalValue}
      />
    );
  };
  const renderStoreWalletCredit = (): JSX.Element => {
    if (
      paymentMethod !== PaymentMethod.customerWallet ||
      walletCredit >= report.total
    )
      return;
    return (
      <ReportItem
        color="danger"
        label={t("STORE_WALLET_CREDIT")}
        price={walletCredit}
      />
    );
  };

  const renderContent = (): JSX.Element => {
    if (!report) return;
    return (
      <Fragment>
        {renderProducts()}
        {renderServices()}
        {renderServicesPackages()}
        {renderDeliveryFees()}
        {renderDigitalFees()}
        {renderDiscount()}
        <div className="ion-margin-top">
          <ReportItem label={t("TOTAL")} price={report.total} />
        </div>
        {renderMinimumTotal()}
        {renderStoreWalletCredit()}
      </Fragment>
    );
  };

  return (
    <IonModal isOpen={isOpen} onDidDismiss={onClose}>
      <ModalHeader onClose={onClose} onSubmit={handleSubmit} />
      <IonContent>{renderContent()}</IonContent>
    </IonModal>
  );
};

export default ReportModal;
