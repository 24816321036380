import { CMSLinkType } from "src/interfaces/CMS";

export default function getCMSLinkFromLinkType(type: CMSLinkType, link: string): string | undefined {
    const isExternalLink = type === CMSLinkType.email || type === CMSLinkType.phone || type === CMSLinkType.externalLink;
    if (!link && isExternalLink) return undefined;
    switch (type) {
        case CMSLinkType.email:
            return `mailto:${link}`;
        case CMSLinkType.phone:
            return `tel:${link}`;
        case CMSLinkType.externalLink:
            return link.startsWith("http://") || link.startsWith("https://") ? link : `https://${link}`;
        default:
            return "#";
    }
};