import { FC } from "react";
import { IonSplitPane, IonApp, setupIonicReact } from "@ionic/react";
import Menu from "./components/Menu";
import Routes from "./Routes";
import { setupManifest } from "./utils/setupManifest";
import { IonReactRouter } from "@ionic/react-router";
import { FirebaseAuthProvider } from "./contexts/FirebaseAuthContext";
import { LiveUpdateProvider } from "./contexts/LiveUpdateContext";
import { SettingsProvider } from "./contexts/SettingsContext";
import StoreGuard from "./components/StoreGuard";
import SettingsGuard from "./components/SettingsGuard";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.scss";

/* Global classes*/
import "./theme/classes.scss";
import "./theme/fonts.scss";
import "./theme/margins.scss";
import "./theme/paddings.scss";

setupIonicReact();
setupManifest();

const App: FC = () => {
  return (
    <IonApp>
      <LiveUpdateProvider>
        <IonReactRouter>
          <SettingsProvider>
            <StoreGuard>
              <FirebaseAuthProvider>
                <SettingsGuard>
                    <IonSplitPane when={false} contentId="main">
                      <Menu />
                      <Routes />
                    </IonSplitPane>
                </SettingsGuard>
              </FirebaseAuthProvider>
            </StoreGuard>
          </SettingsProvider>
        </IonReactRouter>
      </LiveUpdateProvider>
    </IonApp>
  );
};

export default App;
