import { FC } from "react";
import { IonSlides, IonSlide } from "@ionic/react";
import CMSCarouselItem from "./CMSCarouselItem";
import { CMSSlide } from "src/interfaces/CMS";

interface CMSCarouselProps {
  slides: CMSSlide[];
}

const CMSCarousel: FC<CMSCarouselProps> = ({ slides }) => {
  const options = {
    spaceBetween: 8,
  };

  const renderSlides = (): JSX.Element[] => {
    return slides.map((slide, index) => {
      return (
        <IonSlide key={index} className="margin-horizontal-auto">
          <CMSCarouselItem slide={slide} />
        </IonSlide>
      );
    });
  };

  return (
    <IonSlides pager={true} options={options} key={slides.length}>
      {renderSlides()}
    </IonSlides>
  );
};

export default CMSCarousel;
