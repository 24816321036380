import { IonButton } from "@ionic/react";
import React, { FC } from "react";
import classes from "./SubmitButton.module.scss";
import { useTranslation } from "react-i18next";

const SubmitButton: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <IonButton type="submit" expand="block">
        {t("PAGE.FORGET_PASSWORD.SEND_EMAIL_BUTTON")}
      </IonButton>
      <button type="submit" hidden />
    </div>
  );
};
export default SubmitButton;
