import {FC, useEffect} from "react";
import {IonHeader, IonToolbar, IonContent, IonPage, IonButtons, IonMenuButton, IonTitle} from "@ionic/react";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { useAppSelector } from "src/hooks/useAppSelector";
import {subscribeToCMS} from "src/slices/cms";
import {CMSContent, PageLoading} from "src/components";
import getLocalOrDefault from "src/utils/getLocalOrDefault";
import {Redirect} from "react-router-dom";
import getPagePath from "src/utils/getStoreIdCustomPath";
import classes from "./styles.module.scss";

interface CustomPageProps {
  index: number;
}

const CustomPage: FC<CustomPageProps> = ({index}) => {
  const dispatch = useAppDispatch();
  const isLoaded = useAppSelector((state) => state.cmsReducer.isLoaded);
  const page = useAppSelector((state) => state.cmsReducer.cms.pages[index]);
  const pageTitle = page ? getLocalOrDefault(page.name) || page.path : "";
  const homeLink = getPagePath("home");

  const isColorBackground = page.background?.startsWith("#");

  const background = page.background
    ? isColorBackground
      ? page.background
      : `url(${page.background}) 0 0 / cover`
    : "transparent";

  useEffect(() => {
    dispatch(subscribeToCMS());
  }, [dispatch]);

  const renderPageContent = (): JSX.Element => {
    if (!isLoaded) {
      return <PageLoading />;
    }
    return <CMSContent content={page.content} />;
  };

  if (!page) return <Redirect to={homeLink} />;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{pageTitle}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={classes.cover} style={{"--background": background}}>
        {renderPageContent()}
      </IonContent>
    </IonPage>
  );
};

export default CustomPage;
