import {IonAvatar, IonButton, IonButtons, IonCheckbox, IonIcon, IonItem, IonLabel} from "@ionic/react";
import {chevronDown, chevronForward} from "ionicons/icons";
import React, {FC, memo, Fragment, useState} from "react";
import {Category} from "src/interfaces/Category";
import { useAppSelector } from "src/hooks/useAppSelector";
import getLocalOrDefault from "src/utils/getLocalOrDefault";
import classes from "./CategoriesFilterMenuItem.module.scss";

interface CategoriesFilterMenuItemProps {
  category: Category;
  selectedCategories: string[];
  onToggleSelectCategory: (id: string) => void;
  padding: number;
}

const CategoriesFilterMenuItem: FC<CategoriesFilterMenuItemProps> = ({
  category,
  onToggleSelectCategory,
  selectedCategories,
  padding,
}) => {
  const {categories} = useAppSelector((state) => state.categoriesReducer);

  const [isOpen, setIsOpen] = useState(false);

  const isSelected = selectedCategories.includes(category.id);
  const childCategories = categories.filter(({parentId}) => parentId === category.id);
  const expandIcon = childCategories.length ? (isOpen ? chevronDown : chevronForward) : "";

  const handleToggleSelectCategory = (): void => {
    onToggleSelectCategory(category.id);
  };

  const handleToggleExpandCategory = (): void => {
    setIsOpen((prevState) => !prevState);
  };

  const renderChildCategories = (): JSX.Element[] | undefined => {
    if (!isOpen) return;
    return childCategories.map((childCategory) => {
      return (
        <CategoriesFilterMenuItem
          key={childCategory.id}
          category={childCategory}
          selectedCategories={selectedCategories}
          onToggleSelectCategory={onToggleSelectCategory}
          padding={padding + 16}
        />
      );
    });
  };

  return (
    <Fragment>
      <IonItem className={classes.root} style={{paddingInlineStart: padding}}>
        <IonButtons className={classes.expandIcon}>
          <IonButton disabled={!childCategories.length} onClick={handleToggleExpandCategory} size="small">
            <IonIcon icon={expandIcon} size="small" />
          </IonButton>
        </IonButtons>
        <IonAvatar className={classes.image}>
          <img width={50} src={category.image.src} alt={category.image.alt} />
        </IonAvatar>
        <IonLabel>{getLocalOrDefault(category.name)}</IonLabel>
        <IonCheckbox name={category.id} checked={isSelected} onClick={handleToggleSelectCategory} />
      </IonItem>
      {renderChildCategories()}
    </Fragment>
  );
};

export default memo(CategoriesFilterMenuItem);
