import { FC } from "react";
import { IonButton, IonIcon } from "@ionic/react";
import { locationOutline } from "ionicons/icons";

interface LocationButtonProps {
  onChangeLocation: () => void;
}

const LocationButton: FC<LocationButtonProps> = ({ onChangeLocation }) => {
  return (
    <IonButton
      className="ion-no-padding"
      fill="clear"
      color="dark"
      onClick={onChangeLocation}
    >
      <IonIcon
        icon={locationOutline}
        slot="icon-only"
        className="ion-padding-horizontal-small"
      />
    </IonButton>
  );
};

export default LocationButton;
