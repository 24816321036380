import { FC } from "react";
import { IonButton, IonFooter, IonIcon } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { card } from "ionicons/icons";
import getPagePath from "src/utils/getStoreIdCustomPath";
import { useAppSelector } from "src/hooks/useAppSelector";
import { BaseCartItem } from "src/interfaces/BaseCartItem";
import classes from "./styles.module.scss"

const Footer: FC = () => {
  const { t } = useTranslation();
  const cart = useAppSelector((state) => state.cartReducer.cart);
  const disabled = (Object.values(cart) as BaseCartItem[][]).every(
    (list) => !list.length
  );
  return (
    <IonFooter className={classes.root}>
      <IonButton
        disabled={disabled}
        routerLink={getPagePath("checkout")}
        size="large"
        className="ion-no-margin ion-font-medium ion-no-text-transform ion-font-bold ion-full-height"
        fill="solid"
        expand="full"
      >
        <IonIcon slot="start" icon={card} />
        {t("CHECKOUT")}
      </IonButton>
    </IonFooter>
  );
};

export default Footer;
