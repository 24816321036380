import { auth } from "src/firebase";
import { updateCartFB } from "src/firebase/customer";
import { CartProduct } from "src/interfaces/CartProduct";
import { CartAddon } from "src/interfaces/CartAddon";
import { CartAddonsGroup } from "src/interfaces/CartAddonsGroup";
import { CartCustomVariation } from "src/interfaces/CartCustomVariation";
import { CartRequirement } from "src/interfaces/CartRequirement";
import { CartRequirementsGroup } from "src/interfaces/CartRequirementsGroup";
import { CartServicesPackage } from "src/interfaces/CartServicesPackage";
import { Cart } from "src/interfaces/Cart";
import { setCart, setIsSynced } from "src/slices/cart";
import { deleteCartLS, restoreCartLS, storeCartLS } from "src/storage/cart";
import { store } from "src/store";
import { CartService } from "src/interfaces/CartService";

const saveCart = async (cart: Cart): Promise<void> => {
    const isAuthed = auth.currentUser;
    if (isAuthed) {
        await updateCartFB(cart);
    } else {
        await storeCartLS(cart);
        store.dispatch(setCart(cart));
    }
}

const hasSameAddons = (newAddons: CartAddon[], oldAddons: CartAddon[]): boolean => {
    return newAddons.length === oldAddons.length && newAddons.every((newAddon) => oldAddons.some((oldAddon) => oldAddon.addonId === newAddon.addonId))
};


const hasSameAddonsGroups = (newGroups: CartAddonsGroup[], oldGroups: CartAddonsGroup[]): boolean => {
    return newGroups.length === oldGroups.length && newGroups.every((newGroup) => {
        const oldGroup = oldGroups.find((oldGroup) => oldGroup.groupId === newGroup.groupId);
        return oldGroup && hasSameAddons(newGroup.addons, oldGroup.addons);
    });
};

const hasSameCustomVariations = (newVariations: CartCustomVariation[], oldVariations: CartCustomVariation[]) => {
    return newVariations.length === oldVariations.length && newVariations.every((newVariation) => {
        const oldVariation = oldVariations.find((oldVariation) => oldVariation.variationId === newVariation.variationId);
        return oldVariation && oldVariation.value === newVariation.value;
    });
};


const getAvailableCartProduct = (product: CartProduct, products: CartProduct[]): CartProduct => {
    const cartProduct = products.find((cartProduct) =>
        product.productId === cartProduct.productId &&
        product.variationId === cartProduct.variationId &&
        hasSameAddons(product.addons, cartProduct.addons) &&
        hasSameAddonsGroups(product.addonsGroups, cartProduct.addonsGroups) &&
        hasSameCustomVariations(product.customVariations, cartProduct.customVariations));
    return cartProduct;
}

const hasSameRequirements = (newRequirements: CartRequirement[], oldRequirements: CartRequirement[]): boolean => {
    return newRequirements.length === oldRequirements.length && newRequirements.every((newRequirement) => {
        const oldRequirement = oldRequirements.find(({ requirementId }) => requirementId === newRequirement.requirementId);
        return oldRequirement && oldRequirement.value === newRequirement.value;
    })
}

const mergeRequirements = (newGroups: CartRequirementsGroup[], oldGroups: CartRequirementsGroup[]): CartRequirementsGroup[] => {
    return newGroups.reduce((groups, group) => {
        const identicalGroup = groups.find(({ requirements }) => hasSameRequirements(group.requirements, requirements));
        return identicalGroup ? groups.map((group) =>
            group.id === identicalGroup.id ? { ...group, quantity: group.quantity + 1 } : group) : [...groups, group];
    }, oldGroups);
}



const getProductsByUpdateProductQuantity = (products: CartProduct[], id: string, quantity: number, requirements: CartRequirementsGroup[]): CartProduct[] =>
    products.map((cartProduct) => cartProduct.id !== id ? cartProduct :
        { ...cartProduct, quantity, requirements }
    );

const getRequirementsDependsOnQuantity = (requirements: CartRequirementsGroup[], productQuantity: number): CartRequirementsGroup[] => {
    let requirementsQuantity = 0;
    return requirements.reduce((requirements, group, index, groups) => {
        if (requirementsQuantity < productQuantity) {
            const difference = productQuantity - requirementsQuantity;
            const quantity = index === groups.length - 1 ? difference : Math.min(difference, group.quantity);
            requirements.push({ ...group, quantity })
            requirementsQuantity += quantity;
        } return requirements;
    }, [] as CartRequirementsGroup[]);
}

const mergeProducts = (localProducts: CartProduct[], remoteProducts: CartProduct[]): CartProduct[] => {
    return localProducts.reduce((cartProducts, product) => {
        const isFound = cartProducts.some(({ productId, variationId }) =>
            productId === product.productId
            && variationId === product.variationId)
        if (!isFound) cartProducts.push(product);
        return cartProducts;
    }, [...remoteProducts]);
}
const mergeServices = (localServices: CartService[], remoteServices: CartService[]): CartService[] => {
    return localServices.reduce((cartServices, service) => {
        const isFound = cartServices.some(({ serviceId }) => serviceId === service.serviceId)
        if (!isFound) cartServices.push(service);
        return cartServices;
    }, [...remoteServices]);
}
const mergeServicesPackages = (localPackages: CartServicesPackage[], remotePackages: CartServicesPackage[]): CartServicesPackage[] => {
    return localPackages.reduce((cartPackages, servicesPackage) => {
        const isFound = cartPackages.some(({ packageId }) => packageId === servicesPackage.packageId)
        if (!isFound) cartPackages.push(servicesPackage);
        return cartPackages;
    }, [...remotePackages]);
}


export const updateProductQuantity = async (cartProductId: string, quantity: number): Promise<void> => {
    const { cart } = store.getState().cartReducer;
    const cartProduct = cart.products.find(({ id }) => id === cartProductId);
    const requirements = getRequirementsDependsOnQuantity(cartProduct.requirements, quantity)
    const products = getProductsByUpdateProductQuantity(cart.products, cartProductId, quantity, requirements);
    await saveCart({ ...cart, products });
}

export const addProductToCart = async (product: CartProduct): Promise<void> => {
    const { cart } = store.getState().cartReducer;
    const cartProduct = getAvailableCartProduct(product, cart.products);
    const products = cartProduct ?
        getProductsByUpdateProductQuantity(cart.products, cartProduct.id, cartProduct.quantity + product.quantity, mergeRequirements(product.requirements, cartProduct.requirements))
        : [...cart.products, product];
    await saveCart({ ...cart, products });
}

export const removeProductFromCart = async (productId: string): Promise<void> => {
    const { cart } = store.getState().cartReducer;
    const products = cart.products.filter(({ id }) => id !== productId);
    await saveCart({ ...cart, products });
}

export const removeCartServicesPackage = async (id: string): Promise<void> => {
    const { cart } = store.getState().cartReducer;
    const servicesPackages = cart.servicesPackages.filter((item) => item.id !== id);
    await saveCart({ ...cart, servicesPackages });
}

export const removeCartService = async (id: string): Promise<void> => {
    const { cart } = store.getState().cartReducer;
    const services = cart.services.filter((item) => item.id !== id);
    await saveCart({ ...cart, services });
}

export const addServicesPackageToCart = async (servicesPackage: CartServicesPackage): Promise<void> => {
    const { cart } = store.getState().cartReducer;
    const isFound = cart.servicesPackages.some(({ packageId }) => packageId === servicesPackage.packageId);
    if (isFound) return;
    const servicesPackages = [...cart.servicesPackages, servicesPackage];
    await saveCart({ ...cart, servicesPackages });
}

export const addServiceToCart = async (cartService: CartService): Promise<void> => {
    const { cart } = store.getState().cartReducer;
    const isFound = cart.services.some(({ serviceId }) => serviceId === cartService.serviceId);
    const services = isFound ? cart.services.map((service) => service.serviceId === cartService.serviceId ? cartService : service) : [...cart.services, cartService];
    await saveCart({ ...cart, services });
}

export const restoreCart = async (): Promise<void> => {
    const cart = await restoreCartLS();
    store.dispatch(setCart(cart || { products: [], services: [], servicesPackages: [] }));
}

const mergeCart = async (): Promise<void> => {
    const { cart } = store.getState().cartReducer;
    const { customer } = store.getState().customerReducer;
    const products = mergeProducts(cart.products, customer.cart.products);
    const services = mergeServices(cart.services, customer.cart.services);
    const servicesPackages = mergeServicesPackages(cart.servicesPackages, customer.cart.servicesPackages);
    saveCart({ products, services, servicesPackages });
    store.dispatch(setCart({ products, services, servicesPackages }))
    deleteCartLS();
}

export const syncCart = async (): Promise<void> => {
    const { isSynced } = store.getState().cartReducer;
    if (isSynced) {
        const { customer: { cart } } = store.getState().customerReducer;
        store.dispatch(setCart(cart));
    } else {
        store.dispatch(setIsSynced(true));
        mergeCart();
    }
}