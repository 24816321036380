import { FC, Fragment, useEffect } from "react";
import { subscribeToCMSFB } from "src/firebase/cms";
import { subscribeToStoreFB } from "src/firebase/store";
import { useAppSelector } from "src/hooks/useAppSelector";
import SplashScreen from "./SplashScreen";

const StoreGuard: FC = ({ children }) => {
  const { isLoaded } = useAppSelector((state) => state.storeReducer);

  useEffect(() => {
    subscribeToStoreFB();
    subscribeToCMSFB();
  }, []);

  if (!isLoaded) return <SplashScreen />;

  return <Fragment>{children}</Fragment>;
};

export default StoreGuard;
