import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import Page from "src/components/Page";
import Header from "./Header";
import Content from "./Content";
import { subscribeToOrdersFB } from "src/firebase/orders";

const Orders: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    subscribeToOrdersFB();
  }, [dispatch]);

  return (
    <Page title={t("ORDERS")}>
      <Header />
      <Content />
    </Page>
  );
};

export default Orders;
