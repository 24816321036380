import { BookingStatus } from "src/enums/BookingStatus";

export default function getBookingStatusColor(status: BookingStatus): string {
  switch (status) {
    case BookingStatus.canceled:
      return "danger";
    case BookingStatus.attended:
      return "success";
    case BookingStatus.missed:
      return "warning";
    default:
      return "primary";
  }
}