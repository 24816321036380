import {IonItem, IonRouterLink} from "@ionic/react";
import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import getPagePath from "src/utils/getStoreIdCustomPath";
import classes from "./SignupLink.module.scss";

const SignupLink: FC = () => {
  const {t} = useTranslation();

  const signUpLink = getPagePath("signup");

  return (
    <IonItem lines="none">
      <IonRouterLink className={classes.link} routerLink={signUpLink}>
        {t("LOGIN.CREATE_ACCOUNT")}
      </IonRouterLink>
    </IonItem>
  );
};
export default SignupLink;
