import {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
} from "firebase/firestore";
import { BaseDocument } from "src/interfaces/BaseDocument";

const toFirestore = <T extends BaseDocument>(model: T): DocumentData => {
  const documentData = { ...model };
  delete documentData.id;
  delete documentData.createdAt;
  delete documentData.updatedAt;
  return documentData;
};

const fromFirestore = <T extends BaseDocument>(snapshot: QueryDocumentSnapshot<DocumentData>): T => (
  { id: snapshot.id, createdAt: new Date().toISOString(), ...snapshot.data() } as T
);

export const getFirestoreConverter = <T extends BaseDocument>(): FirestoreDataConverter<T> => (
  {
    toFirestore,
    fromFirestore,
  } as FirestoreDataConverter<T>
)

