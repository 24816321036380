import React, {FC} from "react";
import PropTypes from "prop-types";
import {View, Text} from "@react-pdf/renderer";
import styles from "./styles";
import {Order} from "src/interfaces/Order";

interface CustomerInfoProps {
  order: Order;
}

const CustomerInfo: FC<CustomerInfoProps> = ({order}) => {
  return (
    <View style={styles.items}>
      <View style={styles.table}>
        <View style={styles.tableHeader}>
          <View style={styles.tableRow}>
            <View style={styles.tableCell}>
              <Text style={styles.h5}>Customer Info</Text>
            </View>
          </View>
        </View>
        <View style={styles.tableBody}>
          <View style={styles.tableRow}>
            <View style={styles.tableCell}>
              <Text style={styles.body1}>Name</Text>
            </View>
            <View style={styles.tableCell}>
              <Text style={styles.body1}>{`${order.user.firstname} ${order.user.lastname}`}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCell}>
              <Text style={styles.body1}>Phone</Text>
            </View>
            <View style={styles.tableCell}>
              <Text style={styles.body1}>{order.user.phone}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCell}>
              <Text style={styles.body1}>Email</Text>
            </View>
            <View style={styles.tableCell}>
              <Text style={styles.body1}>{order.user.email}</Text>
            </View>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={styles.tableCell}>
            <Text style={styles.body1}>Address</Text>
          </View>
          <View style={styles.tableCell}>
            <Text style={styles.body1}>{order.user.address}</Text>
          </View>
        </View>
      </View>
    </View>
  );
};

CustomerInfo.propTypes = {
  order: PropTypes.any.isRequired,
};

export default CustomerInfo;
