import {IonImg, IonRouterLink, IonText} from "@ionic/react";
import React, {FC, useEffect, useState} from "react";
import ProductPrice from "../ProductPrice";
import {useCallback} from "react";
import {getProductByIdFB} from "src/firebase/products";
import {Product} from "src/interfaces/Product";
import {getFeaturedImage} from "src/utils/getFeaturedImage";
import getLocalOrDefault from "src/utils/getLocalOrDefault";
import classes from "./styles.module.scss";
import getPagePath from "src/utils/getStoreIdCustomPath";

interface CMSSliderProductSlideProps {
  itemId: string | null;
}

const CMSSliderProductSlide: FC<CMSSliderProductSlideProps> = ({itemId}) => {
  const [product, setProduct] = useState<Product>();

  const loadProduct = useCallback(async () => {
    const product = await getProductByIdFB(itemId || "");
    setProduct(product);
  }, [itemId]);

  useEffect(() => {
    loadProduct();
  }, [loadProduct]);

  function renderProduct(): JSX.Element | null {
    if (!product) return null;
    const link = getPagePath(`product/${product.id}`);
    return (
      <IonRouterLink className={classes.root} routerLink={link}>
        <IonImg className={classes.image} src={getFeaturedImage(product.gallery)?.src} />
        <div className={classes.details}>
          <IonText color="primary">
            <h3 className={classes.title}>{getLocalOrDefault(product.name)}</h3>
          </IonText>
          <ProductPrice
            price={product.price}
            salePrice={product.salePrice}
            showSaving
            hasSalePrice={product.hasSalePrice}
          />
        </div>
      </IonRouterLink>
    );
  }

  return renderProduct();
};

export default CMSSliderProductSlide;
