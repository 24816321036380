import { FC } from "react";
import { Order } from "src/interfaces/Order";
import { Text, View } from "@react-pdf/renderer";
import styles from "./styles";
interface PersonalInfoProps {
  order: Order;
}

const PersonalInfo: FC<PersonalInfoProps> = ({ order }) => {
  return (
    <View style={styles.section}>
      <View style={styles.content}></View>
      <View>
        <View style={styles.table}>
          <View>
            <View style={styles.tableRow}>
              <View style={styles.tableCell}>
                <Text style={styles.contentHeader}>Customer Info</Text>
              </View>
            </View>
          </View>
          <View>
            <View style={styles.tableRow}>
              <View style={styles.tableCell}>
                <Text style={styles.contentText}>Name</Text>
              </View>
              <View style={styles.tableCell}>
                <Text
                  style={styles.contentText}
                >{`${order.user.firstname} ${order.user.lastname}`}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCell}>
                <Text style={styles.contentText}>Phone</Text>
              </View>
              <View style={styles.tableCell}>
                <Text style={styles.contentText}>{order.user.phone}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCell}>
                <Text style={styles.contentText}>Email</Text>
              </View>
              <View style={styles.tableCell}>
                <Text style={styles.contentText}>{order.user.email}</Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export default PersonalInfo;
