import {IonItem, IonRouterLink} from "@ionic/react";
import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import classes from "./LoginLink.module.scss";
import getPagePath from "src/utils/getStoreIdCustomPath";

const LoginLink: FC = () => {
  const {t} = useTranslation();
  const loginLink = getPagePath("login");
  return (
    <IonItem lines="none">
      <IonRouterLink className={classes.link} routerLink={loginLink}>
        {t("SIGNUP.HAVE_ACCOUNT")}
      </IonRouterLink>
    </IonItem>
  );
};

export default LoginLink;
