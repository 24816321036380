import { CollectMethod } from "src/enums/CollectMethod";
import { PaymentMethod } from "src/enums/PaymentMethod";
import { CartAddon } from "src/interfaces/CartAddon";
import { CartRequirement } from "src/interfaces/CartRequirement";
import { FawryCard } from "src/interfaces/FawryCard";
import { OrderReport } from "src/interfaces/OrderReport";
import { OrderUser } from "src/interfaces/OrderUser";
import { WorkerAvailability } from "src/interfaces/WorkerAvailability";
import { store } from "src/store";
import firebaseApi from "src/utils/firebaseApi";

const GET_AVAILABLE_BOOKINGS = "storefrontGetAvailableBookings";
const BOOK_SERVICES_PACKAGE_SESSION = "storefrontBookServicesPackageSession";
const SEND_RESET_PASSWORD_EMAIL = "storefrontSendResetPasswordEmail";
const SEND_VERIFICATION_EMAIL = "storefrontSendVerificationEmail";
const GET_PRICES = "storefrontGetPrices";
const ORDER = "storefrontOrder";
const GET_FAWRY_CARDS = "getFawryCards";
const PAY_WITH_CARD = "payWithCard";
const CANCEL_ORDER = "storefrontCancelOrder";

type AvailableBookingsResponse = {
    availabilities: WorkerAvailability[];
    availableWorkers: string[];
    isDayOrMore: boolean;
};

type OrderResponse = {
    orderId: string,
    referenceNumber: string;
    paymobIframe: string;
}

export const sendResetPasswordEmail = async (email: string): Promise<void> => {
    const storeId = store.getState().storeReducer.store.id;
    await firebaseApi.post(SEND_RESET_PASSWORD_EMAIL, {
        email,
        storeId,
    });
}

export const sendVerificationEmail = async (email: string): Promise<void> => {
    const storeId = store.getState().storeReducer.store.id;
    await firebaseApi.post(SEND_VERIFICATION_EMAIL, {
        email,
        storeId,
    });
}

export const getAvailableBookings = async (date: string, addons: CartAddon[], serviceId: string): Promise<AvailableBookingsResponse> => {
    const storeId = store.getState().storeReducer.store.id
    const { data } = await firebaseApi.post<AvailableBookingsResponse>(GET_AVAILABLE_BOOKINGS, {
        date,
        gmtDiff: new Date().getTimezoneOffset(),
        serviceId,
        storeId,
        addons,
    });
    return data;
}

export const bookServicesPackageSession = async (addons: CartAddon[], serviceId: string, workerId: string, requirements: CartRequirement[], orderId: string, packageId: string, bookingTime: string): Promise<void> => {
    const storeId = store.getState().storeReducer.store.id;
    await firebaseApi.post(BOOK_SERVICES_PACKAGE_SESSION, {
        workerId,
        requirements,
        orderId,
        storeId,
        packageId,
        serviceId,
        addons,
        bookingTime
    });
}

export const getOrderReport = async (paymentMethod: PaymentMethod, collectMethod: CollectMethod, city: string, area: string, voucherCode: string): Promise<OrderReport> => {
    const storeId = store.getState().storeReducer.store.id;
    const customerId = store.getState().customerReducer.customer.id;
    const { data } = await firebaseApi.post<OrderReport>(GET_PRICES, {
        paymentMethod,
        collectMethod,
        city,
        area,
        storeId,
        voucherCode,
        customerId,
        gmtDiff: new Date().getTimezoneOffset()
    })
    return data;
}


export const submitOrder = async (paymentMethod: PaymentMethod, collectMethod: CollectMethod, city: string, area: string, voucherCode: string, user: OrderUser, total: number, notes: string, attachments: string[]): Promise<OrderResponse> => {
    const storeId = store.getState().storeReducer.store.id;
    const { data } = await firebaseApi.post<OrderResponse>(ORDER, {
        paymentMethod,
        collectMethod,
        city,
        area,
        storeId,
        voucherCode,
        total,
        user,
        attachments,
        notes,
        gmtDiff: new Date().getTimezoneOffset()
    });
    return data;
}

export const getFawryCards = async (): Promise<FawryCard[]> => {
    const storeId = store.getState().storeReducer.store.id;
    const customerId = store.getState().customerReducer.customer.id;
    const { data } = await firebaseApi.post<FawryCard[]>(GET_FAWRY_CARDS, {
        storeId, customerId
    });
    return data;
}

export const payWithCard = async (orderId: string, token: string, cvv: string): Promise<string> => {
    const storeId = store.getState().storeReducer.store.id;
    const { data } = await firebaseApi.post<string>(PAY_WITH_CARD, {
        storeId, orderId, token, cvv
    });
    return data;
}

export const cancelOrder = async (id: string): Promise<void> => {
    const storeId = store.getState().storeReducer.store.id;
    await firebaseApi.post(CANCEL_ORDER, { storeId, id });
}