import {
  IonButtons,
  IonCard,
  IonContent,
  IonHeader,
  IonLoading,
  IonMenuButton,
  IonPage,
  IonCardContent,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, {useState, FC, FormEvent} from "react";
import {useTranslation} from "react-i18next";
import { useAppSelector } from "src/hooks/useAppSelector";
import {validateEmail, validatePassword} from "src/utils/validation";
import useAuth from "src/hooks/useAuth";
import classes from "./Login.module.scss";
import {LoginInfo} from "src/interfaces/Authentication";
import {InputChangeEventDetail} from "@ionic/core";
import {LoginForm} from "./components";
import useIsMountedRef from "src/hooks/useIsMountedRef";
import getLocalOrDefault from "src/utils/getLocalOrDefault";

const INITIAL_INFO: LoginInfo = {
  email: "",
  password: "",
};

const Login: FC = () => {
  const {t} = useTranslation();
  const {loginWithEmailAndPassword} = useAuth();
  const isMountedRef = useIsMountedRef();

  const store = useAppSelector((state) => state.storeReducer.store);

  const [loginInfo, setLoginInfo] = useState<LoginInfo>(INITIAL_INFO);
  const [isError, setIsError] = useState(false);
  const [isSubmitError, setIsSubmitError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const hasValidLoginInfo = (): boolean => {
    const isValidEmail = validateEmail(loginInfo.email);
    const isValidPassword = validatePassword(loginInfo.password);
    return isValidEmail && isValidPassword;
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    const isValidLoginInfo = hasValidLoginInfo();
    if (!isValidLoginInfo) {
      setIsError(true);
      return;
    }
    try {
      setIsLoading(true);
      await loginWithEmailAndPassword(loginInfo.email, loginInfo.password);
    } catch {
      if (isMountedRef.current) {
        setIsSubmitError(true);
      }
    } finally {
      if (isMountedRef.current) {
        setIsLoading(false);
      }
    }
  };

  const handleChangeLoginInfo = (e: CustomEvent<InputChangeEventDetail>): void => {
    const name = (e.target as HTMLIonInputElement).name;
    const value = e.detail.value;
    setLoginInfo((prevState) => ({...prevState, [name]: value}));
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{t("LOGIN.HEADER_TITLE")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={classes.root}>
        <IonLoading isOpen={isLoading} message={t("PLEASE_WAIT")} />
        <IonCard className={classes.card}>
          <img src={store.logo} alt={getLocalOrDefault(store.storeName)} className={classes.logo} />
          <IonCardContent>
            <LoginForm
              isError={isError}
              loginInfo={loginInfo}
              isSubmitError={isSubmitError}
              onChangeLoginInfo={handleChangeLoginInfo}
              onSubmit={handleSubmit}
            />
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};
export default Login;
