import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {subscribeToCMSFB} from "src/firebase/cms";
import { CMS } from "src/interfaces/CMS";
import {AppThunk} from "src/store";

interface CMSState {
  cms: CMS;
  isLoaded: boolean;
}

const initialState: CMSState = {
  cms: {
    home: [],
    pages: [],
    menu: {items: []},
    homeBackground: "",
  },
  isLoaded: false,
};

export const cmsSlice = createSlice({
  name: "CMS",
  initialState,
  reducers: {
    setCMS: (state: CMSState, action: PayloadAction<CMS>) => {
      const cms = action.payload;
      state.cms = cms;
      state.isLoaded = true;
    },
  },
});

export const {setCMS} = cmsSlice.actions;

export const subscribeToCMS = (): AppThunk => async (dispatch, getState) => {
  const isLoaded = getState().cmsReducer.isLoaded;
  if (isLoaded) return;
  subscribeToCMSFB();
};

export default cmsSlice.reducer;
