import {
  IonButton,
  IonCard,
  IonCardContent,
  IonInput,
  IonItem,
  IonLabel,
  IonButtons,
  IonIcon,
  IonRouterLink,
} from "@ionic/react";
import React, {useState, FC, FormEvent} from "react";
import {useTranslation} from "react-i18next";
import { useAppSelector } from "src/hooks/useAppSelector";
import "./ResetPasswordForm.scss";
import {InputHelperText} from "src/components";
import {eyeOffOutline, eyeOutline} from "ionicons/icons";
import {validateIdenticalTexts, validatePassword} from "src/utils/validation";
import getLocalOrDefault from "src/utils/getLocalOrDefault";
import getPagePath from "src/utils/getStoreIdCustomPath";

interface ResetPasswordFormProps {
  onChangePassword: (password: string) => Promise<void>;
}

const ResetPasswordForm: FC<ResetPasswordFormProps> = ({onChangePassword}) => {
  const {t} = useTranslation();
  const {storeName, logo: storeLogo} = useAppSelector((state) => state.storeReducer.store);
  const [error, setError] = useState({password: false, confirmPassword: false});
  const [form, setForm] = useState({password: "", confirmPassword: ""});
  const [isShowPassword, setIsShowPassword] = useState(false);

  const loginLink = getPagePath("login");
  const handleChangeForm = (e: CustomEvent): void => {
    const name = (e.target as HTMLIonInputElement).name;
    setForm((prevState) => ({...prevState, [name]: e.detail.value}));
    setError((prevState) => ({...prevState, [name]: false}));
  };

  const hasValidData = (): boolean => {
    const isValidPassword = validatePassword(form.password);
    const isValidConfirmPassword = validateIdenticalTexts(form.password, form.confirmPassword);
    setError({confirmPassword: !isValidConfirmPassword, password: !isValidPassword});
    return isValidConfirmPassword && isValidPassword;
  };

  const handleChangePassword = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    if (!hasValidData()) return;
    onChangePassword(form.password);
  };

  const getShowPasswordIcon = (): string => {
    return isShowPassword ? eyeOutline : eyeOffOutline;
  };

  const handleToggleShowPassword = (): void => {
    setIsShowPassword((prevState) => !prevState);
  };

  const getPasswordInputType = (): "text" | "password" => {
    return isShowPassword ? "text" : "password";
  };

  const renderPasswordError = (): JSX.Element | undefined => {
    if (!error.password) return;
    return <InputHelperText color="danger" text={t("INVALID_PASSWORD")} />;
  };

  const renderConfirmPasswordError = (): JSX.Element | undefined => {
    if (!error.confirmPassword) return;
    return <InputHelperText color="danger" text={t("PASSWORD_MISMATCH")} />;
  };

  return (
    <IonCard className="resetPasswordForm">
      <img src={storeLogo} alt={getLocalOrDefault(storeName)} className="resetPasswordForm__logo" />
      <IonCardContent>
        <form noValidate onSubmit={handleChangePassword}>
          <IonItem>
            <IonLabel position="floating">{t("PAGE.RESET_PASSWORD.NEW_PASSWORD_LABEL")}</IonLabel>
            <IonInput
              placeholder={t("PASSWORD")}
              onIonChange={handleChangeForm}
              type={getPasswordInputType()}
              value={form.password}
              name="password"
            />
            <IonButtons slot="end">
              <IonButton color="primary" onClick={handleToggleShowPassword}>
                <IonIcon slot="icon-only" icon={getShowPasswordIcon()} />
              </IonButton>
            </IonButtons>
          </IonItem>
          {renderPasswordError()}

          <IonItem>
            <IonLabel position="floating">{t("PAGE.RESET_PASSWORD.CONFIRM_PASSWORD_LABEL")}</IonLabel>
            <IonInput
              placeholder={t("CONFIRM_PASSWORD")}
              onIonChange={handleChangeForm}
              type={getPasswordInputType()}
              value={form.confirmPassword}
              name="confirmPassword"
            />
            <IonButtons slot="end">
              <IonButton color="primary" onClick={handleToggleShowPassword}>
                <IonIcon slot="icon-only" icon={getShowPasswordIcon()} />
              </IonButton>
            </IonButtons>
          </IonItem>
          {renderConfirmPasswordError()}
          <IonButton type="submit" className="resetPasswordForm__submit" expand="block">
            {t("PAGE.RESET_PASSWORD.CHANGE_PASSWORD_BUTTON")}
          </IonButton>
          <button type="submit" hidden />
          <IonItem lines="none" className="resetPasswordForm__login">
            <IonRouterLink
              className="resetPasswordForm__loginLink"
              routerOptions={{unmount: true}}
              routerLink={loginLink}
            >
              {t("PAGE.RESET_PASSWORD.LOGIN_BUTTON")}
            </IonRouterLink>
          </IonItem>
        </form>
      </IonCardContent>
    </IonCard>
  );
};

export default ResetPasswordForm;
