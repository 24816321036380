import React, {FC, useState, useRef, useEffect} from "react";
import {
  IonToolbar,
  IonContent,
  IonPage,
  IonButtons,
  IonMenuButton,
  IonButton,
  IonIcon,
  IonSearchbar,
  IonHeader,
  IonActionSheet,
  ActionSheetButton,
  IonMenu,
  IonList,
  IonItem,
  IonCheckbox,
  IonLabel,
  IonItemDivider,
  IonChip,
  IonFooter,
  IonMenuToggle,
} from "@ionic/react";
import {close, trendingUp, trendingDown, swapVertical, list} from "ionicons/icons";

import "./Services.scss";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { useAppSelector } from "src/hooks/useAppSelector";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router";
import { Service } from "src/interfaces/Service";
import {subscribeToServices} from "src/slices/services";
import ServicesList from "src/components/ServicesList/ServicesList";
import {subscribeToServicesCategories, selectAllServiceCategories} from "src/slices/servicesCategories";
import {CartButton} from "src/components";
import AppHelmet from "src/components/AppHelmet/AppHelmet";
import {sortServicesByName} from "src/pages/Services/Helpers";

const Services: FC = () => {
  const {t, i18n} = useTranslation();
  const dispatch = useAppDispatch();
  const pageRef = useRef<HTMLElement>(null);
  const location = useLocation();
  const filteredCategories = new URLSearchParams(location.search).get("categories");
  const [openActionSheet, setOpenActionSheet] = useState(false);
  const {services: allServices} = useAppSelector(({servicesReducer}) => servicesReducer);
  const [services, setServices] = useState(allServices);
  const [isListGrouped, setIsListGrouped] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const categories = useAppSelector(selectAllServiceCategories);
  const [serviceFilter, setServiceFilter] = useState([] as string[]);

  const sortedServices = sortServicesByName(services);

  const actionSheetButtonsDefination = [
    {role: "priceHigh", text: "Highest Price", icon: trendingUp},
    {role: "priceLow", text: "Lowest Price", icon: trendingDown},
    {role: "cancel", text: "cancel", icon: close},
  ];

  useEffect(() => {
    dispatch(subscribeToServices());
    dispatch(subscribeToServicesCategories());
  }, [dispatch]);

  useEffect(() => {
    if (filteredCategories) {
      const categoriesIds = filteredCategories
        .split(",")
        .filter((id) => categories.find((category) => category.id === id));
      setServiceFilter(Array.from(new Set(categoriesIds)));
    } else {
      setServiceFilter([]);
    }
  }, [filteredCategories, categories]);

  useEffect(() => {
    if (searchTerm) {
      const searchTermInTitle = (service: Service) =>
        service.name[i18n.language]?.toString().toLowerCase().search(searchTerm.toLowerCase()) !== -1;
      let filteredServices = allServices.filter((service) => searchTermInTitle(service));
      if (serviceFilter.length) {
        filteredServices = filteredServices.filter((service) => serviceFilter.includes(service.category));
      }
      setServices(filteredServices);
    } else {
      const filteredServices = allServices.filter((service) => serviceFilter.includes(service.category));
      serviceFilter.length ? setServices(filteredServices) : setServices(allServices);
    }
  }, [searchTerm, allServices, i18n.language, serviceFilter]);

  const actionSheetButtons: ActionSheetButton[] = actionSheetButtonsDefination.map(({text, icon, role}) => {
    return {
      role,
      text: t(`SERVICES.LIST_BY_${text.toUpperCase().replace(" ", "_")}`),
      icon,
      handler: () => onActionSheetButonClick(role),
    };
  });

  const onActionSheetButonClick = (key: string) => {
    switch (key) {
      case "categoriesGroup":
        setIsListGrouped(!isListGrouped);
        break;

      case "priceHigh":
        const highestPriceSortedServices = [...services];
        setServices(highestPriceSortedServices.sort((a, b) => b.fees - a.fees));
        break;

      case "priceLow":
        const lowestPriceSortedServices = [...services];
        setServices(lowestPriceSortedServices.sort((a, b) => a.fees - b.fees));
        break;

      default:
        setServices(services);
        break;
    }
  };

  const categoryCheckedHandler = (checked: boolean, id: string) => {
    let filterClone = checked ? [...serviceFilter, id] : serviceFilter.filter((categoryId) => id !== categoryId);
    setServiceFilter(Array.from(new Set(filterClone)));
  };

  const renderChips = () => {
    return (
      <>
        {Boolean(serviceFilter.length) &&
          serviceFilter.map((id) => (
            <IonChip className="categoryChip" key={id}>
              {categories.find((category) => category.id === id)?.name[i18n.language]}
            </IonChip>
          ))}
      </>
    );
  };

  const renderNoSearchResults = () => {
    return (
      <div className="noSearchResults">
        {services.length === 0 && searchTerm && (
          <p>
            {t("SERVICES.NO_SEARCH_RESULTS")} <span>{searchTerm}</span>
          </p>
        )}
      </div>
    );
  };

  const renderFilterMenu = () => {
    return (
      <IonList>
        {categories.map((category) => {
          return (
            <IonItem className="filtermenu__category" key={category.id}>
              <IonLabel color="primary" className="ion-text-wrap">
                {category.name[i18n.language]}
              </IonLabel>
              <IonCheckbox
                onIonChange={(e) => {
                  categoryCheckedHandler(e.detail.checked, category.id);
                }}
                slot="end"
                checked={serviceFilter.includes(category.id)}
              />
            </IonItem>
          );
        })}
      </IonList>
    );
  };

  return (
    <>
      <IonMenu
        className="filtermenu"
        type="overlay"
        side="end"
        disabled={false}
        contentId="services-page"
        menuId="filterMenu"
      >
        <IonContent>
          <AppHelmet pageType="product" />
          <IonItemDivider color="primary">
            <h4>{t("SERVICES.FILTER_MENU_CATEGOREIS_TITLE")}</h4>
          </IonItemDivider>
          <IonList>{renderFilterMenu()}</IonList>
        </IonContent>
        <IonFooter className="filtermenu__footer">
          <IonMenuToggle menu="filterMenu">
            <IonButton
              className="filtermemu__clear"
              fill="outline"
              onClick={() => {
                setServiceFilter([]);
              }}
            >
              {t("SERVICES.FILTER_MENU_CLEAR_BUTTON")}
            </IonButton>
          </IonMenuToggle>
        </IonFooter>
      </IonMenu>
      <IonPage ref={pageRef} id="services-page" className="services-page">
        <IonHeader>
          <IonToolbar className="services-page__toolbar" color="primary">
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>
            <IonSearchbar
              className="toolbar__search"
              placeholder={t("SERVICES.SEARCH_PLACEHOLDER")}
              value={searchTerm}
              onIonChange={(e) => setSearchTerm(e.detail.value!)}
              animated
            ></IonSearchbar>
            <IonButtons slot="end">
              <CartButton />
              <IonMenuButton menu="filterMenu">
                <IonButton>
                  <IonIcon icon={list} slot="icon-only" />
                </IonButton>
              </IonMenuButton>
              <IonButton onClick={() => setOpenActionSheet(true)}>
                <IonIcon icon={swapVertical} slot="icon-only" />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent id="services-content">
          <IonActionSheet
            header={t("SERVICES.LIST_BY_HEADER")}
            translucent={true}
            isOpen={openActionSheet}
            buttons={actionSheetButtons}
            onDidDismiss={() => setOpenActionSheet(false)}
          />
          {renderChips()}
          <ServicesList services={sortedServices} />
        </IonContent>
        {renderNoSearchResults()}
      </IonPage>
    </>
  );
};

export default React.memo(Services);
