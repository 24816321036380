import {
  CollectionReference,
  doc,
  FirestoreError,
  getDoc,
  getDocFromCache,
  onSnapshot,
  collection
} from "firebase/firestore";
import { Service } from "src/interfaces/Service";
import { setServices } from "src/slices/services";
import { store } from "src/store";
import { getFirestoreConverter } from "./converter";
import { getStoreDocRef } from "./store";

const COLLECTION = "services";

const getCollection = (): CollectionReference<Service> => {
  return collection(getStoreDocRef(), COLLECTION).withConverter(getFirestoreConverter<Service>());
};


export const subscribeToServicesFB = async (): Promise<void> => {
  try {
    onSnapshot(
      getCollection(),
      (snapshot) => {
        store.dispatch(setServices(snapshot.docs.map((doc) => doc.data())));
      },
      () => {
        store.dispatch(setServices([]));
      },
    );
  } catch {
    store.dispatch(setServices([]));
  }
};

export const getServiceByIdFB = async (id: string, fromCache = true): Promise<Service | undefined> => {
  try {
    const document = doc(getCollection(), id);
    const snapshot = fromCache ? await getDocFromCache(document) : await getDoc(document);
    return snapshot.data();
  } catch (err) {
    const error = err as FirestoreError;
    if (error.code === "unavailable" && fromCache) {
      return await getServiceByIdFB(id, false);
    }
  }
};

export const getServicesByIdsFB = async (ids: string[]): Promise<Service[]> => {
  return await Promise.all(
    await ids.reduce(async (services, id) => {
      const service = await getServiceByIdFB(id);
      if (service) (await services).push(service);
      return services;
    }, Promise.resolve([] as Service[])),
  );
};
