import React, { FC } from "react";
import { IonNote } from "@ionic/react";
import { useTranslation } from "react-i18next";

const ProductOutOfStock: FC = () => {
  const { t } = useTranslation();

    return (
      <IonNote color="danger">
        {t("PRODUCT_DETAILS.PRODUCT_OUT_OF_STOCK_LABEL")}
      </IonNote>
    );

};

export default ProductOutOfStock;
