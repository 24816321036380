import { IonRadioGroup, RadioGroupChangeEventDetail } from "@ionic/react";
import { FC, Fragment, useCallback, useEffect, useState } from "react";
import AddonItem from "./AddonItem";
import { IonRadioGroupCustomEvent } from "@ionic/core";
import { CartAddon } from "src/interfaces/CartAddon";
import { Addon } from "src/interfaces/Addon";
import { getAddonByIdFB } from "src/firebase/addons";

interface AddonsListProps {
  productAddons: string[];
  selectedAddons: CartAddon[];
  groupId?: string;
  onSelectSingleAddon: (
    addonId: string,
    prevId: string,
    groupId: string
  ) => void;
  onSelectMultipleAddon: (
    addonId: string,
    checked: boolean,
    groupId: string
  ) => void;
}

const AddonsList: FC<AddonsListProps> = ({
  productAddons,
  selectedAddons,
  onSelectMultipleAddon,
  onSelectSingleAddon,
  groupId,
}) => {
  const [addons, setAddons] = useState<Addon[]>([]);

  const fetchAddons = useCallback(async () => {
    const addons = await Promise.all(
      await productAddons.reduce(async (addons, id) => {
        const addon = await getAddonByIdFB(id);
        if (addon) (await addons).push(addon);
        return addons;
      }, Promise.resolve([] as Addon[]))
    );
    setAddons(addons);
  }, [productAddons]);

  useEffect(() => {
    setAddons((prevState) => (prevState.length ? [] : prevState));
    fetchAddons();
  }, [fetchAddons]);

  const singleAddon = selectedAddons.find(
    ({ addonId }) => addons.find(({ id }) => addonId === id)?.isSingle
  );
  const singleAddonId = singleAddon ? singleAddon.addonId : "";

  const handleSelectSingleAddon = (
    e: IonRadioGroupCustomEvent<RadioGroupChangeEventDetail>
  ): void => {
    const { value } = e.detail;
    if (!value) return;
    onSelectSingleAddon(value, singleAddonId, groupId);
  };

  const renderAddons = (isSingle: boolean): JSX.Element[] =>
    productAddons.map((addonId) => {
      const addon = addons.find(({ id }) => id === addonId);
      if (!addon || addon.isSingle !== isSingle) return null;
      const checked = selectedAddons.some((addon) => addon.addonId === addonId);
      return (
        <AddonItem
          key={addonId}
          addon={addon}
          checked={checked}
          groupId={groupId}
          onSelectAddon={onSelectMultipleAddon}
        />
      );
    });

  return (
    <Fragment>
      <IonRadioGroup
        className="ion-margin-bottom"
        onIonChange={handleSelectSingleAddon}
        value={singleAddonId}
      >
        {renderAddons(true)}
      </IonRadioGroup>
      <div className="ion-margin-top-small">{renderAddons(false)}</div>
    </Fragment>
  );
};

export default AddonsList;
