import { FC } from "react";
import { IonContent, IonList } from "@ionic/react";
import { useAppSelector } from "src/hooks/useAppSelector";
import OrderItem from "./OrderItem";
import PagePlaceholder from "src/components/PagePlaceholder";
import { receiptOutline } from "ionicons/icons";
import { useTranslation } from "react-i18next";

const Content: FC = () => {
  const { t } = useTranslation();
  const { orders, isLoaded } = useAppSelector((state) => state.ordersReducer);

  const renderOrders = (): JSX.Element[] =>
    orders.map((order) => <OrderItem key={order.id} order={order} />);

  return (
    <IonContent>
      <PagePlaceholder
        isLoading={!isLoaded}
        icon={receiptOutline}
        isEmpty={!orders.length}
        message={t("NO_ORDERS")}
      />
      <IonList lines="none">{renderOrders()}</IonList>
    </IonContent>
  );
};

export default Content;
