import {IonContent} from "@ionic/react";
import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import { useAppSelector } from "src/hooks/useAppSelector";
import getLocalOrDefault from "src/utils/getLocalOrDefault";
import "./DisabledUser.scss";

const DisabledUser: FC = () => {
  const store = useAppSelector((state) => state.storeReducer.store);
  const {t} = useTranslation();

  const storeName = getLocalOrDefault(store.storeName);

  return (
    <IonContent>
      <div className="disabled-user">
        <div className="disabled-user__content">
          <img alt={storeName} src={store.logo} className="logo__image" />
          <h4>{t("PAGES.DISABLED.HEADER")}</h4>
          <p>{t("PAGES.DISABLED.CONTENT")}</p>
        </div>
      </div>
    </IonContent>
  );
};

export default DisabledUser;
