import { IonModal, IonLabel, IonButton } from "@ionic/react";
import { t } from "i18next";
import { FC } from "react";

interface ReferenceNumberModalProps {
  referenceNumber: string;
  isOpen: boolean;
  onClose: () => void;
}

const ReferenceNumberModal: FC<ReferenceNumberModalProps> = ({
  isOpen,
  onClose,
  referenceNumber,
}) => {
  return (
    <IonModal className="ion-width-fit-content ion-height-fit-content" isOpen={isOpen} onDidDismiss={onClose}>
      <IonLabel
        color="dark"
        className="ion-font-xlarge ion-font-bold ion-margin"
      >
        {referenceNumber}
      </IonLabel>
      <IonButton fill="clear" color="dark" expand="block" onClick={onClose}>
        {t("OK")}
      </IonButton>
    </IonModal>
  );
};

export default ReferenceNumberModal;
