import React, {FC} from "react";
import {View, Text} from "@react-pdf/renderer";
import styles from "./styles";
interface OrderTotalInfoProps {
  total: number;
  voucherCode?: string;
}

const OrderTotalInfo: FC<OrderTotalInfoProps> = ({total, voucherCode}) => {
  function renderVoucherCode(): JSX.Element | undefined {
    if (!voucherCode) return;
    return (
      <View style={styles.tableRow}>
        <View style={styles.tableCellGrow}>
          <Text style={styles.contentText}>
            Voucher code applied: <Text style={[styles.contentText, styles.voucher]}>{voucherCode} </Text>
          </Text>
        </View>
      </View>
    );
  }
  return (
    <View>
      <View style={[styles.table, styles.marginTop]}>
        {renderVoucherCode()}
        <View style={styles.tableRow}>
          <View style={styles.tableCellGrow}>
            <Text style={styles.contentText}>Total</Text>
          </View>
          <View style={styles.tableCell}>
            <Text style={[styles.contentText]}>{total.toFixed(2)} EGP</Text>
          </View>
        </View>
      </View>
    </View>
  );
};

OrderTotalInfo.propTypes = {};
export default OrderTotalInfo;
