import { useState, useEffect, useCallback, FC, useMemo } from "react";
import { IonContent } from "@ionic/react";
import { Product } from "src/interfaces/Product";
import { VariationAttribute } from "src/interfaces/VariationAttribute";
import { Variation } from "src/interfaces/Variation";
import Header from "./Header";
import {
  getProductByIdFB,
  getProductVariationsByIdFB,
} from "src/firebase/products";
import { useParams } from "react-router";
import { CartAddon } from "src/interfaces/CartAddon";
import { uuidv4 } from "src/utils/uuidv4";
import { CartAddonsGroup } from "src/interfaces/CartAddonsGroup";
import { CartCustomVariation } from "src/interfaces/CartCustomVariation";
import { CartRequirement } from "src/interfaces/CartRequirement";
import { PageLoading } from "src/components";
import ProductDetails from "./ProductDetails";
import {
  getSelectedVariation,
  updateCustomVariations,
  updateMultipleAddonsGroups,
  updateRequirements,
  updateSingleAddonsGroups,
  validateProduct,
} from "./helpers";
import { CartProduct } from "src/interfaces/CartProduct";
import {  addProductToCart } from "src/utils/cart";
import { Haptics } from "@capacitor/haptics";
import { useAppSelector } from "src/hooks/useAppSelector";
import useToast from "src/hooks/useToast";
import { useTranslation } from "react-i18next";
import Page from "src/components/Page";
import getLocalOrDefault from "src/utils/getLocalOrDefault";

type Params = {
  productId: string;
};

const ProductDetailsPage: FC = () => {
  const { t } = useTranslation();
  const { productId } = useParams<Params>();
  const launchToast = useToast();

  const { products } = useAppSelector((state) => state.cartReducer.cart);

  const [product, setProduct] = useState<Product>();
  const [variations, setVariations] = useState<Variation[]>([]);
  const [attributes, setAttributes] = useState<VariationAttribute[]>([]);
  const [addons, setAddons] = useState<CartAddon[]>([]);
  const [addonsGroups, setAddonsGroups] = useState<CartAddonsGroup[]>(
    []
  );
  const [customVariations, setCustomVariations] = useState<
    CartCustomVariation[]
  >([]);
  const [requirements, setRequirements] = useState<CartRequirement[]>([]);
  const selectedProduct = useMemo(
    () => getSelectedVariation(variations, attributes) || product,
    [product, attributes, variations]
  );
  const name = useMemo(
    () => (selectedProduct ? getLocalOrDefault(selectedProduct.name) : ""),
    [selectedProduct]
  );
  const quantity = useMemo(() => {
    if (!selectedProduct) return 0;
    const cartProduct = products.find(
      (product) =>
        productId === product.productId &&
        product.variationId ===
          (selectedProduct.id === productId ? "" : selectedProduct.id)
    );
    return cartProduct?.quantity || 0;
  }, [products, productId, selectedProduct]);

  const disabled = !validateProduct(
    product,
    selectedProduct,
    attributes,
    customVariations,
    requirements,
    variations,
    quantity
  );

  const fetchProductAndVariations = useCallback(async () => {
    const product = await getProductByIdFB(productId);
    if (product) {
      setProduct(product);
      const variations = await getProductVariationsByIdFB(productId);
      setVariations(variations);
    }
  }, [productId]);

  useEffect(() => {
    fetchProductAndVariations();
  }, [fetchProductAndVariations]);

  const handleClearAddons = (): void => {
    setAddons((prevState) => (prevState.length ? [] : prevState));
    setAddonsGroups((prevState) => (prevState.length ? [] : prevState));
  };

  const handleClearSelectors = useCallback((): void => {
    handleClearAddons();
    setCustomVariations((prevState) => (prevState.length ? [] : prevState));
    setRequirements((prevState) => (prevState.length ? [] : prevState));
  }, []);

  const handleChangeAttribute = useCallback(
    (id: string, value: string) => {
      setAttributes((prevState) => {
        const isFound = prevState.some((attribute) => attribute.id === id);
        if (!isFound) return [...prevState, { id, value }];
        return prevState.map((attribute) =>
          attribute.id !== id ? attribute : { ...attribute, value }
        );
      });
      handleClearSelectors();
    },
    [handleClearSelectors]
  );

  const selectGroupSingleAddon = (
    addonId: string,
    groupId: string,
    prevId: string
  ): void => {
    setAddonsGroups((prevState) =>
      updateSingleAddonsGroups(prevState, groupId, addonId, prevId)
    );
  };

  const selectSingleAddon = (addonId: string, prevId: string): void => {
    setAddons((prevState) => {
      if (!prevId) return [...prevState, { id: uuidv4(), addonId }];
      return prevState.map((addon) => {
        if (addon.addonId !== prevId) return addon;
        return { ...addon, addonId };
      });
    });
  };

  const selectMultipleAddon = (addonId: string, checked: boolean): void => {
    setAddons((prevState) => {
      if (checked) return [...prevState, { id: uuidv4(), addonId }];
      return prevState.filter((addon) => addon.addonId !== addonId);
    });
  };

  const selectGroupMultipleAddon = (
    addonId: string,
    groupId: string,
    checked: boolean
  ): void => {
    setAddonsGroups((prevState) =>
      updateMultipleAddonsGroups(prevState, groupId, addonId, checked)
    );
  };

  const handleSelectSingleAddon = useCallback(
    (addonId: string, prevId: string, groupId?: string): void => {
      groupId
        ? selectGroupSingleAddon(addonId, groupId, prevId)
        : selectSingleAddon(addonId, prevId);
    },
    []
  );

  const handleSelectMultipleAddon = useCallback(
    (addonId: string, checked: boolean, groupId?: string): void => {
      groupId
        ? selectGroupMultipleAddon(addonId, groupId, checked)
        : selectMultipleAddon(addonId, checked);
    },
    []
  );

  const handleClearAttributes = (): void => {
    setAttributes([]);
  };

  const handleChangeCustomVariation = useCallback(
    (id: string, value: number): void => {
      setCustomVariations((prevState) =>
        updateCustomVariations(prevState, id, value)
      );
    },
    []
  );

  const handleChangeRequirement = useCallback(
    (id: string, value: string): void => {
      setRequirements((prevState) => updateRequirements(prevState, id, value));
    },
    []
  );

  const handleAddToCart = async (): Promise<void> => {
    try {
      const variationId =
        selectedProduct.id === productId ? "" : selectedProduct.id;
      const cartProduct: CartProduct = {
        id: uuidv4(),
        addons,
        addonsGroups,
        customVariations,
        name: selectedProduct.name,
        productId,
        quantity: 1,
        requirements: requirements.length
          ? [{ id: uuidv4(), quantity: 1, requirements }]
          : [],
        variationId,
      };
      await addProductToCart(cartProduct);
      await Haptics.vibrate();
      launchToast(t("DONE"), "success");
    } catch {
      launchToast(t("FAILED"), "error");
    }
  };

  const renderContent = (): JSX.Element => {
    if (!selectedProduct) return <PageLoading />;
    return (
      <ProductDetails
        name={name}
        disabled={disabled}
        product={selectedProduct}
        productAttributes={product.attributes}
        addons={addons}
        addonsGroups={addonsGroups}
        attributes={attributes}
        variations={variations}
        requirements={requirements}
        customVariations={customVariations}
        onAddToCart={handleAddToCart}
        onClearAttributes={handleClearAttributes}
        onClearAddons={handleClearAddons}
        onSelectMultipleAddon={handleSelectMultipleAddon}
        onSelectSingleAddon={handleSelectSingleAddon}
        onChangeCustomVariation={handleChangeCustomVariation}
        onChangeRequirement={handleChangeRequirement}
        onChangeAttribute={handleChangeAttribute}
      />
    );
  };

  return (
    <Page title={name}>
      <Header name={name} />
      <IonContent>{renderContent()}</IonContent>
    </Page>
  );
};

export default ProductDetailsPage;
