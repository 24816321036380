import {Country} from "src/interfaces/Country";

export const OPPA_WEBSITE_URL = "https://www.oppa.app";

export const GOOGLE_MAPS_ADDRESS = "https://www.google.com/maps/@";

export const EGYPT_COUNTRY: Country = {
  flag: "https://flagcdn.com/eg.svg",
  name: "Egypt",
  alpha2Code: "EG",
  callingCodes: ["20"],
};

export const REQUESTS_STORAGE_KEY = "requests";