import React, { useState, useEffect, useCallback } from 'react';
import {
  IonContent,
  IonPage,
  IonActionSheet,
  ActionSheetButton,
  IonText,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonProgressBar,
  IonLoading,
} from '@ionic/react';
import { close, trendingUp, trendingDown } from 'ionicons/icons';
import './Products.scss';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { ProductsList } from 'src/components';
import { useTranslation } from 'react-i18next';
import { getCategories } from 'src/slices/categories';
import { selectStoreId } from 'src/slices/store';
import useDebounce from 'src/hooks/useDebounce';
import { CategoriesFilterMenu } from './components';
import ProductsToolbar from 'src/components/ProductsToolbar/ProductsToolbar';
import ProductsCategoriesChips from 'src/components/ProductsCategoriesChips/ProductsCategoriesChips';
import { getUnits } from 'src/slices/units';
import { getAddonsGroups } from 'src/slices/addonsGroups';
import { getAddons } from 'src/slices/addons';
import { getAttributes } from 'src/slices/attributes';
import AppHelmet from 'src/components/AppHelmet/AppHelmet';
import {
  useInstantSearch,
  Configure,
  useInfiniteHits,
  useSearchBox,
  usePagination,
} from 'react-instantsearch-hooks-web';

enum ProductsSorting {
  priceHigh = 'HIGHEST_PRICE',
  priceLow = 'LOWEST_PRICE',
}

const ACTION_SHEET_BUTTONS = [
  { role: ProductsSorting.priceHigh, text: 'HIGHEST_PRICE', icon: trendingUp },
  { role: ProductsSorting.priceLow, text: 'LOWEST_PRICE', icon: trendingDown },
  { role: 'cancel', text: 'CANCEL', icon: close },
];
const ProductsListPage = () => {
  const { results, status } = useInstantSearch();
  const pagination = usePagination();
  const search = useSearchBox();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const storeId = useAppSelector(selectStoreId);
  const [openActionSheet, setOpenActionSheet] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCategories, setFilteredCategories] = useState<string[]>([]);
  const debouncedSearchTerm = useDebounce(searchTerm, 1000);
  const infiniteHits = useInfiniteHits();
  const isProductsLoading = status === 'loading';

  const storeFilter = [`storeId:${storeId}`];
  const categoryFilter = filteredCategories.map((cat) => `categories:${cat}`);

  useEffect(() => {
    search.refine(debouncedSearchTerm);
  }, [debouncedSearchTerm, search]);

  useEffect(() => {
    dispatch(getUnits());
    dispatch(getCategories());
    dispatch(getAddonsGroups());
    dispatch(getAddons());
    dispatch(getAttributes());
  }, [dispatch]);

  const handleClearFilteredCategories = (): void => {
    setFilteredCategories([]);
  };

  const actionSheetButtons: ActionSheetButton[] = ACTION_SHEET_BUTTONS.map(
    ({ text, icon, role }) => {
      return {
        role,
        text: t(`PRODUCTS.LIST_BY_${text}`),
        icon,
        handler: () => handleProductsSortingSelect(role),
      };
    }
  );

  const handleProductsSortingSelect = (role: string) => {
    // switch (role) {
    //   case ProductsSorting.priceHigh:
    //   case ProductsSorting.priceLow:
    //     setProducts((prevState) => {
    //       const sortedProducts = [...prevState];
    //       sortedProducts.sort((p1, p2) => {
    //         const price1 = p1.salePrice || p1.price;
    //         const price2 = p2.salePrice || p2.price;
    //         if (role === ProductsSorting.priceHigh) return price2 - price1;
    //         return price1 - price2;
    //       });
    //       return sortedProducts;
    //     });
    //     break;
    //   default:
    //     break;
    // }
  };

  const handleToggleSelectCategory = useCallback((categoryId: string): void => {
    setFilteredCategories((prevState) => {
      const isSelected = prevState.includes(categoryId);
      return isSelected ? prevState.filter((id) => categoryId !== id) : [...prevState, categoryId];
    });
  }, []);

  const renderNoSearchResults = (): JSX.Element | undefined => {
    if (!isProductsLoading && results.nbHits === 0 && searchTerm) {
      return (
        <div className='noSearchResults'>
          <IonText>
            <p>
              {t('PRODUCTS.NO_SEARCH_RESULTS')} <span>{searchTerm}</span>
            </p>
          </IonText>
        </div>
      );
    }
  };

  const handleChangeSearchText = (e: CustomEvent): void => {
    setSearchTerm(e.detail.value);
  };

  const handleOpenActionSheet = (): void => {
    setOpenActionSheet(true);
  };

  const handleCloseActionSheet = (): void => {
    setOpenActionSheet(false);
  };

  const handleInfiniteScroll = (e: CustomEvent) => {
    infiniteHits.showMore();

    (e.target as HTMLIonInfiniteScrollElement).complete();
  };

  return (
    <>
      <AppHelmet pageType='product' />
      <Configure facetFilters={[categoryFilter, storeFilter]} />

      <CategoriesFilterMenu
        onClear={handleClearFilteredCategories}
        onToggleSelectCategory={handleToggleSelectCategory}
        selectedCategories={filteredCategories}
      />
      <IonPage id='products-page' className='productsPage'>
        {isProductsLoading && <IonProgressBar type='indeterminate'></IonProgressBar>}
        <IonLoading isOpen={isProductsLoading} message={'Please wait...'} />
        <ProductsToolbar
          searchTerm={searchTerm}
          onChangeSearch={handleChangeSearchText}
          onOpenSortActionSheet={handleOpenActionSheet}
        />

        <IonContent id='products-content'>
          <ProductsCategoriesChips filteredCategories={filteredCategories} />
          {renderNoSearchResults()}

          <ProductsList numFound={results.nbHits} />
          <IonInfiniteScroll
            onIonInfinite={handleInfiniteScroll}
            position='bottom'
            disabled={pagination.isLastPage}
          >
            <IonInfiniteScrollContent className='productsLoader' />
          </IonInfiniteScroll>
          <IonActionSheet
            header={t('PRODUCTS.LIST_BY_HEADER')}
            keyboardClose
            isOpen={openActionSheet}
            buttons={actionSheetButtons}
            onDidDismiss={handleCloseActionSheet}
          />
        </IonContent>
      </IonPage>
    </>
  );
};

export default ProductsListPage;
