import { IonLabel } from "@ionic/react";
import { FC, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { CartRequirement } from "src/interfaces/CartRequirement";
import { RequiredField } from "src/interfaces/Requirement";
import RequirementItem from "./RequirementItem";

interface RequirementsGroupItemProps {
  requirements: CartRequirement[];
  fields: RequiredField[];
}

const Requirements: FC<RequirementsGroupItemProps> = ({
  requirements,
  fields,
}) => {
  const { t } = useTranslation();

  const renderRequirements = (): JSX.Element[] => {
    return requirements.map(({ id, requirementId, value }) => {
      const field = fields.find(({ id }) => id === requirementId);
      if (!field) return null;
      return <RequirementItem key={id} field={field} value={value} />;
    });
  };

  return (
    <Fragment>
      <IonLabel className="ion-margin-top ion-font-bold" color="medium">
        {t("REQUIREMENTS")}
      </IonLabel>
      {renderRequirements()}
    </Fragment>
  );
};

export default Requirements;
