import {StyleSheet} from "@react-pdf/renderer";

const receiptPrinterStyles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    fontFamily: "Cairo",
    margin: 0,
    padding: 2,
  },

  logo: {
    height: 50,
    width: "auto",
    marginBottom: 1,
  },
  header: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginBottom: 2,
  },

  section: {
    fontSize: 7,
    margin: 2,
  },
  heading: {
    fontSize: 9,
    lineHeight: 1.2,
  },
  contentHeader: {
    fontSize: 8,
    lineHeight: 1.5,
  },
  contentText: {
    fontSize: 7,
    lineHeight: 1.5,
  },

  contentHeight: {
    lineHeight: 1.5,
  },

  content: {
    display: "flex",
    width: "100%",
  },

  table: {
    display: "flex",
    width: "100%",
    border: 0.3,
    borderColor: "#333",
    padding: "1 7",
  },
  tableRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    borderStyle: "solid",
    width: "100%",
  },
  tableCell: {
    padding: 1,
  },
  tableCellSmall: {
    padding: 1,
    width: 30,
  },
  tableCellMedium: {
    padding: 1,
    width: 50,
  },
  tableCellGrow: {
    padding: 1,
    flexGrow: 1,
    width: 80,
    maxWidth: 90,
    textOverflow: "ellipsis",
  },
  tableNestedChild: {
    paddingLeft: 5,
  },

  marginTop: {
    marginTop: "10px",
  },
  tableNestedContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
  },

  voucher: {
    color: "#4caf50",
  },

  sideContent: {
    flexDirection: "column",
    justifyContent: "space-between",
    borderStyle: "solid",
    width: "100%",
  },

  sideContentItemPadding: {
    paddingLeft: 15,
  },

  sideContentText: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
  },
  textLeft: {textAlign: "left"},
  textRight: {textAlign: "right"},
  textCenter: {textAlign: "center"},
});

export default receiptPrinterStyles;
