import {IonItem, IonRouterLink} from "@ionic/react";
import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import getPagePath from "src/utils/getStoreIdCustomPath";
import classes from "./ForgetPasswordLink.module.scss";

const ForgetPasswordLink: FC = () => {
  const {t} = useTranslation();

  const forgetPasswordLink = getPagePath("forget-password");

  return (
    <IonItem lines="none" className={classes.forgetpassword}>
      <IonRouterLink className={classes.link} routerLink={forgetPasswordLink}>
        {t("PAGE.LOGIN.FORGET_PASSWORD")}
      </IonRouterLink>
    </IonItem>
  );
};
export default ForgetPasswordLink;
