import {
  InputChangeEventDetail,
  IonInput,
  IonItem,
  IonLabel,
} from "@ionic/react";
import { FC, memo } from "react";
import getLocalOrDefault from "src/utils/getLocalOrDefault";
import { IonInputCustomEvent } from "@ionic/core";
import { RequiredField } from "src/interfaces/Requirement";
import { RequirementType } from "src/enums/RequirementType";

interface RequirementItemProps {
  field: RequiredField;
  value: string;
  onChangeRequirement: (id: string, value: string) => void;
}

const RequirementItem: FC<RequirementItemProps> = ({
  field,
  value,
  onChangeRequirement,
}) => {
  const max = field.hasMaxValue && field.max;
  const min = field.hasMinValue && field.min;

  const handleChangeRequirement = (
    e: IonInputCustomEvent<InputChangeEventDetail>
  ): void => {
    onChangeRequirement(field.id, e.detail.value);
  };

  const renderInput = (): JSX.Element => {
    const isNumber = field.type === RequirementType.number;
    const type = isNumber ? "number" : "text";
    const mode = isNumber ? "numeric" : "text";
    switch (field.type) {
      case RequirementType.text:
      case RequirementType.number:
        return (
          <IonInput
            type={type}
            inputMode={mode}
            inputmode={mode}
            max={max}
            min={min}
            onIonChange={handleChangeRequirement}
            value={value}
          />
        );
      default:
        return;
    }
  };

  return (
    <IonItem>
      <IonLabel position="floating">
        {getLocalOrDefault(field.label)}
      </IonLabel>
      {renderInput()}
    </IonItem>
  );
};

export default memo(RequirementItem);
