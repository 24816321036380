import { firestore } from "src/firebase";
import { store } from "src/store";
import { CMS } from "src/interfaces/CMS";
import { setCMS } from "src/slices/cms";
import getStoreId from "src/utils/getStoreId";
import { collection, CollectionReference, doc, DocumentReference, onSnapshot } from "firebase/firestore";
import { getFirestoreConverter } from "./converter";

const TEST_CMS_COLLECTION_NAME = "test-cms";
const LIVE_CMS_COLLECTION_NAME = "live-cms";

const getCollection = (): CollectionReference<CMS> => {
  const isTest = new URLSearchParams(window.location.search).get("test");
  return collection(firestore, isTest ? TEST_CMS_COLLECTION_NAME : LIVE_CMS_COLLECTION_NAME).withConverter(getFirestoreConverter<CMS>());
};

const getDocument = async (): Promise<DocumentReference<CMS>> => {
  const storeId = await getStoreId()
  return doc(getCollection(), storeId)
}

export const subscribeToCMSFB = async (): Promise<void> => {
  onSnapshot(await getDocument(), (snapshot) => {
    const cms = snapshot.data() as CMS;
    store.dispatch(setCMS(cms));
  });
};
