import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {subscribeToServicesCategoriesFB} from "../firebase/servicesCategories";
import {Category} from "../interfaces/Category";
import {AppThunk, RootState} from "src/store";

interface ServicesCategoriesState {
  servicesCategories: Category[];
  isLoaded: boolean;
}

const initialState: ServicesCategoriesState = {
  servicesCategories: [],
  isLoaded: false,
};

export const serviceCategories = createSlice({
  name: "SERVICES-CATEGORIES",
  initialState,
  reducers: {
    setServiceCategories: (state: ServicesCategoriesState, action: PayloadAction<Category[]>) => {
      const categories = action.payload;
      state.servicesCategories = categories;
      state.isLoaded = true;
    },
  },
});

export const {setServiceCategories} = serviceCategories.actions;

export const subscribeToServicesCategories = (): AppThunk => async (dispatch, getState) => {
  const isLoaded = getState().servicesCategoriesReducer.isLoaded;
  if (isLoaded) return;
  subscribeToServicesCategoriesFB();
};

export const selectAllServiceCategories = (state: RootState) => state.servicesCategoriesReducer.servicesCategories;
export const selectAllChildCategoriesById = (state: RootState) => (id: string) => {
  return state.servicesCategoriesReducer.servicesCategories.filter(
    (serviceCategory) => serviceCategory.parentId === id,
  );
};
export const selectServiceCategoryById =
  (state: RootState) =>
  (id: string): Category | null => {
    return (
      state.servicesCategoriesReducer.servicesCategories.find((serviceCategory) => serviceCategory.id === id) || null
    );
  };
export const selectLatestServiceCategories =
  (state: RootState) =>
  (count: number): Category[] =>
    state.servicesCategoriesReducer.servicesCategories
      .filter((serviceCategory) => !serviceCategory.parentId)
      .slice(-1 * count);

export default serviceCategories.reducer;
