import { IonItem } from "@ionic/react";
import { FC, useCallback, useEffect, useState, memo } from "react";
import ItemPlaceholder from "../ItemPlaceholder";
import { CartServicesPackage } from "src/interfaces/CartServicesPackage";
import { ServicesPackage } from "src/interfaces/ServicesPackage";
import { getServicesPackageByIdFB } from "src/firebase/servicesPackages";
import CartPackageItem from "./CartPackageItem";
import { Service } from "src/interfaces/Service";
import { getServiceByIdFB } from "src/firebase/services";

interface PackageItemProps {
  cartPackage: CartServicesPackage;
  onRemovePackage: (id: string) => void;
}

const PackageItem: FC<PackageItemProps> = ({
  cartPackage,
  onRemovePackage,
}) => {
  const [servicesPackage, setServicesPackage] = useState<ServicesPackage>();
  const [services, setServices] = useState<Service[]>([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const fetchServices = useCallback(async (servicesIds: string[]) => {
    const services = await Promise.all(
      await servicesIds.reduce(async (services, id) => {
        const service = await getServiceByIdFB(id);
        if (service) (await services).push(service);
        return services;
      }, Promise.resolve([] as Service[]))
    );
    setServices(services);
  }, []);

  const fetchPackage = useCallback(async () => {
    if (servicesPackage) return;
    const { packageId } = cartPackage;
    const fetchedPackage = await getServicesPackageByIdFB(packageId);
    if (fetchedPackage) {
      setServicesPackage(fetchedPackage);
      await fetchServices(fetchedPackage.services);
    }
    setIsLoaded(true);
  }, [cartPackage, servicesPackage, fetchServices]);

  useEffect(() => {
    fetchPackage();
  }, [fetchPackage]);

  const renderContent = (): JSX.Element => {
    if (!servicesPackage || !services.length) {
      return (
        <ItemPlaceholder
          item={cartPackage}
          isLoaded={isLoaded}
          onRemoveItem={onRemovePackage}
        />
      );
    }
    return (
      <CartPackageItem
        servicesPackage={servicesPackage}
        cartPackage={cartPackage}
        onRemovePackage={onRemovePackage}
        services={services}
      />
    );
  };

  return <IonItem lines="full">{renderContent()}</IonItem>;
};

export default memo(PackageItem);
