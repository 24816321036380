import {
  IonButtons,
  IonCard,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLoading,
  IonMenuButton,
  IonPage,
  IonCardContent,
  IonTitle,
  IonToolbar,
  IonLabel,
} from "@ionic/react";
import React, { useState, FC, FormEvent } from "react";
import classes from "./ForgetPassword.module.scss";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "src/hooks/useAppSelector";
import { validateEmail } from "src/utils/validation";
import { InputHelperText, LoginLink } from "src/components";
import useToast from "src/hooks/useToast";
import useAuth from "src/hooks/useAuth";
import { InputChangeEventDetail } from "@ionic/core";
import useIsMountedRef from "src/hooks/useIsMountedRef";
import { SubmitButton } from "./components";
import getLocalOrDefault from "src/utils/getLocalOrDefault";

const ForgetPassword: FC = () => {
  const { t } = useTranslation();
  const launchToast = useToast();
  const { sendResetPasswordEmail } = useAuth();
  const isMountedRef = useIsMountedRef();

  const store = useAppSelector((state) => state.storeReducer.store);

  const [email, setEmail] = useState("");
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const hasValidEmail = (): boolean => {
    const isValidEmail = validateEmail(email);
    return isValidEmail;
  };

  const handleSendEmail = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    const isValidEmail = hasValidEmail();
    if (!isValidEmail) {
      setIsError(true);
      return;
    }
    try {
      setIsLoading(true);
      await sendResetPasswordEmail(email);
      launchToast("PAGE.FORGET_PASSWORD.EMAIL_SENT_SUCCESS_MESSAGE", "success");
    } catch {
      launchToast("PAGE.FORGET_PASSWORD.EMAIL_SENT_ERROR_MESSAGE", "danger");
    } finally {
      if (isMountedRef.current) {
        setIsLoading(false);
      }
    }
  };

  const handleChangeEmail = (e: CustomEvent<InputChangeEventDetail>): void => {
    const value = e.detail.value!;
    setEmail(value);
  };

  const renderEmailError = (): JSX.Element | undefined => {
    if (!isError || validateEmail(email)) return;
    return <InputHelperText color="danger" text={t("INVALID_EMAIL_ADDRESS")} />;
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{t("PAGE.FORGET_PASSWORD.HEADER_TITLE")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={classes.root}>
        <IonCard className={classes.card}>
          <img src={store.logo} alt={getLocalOrDefault(store.storeName)} className={classes.logo} />
          <IonCardContent>
            <form noValidate onSubmit={handleSendEmail}>
              <IonItem>
                <IonLabel position="floating">{t("EMAIL_ADDRESS")}</IonLabel>
                <IonInput
                  onIonChange={handleChangeEmail}
                  placeholder={t("EMAIL_ADDRESS")}
                  value={email}
                  type="email"
                  inputMode="email"
                  inputmode="email"
                />
              </IonItem>
              {renderEmailError()}

              <SubmitButton />
              <LoginLink />
            </form>
          </IonCardContent>
        </IonCard>
        <IonLoading isOpen={isLoading} message={t("PLEASE_WAIT")} />
      </IonContent>
    </IonPage>
  );
};
export default ForgetPassword;
