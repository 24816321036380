import {
  CollectionReference,
  onSnapshot,
  collection,
  getDoc,
  doc,
  getDocFromCache,
  FirestoreError,
} from "firebase/firestore";
import { ServicesPackage } from "src/interfaces/ServicesPackage";
import { setServicesPackages } from "src/slices/servicesPackages";
import { store } from "src/store";
import { getFirestoreConverter } from "./converter";
import { getStoreDocRef } from "./store";

const COLLECTION = "services-packages";

const getCollection = (): CollectionReference<ServicesPackage> => {
  return collection(getStoreDocRef(), COLLECTION).withConverter(getFirestoreConverter<ServicesPackage>());
};

export const subscribeToServicesPackagesFB = async (): Promise<void> => {
  try {
    onSnapshot(
      getCollection(),
      (snapshot) => {
        store.dispatch(setServicesPackages(snapshot.docs.map((doc) => doc.data())));
      },
      () => {
        store.dispatch(setServicesPackages([]));
      },
    );
  } catch {
    store.dispatch(setServicesPackages([]));
  }
};

export const getServicesPackageByIdFB = async (id: string, fromCache = true): Promise<ServicesPackage | undefined> => {
  try {
    const document = doc(getCollection(), id);
    const snapshot = fromCache ? await getDocFromCache(document) : await getDoc(document);
    return snapshot.data();
  } catch (err) {
    const error = err as FirestoreError;
    if (error.code === "unavailable" && fromCache) {
      return await getServicesPackageByIdFB(id, false);
    }
  }
};
