import {IonNote} from "@ionic/react";
import React, {FC} from "react";
import {useTranslation} from "react-i18next";

const SignupNote: FC = () => {
  const {t} = useTranslation();

  return <IonNote color="success">{t("SIGN_UP.NOTE")}</IonNote>;
};
export default SignupNote;
