import { IonButton, IonButtons, IonIcon } from "@ionic/react";
import React, { FC } from "react";

import { eyeOffOutline, eyeOutline } from "ionicons/icons";

interface ShowPasswordTogglerProps {
  onToggleShowPassword: () => void;
  isShowPassword: boolean;
}

const ShowPasswordToggler: FC<ShowPasswordTogglerProps> = ({ isShowPassword, onToggleShowPassword }) => {
  const icon = isShowPassword ? eyeOutline : eyeOffOutline;
  return (
    <IonButtons slot="end">
      <IonButton onClick={onToggleShowPassword}>
        <IonIcon slot="icon-only" icon={icon} />
      </IonButton>
    </IonButtons>
  );
};
export default ShowPasswordToggler;
