import {
  addDoc,
  collection,
  CollectionReference,
  deleteDoc,
  doc,
  DocumentReference,
  FieldValue,
  getDocs,
  onSnapshot,
  Query,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { auth } from "src/firebase";
import { Customer, CustomerKey } from "src/interfaces/Customer";
import { setCustomer, setIsDeleting } from "src/slices/customer";
import { setIsSynced } from "src/slices/cart";
import { store } from "src/store";
import { syncCart } from "src/utils/cart";
import { registerFCMToken } from "src/utils/registerFCMToken";
import { getFirestoreConverter } from "./converter";
import { getStoreDocRef } from "./store";
import { Cart } from "src/interfaces/Cart";
import { signOut } from "firebase/auth";
import { restoreSettingsLS } from "src/storage/settings";

const COLLECTION_NAME = "customers";

let unsubscribe = null;

const getCollection = (): CollectionReference<Customer> =>
  collection(getStoreDocRef(), COLLECTION_NAME).withConverter(getFirestoreConverter<Customer>());

const getCustomerQuery = (): Query<Customer> => {
  if (!auth.currentUser) throw Error();
  return query(getCollection(), where(CustomerKey.userId, "==", auth.currentUser.uid));
};

const getCustomerDoc = (): DocumentReference<Customer> => {
  const { customer } = store.getState().customerReducer;
  if (!customer) throw Error();
  return doc(getCollection(), customer.id);
};

const generateCustomerFormUser = async (): Promise<Customer> => {
  const { user } = store.getState().userReducer;
  if (!user) throw Error();
  const { country, email, firstName, lastName, phone, id: userId } = user;
  const { paymentMethods } = store.getState().storeReducer.store;
  const { isDarkTheme, language } = await restoreSettingsLS();
  return {
    firstName, lastName, email, phone, userId,
    paymentMethod: paymentMethods[0], isDarkTheme, language,
    addresses: [], country, location: null,
    disabled: false,
    cart: { services: [], products: [], servicesPackages: [] },
    requirements: [], walletCredit: 0, fcmTokens: [],
  } as Customer;
};


const createCustomerFB = async (): Promise<void> => {
  await addDoc(getCollection(), await generateCustomerFormUser());
};

export const checkCustomerAuthorizationFB = async (): Promise<boolean> => {
  const snapshot = await getDocs(getCustomerQuery());
  if (snapshot.docs.length) return true;
  const { privateRegisteration } = store.getState().storeReducer.store;
  return !privateRegisteration;
};

export const subscribeToCustomerFB = async (): Promise<void> => {
  try {
    if (unsubscribe) return;
    unsubscribe = onSnapshot(getCustomerQuery(), async (snapshot) => {
      try {
        const document = snapshot.docs[0];
        const { customer } = store.getState().customerReducer;
        if (!document) {
          if (!customer) {
            await createCustomerFB();
          } else {
            await signOut(auth);
          }
          return;
        }
        registerFCMToken();
        store.dispatch(setCustomer(document.data()));
        syncCart();
      } catch {
        store.dispatch(setCustomer(null));
      }
    }, () => {
      store.dispatch(setCustomer(null));
    });
  } catch {
    store.dispatch(setCustomer(null));
  }
};

export const unsubscribeToCustomerFB = (): void => {
  store.dispatch(setCustomer(null));
  store.dispatch(setIsSynced(false));
  if (!unsubscribe) return;
  unsubscribe();
  unsubscribe = null;
};


export const updateCustomerLanguageFB = async (language: string): Promise<void> => {
  await updateDoc(getCustomerDoc(), { language });
};

export const changeCustomerIsDarkThemeFB = async (isDarkTheme: boolean): Promise<void> => {
  await updateDoc(getCustomerDoc(), { isDarkTheme });
};

export const updateCustomerFB = async (customer: Customer): Promise<void> => {
  await setDoc(getCustomerDoc(), customer, { merge: true });
};

export const updateCustomerFCMTokensFB = async (fcmTokens: string[]): Promise<void> => {
  await updateDoc(getCustomerDoc(), { fcmTokens });
};

export const deleteCustomerFB = async (): Promise<void> => {
  store.dispatch(setIsDeleting());
  await deleteDoc(getCustomerDoc());
};

export const updateCartFB = async (updatedCart: Cart): Promise<void> => {
  const cart = updatedCart as unknown as FieldValue;
  await updateDoc(getCustomerDoc(), { cart });
}