import { IonLabel } from "@ionic/react";
import { FC } from "react";
import getLocalOrDefault from "src/utils/getLocalOrDefault";
import { Addon } from "src/interfaces/Addon";
import Price from "src/components/Price";

interface AddonItemProps {
  addon: Addon;
}

const AddonItem: FC<AddonItemProps> = ({ addon }) => {
  return (
    <div className="ion-display-flex ion-justify-content-between">
      <IonLabel>{getLocalOrDefault(addon.name)}</IonLabel>
      <IonLabel className="ion-font-bold ion-overflow-visible">
        <Price price={addon.price} />
      </IonLabel>
    </div>
  );
};

export default AddonItem;
