import { collection, CollectionReference, doc, FirestoreError, getDoc, getDocFromCache, getDocs } from "firebase/firestore";
import { AddonsGroup } from "src/interfaces/AddonsGroup";
import { getFirestoreConverter } from "./converter";
import { getStoreDocRef } from "./store";

const COLLECTION = "addons-groups";

const getCollection = (): CollectionReference<AddonsGroup> => {
  return collection(getStoreDocRef(), COLLECTION).withConverter(getFirestoreConverter<AddonsGroup>());
};

export const getAddonsGroupsFB = async (): Promise<AddonsGroup[]> => {
  try {
    const snapshot = await getDocs(getCollection());
    return snapshot.docs.map((doc) => doc.data());
  } catch {
    return [];
  }
};


export const getAddonsGroupByIdFB = async (id: string, fromCache = true): Promise<AddonsGroup | undefined> => {
  try {
    const document = doc(getCollection(), id);
    const snapshot = fromCache ? await getDocFromCache(document) : await getDoc(document);
    return snapshot.data();
  } catch (err) {
    const error = err as FirestoreError;
    if (error.code === "unavailable" && fromCache) {
      return await getAddonsGroupByIdFB(id, false);
    }
  }
};
