import {getDomainStoreId} from "src/firebase/domains";
import {App} from "@capacitor/app"
import {Device} from "@capacitor/device";
import hasCustomDomain from "./hasCustomDomain";
import { Capacitor } from "@capacitor/core";

const getPath = (path: string) => {
  return path.split("/").filter((p) => p !== "");
};

export default async function getStoreId(): Promise<string> {
  if (Capacitor.isNativePlatform()) {
    const {id} = await App.getInfo();
    const {platform} = await Device.getInfo();
    if ((platform === "android" || platform === "ios") && (id.includes("-") || id.includes("_"))) {
      return platform === "ios" ? id.split(".").pop()! : id.split(".").pop()!.replace(/_/g, "-");
    }
    return id
      .split(".")
      .pop()!
      .split(/(?=[A-Z1])/)
      .join("-")
      .toLowerCase();
  } else {
    const isCustomeDomain = hasCustomDomain();
    if (isCustomeDomain) {
      const storeId = await getDomainStoreId();
      if (storeId) return storeId;
    }
    return getPath(window.location.pathname)[0];
  }
}
