import { FC } from "react";
import { IonLabel } from "@ionic/react";
import { concClasses } from "src/utils/concClasses";

interface ErrorLabelProps {
  className?: string;
}

const ErrorLabel: FC<ErrorLabelProps> = ({ children, className }) => {
  return (
    <IonLabel
      color="danger"
      className={concClasses(className, "ion-font-small ion-font-bold")}
    >
      {children}
    </IonLabel>
  );
};

export default ErrorLabel;
