import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getCategoriesFB } from "../firebase/categories";
import { Category } from "../interfaces/Category";
import { AppThunk, RootState } from "src/store";

interface CategoriesState {
  categories: Category[];
  isLoaded: boolean;
  isError: boolean;
}

const initialState: CategoriesState = {
  categories: [],
  isLoaded: false,
  isError: false,
};

export const categoriesSlice = createSlice({
  name: "CATEGORIES",
  initialState,
  reducers: {
    setCategories: (state, action: PayloadAction<CategoriesState>) => action.payload
  },
});

export const { setCategories } = categoriesSlice.actions;

export const getCategories = (): AppThunk => async (dispatch, getState) => {
  const isLoaded = getState().categoriesReducer.isLoaded;
  if (isLoaded) return;
  try {
    const categories = await getCategoriesFB();
    dispatch(setCategories({ categories, isLoaded: true, isError: false }));
  } catch {
    dispatch(setCategories({ categories: [], isLoaded: true, isError: false }));
  }
}

export const selectCategoriesState = (state: RootState) => state.categoriesReducer;
export const selectAllCategories = (state: RootState): Category[] => state.categoriesReducer.categories;
export const selectCategoryById = (state: RootState) => (id: string): Category | null => {
  return state.categoriesReducer.categories.find((category) => category.id === id) || null;
};
export const selectLatestCategories = (state: RootState) => (count: number): Category[] =>
  state.categoriesReducer.categories.filter((category) => !category.parentId).slice(-1 * count);

export default categoriesSlice.reducer;
