import {FC, useEffect} from "react";
import {IonHeader, IonToolbar, IonContent, IonPage, IonButtons, IonMenuButton, IonTitle} from "@ionic/react";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { useAppSelector } from "src/hooks/useAppSelector";
import {CartButton, NotificationsButton, CMSContent, DefaultHome} from "src/components";
import useAuth from "src/hooks/useAuth";
import {getUnits} from "src/slices/units";
import {getAddonsGroups} from "src/slices/addonsGroups";
import {getAddons} from "src/slices/addons";
import {getAttributes} from "src/slices/attributes";
import {StoreSpecialization} from "src/interfaces/Store";
import getLocalOrDefault from "src/utils/getLocalOrDefault";
import AppHelmet from "src/components/AppHelmet/AppHelmet";

const Home: FC = () => {
  const dispatch = useAppDispatch();
  const {isAuthenticated} = useAuth();
  const store = useAppSelector((state) => state.storeReducer.store);
  const {home: content, homeBackground} = useAppSelector((state) => state.cmsReducer.cms);

  const storeName = getLocalOrDefault(store.storeName);

  const isColorBackground = homeBackground?.startsWith("#");

  const background = homeBackground
    ? isColorBackground
      ? homeBackground
      : `url(${homeBackground}) 0 0 / cover`
    : "transparent";

  useEffect(() => {
    dispatch(getUnits());
    dispatch(getAddonsGroups());
    dispatch(getAddons());
    dispatch(getAttributes());
  }, [dispatch]);

  const renderHomeContent = (): JSX.Element => {
    if (!content.length) return <DefaultHome store={store} />;
    return <CMSContent content={content} />;
  };

  const renderNotificationsButton = (): JSX.Element | undefined => {
    if (!isAuthenticated) return;
    return <NotificationsButton />;
  };

  const renderCartButton = (): JSX.Element | undefined => {
    if (
      !store.storeSpecializations.includes(StoreSpecialization.services) &&
      !store.storeSpecializations.includes(StoreSpecialization.ecommerce)
    )
      return;
    return <CartButton />;
  };

  return (
    <IonPage>
      <AppHelmet pageType="store" />
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonButtons slot="end">
            {renderCartButton()}
            {renderNotificationsButton()}
          </IonButtons>
          <IonTitle>{storeName}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent style={{"--background": background}}>{renderHomeContent()}</IonContent>
    </IonPage>
  );
};

export default Home;
