import { FC } from "react";
import { IonContent } from "@ionic/react";
import Header from "./Header";
import Products from "./products";
import ServicesPackages from "./servicesPackages";
import Services from "./services";
import Footer from "./footer";
import Page from "src/components/Page";
import { useTranslation } from "react-i18next";
import Placeholder from "./Placeholder";

const CartPage: FC = () => {
  const { t } = useTranslation();
  return (
    <Page title={t("CART")}>
      <Header />
      <IonContent>
        <Products />
        <Services />
        <ServicesPackages />
        <Placeholder />
      </IonContent>
      <Footer />
    </Page>
  );
};

export default CartPage;
