import { IonLabel } from "@ionic/react";
import { FC } from "react";
import getLocalOrDefault from "src/utils/getLocalOrDefault";
import { ServicesAddon } from "src/interfaces/ServicesAddon";
import Price from "src/components/Price";
import ValueUnit from "src/components/ValueUnit";
import { useTranslation } from "react-i18next";

interface AddonItemProps {
  addon: ServicesAddon;
}

const AddonItem: FC<AddonItemProps> = ({ addon }) => {
  const { t } = useTranslation();
  return (
    <div className="ion-display-flex ion-justify-content-between">
      <IonLabel>{getLocalOrDefault(addon.name)}</IonLabel>
      <IonLabel className="ion-font-bold ion-overflow-visible">
        <ValueUnit value={addon.duration} unit={t(addon.durationUnit)} />
        &nbsp;&nbsp;
        <Price price={addon.price} />
      </IonLabel>
    </div>
  );
};

export default AddonItem;
