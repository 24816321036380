import { FC, Fragment } from "react";
import { useTranslation } from "react-i18next";
import ValueUnit from "src/components/ValueUnit";
import { TimeUnit } from "src/enums/TimeUnit";

interface SessionsInDurationProps {
  sessions: number;
  duration: number;
  unit: TimeUnit;
}

const SessionsInDuration: FC<SessionsInDurationProps> = ({
  sessions,
  duration,
  unit,
}) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <ValueUnit isSup value={sessions} unit={t("SESSIONS")} />
      &nbsp;/&nbsp;
      <ValueUnit isSup value={duration} unit={t(unit)} />
    </Fragment>
  );
};

export default SessionsInDuration;
