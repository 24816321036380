import {IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonButton, IonIcon, IonSpinner} from "@ionic/react";
import React, {FC, useState, useEffect, useCallback} from "react";
import {CMSAttendance} from "src/interfaces/CMS";
import getLocalOrDefault from "src/utils/getLocalOrDefault";
import { getGPSLocation } from "src/utils/getGPSLocation";
import useToast from "src/hooks/useToast";
import {useTranslation} from "react-i18next";
import {CMSFormFieldsView} from "..";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { useAppSelector } from "src/hooks/useAppSelector";
import useIsMountedRef from "src/hooks/useIsMountedRef";
import {RequirementAttachmentFiles, RequirementAttachmentLinks} from "src/interfaces/Requirement";
import { AttendanceType } from "src/enums/AttendanceType";
import {uploadAttendanceFile, createAttendance, subscribeToAttendances} from "src/slices/attendances";
import classes from "./styles.module.scss";
import initCMSFormRequirements from "src/utils/initCMSFormRequirements";
import useAuth from "src/hooks/useAuth";
import { FormFieldType } from "src/enums/FormFieldType";
import { FormRequestField } from "src/interfaces/FormRequestField";

interface CMSAttendanceViewProps {
  attendance: CMSAttendance;
}

const CMSAttendanceView: FC<CMSAttendanceViewProps> = ({attendance}) => {
  const launchToast = useToast();
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const isMountedRef = useIsMountedRef();

  const {isLoaded, attendances} = useAppSelector((state) => state.attendancesReducer);
  const {customer} = useAppSelector((state) => state.customerReducer);
  const {isAuthenticated} = useAuth();

  const forms = useAppSelector((state) => state.formsReducer.forms);

  const [requirements, setRequirements] = useState<FormRequestField[]>([]);
  const [attachments, setAttachments] = useState<RequirementAttachmentFiles[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isError, setIsError] = useState(false);

  const filteredAttendances = attendances.filter(({attendanceId}) => attendance.attendanceId === attendanceId);
  const lastAttendance = filteredAttendances[0];
  const type = lastAttendance?.type === AttendanceType.checkIn ? AttendanceType.checkOut : AttendanceType.checkIn;

  const isCheckIn = type === AttendanceType.checkIn;
  const attendanceCheck = isCheckIn ? attendance.checkIn : attendance.checkOut;

  const attendanceCheckForm = forms.find(({id}) => id === attendanceCheck.formId);

  const isDisabled = isSubmitting || !isLoaded || !customer;

  const initializeRequirements = useCallback(() => {
    if (isLoaded || !isAuthenticated) {
      const fields = initCMSFormRequirements(attendanceCheckForm?.fields||[]);
      setRequirements(fields);
      setAttachments([]);
    }
  }, [isLoaded, attendanceCheckForm, isAuthenticated]);

  useEffect(() => {
    if (customer) {
      dispatch(subscribeToAttendances());
    }
  }, [dispatch, customer]);

  useEffect(() => {
    initializeRequirements();
  }, [initializeRequirements]);

  const handleChangeAttachments = (attachments: RequirementAttachmentFiles[]): void => {
    setAttachments(attachments);
  };

  const handleChangeRequirements = (fields: FormRequestField[]): void => {
    setRequirements(fields);
  };

  const uploadAttachments = async (): Promise<RequirementAttachmentLinks[]> => {
    return await Promise.all(
      attachments.map(async ({id, files}) => {
        const links = await Promise.all(
          files.map(async (file) => (await dispatch(uploadAttendanceFile(file))) as string),
        );
        return {id, links};
      }),
    );
  };

  const hasValidAttendance = (): boolean => {
    return requirements.every((field) => {
      const isRequired = attendanceCheckForm?.fields.find(({id}) => id === field.id)?.isRequired;
      if (!isRequired) return true;
      switch (field.type) {
        case FormFieldType.file:
          const attachment = attachments.find((attachment) => attachment.id === field.id);
          return attachment?.files.length;
        case FormFieldType.selectForm:
          return field.selectFormItemsRequirements.length;
        default:
          return field.value;
      }
    });
  };

  const handleSubmitAttendance = async (): Promise<void> => {
    const isValidAttendance = hasValidAttendance();
    setIsError(!isValidAttendance);
    if (!isValidAttendance) return;
    try {
      setIsSubmitting(true);
      const location = attendanceCheck.isIncludesLocation ? await getGPSLocation() : null;
      const links = await uploadAttachments();
      const requirementsWithLinks = requirements.map((field) => {
        const attachment = links.find((attachmentLink) => attachmentLink.id === field.id);
        return attachment ? {...field, attachments: attachment.links} : field;
      });
      setRequirements(requirementsWithLinks);
      await dispatch(createAttendance(attendance.attendanceId, requirementsWithLinks, location, type));
      launchToast(t("PAGE.HOME.SUBMIT_FORM_SUCCESS_MESSAGE"), "success");
    } catch {
      launchToast(t("PAGE.HOME.SUBMIT_FORM_ERROR_MESSAGE"), "danger");
    } finally {
      if (isMountedRef.current) {
        setIsSubmitting(false);
      }
    }
  };

  const renderSubmitButtonLoader = (): JSX.Element | undefined => {
    if (!isSubmitting) return <IonIcon slot="start" />;
    return <IonSpinner className={classes.loader} />;
  };

  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>{getLocalOrDefault(attendance.label)}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <CMSFormFieldsView
          attachments={attachments}
          fields={attendanceCheckForm?.fields || []}
          isError={isError}
          isSubmitting={isSubmitting}
          onChangeAttachments={handleChangeAttachments}
          onChangeRequirements={handleChangeRequirements}
          requirements={requirements}
        />
        <IonButton
          className="ion-margin-top"
          disabled={isDisabled}
          onClick={handleSubmitAttendance}
          color="primary"
          expand="block"
        >
          {renderSubmitButtonLoader()}
          {t(type)}
        </IonButton>
      </IonCardContent>
    </IonCard>
  );
};
export default CMSAttendanceView;
