import configs from "./configs";
import { initializeApp } from "firebase/app";
import { clearIndexedDbPersistence, getFirestore, enableIndexedDbPersistence, FirestoreError } from "firebase/firestore";
import { getAuth, initializeAuth, indexedDBLocalPersistence as persistence } from "firebase/auth";
import { Capacitor } from "@capacitor/core";

const app = initializeApp(configs);
export const auth = Capacitor.isNativePlatform() ? initializeAuth(app, { persistence }) : getAuth(app);
export const firestore = getFirestore(app);
clearIndexedDbPersistence(firestore);
enableIndexedDbPersistence(firestore).catch((err) => {
  const error = err as FirestoreError;
  if (error.code === "failed-precondition") {
    // Multiple tabs open, persistence can only be enabled
    // in one tab at a a time.
  } else if (error.code === "unimplemented") {
    // The current browser does not support all of the
    // features required to enable persistence
  }
});
