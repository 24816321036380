import { IonLabel, IonSpinner } from "@ionic/react";
import { FC, Fragment } from "react";
import getLocalOrDefault from "src/utils/getLocalOrDefault";
import DeleteButton from "./deleteButton";
import { useTranslation } from "react-i18next";
import { BaseCartItem } from "src/interfaces/BaseCartItem";

interface ItemPlaceholderProps {
  item: BaseCartItem;
  isLoaded: boolean;
  onRemoveItem: (id: string) => void;
}

const ItemPlaceholder: FC<ItemPlaceholderProps> = ({
  item,
  isLoaded,
  onRemoveItem,
}) => {
  const { t } = useTranslation();

  const renderAction = (): JSX.Element => {
    if (!isLoaded) return <IonSpinner name="lines-small" />;
    return <DeleteButton id={item.id} onRemoveItem={onRemoveItem} />;
  };

  const renderOutOfStock = (): JSX.Element => {
    if (!isLoaded) return;
    return (
      <IonLabel className="ion-font-bold ion-font-small" color="danger">
        {t("OUT_OF_STOCK")}
      </IonLabel>
    );
  };

  return (
    <Fragment>
      <div className="ion-flex-grow">
        <IonLabel>{getLocalOrDefault(item.name)}</IonLabel>
        {renderOutOfStock()}
      </div>
      {renderAction()}
    </Fragment>
  );
};

export default ItemPlaceholder;
