import { FC } from "react";
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCol,
  InputChangeEventDetail,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { IonInputCustomEvent } from "@ionic/core";
import FloatingLabelInput from "src/components/FloatingLabelInput";

interface VoucherProps {
  onChangeVoucherCode: (e: IonInputCustomEvent<InputChangeEventDetail>) => void;
}

const Voucher: FC<VoucherProps> = ({ onChangeVoucherCode }) => {
  const { t } = useTranslation();

  return (
    <IonCol size="12">
      <IonCard className="ion-no-margin">
        <IonCardHeader>
          <IonCardSubtitle className="ion-font-bold">
            {t("VOUCHER")}
          </IonCardSubtitle>
        </IonCardHeader>
        <IonCardContent>
          <FloatingLabelInput
            autocorrect="off"
            autocomplete="off"
            placeholder={t("VOUCHER_CODE")}
            onIonChange={onChangeVoucherCode}
          />
        </IonCardContent>
      </IonCard>
    </IonCol>
  );
};

export default Voucher;
