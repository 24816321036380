import {IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar} from "@ionic/react";
import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import { useAppSelector } from "src/hooks/useAppSelector";
import {PageLoading} from "src/components";
import classes from "./StoreWallet.module.scss";
import {StoreWalletCard} from "./components";

const StoreWallet: FC = () => {
  const {t} = useTranslation();

  const {customer} = useAppSelector((state) => state.customerReducer);

  const renderStoreWallet = (): JSX.Element => {
    return customer ? <StoreWalletCard /> : <PageLoading />;
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{t("STORE_WALLET")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={classes.root}>{renderStoreWallet()}</IonContent>
    </IonPage>
  );
};
export default StoreWallet;
