import React, {FC} from "react";
import PropTypes from "prop-types";
import {View, Text} from "@react-pdf/renderer";
import styles from "./styles";
import {Order} from "src/interfaces/Order";
import OrderInvoiceBooking from "./OrderInvoiceBooking";

interface BookingsInfoProps {
  order: Order;
}
const BookingsInfo: FC<BookingsInfoProps> = ({order}) => {
  function renderBookings(): JSX.Element[] {
    return order.services?.map((service, index: number) => {
      return <OrderInvoiceBooking service={service} index={index} key={service.id} />;
    });
  }

  function renderBookingsTable(): JSX.Element {
    if (!order.services.length) return <></>;
    return (
      <View style={[styles.table]}>
        <View>
          <View style={styles.tableRow}>
            <View style={styles.tableCell}>
              <Text style={styles.h5}>Booking details</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCellSmall}>
              <Text style={styles.h6}>#</Text>
            </View>
            <View style={styles.tableNestedContainer}>
              <View style={styles.tableCellGrow}>
                <Text style={styles.h6}>Name</Text>
              </View>
              <View style={styles.tableCellMedium}>
                <Text style={[styles.h6]}>Price</Text>
              </View>
              <View style={styles.tableCellMedium}>
                <Text style={[styles.h6]}>Addons</Text>
              </View>
              <View style={styles.tableCellMedium}>
                <Text style={[styles.h6]}>Date</Text>
              </View>
              <View style={styles.tableCellMedium}>
                <Text style={[styles.h6]}>Time</Text>
              </View>
              <View style={styles.tableCellMedium}>
                <Text style={[styles.h6]}>Duration</Text>
              </View>
              <View style={styles.tableCellMedium}>
                <Text style={[styles.h6, styles.alignCenter]}>Subtotal</Text>
              </View>
            </View>
          </View>
        </View>
        {renderBookings()}
      </View>
    );
  }
  return <View style={styles.marginTop}>{renderBookingsTable()}</View>;
};

BookingsInfo.propTypes = {
  order: PropTypes.any.isRequired,
};

export default BookingsInfo;
