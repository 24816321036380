import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {subscribeToFormsFB} from "src/firebase/forms";
import {Form} from "src/interfaces/Form";
import {AppThunk} from "../store";

interface FormsState {
  forms: Form[];
  isLoaded: boolean;
}

const initialState: FormsState = {
  forms: [],
  isLoaded: false,
};

export const formsSlice = createSlice({
  name: "forms",
  initialState,
  reducers: {
    setForms: (state: FormsState, action: PayloadAction<Form[]>) => {
      state.forms = action.payload;
      state.isLoaded = true;
    },
  },
});

export const {setForms} = formsSlice.actions;

export const subscribeToForms =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    if (getState().formsReducer.isLoaded) return;
    subscribeToFormsFB();
  };

export default formsSlice.reducer;
