import React, { Fragment, useEffect, useState, FC, useRef } from 'react';
import {
  IonPage,
  IonHeader,
  IonButtons,
  IonToolbar,
  IonContent,
  IonTitle,
  IonSearchbar,
  IonButton,
  IonIcon,
  IonBackButton,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonLoading,
  IonProgressBar,
} from '@ionic/react';
import './CategoryProductList.scss';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { getCategories } from 'src/slices/categories';
import { useTranslation } from 'react-i18next';
import { closeOutline, searchOutline } from 'ionicons/icons';
import { useParams } from 'react-router';
import { ProductsList, HorizontalCategoriesList } from 'src/components';
import getLocalOrDefault from 'src/utils/getLocalOrDefault';
import getPagePath from 'src/utils/getStoreIdCustomPath';
import {
  Configure,
  useInfiniteHits,
  useInstantSearch,
  usePagination,
} from 'react-instantsearch-hooks-web';

interface ParamsType {
  id: string;
}

const CategoryProductListPage: FC = () => {
  const { results, status } = useInstantSearch();
  const pagination = usePagination();
  const infiniteHits = useInfiniteHits();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { id } = useParams<ParamsType>();
  const { hideSubcategories } = useAppSelector((state) => state.storeReducer.store);
  const storeId = useAppSelector((state) => state.storeReducer.store.id!);
  const categories = useAppSelector((state) => state.categoriesReducer.categories);

  const [search, setSearch] = useState('');
  const searchRef = useRef<any>(null);
  const [isShowSearch, setIsShowSearch] = useState(false);

  const category = categories.find((category) => category.id === id);
  const childCategoriesIds = categories
    .filter((category) => category.parentId === id)
    .map(({ id }) => id);
  const categoryName = category ? getLocalOrDefault(category.name) : '';
  const categoriesLink = getPagePath('categories');
  const storeFilter = [`storeId:${storeId}`];
  const categoryFilter = categories.map((cat) => `categories:${id}`);

  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  useEffect(() => {
    if (isShowSearch) {
      searchRef.current.setFocus();
    }
  }, [isShowSearch]);

  const handleChangeSearch = (e: CustomEvent): void => {
    setSearch(e.detail.value);
  };

  const handleCloseSearch = (): void => {
    setIsShowSearch(false);
    setSearch('');
  };

  const handleOpenSearch = (): void => {
    setIsShowSearch(true);
  };

  const filterSearchedCategories = (categoriesIds: string[]): string[] => {
    if (!search) return categoriesIds;
    return categoriesIds.filter((categoryId) => {
      const category = categories.find(({ id }) => id === categoryId);
      if (!category) return false;
      return Object.values(category.name).some((name) =>
        name!.toLowerCase().includes(search.toLowerCase())
      );
    });
  };

  const renderSearchIcon = (): JSX.Element | undefined => {
    if (childCategoriesIds.length) {
      return (
        <IonButtons slot='end'>
          <IonButton ref={searchRef} onClick={handleOpenSearch}>
            <IonIcon icon={searchOutline} />
          </IonButton>
        </IonButtons>
      );
    }
  };

  const renderHeader = (): JSX.Element => {
    if (isShowSearch && childCategoriesIds.length) {
      return (
        <Fragment>
          <IonSearchbar
            placeholder={t('CATEGORIES.SEARCH')}
            value={search}
            ref={searchRef}
            onIonChange={handleChangeSearch}
            animated
          />
          <IonButtons slot='end'>
            <IonButton onClick={handleCloseSearch}>
              <IonIcon icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </Fragment>
      );
    }
    return (
      <Fragment>
        <IonTitle>{categoryName}</IonTitle>
        {renderSearchIcon()}
      </Fragment>
    );
  };

  const handleInfiniteScroll = (e: CustomEvent) => {
    infiniteHits.showMore();

    (e.target as HTMLIonInfiniteScrollElement).complete();
  };

  const renderSubCategories = (): JSX.Element | undefined => {
    if (hideSubcategories) return;
    return (
      <HorizontalCategoriesList categoriesIds={filterSearchedCategories(childCategoriesIds)} />
    );
  };

  return (
    <IonPage id='category-products-page'>
      <Configure facetFilters={[categoryFilter, storeFilter]} />

      {status === 'loading' && <IonProgressBar type='indeterminate'></IonProgressBar>}
      <IonLoading isOpen={status === 'loading'} message={'Please wait...'} />
      <IonHeader color='primary'>
        <IonToolbar color='primary'>
          <IonButtons slot='start'>
            <IonBackButton text='' defaultHref={categoriesLink} />
          </IonButtons>
          {renderHeader()}
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {renderSubCategories()}
        <ProductsList numFound={results.nbHits} />
        <IonInfiniteScroll
          onIonInfinite={handleInfiniteScroll}
          position='bottom'
          disabled={pagination.isLastPage}
        >
          <IonInfiniteScrollContent className='productsLoader' />
        </IonInfiniteScroll>
      </IonContent>
    </IonPage>
  );
};

export default CategoryProductListPage;
