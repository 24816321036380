import { FC } from "react";
import { IonItem, IonLabel, IonTextarea } from "@ionic/react";
import { JSX as IonJSX } from "@ionic/core/components";
import ErrorLabel from "./ErrorLabel";

interface FloatingLabelTextareaProps extends IonJSX.IonTextarea {
  error?: boolean;
  helperText?: string;
  className?: string;
}

const FloatingLabelTextarea: FC<FloatingLabelTextareaProps> = ({
  helperText,
  error,
  className,
  ...props
}) => {
  const renderHelperText = (): JSX.Element => {
    if (!error || !helperText) return;
    return (
      <ErrorLabel className="ion-margin-horizontal">{helperText}</ErrorLabel>
    );
  };
  return (
    <div className={className}>
      <IonItem>
        <IonLabel position="floating">{props.placeholder}</IonLabel>
        <IonTextarea {...props} placeholder="" />
      </IonItem>
      {renderHelperText()}
    </div>
  );
};

export default FloatingLabelTextarea;
