import { CustomerRequirement } from "src/interfaces/CustomerRequirement";
import {RequirementAttachmentFile, RequirementAttachmentLink} from "src/interfaces/Requirement";
import {CustomersRequirementsGroup} from "src/interfaces/Store";
import {uploadCustomerFile} from "src/slices/customer";
import {store} from "src/store";

export const generateInitialRequirements = (requirements: CustomersRequirementsGroup[]): CustomerRequirement[] => {
  return requirements.reduce((requirements, group) => {
    if (group.inRegistration) {
      group.fields.forEach((field) => {
        const requirement: CustomerRequirement = {
          fieldId: field.id,
          groupId: group.id,
          type: field.type,
          isRequired: field.isRequired,
          value: "",
        };
        requirements.push(requirement);
      });
    }
    return requirements;
  }, [] as CustomerRequirement[]);
};

export const uploadRequirementsAttachments = async (
  attachments: RequirementAttachmentFile[],
  requirements: CustomerRequirement[],
): Promise<RequirementAttachmentLink[]> => {
  return await Promise.all(
    attachments.map(async ({id, file}) => {
      const uploadedLink = requirements.find(({fieldId}) => fieldId === id);
      if (uploadedLink?.value) {
        const link = uploadedLink.value as string;
        return {id, link};
      }
      const link = (await store.dispatch(uploadCustomerFile(file))) as string;
      return {id, link};
    }),
  );
};

export const updateRequirementsWithAttachmentsLinks =  (
  attachmentsLinks: RequirementAttachmentLink[],
  requirements: CustomerRequirement[],
): CustomerRequirement[] => {
  return requirements.map((requirement) => {
    const attachment = attachmentsLinks.find((attachmentLink) => attachmentLink.id === requirement.fieldId);
    return attachment ? { ...requirement, value: attachment.link } : requirement;
  });
};
