import React, { FC } from "react";
import { View, Text } from "@react-pdf/renderer";
import { OrderProduct } from "src/interfaces/OrderProduct";
import styles from "../styles";
import getLocalOrDefault from "src/utils/getLocalOrDefault";


interface ReceiptProductProps {
  product: OrderProduct;
  index: number;
}

const ReceiptProduct: FC<ReceiptProductProps> = ({ index, product }) => {
  const total = `${product.total.toFixed(2)}`;

  function renderProductCustomeVariations():
    | JSX.Element[]
    | JSX.Element
    | undefined {
    if (product.customVariations.length === 0) return;

    const mappedVariations = product.customVariations.map((variation) => {
      const price = variation.isPercentage
        ? (variation.price * variation.value) / 100
        : variation.price * variation.value;

      return (
        <View
          key={variation.id}
          style={[styles.sideContent, styles.sideContentItemPadding]}
        >
          <View>
            <Text style={[styles.contentText, styles.sideContentText]}>
              Name: {getLocalOrDefault(variation.name)}
            </Text>
          </View>
          <View>
            <Text style={[styles.contentText, styles.sideContentText]}>
              Price: {price}
            </Text>
          </View>
        </View>
      );
    });
    return (
      <View>
        <View>
          <Text style={styles.contentText}>Custom Variations</Text>
        </View>
        <View>{mappedVariations}</View>
      </View>
    );
  }


  return (
    <View>
      <View style={styles.tableRow}>
        <View style={styles.tableNestedContainer}>
          <View style={styles.tableCellSmall}>
            <Text style={styles.contentText}>{index + 1}</Text>
          </View>
         
          <View style={styles.sideContent}>
            <View>
              <Text style={styles.contentText}>
                Product Name: {getLocalOrDefault(product.productName)}
              </Text>
            </View>
            <View>
              <Text style={[styles.contentText]}>
                Quantity : {product.quantity}
              </Text>
            </View>
            <View>
              <Text style={[styles.contentText]}>
                SKU : {product.sku || "-"}
              </Text>
            </View>
            <View>
              <Text style={[styles.contentText]}>Price: {`${total} EGP`}</Text>
            </View>
          </View>
        </View>
      </View>
      {renderProductCustomeVariations()}
      <View>
     
      </View>
    </View>
  );
};

ReceiptProduct.propTypes = {};
export default ReceiptProduct;
