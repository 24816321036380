import { FC } from "react";
import {
  IonModal,
  IonContent,
} from "@ionic/react";
import ModalHeader from "./ModalHeader";

interface GalleryImageModalProps {
  image: string;
  name: string;
  isOpen: boolean;
  onClose: () => void;
}

const GalleryImageModal: FC<GalleryImageModalProps> = ({
  image,
  name,
  isOpen,
  onClose,
}) => {
  return (
    <IonModal isOpen={isOpen} onDidDismiss={onClose}>
      <ModalHeader onClose={onClose} hideSubmit />
      <IonContent>
        <img src={image} alt={name} className="ion-position-center"  width="100%" />
      </IonContent>
    </IonModal>
  );
};

export default GalleryImageModal;
