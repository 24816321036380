import { IonButton, IonLabel } from "@ionic/react";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { CartAddon } from "src/interfaces/CartAddon";
import AddonsList from "./AddonsList";
import { ServicesAddon } from "src/interfaces/ServicesAddon";

interface AddonsProps {
  addons: ServicesAddon[];
  selectedAddons: CartAddon[];
  onClearAddons: () => void;
  onSelectSingleAddon: (addonId: string, prevId: string) => void;
  onSelectMultipleAddon: (addonId: string, checked: boolean) => void;
}

const Addons: FC<AddonsProps> = ({
  addons,
  selectedAddons,
  onClearAddons,
  onSelectMultipleAddon,
  onSelectSingleAddon,
}) => {
  const { t } = useTranslation();

  const disabled = !selectedAddons.length;

  return (
    <div className="ion-margin-bottom">
      <div className="ion-display-flex ion-justify-content-between">
        <IonLabel
          className="ion-font-bold ion-margin-vertical-auto"
          color="medium"
        >
          {t("ADDONS")}
        </IonLabel>
        <IonButton
          disabled={disabled}
          onClick={onClearAddons}
          fill="clear"
          color="secondary"
          size="small"
        >
          {t("CLEAR")}
        </IonButton>
      </div>
      <AddonsList
        addons={addons}
        selectedAddons={selectedAddons}
        onSelectMultipleAddon={onSelectMultipleAddon}
        onSelectSingleAddon={onSelectSingleAddon}
      />
    </div>
  );
};

export default Addons;
