import { IonButton, IonIcon } from "@ionic/react";
import { FC } from "react";
import classes from "./styles.module.scss";
import { trash } from "ionicons/icons";
import { concClasses } from "src/utils/concClasses";

interface DeleteButtonProps {
  id: string;
  onRemoveItem: (id: string) => void;
}

const DeleteButton: FC<DeleteButtonProps> = ({ id, onRemoveItem }) => {
  const handleRemoveItem = (): void => {
    onRemoveItem(id);
  };

  return (
    <IonButton
      onClick={handleRemoveItem}
      className={concClasses(classes.root, "ion-no-padding")}
      fill="clear"
      color="danger"
      shape="round"
    >
      <IonIcon icon={trash} />
    </IonButton>
  );
};

export default DeleteButton;
