import {
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  SelectChangeEventDetail,
} from "@ionic/react";
import { FC, memo, useCallback, useEffect, useState } from "react";
import { Attribute } from "src/interfaces/Attribute";
import getLocalOrDefault from "src/utils/getLocalOrDefault";
import { IonSelectCustomEvent } from "@ionic/core";
import { hasValidAttributeOption } from "./helpers";
import { VariationAttribute } from "src/interfaces/VariationAttribute";
import { Variation } from "src/interfaces/Variation";
import { getAttributeByIdFB } from "src/firebase/attributes";

interface AttributeItemProps {
  attributeId: string;
  values: string[];
  variations: Variation[];
  selectedAttributes: VariationAttribute[];
  onChangeAttribute: (id: string, value: string) => void;
}

const AttributeItem: FC<AttributeItemProps> = ({
  attributeId,
  values,
  variations,
  selectedAttributes,
  onChangeAttribute,
}) => {
  const [attribute, setAttribute] = useState<Attribute>();

  const fetchAttribute = useCallback(async () => {    
    setAttribute(await getAttributeByIdFB(attributeId));
  }, [attributeId]);

  useEffect(() => {
    setAttribute((prevState) => (prevState ? null : prevState));
    fetchAttribute();
  }, [fetchAttribute]);

  const value = attribute
    ? selectedAttributes.find(({ id }) => id === attribute.id)?.value || ""
    : "";

  const handleChangeValue = (
    e: IonSelectCustomEvent<SelectChangeEventDetail<string>>
  ): void => {
    const { value } = e.detail;
    if (!value) return;
    onChangeAttribute(attribute.id, value);
  };

  const renderOptions = (): JSX.Element[] => {
    return values.map((productValue) => {
      const value = attribute.values.find(({ id }) => id === productValue);
      if (!value) return null;
      const disabled = !hasValidAttributeOption(
        productValue,
        attribute.id,
        values,
        variations,
        selectedAttributes
      );
      return (
        <IonSelectOption
          disabled={disabled}
          value={productValue}
          key={productValue}
        >
          {getLocalOrDefault(value.name)}
        </IonSelectOption>
      );
    });
  };

  if (!attribute) return null;

  return (
    <IonItem>
      <IonLabel>{getLocalOrDefault(attribute.name)}</IonLabel>
      <IonSelect
        onIonChange={handleChangeValue}
        value={value}
        interface="popover"
      >
        {renderOptions()}
      </IonSelect>
    </IonItem>
  );
};

export default memo(AttributeItem);
