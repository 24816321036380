import { FC, CSSProperties } from "react";
import { CMSBanner, CMSBannerAlign } from "src/interfaces/CMS";

interface BannerProps {
  banner: CMSBanner;
}

const Banner: FC<BannerProps> = ({ banner }) => {
  const objectFit =
    banner.align === CMSBannerAlign.stretch
      ? "fill"
      : banner.align === CMSBannerAlign.crop
      ? "cover"
      : "contain";

  const maxHeight = !banner.isCustom
    ? "100%"
    : banner.isPercentage
    ? `${banner.maxHeight}vh`
    : banner.maxHeight;

  const bannerStyle: CSSProperties = {
    maxHeight,
    objectFit,
  };

  const renderImage = (): JSX.Element | undefined => {
    if (!banner.src) return;
    return (
      <img width="100%" src={banner.src} alt="banner" style={bannerStyle} />
    );
  };

  return (
    <div className="ion-margin-top-small ion-text-center">{renderImage()}</div>
  );
};

export default Banner;
