import { FC, Fragment } from "react";
import Addons from "./Addons";
import Requirements from "./Requirements";
import { OrderService } from "src/interfaces/OrderService";

interface ServiceDetailsProps {
  service: OrderService;
}

const ServiceDetails: FC<ServiceDetailsProps> = ({ service }) => {
  return (
    <Fragment>
      <Addons addons={service.addons} />
      <Requirements requirements={service.requirements} />
    </Fragment>
  );
};

export default ServiceDetails;
