import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import { addMissingTranslationKeysFB } from "./firebase/translations";
import API from "./constants/api";

i18n
  .use(initReactI18next)
  .use(Backend)
  .init({
    backend: { loadPath: process.env.REACT_APP_API_BASE_URL.concat("/", API.I18N_LOAD_TRANSLATIONS) },
    missingKeyHandler: addMissingTranslationKeysFB,
    saveMissingTo: "all",
    fallbackLng: "en",
    saveMissing: true,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

