import React, {useEffect, FC, Fragment} from "react";
import {IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar} from "@ionic/react";
import {useTranslation} from "react-i18next";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { useAppSelector } from "src/hooks/useAppSelector";
import {useParams} from "react-router";
import {BookingInfo} from "./components";
import {PageLoading} from "src/components";
import {subscribeToBookings} from "src/slices/bookings";

type URLParams = {
  bookingId: string;
};

const BookingDetails: FC = () => {
  const {bookingId} = useParams<URLParams>();
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const selectedBooking = useAppSelector((state) => state.bookingsReducer.bookings.find(({id}) => bookingId === id));

  useEffect(() => {
    dispatch(subscribeToBookings());
  }, [dispatch]);

  const renderBookingDetailsView = (): JSX.Element => {
    if (!selectedBooking) return <PageLoading />;
    return (
      <Fragment>
        <BookingInfo booking={selectedBooking} />
      </Fragment>
    );
  };

  return (
    <IonPage id="bookingDetails">
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{t("PAGE.BOOKING_DETAILS.PAGE_TITLE")}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>{renderBookingDetailsView()}</IonContent>
    </IonPage>
  );
};

export default BookingDetails;
