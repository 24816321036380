import { IonLabel } from "@ionic/react";
import { FC, Fragment } from "react";
import getLocalOrDefault from "src/utils/getLocalOrDefault";
import Price from "src/components/Price";
import { OrderCustomVariation } from "src/interfaces/OrderCustomVariation";

interface CustomVariationItemProps {
  customVariation: OrderCustomVariation;
}

const CustomVariationItem: FC<CustomVariationItemProps> = ({
  customVariation,
}) => {
  const renderPercentage = (): JSX.Element => {
    if (!customVariation.isPercentage) return;
    return <Fragment>&#x25;</Fragment>;
  };
  return (
    <div className="ion-display-flex ion-justify-content-between">
      <IonLabel>{getLocalOrDefault(customVariation.name)}</IonLabel>
      <div className="ion-display-flex">
        <IonLabel>
          ( {customVariation.value} {renderPercentage()} )
        </IonLabel>
        &nbsp;
        <IonLabel className="ion-font-bold">
          <Price price={customVariation.price} />
        </IonLabel>
      </div>
    </div>
  );
};

export default CustomVariationItem;
