import { FC, Fragment, useState } from "react";
import { IonSlide, IonSlides } from "@ionic/react";
import { Image } from "src/interfaces/Image";
import GalleryImageModal from "./GalleryImageModal";

interface GalleryProps {
  gallery: Image[];
  name: string;
}

const Gallery: FC<GalleryProps> = ({ gallery, name }) => {
  const [selectedImage, setSelectedImage] = useState("");

  const isOpen = !!selectedImage;
  const key = gallery.map(({ src }) => src).join(",");

  const handleCloseModal = (): void => {
    setSelectedImage("");
  };

  const handleSelectImage = (src: string): (() => void) => {
    return (): void => {
      setSelectedImage(src);
    };
  };

  const renderSlides = (): JSX.Element[] =>
    gallery.map(({ src }) => (
      <IonSlide key={src} onClick={handleSelectImage(src)}>
        <img src={src} alt={name} height="50%" />
      </IonSlide>
    ));

  return (
    <Fragment>
      <IonSlides key={key} className="ion-half-height" pager={true}>
        {renderSlides()}
      </IonSlides>
      <GalleryImageModal
        isOpen={isOpen}
        image={selectedImage}
        name={name}
        onClose={handleCloseModal}
      />
    </Fragment>
  );
};

export default Gallery;
