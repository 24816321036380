import { FC, Fragment, MouseEvent } from "react";
import {
  IonModal,
  IonContent,
  IonDatetime,
  IonItem,
  IonIcon,
  IonLabel,
  DatetimeChangeEventDetail,
} from "@ionic/react";
import classes from "./styles.module.scss";
import { calendar } from "ionicons/icons";
import { IonDatetimeCustomEvent } from "@ionic/core";

interface DatePickerProps {
  isOpen: boolean;
  label: string;
  date: string;
  min?: string;
  max?: string;
  onSelectDate: (e: IonDatetimeCustomEvent<DatetimeChangeEventDetail>) => void;
  onClose: () => void;
  onOpen: () => void;
}

const DatePicker: FC<DatePickerProps> = ({
  isOpen,
  date,
  label,
  min,
  max,
  onSelectDate,
  onClose,
  onOpen,
}) => {
  const value = date ? new Date(date).toLocaleDateString() : "";

  const handleClickPicker = (e: MouseEvent<HTMLIonDatetimeElement>): void => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleSelectDate = (
    e: IonDatetimeCustomEvent<DatetimeChangeEventDetail>
  ): void => {
    onSelectDate(e);
    onClose();
  };

  return (
    <Fragment>
      <IonItem lines="full" button detail onClick={onOpen}>
        <IonIcon
          icon={calendar}
          className="ion-margin-end-small"
          color="dark"
          size="small"
        />
        <IonLabel>{label}</IonLabel>
        <IonLabel slot="end">{value}</IonLabel>
      </IonItem>
      <IonModal isOpen={isOpen} className={classes.root} onDidDismiss={onClose}>
        <IonContent className="ion-no-background" onClick={onClose}>
          <IonDatetime
            color="dark"
            presentation="date"
            className="ion-position-center ion-padding-vertical"
            onClick={handleClickPicker}
            value={date}
            onIonChange={handleSelectDate}
            min={min}
            max={max}
          />
        </IonContent>
      </IonModal>
    </Fragment>
  );
};

export default DatePicker;
