import { FC, ChangeEvent, useEffect, useState } from "react";
import {
  IonButton,
  IonButtons,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonThumbnail,
} from "@ionic/react";
import { attachOutline, trashOutline } from "ionicons/icons";
import { RequirementType } from "src/enums/RequirementType";

interface AttachmentRequirementProps {
  label: string;
  id: string;
  attachment?: File;
  type: RequirementType.image | RequirementType.file;
  onChangeAttachment: (e: ChangeEvent<HTMLInputElement>) => void;
  onRemoveAttchment: (id: string) => void;
}

const AttachmentRequirement: FC<AttachmentRequirementProps> = ({
  label,
  attachment,
  id,
  type,
  onChangeAttachment,
  onRemoveAttchment,
}) => {
  const [attachmentURL, setAttachmentURL] = useState("");

  const accept = type === RequirementType.image ? "image/*" : "*";

  useEffect(() => {
    try {
      if (!attachment) return setAttachmentURL("");
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>): void => {
        if (!e.target) {
          setAttachmentURL("");
          return;
        }
        const url = e.target.result as string;
        setAttachmentURL(url);
      };
      reader.readAsDataURL(attachment);
    } catch (error) {
      console.error(error);
    }
  }, [attachment]);

  const handleRemoveAttachment = (id: string): (() => void) => {
    return (): void => {
      onRemoveAttchment(id);
    };
  };

  const renderImage = (): JSX.Element | undefined => {
    if (type === RequirementType.file) return;
    return (
      <IonThumbnail slot="start">
        <IonImg src={attachmentURL} />
      </IonThumbnail>
    );
  };

  const renderAttachment = (): JSX.Element | undefined => {
    if (!attachment) return;
    return (
      <IonItem lines="none">
        {renderImage()}
        <IonLabel>{attachment.name}</IonLabel>
        <IonButtons slot="end">
          <IonButton onClick={handleRemoveAttachment(id)} color="danger">
            <IonIcon size="small" icon={trashOutline} slot="icon-only" />
          </IonButton>
        </IonButtons>
      </IonItem>
    );
  };

  return (
    <div>
      <IonButton>
        <IonIcon slot="start" icon={attachOutline} />
        {label}
        <input
          type="file"
          accept={accept}
          onChange={onChangeAttachment}
          className="ion-position-absolute ion-no-opacity ion-full-width ion-full-height ion-pointer"
        />
      </IonButton>
      {renderAttachment()}
    </div>
  );
};
export default AttachmentRequirement;
