import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getAddonsFB } from "src/firebase/addons";
import { Addon } from "src/interfaces/Addon";
import { AppThunk } from "../store";

interface AddonsState {
    addons: Addon[];
    isLoaded: boolean;
}

const initialState: AddonsState = {
    addons: [],
    isLoaded: false,
};

export const addonsGroupsSlice = createSlice({
    name: "ADDONS",
    initialState,
    reducers: {
        setAddons: (state: AddonsState, action: PayloadAction<Addon[]>) => {
            state.addons = action.payload;
            state.isLoaded = true;
        },
    },
});

export const { setAddons } = addonsGroupsSlice.actions;

export const getAddons = (): AppThunk => async (dispatch, getState): Promise<void> => {
    const isLoaded = getState().addonsReducer.isLoaded;
    if (isLoaded) return;
    const addons = await getAddonsFB();
    dispatch(setAddons(addons));
};


export default addonsGroupsSlice.reducer;
