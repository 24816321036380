import { getMessaging, getToken } from "firebase/messaging";
import { store } from "../store";
import { saveCustomerFCMToken } from "src/slices/customer";
import { PushNotifications } from "@capacitor/push-notifications";
import { LocalNotifications } from "@capacitor/local-notifications";
import { isNativePlatform } from "./isNativePlatform";

let didSendMessage = false;

async function sendMessageToServiceWorker() {
  const regs = await navigator.serviceWorker.getRegistrations();
  const fcmSW = regs.find((reg) => reg.scope.includes("firebase-cloud-messaging-push-scope"));
  fcmSW?.active?.postMessage({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_ADMIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MSG_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_GA_ID,
  });
}

const registerWebToken = async (): Promise<void> => {
  try {
    const messaging = getMessaging();
    const token = await getToken(messaging, { vapidKey: process.env.REACT_APP_VAPID_KEY });
    if (!didSendMessage) {
      await sendMessageToServiceWorker();
      didSendMessage = false;
    }
    store.dispatch(saveCustomerFCMToken(token));
  } catch {
  }
}

const registerNativeToken = async (): Promise<void> => {
  PushNotifications.addListener("registration", (token) => {
    store.dispatch(saveCustomerFCMToken(token.value));
  });

  PushNotifications.addListener("pushNotificationReceived", (notification) => {
    const localNotification = {
      id: Date.now(),
      title: notification.data.title,
      body: notification.data.body,
    };
    LocalNotifications.schedule({ notifications: [localNotification] });
  });

  let permStatus = await PushNotifications.checkPermissions();

  if (permStatus.receive === "prompt") {
    permStatus = await PushNotifications.requestPermissions();
  }
  if (permStatus.receive !== "granted") {
    return;
  }

  await PushNotifications.register();
}

export const registerFCMToken = (): void => {
  const { isFCMRegistered } = store.getState().customerReducer;
  if (isFCMRegistered) return;  
  isNativePlatform ? registerNativeToken() : registerWebToken();
}
