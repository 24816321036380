import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonList,
} from "@ionic/react";
import { FC, Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import PackageItem from "./PackageItem";
import { OrderServicesPackage } from "src/interfaces/OrderServicesPackage";
import ServicesModal from "./ServicesModal";

interface ServicesPackagesProps {
  servicesPackages: OrderServicesPackage[];
}

const ServicesPackages: FC<ServicesPackagesProps> = ({ servicesPackages }) => {
  const { t } = useTranslation();
  const [packageId, setPackageId] = useState("");

  const isModalOpen = !!packageId;
  const services =
    servicesPackages.find(({ id }) => id === packageId)?.services || [];

  const handleOpenModal = (id: string): void => {
    setPackageId(id);
  };

  const handleCloseModal = (): void => {
    setPackageId("");
  };

  const renderPackages = (): JSX.Element[] =>
    servicesPackages.map((servicesPackage) => (
      <PackageItem
        key={servicesPackage.id}
        servicesPackage={servicesPackage}
        onBookSession={handleOpenModal}
      />
    ));

  if (!servicesPackages.length) return null;

  return (
    <Fragment>
      <IonCard>
        <IonCardHeader>
          <IonCardSubtitle className="ion-font-bold">
            {t("SERVICES_PACKAGES")}
          </IonCardSubtitle>
        </IonCardHeader>
        <IonCardContent>
          <IonList>{renderPackages()}</IonList>
        </IonCardContent>
      </IonCard>
      <ServicesModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        services={services}
      />
    </Fragment>
  );
};

export default ServicesPackages;
