import { ChangeEvent, FC } from "react";
import {
  IonContent,
  IonGrid,
  IonRow,
  RadioGroupChangeEventDetail,
  SelectChangeEventDetail,
  TextareaChangeEventDetail,
  InputChangeEventDetail,
} from "@ionic/react";
import DeliveryMethods from "./DeliveryMethods";
import { CollectMethod } from "src/enums/CollectMethod";
import { IonRadioGroupCustomEvent, IonSelectCustomEvent } from "@ionic/core";
import Destination from "./Destination";
import PaymentMethods from "./PaymentMethods";
import { PaymentMethod } from "src/enums/PaymentMethod";
import { OrderUser } from "src/interfaces/OrderUser";
import UserForm from "./UserForm";
import { IonInputCustomEvent, IonTextareaCustomEvent } from "@ionic/core";
import Voucher from "./Voucher";

interface ContentProps {
  area: string;
  city: string;
  notes: string;
  user: OrderUser;
  isError: boolean;
  attachments: File[];
  paymentMethod: PaymentMethod;
  collectMethod: CollectMethod;
  isNoDelivery: boolean;
  onChangeLocation: () => void;
  onChangeCollectMethod: (
    e: IonRadioGroupCustomEvent<RadioGroupChangeEventDetail>
  ) => void;
  onChangePaymentMethod: (
    e: IonRadioGroupCustomEvent<RadioGroupChangeEventDetail>
  ) => void;
  onRemoveAttachment: (index: number) => void;
  onChangeNotes: (e: IonTextareaCustomEvent<TextareaChangeEventDetail>) => void;
  onChangeVoucherCode: (e: IonInputCustomEvent<InputChangeEventDetail>) => void;
  onAddAttachments: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeUser: (
    e:
      | IonInputCustomEvent<InputChangeEventDetail>
      | IonTextareaCustomEvent<TextareaChangeEventDetail>
  ) => void;
  onChangeCity: (
    e: IonSelectCustomEvent<SelectChangeEventDetail<string>>
  ) => void;
  onChangeArea: (
    e: IonSelectCustomEvent<SelectChangeEventDetail<string>>
  ) => void;
}

const Content: FC<ContentProps> = ({
  user,
  area,
  city,
  notes,
  isError,
  attachments,
  collectMethod,
  paymentMethod,
  isNoDelivery,
  onChangeArea,
  onChangeCity,
  onChangeUser,
  onChangeNotes,
  onChangeLocation,
  onAddAttachments,
  onRemoveAttachment,
  onChangeVoucherCode,
  onChangeCollectMethod,
  onChangePaymentMethod,
}) => {
  const renderDestination = (): JSX.Element => {
    if (collectMethod !== CollectMethod.delivery) return;
    return (
      <Destination
        area={area}
        city={city}
        isError={isError}
        onChangeCity={onChangeCity}
        onChangeArea={onChangeArea}
      />
    );
  };

  const renderUserForm = (): JSX.Element => {
    if (!user) return;
    return (
      <UserForm
        user={user}
        notes={notes}
        isError={isError}
        attachments={attachments}
        collectMethod={collectMethod}
        onChangeNotes={onChangeNotes}
        onChangeOrderUser={onChangeUser}
        onChangeLocation={onChangeLocation}
        onAddAttachments={onAddAttachments}
        onRemoveAttachment={onRemoveAttachment}
      />
    );
  };

  const renderCollectMethod = (): JSX.Element => {
    if (isNoDelivery) return;
    return (
      <DeliveryMethods
        method={collectMethod}
        onChangeMethod={onChangeCollectMethod}
      />
    );
  };

  return (
    <IonContent>
      <IonGrid>
        <IonRow>
          {renderCollectMethod()}
          {renderDestination()}
          <PaymentMethods
            method={paymentMethod}
            onChangeMethod={onChangePaymentMethod}
          />
          {renderUserForm()}
          <Voucher onChangeVoucherCode={onChangeVoucherCode} />
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};

export default Content;
