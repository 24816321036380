import {Domain} from "../interfaces/Domain";
import {firestore} from "src/firebase";
import {
  collection,
  CollectionReference,
  DocumentData,
  getDocs,
  query,
  QueryDocumentSnapshot,
  where,
} from "firebase/firestore";

const DOMAINS_COLLECTION_NAME = "domains";

const getDomainsCollection = (): CollectionReference<DocumentData> => {
  return collection(firestore, DOMAINS_COLLECTION_NAME);
};

function convertToFirestore(domain: Domain): DocumentData {
  delete domain.id;
  return domain;
}

function convertFromFirestore(snapshot: QueryDocumentSnapshot): Domain {
  const data = snapshot.data();
  return {...data, id: snapshot.id} as Domain;
}

export const converter = {
  toFirestore: convertToFirestore,
  fromFirestore: convertFromFirestore,
};

export const getDomainStoreId = async (): Promise<string> => {
  const domain = window.location.hostname.replace(/www\./, "");
  const collection = getDomainsCollection();
  const domainsQuery = query(
    collection,
    where("domainName", "==", domain),
    where("isVerified", "==", true),
  ).withConverter(converter);
  const snapshot = await getDocs(domainsQuery);
  const storeId = snapshot.docs[0]?.data().storeId || "";
  return storeId;
};
