import { FC, Fragment } from "react";
import PropTypes from "prop-types";
import {
  IonCard,
  IonCardContent,
  IonCol,
  IonGrid,
  IonLabel,
  IonRow,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { getOrderPaymentStatusColor, getOrderStatusColor } from "src/utils";
import { Order } from "src/interfaces/Order";
import Price from "src/components/Price";
import getLocalOrDefault from "src/utils/getLocalOrDefault";

interface OrderDetailsProps {
  order: Order;
}

const OrderDetails: FC<OrderDetailsProps> = ({ order }) => {
  const { t } = useTranslation();

  const statusColor = getOrderStatusColor(order.orderStatus);
  const paymentColor = getOrderPaymentStatusColor(order.paymentStatus);

  const renderDeliveryAmount = (): JSX.Element => {
    if (!order.deliveryAmount) return;
    return (
      <IonCol size="12" sizeMd="6">
        <div className="ion-display-flex ion-justify-content-between">
          <IonLabel>{t("DELIVERY_AMOUNT")}</IonLabel>
          <IonLabel color="dark" className="ion-font-bold">
            <Price price={order.deliveryAmount} />
          </IonLabel>
        </div>
      </IonCol>
    );
  };

  const renderDigitalFees = (): JSX.Element => {
    if (!order.digitalFees) return;
    return (
      <IonCol size="12" sizeMd="6">
        <div className="ion-display-flex ion-justify-content-between">
          <IonLabel>{t("DIGITAL_FEES")}</IonLabel>
          <IonLabel color="dark" className="ion-font-bold">
            <Price price={order.digitalFees} />
          </IonLabel>
        </div>
      </IonCol>
    );
  };

  const renderCity = (): JSX.Element => {
    if (!order.city) return;
    return (
      <IonCol size="12" sizeMd="6">
        <div className="ion-display-flex ion-justify-content-between">
          <IonLabel>{t("CITY")}</IonLabel>
          <IonLabel color="dark" className="ion-font-bold">
            {getLocalOrDefault(order.city.name)}
          </IonLabel>
        </div>
      </IonCol>
    );
  };

  const renderArea = (): JSX.Element => {
    if (!order.area) return;
    return (
      <IonCol size="12" sizeMd="6">
        <div className="ion-display-flex ion-justify-content-between">
          <IonLabel>{t("AREA")}</IonLabel>
          <IonLabel color="dark" className="ion-font-bold">
            {getLocalOrDefault(order.area.name)}
          </IonLabel>
        </div>
      </IonCol>
    );
  };

  const renderDiscount = (): JSX.Element => {
    if (order.voucher.isPercentage) {
      return <Fragment>{order.voucher.value} %</Fragment>;
    } else {
      return <Price price={order.voucher.value} />;
    }
  };

  const renderVoucher = (): JSX.Element => {
    if (!order.voucher) return;
    return (
      <Fragment>
        <IonCol size="12" sizeMd="6">
          <div className="ion-display-flex ion-justify-content-between">
            <IonLabel>{t("VOUCHER_CODE")}</IonLabel>
            <IonLabel color="dark" className="ion-font-bold">
              {order.voucher.code}
            </IonLabel>
          </div>
        </IonCol>
        <IonCol size="12" sizeMd="6">
          <div className="ion-display-flex ion-justify-content-between">
            <IonLabel>{t("DISCOUNT")}</IonLabel>
            <IonLabel color="dark" className="ion-font-bold">
              {renderDiscount()}
            </IonLabel>
          </div>
        </IonCol>
      </Fragment>
    );
  };

  const renderReferenceNumber = (): JSX.Element => {
    if (!order.billReference) return;
    return (
      <IonCol size="12" sizeMd="6">
        <div className="ion-display-flex ion-justify-content-between">
          <IonLabel>{t("REFERENCE_NUMBER")}</IonLabel>
          <IonLabel color="dark" className="ion-font-bold">
            {order.billReference}
          </IonLabel>
        </div>
      </IonCol>
    );
  };

  return (
    <IonCard>
      <IonCardContent>
        <IonLabel
          className="ion-font-bold ion-font-xlarge ion-display-block ion-text-center"
          color="dark"
        >
          {order.invoiceId}
        </IonLabel>
        <IonGrid>
          <IonRow>
            <IonCol size="12" sizeMd="6">
              <div className="ion-display-flex ion-justify-content-between">
                <IonLabel>{t("ORDER_STATUS")}</IonLabel>
                <IonLabel color={statusColor} className="ion-font-bold">
                  {t(order.orderStatus)}
                </IonLabel>
              </div>
            </IonCol>
            <IonCol size="12" sizeMd="6">
              <div className="ion-display-flex ion-justify-content-between">
                <IonLabel>{t("DELIVERY_METHOD")}</IonLabel>
                <IonLabel color="dark" className="ion-font-bold">
                  {t(order.deliveryMethod)}
                </IonLabel>
              </div>
            </IonCol>
            <IonCol size="12" sizeMd="6">
              <div className="ion-display-flex ion-justify-content-between">
                <IonLabel>{t("PAYMENT_METHOD")}</IonLabel>
                <IonLabel color="dark" className="ion-font-bold">
                  {t(order.paymentMethod)}
                </IonLabel>
              </div>
            </IonCol>
            <IonCol size="12" sizeMd="6">
              <div className="ion-display-flex ion-justify-content-between">
                <IonLabel>{t("PAYMENT_STATUS")}</IonLabel>
                <IonLabel color={paymentColor} className="ion-font-bold">
                  {t(order.paymentStatus)}
                </IonLabel>
              </div>
            </IonCol>
            {renderDeliveryAmount()}
            {renderDigitalFees()}
            {renderCity()}
            {renderArea()}
            {renderVoucher()}
            {renderReferenceNumber()}
            <IonCol size="12">
              <div className="ion-display-flex ion-justify-content-between">
                <IonLabel className="ion-font-bold ion-font-large">
                  {t("TOTAL")}
                </IonLabel>
                <IonLabel className="ion-font-bold ion-font-large" color="dark">
                  <Price price={order.total} />
                </IonLabel>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCardContent>
    </IonCard>
  );
};

OrderDetails.propTypes = {
  order: PropTypes.any.isRequired,
};

export default OrderDetails;
