import {
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonToolbar,
} from "@ionic/react";
import { checkmarkOutline, closeOutline } from "ionicons/icons";
import { FC } from "react";

interface PagePlaceholderProps {
  onClose: () => void;
  onSubmit?: () => void;
  hideSubmit?: boolean;
}

const ModalHeader: FC<PagePlaceholderProps> = ({
  onClose,
  onSubmit,
  hideSubmit,
}) => {
  const renderSubmit = (): JSX.Element => {
    if (hideSubmit) return;
    return (
      <IonButtons slot="end">
        <IonButton onClick={onSubmit} color="success">
          <IonIcon
            className="ion-icon-stroke-bold"
            icon={checkmarkOutline}
            slot="icon-only"
          />
        </IonButton>
      </IonButtons>
    );
  };

  return (
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonButton onClick={onClose} color="danger">
            <IonIcon
              className="ion-icon-stroke-bold"
              icon={closeOutline}
              slot="icon-only"
            />
          </IonButton>
        </IonButtons>
        {renderSubmit()}
      </IonToolbar>
    </IonHeader>
  );
};

export default ModalHeader;
