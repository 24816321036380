import {
  InputChangeEventDetail,
  IonInput,
  IonItem,
  IonLabel,
} from "@ionic/react";
import { FC, Fragment, memo } from "react";
import Price from "src/components/Price";
import { CustomVariation } from "src/interfaces/CustomVariation";
import getLocalOrDefault from "src/utils/getLocalOrDefault";
import { IonInputCustomEvent } from "@ionic/core";

interface CustomVariationItemProps {
  variation: CustomVariation;
  value: string;
  onChangeVariation: (id: string, value: number) => void;
}

const CustomVariationItem: FC<CustomVariationItemProps> = ({
  variation,
  onChangeVariation,
  value,
}) => {
  const min = variation.hasMin ? variation.min : 0;
  const max = variation.hasMax && variation.max;

  const handleChangeVariation = (
    e: IonInputCustomEvent<InputChangeEventDetail>
  ): void => {
    const variationValue = +e.detail.value;
    if (variationValue === +value) return;
    onChangeVariation(variation.id, +e.detail.value);
  };

  const renderPercentage = (): JSX.Element => {
    if (!variation.isPercentage) return;
    return <Fragment>%</Fragment>;
  };

  return (
    <IonItem lines="full">
      <div className="ion-flex-grow">
        <IonLabel position="floating">
          {getLocalOrDefault(variation.name)}
        </IonLabel>
        <IonInput
          type="number"
          inputMode="numeric"
          inputmode="numeric"
          onIonChange={handleChangeVariation}
          min={min}
          max={max}
          value={value}
        />
      </div>
      <IonLabel slot="end" className="ion-font-bold ion-margin-start">
        {renderPercentage()} &times; <Price price={variation.price} />
      </IonLabel>
    </IonItem>
  );
};
export default memo(CustomVariationItem);
