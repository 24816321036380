import React, { FC } from "react";
import styles from "./styles";
import { View, Text } from "@react-pdf/renderer";
import { Order } from "src/interfaces/Order";

interface DeliveryInfoProps {
  order: Order;
}
const DeliveryInfo: FC<DeliveryInfoProps> = ({ order }) => {
  function renderDeliveryTable(): JSX.Element | undefined {
    if (!order.deliveryAmount) return;
    return (
      <View style={[styles.table]}>
        <View>
          <View style={styles.tableRow}>
            <View style={styles.tableCell}>
              <Text style={styles.h5}>Delivery details</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCellGrow}>
              <Text style={styles.h6}>Delivery Method</Text>
            </View>
            <View style={styles.tableCellMedium}>
              <Text style={[styles.h6]}>City</Text>
            </View>
            <View style={styles.tableCellMedium}>
              <Text style={[styles.h6]}>Area</Text>
            </View>
            <View style={styles.tableCellMedium}>
              <Text style={[styles.h6, styles.alignCenter]}>Amount</Text>
            </View>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={styles.tableCellGrow}>
            <Text style={styles.h6}>{order.deliveryMethod}</Text>
          </View>
          <View style={styles.tableCellMedium}>
            <Text style={[styles.h6, styles.alignCenter]}>
              {order.deliveryAmount}
            </Text>
          </View>
        </View>
      </View>
    );
  }
  return <View style={styles.marginTop}>{renderDeliveryTable()}</View>;
};

export default DeliveryInfo;
