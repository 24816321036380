import React, {FC, memo} from "react";
import {IonButton, IonButtons, IonIcon, IonItem, IonLabel} from "@ionic/react";
import { CustomerAddress } from "src/interfaces/CustomerAddress";
import {trashOutline} from "ionicons/icons";

interface AddressProps {
  address: CustomerAddress;
  onRemoveAddress: (label: string) => void;
}
const Address: FC<AddressProps> = ({address, onRemoveAddress}) => {
  const handleRemoveAddress = (): void => {
    onRemoveAddress(address.label);
  };

  return (
    <IonItem lines="none">
      <IonLabel>
        <h6>{address.label}</h6>
        <p>{address.address}</p>
      </IonLabel>
      <IonButtons slot="end">
        <IonButton color="danger" onClick={handleRemoveAddress}>
          <IonIcon size="small" icon={trashOutline} slot="icon-only" />
        </IonButton>
      </IonButtons>
    </IonItem>
  );
};

export default memo(Address);
