import { FC } from "react";
import {
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  SelectChangeEventDetail,
} from "@ionic/react";
import getLocalOrDefault from "src/utils/getLocalOrDefault";
import { IonSelectCustomEvent } from "@ionic/core";
import { City } from "src/interfaces/City";
import { useAppSelector } from "src/hooks/useAppSelector";
import Price from "src/components/Price";

interface DestinationSelectorProps {
  options: City[];
  destinationId: string;
  label: string;
  isLoaded: boolean;
  hidePrice?: boolean;
  onChangeDestination: (
    e: IonSelectCustomEvent<SelectChangeEventDetail<string>>
  ) => void;
}

const DestinationSelector: FC<DestinationSelectorProps> = ({
  onChangeDestination,
  label,
  isLoaded,
  destinationId,
  options,
  hidePrice,
}) => {
  const { products, services, servicesPackages } = useAppSelector(
    (state) => state.cartReducer.cart
  );
  const destination = options.find(({ id }) => id === destinationId);
  const renderOptions = (): JSX.Element[] => {
    return options.map((option) => {
      const disabled =
        (!option.isProducts && !!products.length) ||
        (!option.isServices &&
          (!!services.length || !!servicesPackages.length));
      return (
        <IonSelectOption key={option.id} value={option.id} disabled={disabled}>
          {getLocalOrDefault(option.name)}
        </IonSelectOption>
      );
    });
  };

  const renderLoader = (): JSX.Element => {
    if (isLoaded) return;
    return <IonSpinner name="lines-small" />;
  };

  const renderPrice = (): JSX.Element => {
    if (!destination || hidePrice) return;
    return (
      <IonLabel
        slot="end"
        className="ion-font-bold ion-font-small ion-no-margin"
      >
        <Price price={destination.fees} />
      </IonLabel>
    );
  };

  return (
    <IonItem >
      <IonLabel>{label}</IonLabel>
      <IonSelect
        disabled={!options.length}
        interface="popover"
        onIonChange={onChangeDestination}
      >
        {renderOptions()}
      </IonSelect>
      {renderPrice()}
      {renderLoader()}
    </IonItem>
  );
};

export default DestinationSelector;
