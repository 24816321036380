import {IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonButton, IonIcon, IonSpinner} from "@ionic/react";
import React, {FC, useState, useEffect, useCallback} from "react";
import {CMSForm} from "src/interfaces/CMS";
import getLocalOrDefault from "src/utils/getLocalOrDefault";
import useToast from "src/hooks/useToast";
import {useTranslation} from "react-i18next";
import classes from "./styles.module.scss";
import {CMSFormFieldsView} from "..";
import {FormRequest} from "src/interfaces/FormRequest";
import { FormRequestField } from "src/interfaces/FormRequestField";
import { FormRequestUser } from "src/interfaces/FormRequestUser";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { useAppSelector } from "src/hooks/useAppSelector";
import {createRequest, uploadRequestFile} from "src/slices/formsRequests";
import useIsMountedRef from "src/hooks/useIsMountedRef";
import {RequirementAttachmentLinks, RequirementAttachmentFiles} from "src/interfaces/Requirement";
import saveRequestToStorage from "src/utils/saveRequestToStorage";
import checkNetworkConnection from "src/utils/checkNetworkConnection";
import initCMSFormRequirements from "src/utils/initCMSFormRequirements";
import { FormFieldType } from "src/enums/FormFieldType";
import { FormField } from "src/interfaces/FormField";

interface CMSFormViewProps {
  form: CMSForm;
}

const CMSFormView: FC<CMSFormViewProps> = ({form}) => {
  const launchToast = useToast();
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const isMountedRef = useIsMountedRef();

  const {customer} = useAppSelector((state) => state.customerReducer);
  const forms = useAppSelector((state) => state.formsReducer.forms);
  const selectedForm = forms.find(({id}) => id === form.formId);

  const formName = selectedForm ? getLocalOrDefault(selectedForm.name) : "";

  const [request, setRequest] = useState<FormRequest>({
    fields: [],
    isGuest: true,
    formId: selectedForm?.id,
    formName: selectedForm?.name,
  });
  const [attachments, setAttachments] = useState<RequirementAttachmentFiles[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isError, setIsError] = useState(false);

  const initRequest = useCallback(() => {
    setRequest((prevState) => ({
      ...prevState,
      fields: initCMSFormRequirements(selectedForm.fields),
      formId: selectedForm.id,
    }));
    setAttachments([]);
  }, [selectedForm]);

  useEffect(() => {
    if (customer?.id) {
      const {id, firstName, lastName, email, phone} = customer;
      const user: FormRequestUser = {id, firstName, lastName, email, phone};
      setRequest((prevState) => ({...prevState, isGuest: false, user}));
    }
  }, [customer]);

  useEffect(() => {
    initRequest();
  }, [initRequest]);

  const uploadAttachments = async (): Promise<RequirementAttachmentLinks[]> => {
    return await Promise.all(
      attachments.map(async ({id, files}) => {
        const links = await Promise.all(files.map(async (file) => (await dispatch(uploadRequestFile(file))) as string));
        return {id, links};
      }),
    );
  };

  const hasValidRequest = (fields: FormField[], requirements: FormRequestField[]): boolean => {
    return requirements.every((requirement) => {
      const type = requirement.type;
      const field = fields.find(({id}) => id === requirement.id);
      if (!field?.isRequired) return true;
      switch (type) {
        case FormFieldType.file:
          const attachment = attachments.find((attachment) => attachment.id === requirement.id);
          return attachment?.files.length;
        case FormFieldType.selectForm:
          return requirement.selectFormItemsRequirements.every((requirement) => {
            const fields = field.selectFormItems.find(({id}) => id === requirement.id)?.fields || [];
            return hasValidRequest(fields, requirement.fields);
          });
        default:
          return requirement.value;
      }
    });
  };

  const handleChangeAttachments = (attachments: RequirementAttachmentFiles[]): void => {
    setAttachments(attachments);
  };

  const handleChangeRequirements = (fields: FormRequestField[]): void => {
    setRequest((prevState) => ({...prevState, fields}));
  };

  const mergeRequirementsWithAttachments = (
    requirements: FormRequestField[],
    links: RequirementAttachmentLinks[],
  ): FormRequestField[] => {
    return requirements.map((field) => {
      if (field.type === FormFieldType.selectForm) {
        const selectFormItemsRequirements = field.selectFormItemsRequirements.map((requirement) => ({
          ...requirement,
          fields: mergeRequirementsWithAttachments(requirement.fields, links),
        }));
        return {...field, selectFormItemsRequirements};
      }
      const attachment = links.find((link) => link.id === field.id);
      return attachment ? {...field, attachments: attachment.links} : field;
    });
  };

  const handleSubmitRequest = async (): Promise<void> => {
    const isValidRequest = hasValidRequest(selectedForm.fields, request.fields);
    setIsError(!isValidRequest);
    if (!isValidRequest) return;
    try {
      setIsSubmitting(true);
      const isConnceted = await checkNetworkConnection(true);
      if (isConnceted) {
        const links = await uploadAttachments();
        const requirements = mergeRequirementsWithAttachments(request.fields, links);
        setRequest((prevState) => ({...prevState, fields: requirements}));
        const requestWithAttachments = {...request, fields: requirements};
        await dispatch(createRequest(requestWithAttachments));
        launchToast(t("PAGE.HOME.SUBMIT_FORM_SUCCESS_MESSAGE"), "success");
      } else {
        await saveRequestToStorage(request, attachments);
        launchToast(t("PAGE.HOME.SUBMIT_FORM_SAVED_LOCAL_MESSAGE"), "success");
      }

      if (isMountedRef.current) {
        initRequest();
      }
    } catch {
      launchToast(t("PAGE.HOME.SUBMIT_FORM_ERROR_MESSAGE"), "danger");
    } finally {
      if (isMountedRef.current) {
        setIsSubmitting(false);
      }
    }
  };

  const renderSubmitButtonLoader = (): JSX.Element | undefined => {
    if (!isSubmitting) return <IonIcon slot="start" />;
    return <IonSpinner className={classes.submitLoader} />;
  };

  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle className={classes.title}>{formName}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <CMSFormFieldsView
          attachments={attachments}
          fields={selectedForm.fields}
          isError={isError}
          isSubmitting={isSubmitting}
          requirements={request.fields}
          onChangeAttachments={handleChangeAttachments}
          onChangeRequirements={handleChangeRequirements}
        />
        <IonButton
          className={classes.submitButton}
          disabled={isSubmitting}
          onClick={handleSubmitRequest}
          color="primary"
          expand="block"
        >
          {renderSubmitButtonLoader()}
          {t("SUBMIT")}
        </IonButton>
      </IonCardContent>
    </IonCard>
  );
};
export default CMSFormView;
