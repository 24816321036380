import {FC} from "react";
import { useAppSelector } from "src/hooks/useAppSelector";
import {Redirect} from "react-router-dom";
import getPagePath from "src/utils/getStoreIdCustomPath";
import {auth} from "src/firebase";
import useAuth from "src/hooks/useAuth";

const GuestGuard: FC = ({children}) => {
  const store = useAppSelector((state) => state.storeReducer.store);
  const {isAuthenticated} = useAuth();
  const inactiveLink = getPagePath("inactive");
  const homeLink = getPagePath("home");

  if (!store.active) {
    return <Redirect to={inactiveLink} />;
  }

  if (auth.currentUser && isAuthenticated) {
    return <Redirect to={homeLink} />;
  }

  return <>{children}</>;
};

export default GuestGuard;
