import { FC } from "react";
import PropTypes from "prop-types";
import { IonAlert } from "@ionic/react";
import { useTranslation } from "react-i18next";

interface AlertDialogProps {
  open: boolean;
  title: string;
  message: string;
  onConfirm: () => void;
  onClose: () => void;
}

const AlertDialog: FC<AlertDialogProps> = ({
  open,
  title,
  message,
  onConfirm,
  onClose,
}) => {
  const { t } = useTranslation();
  return (
    <IonAlert
      isOpen={open}
      onDidDismiss={onClose}
      header={title}
      message={message}
      buttons={[
        {
          text: t("CANCEL"),
          role: "cancel",
          cssClass: "ion-font-color-danger",
        },
        { text: t("CONFIRM"), handler: onConfirm, cssClass: "ion-font-color-dark" },
      ]}
    />
  );
};

AlertDialog.propTypes = {
  message: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

export default AlertDialog;
