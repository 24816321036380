import { FC, memo } from "react";
import { IonItem, IonLabel } from "@ionic/react";
import { TimeSlot } from "src/interfaces/TimeSlot";
import { WeekDay } from "src/enums/WeekDay";
import { useTranslation } from "react-i18next";
import { generateSlotDuration } from "src/utils/generateSlotDuration";

interface WorkingDayItemProps {
  slots: TimeSlot[];
  day?: WeekDay;
}

const WorkingDayItem: FC<WorkingDayItemProps> = ({ day, slots }) => {
  const { t } = useTranslation();

  const renderSlots = (): JSX.Element[] => {
    return slots.map((slot) => {
      const isFullDay = slot.from === slot.to;
      const label = isFullDay ? t("FULL_DAY") : generateSlotDuration(slot);
      return (
        <IonLabel key={slot.from} color="medium" className="ion-font-small">
          {label}
        </IonLabel>
      );
    });
  };

  const renderDay = (): JSX.Element => {
    const label = day ? t(day) : t("SERVICE_DETAILS.SEVEN_DAYS_WORK");
    return <IonLabel className="ion-font-small">{label}</IonLabel>;
  };

  return (
    <IonItem lines="full">
      <div className="ion-display-block ion-no-margin">
        {renderDay()}
        {renderSlots()}
      </div>
    </IonItem>
  );
};

export default memo(WorkingDayItem);
