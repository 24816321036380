import {
  collection,
  CollectionReference,
  doc,
  getDoc,
  onSnapshot,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { BaseNote } from "src/interfaces/BaseNote";
import { Order } from "src/interfaces/Order";
import { setOrders } from "src/slices/orders";
import { store } from "src/store";
import { sortOrdersByInvoiceId } from "src/utils/sortOrdersByInvoiceId";
import { getFirestoreConverter } from "./converter";
import { getStoreDocRef } from "./store";

const COLLECTION = "orders";

const getCollection = (): CollectionReference<Order> => {
  return collection(getStoreDocRef(), COLLECTION).withConverter(getFirestoreConverter<Order>());
};

export const subscribeToOrdersFB = (): void => {
  const customerId = store.getState().customerReducer.customer.id;
  const isLoaded = store.getState().ordersReducer.isLoaded;
  if (isLoaded) return;
  onSnapshot(query(getCollection(), where("user.customerId", "==", customerId)), (snapshot) => {
    store.dispatch(setOrders(sortOrdersByInvoiceId(snapshot.docs.map((doc) => doc.data()))));
  });
}

export const updateOrderNotesFB = async (id: string, userNotes: BaseNote[]): Promise<void> => {
  await updateDoc(doc(getCollection(), id), { userNotes });
}

export async function updateOrderAttachmentsFB(attachments: string[], orderId: string): Promise<void> {
  const collection = getCollection();
  await updateDoc(doc(collection, orderId), { attachments });
}

export const getOrderByIdFB = async (id: string): Promise<Order> => {
  const snapshot = await getDoc(doc(getCollection(), id));
  return snapshot.data();
}