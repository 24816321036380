import { IonRadioGroup, RadioGroupChangeEventDetail } from "@ionic/react";
import { FC, Fragment } from "react";
import AddonItem from "./AddonItem";
import { IonRadioGroupCustomEvent } from "@ionic/core";
import { CartAddon } from "src/interfaces/CartAddon";
import { ServicesAddon } from "src/interfaces/ServicesAddon";

interface AddonsListProps {
  addons: ServicesAddon[];
  selectedAddons: CartAddon[];
  onSelectSingleAddon: (addonId: string, prevId: string) => void;
  onSelectMultipleAddon: (addonId: string, checked: boolean) => void;
}

const AddonsList: FC<AddonsListProps> = ({
  addons,
  selectedAddons,
  onSelectMultipleAddon,
  onSelectSingleAddon,
}) => {
  const singleAddon = selectedAddons.find(
    ({ addonId }) => addons.find(({ id: key }) => addonId === key)?.isSingle
  );
  const singleAddonId = singleAddon ? singleAddon.addonId : "";

  const handleSelectSingleAddon = (
    e: IonRadioGroupCustomEvent<RadioGroupChangeEventDetail>
  ): void => {
    const { value } = e.detail;
    if (!value) return;
    onSelectSingleAddon(value, singleAddonId);
  };

  const renderAddons = (isSingle: boolean): JSX.Element[] =>
    addons.map((addon) => {
      if (addon.isSingle !== isSingle) return null;
      const checked = selectedAddons.some(
        ({ addonId }) => addonId === addon.id
      );
      return (
        <AddonItem
          key={addon.id}
          addon={addon}
          checked={checked}
          onSelectAddon={onSelectMultipleAddon}
        />
      );
    });

  return (
    <Fragment>
      <IonRadioGroup
        className="ion-margin-bottom"
        onIonChange={handleSelectSingleAddon}
        value={singleAddonId}
      >
        {renderAddons(true)}
      </IonRadioGroup>
      <div className="ion-margin-top-small">{renderAddons(false)}</div>
    </Fragment>
  );
};

export default AddonsList;
