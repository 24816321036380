import {
  IonButtons,
  IonCard,
  IonContent,
  IonHeader,
  IonLoading,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCardContent,
} from "@ionic/react";
import React, {useState, FC, FormEvent} from "react";
import classes from "./Signup.module.scss";
import {useTranslation} from "react-i18next";
import { useAppSelector } from "src/hooks/useAppSelector";
import API from "src/constants/api";
import {trackCompleteRegistration} from "src/utils/track";
import {Country} from "src/interfaces/Country";
import useAuth from "src/hooks/useAuth";
import {EGYPT_COUNTRY} from "src/constants/constants";
import {SignupNote, SignupForm} from "./components";
import {SignupError, SignupInfo, SignupResponseError} from "src/interfaces/Authentication";
import {InputChangeEventDetail} from "@ionic/core";
import useIsMountedRef from "src/hooks/useIsMountedRef";
import useSettings from "src/hooks/useSettings";
import {validateSignupInfo} from "./Validations";
import {
  generateInitialRequirements,
  updateRequirementsWithAttachmentsLinks,
  uploadRequirementsAttachments,
} from "./Helpers";
import {RequirementAttachmentFile} from "src/interfaces/Requirement";
import firebaseApi from "src/utils/firebaseApi";
import getLocalOrDefault from "src/utils/getLocalOrDefault";

const INITIAL_INFO: SignupInfo = {
  email: "",
  password: "",
  confirmPassword: "",
  firstName: "",
  lastName: "",
  phone: "",
  country: EGYPT_COUNTRY,
  storeId: "",
  language: "",
  requirements: [],
};

const Signup: FC = () => {
  const {t} = useTranslation();
  const {loginWithEmailAndPassword: signInWithEmailAndPasswordFB} = useAuth();
  const isMountedRef = useIsMountedRef();
  const {language} = useSettings();

  const store = useAppSelector((state) => state.storeReducer.store);

  const initialRequirements = generateInitialRequirements(store.customersRequirements);

  const [signupInfo, setSignupInfo] = useState<SignupInfo>({...INITIAL_INFO, requirements: initialRequirements});
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [submitError, setSubmitError] = useState<SignupError | "">("");
  const [attachments, setAttachments] = useState<RequirementAttachmentFile[]>([]);

  const storeId = store.id!;

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    const isValidSignupInfo = validateSignupInfo(signupInfo, attachments);
    if (!isValidSignupInfo) {
      setIsError(true);
      return;
    }
    try {
      setIsLoading(true);
      const attachmentsLinks = await uploadRequirementsAttachments(attachments, signupInfo.requirements);
      const requirements = updateRequirementsWithAttachmentsLinks(attachmentsLinks, signupInfo.requirements);
      setSignupInfo((prevState) => ({...prevState, requirements}));
      await firebaseApi.post(API.REGISTER, {...signupInfo, language, storeId, requirements});
      await signInWithEmailAndPasswordFB(signupInfo.email, signupInfo.password);
      trackCompleteRegistration();
    } catch (err) {
      const error = err as SignupResponseError;
      if (isMountedRef.current) {
        setIsLoading(false);
        setSubmitError(error.response?.data?.message || SignupError.unknownError);
      }
    }
  };

  const handleChangeSignupInfo = (e: CustomEvent<InputChangeEventDetail>): void => {
    const name = (e.target as HTMLIonInputElement).name;
    const value = e.detail.value;
    setSignupInfo((prevState) => ({...prevState, [name]: value}));
  };

  const handleChangeRequirement = (id: string, value: string): void => {
    setSignupInfo((prevState) => ({
      ...prevState,
      requirements: prevState.requirements.map((field) => (field.fieldId === id ? {...field, value} : field)),
    }));
  };

  const handleChangeAttachmentRequirement = (id: string, file: File): void => {
    setAttachments((prevState) => {
      const isAdded = prevState.some((attachment) => attachment.id === id);
      const updatedAttachment: RequirementAttachmentFile = {id, file};
      if (isAdded) return prevState.map((attachment) => (attachment.id === id ? updatedAttachment : attachment));
      return [...prevState, updatedAttachment];
    });
  };

  const handleRemoveAttachmentRequirement = (id: string): void => {
    setAttachments((prevState) => prevState.filter((attachment) => attachment.id !== id));
  };

  const handleChangeCountry = (country: Country): void => {
    setSignupInfo((prevState) => ({...prevState, country}));
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{t("SIGNUP.HEADER_TITLE")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={classes.root}>
        <IonCard className={classes.card}>
          <img alt={getLocalOrDefault(store.storeName)} src={store.logo} className={classes.logo} />
          <IonCardContent>
            <SignupNote />
            <SignupForm
              onChangeCountry={handleChangeCountry}
              onChangeSignupInfo={handleChangeSignupInfo}
              signupInfo={signupInfo}
              isError={isError}
              submitError={submitError}
              onSubmit={handleSubmit}
              attachments={attachments}
              onChangeRequirement={handleChangeRequirement}
              onChangeAttachmentRequirement={handleChangeAttachmentRequirement}
              onRemoveAttachmentRequirement={handleRemoveAttachmentRequirement}
            />
            <IonLoading isOpen={isLoading} message={t("PLEASE_WAIT")} />
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default Signup;
