import React, { FC } from "react";
import PropTypes from "prop-types";
import { IonCol, IonLabel } from "@ionic/react";
import "./BookingDetailsItem.scss";

interface BookingDetailsItemProps {
    label: string;
    value?: string;
    color?: string;
    fullWidth?: boolean;
}
const BookingDetailsItem: FC<BookingDetailsItemProps> = ({ label, value, fullWidth = false, color = "" }) => {
    const sizeMd = fullWidth ? "12" : "6";
    return (
        <IonCol sizeSm="12" sizeMd={sizeMd}>
            <div className="bookingDetailsItem">
                <IonLabel>{label}</IonLabel>
                <IonLabel color={color} className="bookingDetailsItem__value">{value}</IonLabel>
            </div>
        </IonCol>

    );
};

BookingDetailsItem.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string,
    color: PropTypes.string,
};

export default BookingDetailsItem;
