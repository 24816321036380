import { FC, Fragment } from "react";
import { useTranslation } from "react-i18next";
import ValueUnit from "../ValueUnit";
import classes from "./styles.module.scss";

interface PriceProps {
  price: number;
  hasSalePrice?: boolean;
  showSaving?: boolean;
  salePrice?: number;
}

const Price: FC<PriceProps> = ({
  price,
  hasSalePrice,
  salePrice,
  showSaving,
}) => {
  const { t } = useTranslation();

  const currentPrice = hasSalePrice ? salePrice : price;

  const renderOldPrice = (): JSX.Element => {
    if (!hasSalePrice) return;
    return <del className={classes.old}>{price}</del>;
  };

  const renderSaving = (): JSX.Element => {
    if (!showSaving || !hasSalePrice) return;
    const difference = price - +salePrice;
    const sale = (difference * 100) / price;
    return (
      <span className={classes.saving}>
        {t("PRODUCT_PRICE.SAVING_TEXT")} {sale.toFixed(0)}% (
        {difference.toFixed(0)} <sub>{t("EGP")}</sub>)
      </span>
    );
  };

  return (
    <Fragment>
      {renderOldPrice()} <ValueUnit value={currentPrice} unit={t("EGP")} />
      {renderSaving()}
    </Fragment>
  );
};

export default Price;
