import classes from "./styles.module.scss";
import {FC} from "react";
import {
  IonCardTitle,
  IonCard,
  IonCardHeader,
  IonImg,
  IonCardContent,
  IonNote,
} from "@ionic/react";
import { useAppSelector } from "src/hooks/useAppSelector";
import {Product} from "src/interfaces/Product";
import ProductPrice from "../ProductPrice";
import ProductOutOfStock from "../ProductOutOfStock";
import {useTranslation} from "react-i18next";
import CategoryBadge from "../CategoryBadge";
import getLocalOrDefault from "src/utils/getLocalOrDefault";
import getPagePath from "src/utils/getStoreIdCustomPath";

interface ProductListViewProps {
  product: Product;
  isShowCategories?: boolean;
}

const ProductListView: FC<ProductListViewProps> = ({product, isShowCategories}) => {
  const {t} = useTranslation();

  const {logo} = useAppSelector((state) => state.storeReducer.store);
  const categories = useAppSelector((state) => state.categoriesReducer.categories);

  const isOutOfStock = !product.isUnlimitedQuantity && !product.quantity;

  const productLink = getPagePath(`product/${product.id}`);

  const getFeaturedImage = (): string => {
    return product.gallery.find((img) => img.isFeatured)?.src || product.gallery[0]?.src;
  };

  const handleImageError = (e: CustomEvent): void => {
    (e.target as HTMLIonImgElement).src = logo;
  };

  const renderCategoriesBadges = (): (JSX.Element | null)[] | undefined => {
    if (!isShowCategories) return;
    return product.categories.map((categoryId) => {
      const category = categories.find((category) => category.id === categoryId);
      if (!category) return null;
      return <CategoryBadge key={categoryId} category={getLocalOrDefault(category.name)} />;
    });
  };


  const renderProductStock = (): JSX.Element | undefined => {
    if (isOutOfStock) return <ProductOutOfStock />;
    if (!product.isShowQuantity || product.isUnlimitedQuantity) return;
    return (
      <IonNote>
        {product.quantity} {t("IN_STOCK")}
      </IonNote>
    );
  };

  return (
    <IonCard className={classes.root} routerLink={productLink}>
      <div className={classes.container}>
        <div>
          <IonImg onIonError={handleImageError} className={classes.image} src={getFeaturedImage()} />
        </div>
        <div className={classes.details}>
          <IonCardHeader className={classes.header}>
            <IonCardTitle className={classes.title}>{getLocalOrDefault(product.name)}</IonCardTitle>
            {renderCategoriesBadges()}
          </IonCardHeader>
          <IonCardContent className={classes.content}>
            <ProductPrice price={product.price} salePrice={product.salePrice} hasSalePrice={product.hasSalePrice} />
            {renderProductStock()}
          </IonCardContent>
        </div>
      </div>
    </IonCard>
  );
};

export default ProductListView;
