import { FC, useEffect, useRef } from "react";
import { IonInput, IonItem, IonLabel } from "@ionic/react";
import { JSX as IonJSX } from "@ionic/core/components";
import ErrorLabel from "./ErrorLabel";

interface FloatingLabelInputProps extends IonJSX.IonInput {
  error?: boolean;
  helperText?: string;
  className?: string;
}

const FloatingLabelInput: FC<FloatingLabelInputProps> = ({
  helperText,
  error,
  className,
  ...props
}) => {
  const ref = useRef<HTMLIonInputElement>();
  useEffect(() => {
    if (error && ref) {
      ref.current.setFocus();
    }
  }, [error]);
  const renderHelperText = (): JSX.Element => {
    if (!error) return;
    return (
      <ErrorLabel className="ion-margin-horizontal"> {helperText}</ErrorLabel>
    );
  };
  return (
    <div className={className}>
      <IonItem>
        <IonLabel position="floating">{props.placeholder}</IonLabel>
        <IonInput {...props} ref={ref} autofocus={error} placeholder="" />
      </IonItem>
      {renderHelperText()}
    </div>
  );
};

export default FloatingLabelInput;
