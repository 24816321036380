import  {FC, ChangeEvent, Fragment} from "react";
import { FormRequestSelectFormItem } from "src/interfaces/FormRequestSelectFormItem";
import getLocalOrDefault from "src/utils/getLocalOrDefault";
import { getGPSLocation } from "src/utils/getGPSLocation";
import useToast from "src/hooks/useToast";
import {useTranslation} from "react-i18next";
import {
  InputHelperText,
  CMSFormSelectField,
  CMSFormSelectFormField,
  CMSFormTextNumberField,
  CMSFormDateField,
  CMSFormLocationField,
  CMSFormFileField,
} from ".";
import {RequirementAttachmentFiles} from "src/interfaces/Requirement";
import {InputChangeEventDetail} from "@ionic/core";
import {TranslationMap} from "src/interfaces/Translation";
import initCMSFormRequirements from "src/utils/initCMSFormRequirements";
import { FormFieldType } from "src/enums/FormFieldType";
import { FormField } from "src/interfaces/FormField";
import { FormRequestField } from "src/interfaces/FormRequestField";

interface CMSFormFieldsViewProps {
  fields: FormField[];
  requirements: FormRequestField[];
  attachments: RequirementAttachmentFiles[];
  isError: boolean;
  isSubmitting: boolean;
  onChangeRequirements: (requirements: FormRequestField[]) => void;
  onChangeAttachments: (attachments: RequirementAttachmentFiles[]) => void;
}

const CMSFormFieldsView: FC<CMSFormFieldsViewProps> = ({
  fields,
  requirements,
  isSubmitting,
  attachments,
  isError,
  onChangeRequirements,
  onChangeAttachments,
}) => {
  const launchToast = useToast();
  const {t} = useTranslation();

  const handleChangeTextNumberField = (id: string): ((e: CustomEvent<InputChangeEventDetail>) => void) => {
    return (e: CustomEvent<InputChangeEventDetail>): void => {
      const value = e.detail.value || "";
      const updatedRequirements = requirements.map((requirement) =>
        requirement.id === id ? {...requirement, value} : requirement,
      );
      onChangeRequirements(updatedRequirements);
    };
  };

  const handleChangeSelectField = (id: string): ((value: string, selectValue: TranslationMap | null) => void) => {
    return (value: string, selectValue: TranslationMap | null): void => {
      const updatedRequirements = requirements.map((requirement) =>
        requirement.id === id ? {...requirement, value, selectValue} : requirement,
      );
      onChangeRequirements(updatedRequirements);
    };
  };

  const handleChangeLocationField = (id: string): (() => Promise<void>) => {
    return async (): Promise<void> => {
      try {
        const value = await getGPSLocation();
        const updatedRequirements = requirements.map(
          (requirement) => (requirement.id === id ? {...requirement, value} : requirement) as FormRequestField,
        );
        onChangeRequirements(updatedRequirements);
        launchToast(t("GPS_LOCATION_SUCCESS_MESSAGE"), "success");
      } catch {
        launchToast(t("GPS_LOCATION_FAILED_MESSAGE"), "danger");
      }
    };
  };

  const handleChangeAttachmentField = (id: string): ((e: ChangeEvent<HTMLInputElement>) => void) => {
    return (e: ChangeEvent<HTMLInputElement>): void => {
      const files = Object.values(e.target.files || []);
      if (!files.length) return;
      const results: string[] = [];
      const reader = new FileReader();
      const readFile = (index: number): void => {
        if (index >= files.length) {
          const isAdded = attachments.some((attachment) => attachment.id === id);
          const updatedAttachment: RequirementAttachmentFiles = {id, files, results};
          const updatedAttachments = isAdded
            ? attachments.map((attachment) => (attachment.id === id ? updatedAttachment : attachment))
            : [...attachments, updatedAttachment];
          onChangeAttachments(updatedAttachments);
          return;
        }
        const file = files[index];
        reader.onload = (): void => {
          const base64 = reader.result as string;
          results.push(base64);
          readFile(index + 1);
        };
        reader.readAsBinaryString(file);
      };
      readFile(0);
    };
  };

  const handleChangeSelectFormItems = (fieldId: string): ((items: string[]) => void) => {
    return (items: string[]): void => {
      const updatedRequirements = requirements.map((field) => {
        if (field.id === fieldId) {
          const selectFormItemsRequirements: FormRequestSelectFormItem[] = items.map((itemId) => {
            const requirement = field.selectFormItemsRequirements.find(({id}) => id === itemId);
            const itemFields =
              fields.find(({id}) => id === fieldId)?.selectFormItems.find(({id}) => id === itemId)?.fields || [];
            const requirementsFields = requirement?.fields || initCMSFormRequirements(itemFields);
            return {id: itemId, fields: requirementsFields};
          });
          return {...field, selectFormItemsRequirements};
        }
        return field;
      });
      onChangeRequirements(updatedRequirements);
    };
  };

  const handleChangeSelectFormRequirements = (
    id: string,
    selectFormItemsRequirements: FormRequestSelectFormItem[],
  ): void => {
    const updatedRequirements = requirements.map((requirement) =>
      id === requirement.id ? {...requirement, selectFormItemsRequirements} : requirement,
    );
    onChangeRequirements(updatedRequirements);
  };

  const handleRemoveAttachment = (id: string): ((deletedIndex: number) => void) => {
    return (deletedIndex: number): void => {
      const updatedAttachments = attachments.map((attachment) =>
        attachment.id === id
          ? {...attachment, files: attachment.files.filter((file, index) => index !== deletedIndex)}
          : attachment,
      );
      onChangeAttachments(updatedAttachments);
    };
  };

  const renderInputError = (field: FormRequestField, isRequired: boolean, label: string): JSX.Element | undefined => {
    if (!isError || !isRequired) return;
    switch (field.type) {
      case FormFieldType.file:
        const attachment = attachments.find((attachment) => attachment.id === field.id);
        if (attachment?.files.length) return;
        break;
      case FormFieldType.selectForm:
        if (field.selectFormItemsRequirements.length) return;
        break;
      default:
        if (field.value) return;
        break;
    }
    const message = `${label} ${t("IS_REQUIRED")}`;
    return <InputHelperText color="danger" text={message} />;
  };

  const renderFields = (): (JSX.Element | null)[] => {
    return fields.map((field) => {
      const requirement = requirements.find(({id}) => id === field.id);
      if (!requirement) return null;

      const label = getLocalOrDefault(field.name);
      const isRequired = field.isRequired;
      const value = requirement.value as string;

      const type = requirement.type;

      switch (type) {
        case FormFieldType.text:
        case FormFieldType.number:
          return (
            <Fragment key={field.id}>
              <CMSFormTextNumberField
                disabled={isSubmitting}
                type={type}
                label={label}
                onChangeField={handleChangeTextNumberField(field.id)}
                value={value}
              />
              {renderInputError(requirement, isRequired, label)}
            </Fragment>
          );

        case FormFieldType.date:
          return (
            <Fragment key={field.id}>
              <CMSFormDateField
                disabled={isSubmitting}
                label={label}
                onChangeField={handleChangeTextNumberField(field.id)}
                value={value}
              />
              {renderInputError(requirement, isRequired, label)}
            </Fragment>
          );

        case FormFieldType.location:
          return (
            <Fragment key={field.id}>
              <CMSFormLocationField
                disabled={isSubmitting}
                label={label}
                onChangeLocation={handleChangeLocationField(field.id)}
              />
              {renderInputError(requirement, isRequired, label)}
            </Fragment>
          );

        case FormFieldType.file:
          const files = attachments.find(({id}) => id === field.id)?.files || [];
          return (
            <Fragment key={field.id}>
              <CMSFormFileField
                disabled={isSubmitting}
                label={label}
                files={files}
                onChangeAttachment={handleChangeAttachmentField(field.id)}
                onRemoveAttachment={handleRemoveAttachment(field.id)}
              />
              {renderInputError(requirement, isRequired, label)}
            </Fragment>
          );

        case FormFieldType.select:
          return (
            <Fragment key={field.id}>
              <CMSFormSelectField
                label={label}
                options={field.options}
                onChangeSelectField={handleChangeSelectField(field.id)}
                value={value}
              />
              {renderInputError(requirement, isRequired, label)}
            </Fragment>
          );

        case FormFieldType.selectForm:
          return (
            <Fragment key={field.id}>
              <CMSFormSelectFormField
                key={field.id}
                items={field.selectFormItems}
                itemsRequirements={requirement.selectFormItemsRequirements}
                label={label}
                onChangeSelectedForms={handleChangeSelectFormItems(field.id)}
                isError={isError}
                isSubmitting={isSubmitting}
                fieldId={field.id}
                attachments={attachments}
                onChangeRequirements={handleChangeSelectFormRequirements}
                onChangeAttachments={onChangeAttachments}
              />
              {renderInputError(requirement, isRequired, label)}
            </Fragment>
          );

        default:
          return null;
      }
    });
  };

  return <div>{renderFields()}</div>;
};

export default CMSFormFieldsView;
