import {Customer} from "src/interfaces/Customer";
import { CustomerRequirement } from "src/interfaces/CustomerRequirement";
import {RequirementAttachmentFile} from "src/interfaces/Requirement";
import { RequirementType } from "src/enums/RequirementType";
import {validateEmail, validateRequiredText} from "src/utils/validation";

const validateRequirements = (
  requirements: CustomerRequirement[],
  attachments: RequirementAttachmentFile[],
): boolean => {
  const isValidRequirements = requirements.every((requirement) => {
    if (requirement.type === RequirementType.file || requirement.type === RequirementType.image) {
      const attachment = attachments.some(({id}) => id === requirement.fieldId);
      return attachment || !requirement.isRequired;
    }
    return requirement.value || !requirement.isRequired;
  });
  return isValidRequirements;
};

export const validateSettings = (customer: Customer, attachments: RequirementAttachmentFile[]): boolean => {
  const isValidFirstname = validateRequiredText(customer.firstName);
  const isValidLastname = validateRequiredText(customer.lastName);
  const isValidPhone = validateRequiredText(customer.phone);
  const isValidEmail = validateEmail(customer.email);
  const isValidRequirements = validateRequirements(customer.requirements, attachments);

  return isValidEmail && isValidPhone && isValidFirstname && isValidLastname && isValidRequirements;
};
