import {getStoreDocRef} from "./store";
import {Attendance} from "src/interfaces/Attendance";
import {store} from "src/store";
import { sortFirestoreDocuments } from "src/utils/sortFirestoreDocuments";
import {setAttendances} from "src/slices/attendances";
import {
  addDoc,
  collection,
  CollectionReference,
  DocumentData,
  onSnapshot,
  query,
  QueryDocumentSnapshot,
  where,
} from "firebase/firestore";

const ATTENDANCES_COLLECTION_NAME = "attendances";

const getAttendancesCollection = (): CollectionReference<DocumentData> => {
  return collection(getStoreDocRef(), ATTENDANCES_COLLECTION_NAME);
};

function toFirestore(attendance: Attendance): DocumentData {
  delete attendance.id;
  return attendance;
}

function fromFirestore(snapshot: QueryDocumentSnapshot): Attendance {
  const data = snapshot.data();
  const createdAt = data.createdAt || new Date().toISOString();
  const id = snapshot.id;
  return {...data, id, createdAt} as Attendance;
}

export const converter = {
  toFirestore,
  fromFirestore,
};

export async function createAttendanceFB(attendance: Attendance): Promise<void> {
  const collection = getAttendancesCollection();
  await addDoc(collection, attendance);
}

export function subscribeToAttendancesFB(): void {
  const collection = getAttendancesCollection();
  const customerId = store.getState().customerReducer.customer!.id;
  const attendancesQuery = query(collection, where("customerId", "==", customerId)).withConverter(converter);
  onSnapshot(
    attendancesQuery,
    (snapshot) => {
      const attendances = sortFirestoreDocuments(snapshot.docs.map((doc) => doc.data()));
      store.dispatch(setAttendances(attendances));
    },
    () => {
      store.dispatch(setAttendances([]));
    },
  );
}
