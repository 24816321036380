const API = {
  I18N_LOAD_TRANSLATIONS: "commonGetTranslation/storefront/{{lng}}.json",
  PRICES: "storefrontGetPrices",
  REGISTER: "storefrontRegister",
  ORDER: "storefrontOrder",
  BOOK_SERVICES_PACKAGE_SESSION: "storefrontBookServicesPackageSession",
  PAYMENT: `${process.env.REACT_APP_API_BASE_URL}/storefrontGetPaymentIntegration`,
  PAY: `/storefrontPay`,
  MANIFEST: "storefrontGetManifest",
  GET_AVAILABLE_BOOKINGS: "storefrontGetAvailableBookings",
  SEARCH_PRODUCTS: `${process.env.REACT_APP_SEARCH_BASE_URL}/search`,
};

export default API;
