import React, {FC} from "react";
import { useAppSelector } from "src/hooks/useAppSelector";
import {Redirect} from "react-router-dom";
import {GuestGuard} from "..";
import getPagePath from "src/utils/getStoreIdCustomPath";

const RegisterationGuard: FC = ({children}) => {
  const store = useAppSelector((state) => state.storeReducer.store);
  const loginLink = getPagePath("login");

  if (store.privateRegisteration) {
    return <Redirect to={loginLink} />;
  }

  return <GuestGuard>{children}</GuestGuard>;
};

export default RegisterationGuard;
