import { FC } from "react";
import { CartCustomVariation } from "src/interfaces/CartCustomVariation";
import { CustomVariation } from "src/interfaces/CustomVariation";
import CustomVariationItem from "./CustomVariationItem";

interface CustomVariationsProps {
  productVariations: CustomVariation[];
  customVariations: CartCustomVariation[];
  onChangeCustomVariation: (id: string, value: number) => void;
}

const CustomVariations: FC<CustomVariationsProps> = ({
  productVariations,
  customVariations,
  onChangeCustomVariation,
}) => {
  const renderVariations = (): JSX.Element[] =>
    productVariations.map((variation) => {
      const customVariation = customVariations.find(
        ({ variationId }) => variationId === variation.id
      );
      const value = customVariation?.value.toString() || "";
      return (
        <CustomVariationItem
          key={variation.id}
          variation={variation}
          value={value}
          onChangeVariation={onChangeCustomVariation}
        />
      );
    });

  return <div className="ion-margin-bottom">{renderVariations()}</div>;
};

export default CustomVariations;
