import { IonButton, IonIcon } from "@ionic/react";
import { locate } from "ionicons/icons";
import { FC } from "react";

interface CMSFormLocationFieldProps {
  disabled: boolean;
  label: string;
  onChangeLocation: () => void;
}

const CMSFormLocationField: FC<CMSFormLocationFieldProps> = ({
  disabled,
  label,
  onChangeLocation,
}) => {
  return (
    <IonButton
      disabled={disabled}
      className="ion-margin-top"
      onClick={onChangeLocation}
    >
      <IonIcon slot="start" icon={locate} />
      {label}
    </IonButton>
  );
};

export default CMSFormLocationField;
