import { Country } from "./Country";
import { CustomerRequirement } from "./CustomerRequirement";

export enum SignupInfoName {
  firstName = "firstName",
  lastName = "lastName",
  email = "email",
  password = "password",
  confirmPassword = "confirmPassword",
  country = "country",
  phone = "phone",
}

export enum LoginInfoName {
  email = "email",
  password = "password",
}

export enum SignupError {
  phoneExists = "PHONE_EXISTS",
  phoneInvalid = "INVALID_PHONE",
  emailExists = "EMAIL_EXISTS",
  unknownError = "UNKNOWN_ERROR",
}

export interface SignupInfo {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  country: Country;
  phone: string;
  storeId: string;
  language: string;
  requirements: CustomerRequirement[];
}

export interface LoginInfo {
  email: string;
  password: string;
}

export type SignupResponseError = {
  response: {
    data: {
      message: SignupError;
    };
  };
};
