import { IonIcon, IonLabel } from "@ionic/react";
import { FC, Fragment } from "react";
import SplashScreen from "./SplashScreen";

interface PagePlaceholderProps {
  isLoading: boolean;
  isEmpty: boolean;
  message: string;
  icon: string;
}

const PagePlaceholder: FC<PagePlaceholderProps> = ({
  isEmpty,
  isLoading,
  message,
  icon,
}) => {
  const renderContent = (): JSX.Element => {
    if (isLoading) return <SplashScreen />;
    if (isEmpty)
      return (
        <div className="ion-position-center ion-text-center">
          <IonIcon
            icon={icon}
            size="large"
            color="medium"
            className="ion-icon-stroke-light ion-icon-xlarge"
          />
          <IonLabel
            className="ion-font-light ion-font-xlarge ion-display-block"
            color="medium"
          >
            {message}
          </IonLabel>
        </div>
      );
  };

  return <Fragment>{renderContent()}</Fragment>;
};

export default PagePlaceholder;
