import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { subscribeToBookingsFB } from "src/firebase/bookings";
import {Booking} from "src/interfaces/Booking";
import { AppThunk } from "src/store";

interface BookingsSlice {
  bookings: Booking[];
  isSubscribed: boolean;
}

const initialState: BookingsSlice = {
  bookings: [],
  isSubscribed: false,
};

export const bookingsSlice = createSlice({
  name: "BOOKINGS",
  initialState,
  reducers: {
    setBookings: (state, action: PayloadAction<Booking[]>) => {
      state.bookings = action.payload;
      state.isSubscribed = true;
    },
  },
});

export const { setBookings } = bookingsSlice.actions;

export const subscribeToBookings = (): AppThunk => async (dispatch, getState): Promise<void> => {
  const isSubscribed = getState().bookingsReducer.isSubscribed;
  if (isSubscribed) return;
  subscribeToBookingsFB();
};

export default bookingsSlice.reducer;
