import { IonLabel } from "@ionic/react";
import { FC, Fragment } from "react";
import { OrderRequirementsGroup } from "src/interfaces/OrderRequirementsGroup";
import RequirementItem from "./RequirementItem";

interface RequirementsGroupItemProps {
  group: OrderRequirementsGroup;
}

const RequirementsGroupItem: FC<RequirementsGroupItemProps> = ({ group }) => {
  const renderRequirements = (): JSX.Element[] => {
    return group.requirements.map((requirement) => {
      return <RequirementItem key={requirement.id} requirement={requirement} />;
    });
  };
  return (
    <Fragment>
      {renderRequirements()}
      <IonLabel className="ion-font-light ion-text-end ion-font-large">
        &times;{group.quantity}
      </IonLabel>
    </Fragment>
  );
};

export default RequirementsGroupItem;
