import {IonImg, IonRouterLink, IonText} from "@ionic/react";
import React, {FC, useEffect} from "react";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { useAppSelector } from "src/hooks/useAppSelector";
import {getFeaturedImage} from "src/utils/getFeaturedImage";
import getLocalOrDefault from "src/utils/getLocalOrDefault";
import {subscribeToServices} from "src/slices/services";
import {useTranslation} from "react-i18next";
import classes from "./styles.module.scss";
import getPagePath from "src/utils/getStoreIdCustomPath";

interface CMSSliderServiceSlideProps {
  itemId: string | null;
}

const CMSSliderServiceSlider: FC<CMSSliderServiceSlideProps> = ({itemId}) => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const service = useAppSelector((state) => state.servicesReducer.services.find(({id}) => id === itemId));

  useEffect(() => {
    dispatch(subscribeToServices());
  }, [dispatch]);

  function renderService(): JSX.Element | null {
    if (!service) return null;
    const link = getPagePath(`service/${service.id}`);
    return (
      <IonRouterLink className={classes.root} routerLink={link}>
        <IonImg className={classes.image} src={getFeaturedImage(service.gallery)?.src} />
        <div className={classes.details}>
          <IonText color="primary">
            <h3 className={classes.title}>{getLocalOrDefault(service.name)}</h3>
          </IonText>
          <IonText color="primary">
            <h6>
              {service.fees} {t("EGP")}
            </h6>
          </IonText>
        </div>
      </IonRouterLink>
    );
  }

  return renderService();
};

export default CMSSliderServiceSlider;
