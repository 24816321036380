import {IonInput, IonItem, IonLabel} from "@ionic/react";
import React, {FC} from "react";
import classes from "./PhoneNumber.module.scss";
import {useTranslation} from "react-i18next";

interface PhoneNumberInputProps {
  phone: string;
  code: string;
  name?: string;
  onChangePhoneNumber: (e: CustomEvent) => void;
}

const PhoneNumberInput: FC<PhoneNumberInputProps> = ({phone, onChangePhoneNumber, code, name = ""}) => {
  const {t} = useTranslation();

  const renderCode = (): JSX.Element | undefined => {
    if (!code) return;
    return (
      <IonLabel className={classes.code} slot="start">
        +{code}
      </IonLabel>
    );
  };

  return (
    <IonItem lines="full" dir="ltr">
      {renderCode()}
      <IonLabel position="floating" dir="ltr" className={classes.label}>
        {t("SIGNUP.PHONE")}
      </IonLabel>
      <IonInput
        dir="ltr"
        type="tel"
        inputmode="tel"
        pattern="tel"
        autocomplete="off"
        name={name}
        placeholder={t("SIGNUP.PHONE")}
        value={phone}
        onIonChange={onChangePhoneNumber}
      />
    </IonItem>
  );
};

export default PhoneNumberInput;
