import { IonRouterOutlet } from "@ionic/react";
import { Redirect, Route, useLocation } from "react-router-dom";
import { FC, lazy, Suspense, useEffect } from "react";
import {
  PrivateGuard,
  GuestGuard,
  PublicGuard,
  DisabledGuard,
  InactiveGuard,
  ProtectedGuard,
  RegisterationGuard,
} from "./components";
import { useAppSelector } from "src/hooks/useAppSelector";
import {
  Home,
  Products,
  Settings,
  Signup,
  Login,
  Auth,
  CategoryProductList,
  Categories,
  CategoryServicesList,
  DisabledStore,
  DisabledUser,
  ServiceCategories,
  Services,
  Notifications,
  NotificationDetails,
  ForgetPassword,
  ResetPassword,
  Bookings,
  BookingDetails,
  CustomPage,
  StoreWallet,
} from "./pages";
import { updateCurrentView } from "./firebase/analytics";
import hasCustomDomain from "./utils/hasCustomDomain";
import { PageLoading } from "src/components";
import SuccessfulBooking from "./pages/SuccessfulBooking";
import CartPage from "src/pages/cart";
import ProductDetails from "src/pages/productDetails";
import ServiceDetails from "src/pages/serviceDetails";
import Checkout from "src/pages/checkout";
import Orders from "src/pages/Orders";
import OrderDetails from "src/pages/OrderDetails";

type AppRoute = {
  path: string;
  component: FC;
  guard: FC<{ redirect?: string }>;
  redirect?: string;
};

const routes: AppRoute[] = [
  { path: "/signup", component: Signup, guard: RegisterationGuard },
  { path: "/login", component: Login, guard: GuestGuard },
  { path: "/forget-password", component: ForgetPassword, guard: PublicGuard },
  { path: "/reset-password", component: ResetPassword, guard: PublicGuard },
  { path: "/home", component: Home, guard: ProtectedGuard },
  { path: "/categories", component: Categories, guard: ProtectedGuard },
  {
    path: "/category/:id",
    component: CategoryProductList,
    guard: ProtectedGuard,
  },
  { path: "/products", component: Products, guard: ProtectedGuard },
  {
    path: "/product/:productId",
    component: ProductDetails,
    guard: ProtectedGuard,
  },
  {
    path: "/service-categories",
    component: ServiceCategories,
    guard: ProtectedGuard,
  },
  {
    path: "/service-category/:id",
    component: CategoryServicesList,
    guard: ProtectedGuard,
  },
  { path: "/services", component: Services, guard: ProtectedGuard },
  {
    path: "/service/:serviceId",
    component: ServiceDetails,
    guard: ProtectedGuard,
  },
  {
    path: "/services-packages",
    component: lazy(
      () => import("src/pages/servicesPackages/ServicesPackagesList")
    ),
    guard: ProtectedGuard,
  },
  {
    path: "/services-packages/:packageId",
    component: lazy(() => import("src/pages/servicesPackages/detailsPage")),
    guard: ProtectedGuard,
  },
  { path: "/cart", component: CartPage, guard: ProtectedGuard },
  { path: "/disabled", component: DisabledUser, guard: DisabledGuard },
  { path: "/inactive", component: DisabledStore, guard: InactiveGuard },
  {
    path: "/checkout",
    component: Checkout,
    guard: PrivateGuard,
    redirect: "cart",
  },
  {
    path: "/settings",
    component: Settings,
    guard: PrivateGuard,
    redirect: "settings",
  },
  {
    path: "/orders",
    component: Orders,
    guard: PrivateGuard,
    redirect: "orders",
  },
  { path: "/order/:orderId", component: OrderDetails, guard: PrivateGuard },
  {
    path: "/bookings",
    component: Bookings,
    guard: PrivateGuard,
    redirect: "bookings",
  },
  {
    path: "/booking/:bookingId",
    component: BookingDetails,
    guard: PrivateGuard,
  },
  {
    path: "/successful-booking",
    component: SuccessfulBooking,
    guard: PrivateGuard,
  },
  {
    path: "/notifications",
    component: Notifications,
    guard: PrivateGuard,
    redirect: "notifications",
  },
  {
    path: "/notification/:id",
    component: NotificationDetails,
    guard: PrivateGuard,
  },
  { path: "/store-wallet", component: StoreWallet, guard: PrivateGuard },
];

const Routes: FC = () => {
  const location = useLocation();
  const storeId = useAppSelector((state) => state.storeReducer.store.id);
  const pages = useAppSelector((state) => state.cmsReducer.cms.pages);

  const isCustomDomain = hasCustomDomain();
  const authPath = isCustomDomain ? "/auth" : "/:storeId/auth";

  useEffect(() => {
    updateCurrentView(location.pathname);
  }, [location]);

  const renderRoutes = (): JSX.Element[] => {
    return routes.map((route) => {
      const Component = route.component;
      const Guard = route.guard;
      const path = isCustomDomain ? route.path : `/:storeId${route.path}`;
      return (
        <Route key={path} path={path} exact>
          <Guard redirect={route.redirect}>
            <Suspense fallback={<PageLoading />}>
              <Component />
            </Suspense>
          </Guard>
        </Route>
      );
    });
  };

  const renderCustomRoutes = (): JSX.Element[] => {
    return pages.map((page, index) => {
      const path = isCustomDomain ? `/${page.path}` : `/:storeId/${page.path}`;
      return (
        <Route key={page.id} path={path} exact>
          <ProtectedGuard>
            <CustomPage index={index} />
          </ProtectedGuard>
        </Route>
      );
    });
  };

  function renderBaseRoute(): JSX.Element {
    if (isCustomDomain) return <Redirect exact path="/" to="/home" />;
    return <Redirect exact path={`/${storeId}`} to={`/${storeId}/home`} />;
  }

  return (
    <IonRouterOutlet id="main">
      <Route path={authPath} exact>
        <Auth />
      </Route>
      {renderRoutes()}
      {renderCustomRoutes()}
      {renderBaseRoute()}
    </IonRouterOutlet>
  );
};
export default Routes;
