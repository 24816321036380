import { GPSLocation } from "src/interfaces/GPSLocation";
import { NullableObject } from "src/interfaces/NullableObject";
import { CollectMethod } from "src/enums/CollectMethod";
import { OrderUser } from "src/interfaces/OrderUser"
import { store } from "src/store";
import { validateEmail, validateRequiredText } from "src/utils/validation";

export const validateAddress = (address: string, collectMethod: CollectMethod): boolean => {
    const isDelivery = collectMethod === CollectMethod.delivery;
    return (validateRequiredText(address) || !isDelivery)
}

export const validateDestination = (city: string, collectMethod: CollectMethod): boolean => {
    const isDelivery = collectMethod === CollectMethod.delivery;
    return !!city || !isDelivery;
}

export const validateLocation = (location: NullableObject<GPSLocation>): boolean => {
    const { isLocationMandatoryInOrder } = store.getState().storeReducer.store;
    return (location !== null || !isLocationMandatoryInOrder)
}

const validateUser = (user: OrderUser, collectMethod: CollectMethod, city: string): boolean => {
    if (!user) return false;
    const { firstname, email, lastname, phone } = user;
    return validateRequiredText(firstname)
        && validateRequiredText(lastname)
        && validateEmail(email)
        && validateRequiredText(phone)

}

export const validateOrder = async (user: OrderUser, collectMethod: CollectMethod, city: string): Promise<boolean> => {
    return validateUser(user, collectMethod, city)
        && validateLocation(user.location)
        && validateDestination(city, collectMethod)
        && validateAddress(user.address, collectMethod);;
}