import {
  IonAccordion,
  IonAccordionGroup,
  IonItem,
  IonLabel,
} from "@ionic/react";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import classes from "./styles.module.scss";
import { caretDown } from "ionicons/icons";

const DetailsAccordion: FC = ({ children }) => {
  const { t } = useTranslation();

  return (
    <IonAccordionGroup className="ion-full-width ion-margin-bottom-small">
      <IonAccordion
        toggleIcon={caretDown}
        className={classes.accordion}
        toggleIconSlot="start"
      >
        <IonItem slot="header">
          <IonLabel className="ion-font-bold ion-font-small">
            {t("MORE")}
          </IonLabel>
        </IonItem>
        <div
          slot="content"
          className="ion-padding-horizontal ion-font-small"
        >
          {children}
        </div>
      </IonAccordion>
    </IonAccordionGroup>
  );
};

export default DetailsAccordion;
