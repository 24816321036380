import { auth } from "src/firebase";
import firebaseApi from "./firebaseApi";

export const updatefirebaseApiAuthHeader = async (): Promise<void> => {
    try {
        const user = auth.currentUser;
        if (!user) throw Error();
        const token = await user.getIdToken();
        firebaseApi.defaults.headers.common.Authorization = `Bearer ${token}`;
    } catch {
        firebaseApi.defaults.headers.common.Authorization = "";
    }
};
