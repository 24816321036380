import { FC } from "react";
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCol,
  IonItem,
  IonLabel,
  IonList,
  IonRadio,
  IonRadioGroup,
  RadioGroupChangeEventDetail,
} from "@ionic/react";
import { useAppSelector } from "src/hooks/useAppSelector";
import { useTranslation } from "react-i18next";
import { CollectMethod } from "src/enums/CollectMethod";
import { IonRadioGroupCustomEvent } from "@ionic/core";

interface DeliveryMethodsProps {
  method: CollectMethod;
  onChangeMethod: (
    event: IonRadioGroupCustomEvent<
      RadioGroupChangeEventDetail<CollectMethod>
    >
  ) => void;
}

const DeliveryMethods: FC<DeliveryMethodsProps> = ({
  method,
  onChangeMethod,
}) => {
  const { t } = useTranslation();

  const store = useAppSelector((state) => state.storeReducer.store);

  const renderMethods = (): JSX.Element[] => {
    return store.orderCollectMethods.map((method) => {
      return (
        <IonItem key={method}>
          <IonLabel>{t(method)}</IonLabel>
          <IonRadio slot="end" value={method} />
        </IonItem>
      );
    });
  };

  return (
    <IonCol size="12">
      <IonCard className="ion-no-margin">
        <IonCardHeader>
          <IonCardSubtitle className="ion-font-bold">
            {t("CHECKOUT.DELIVERY_OPTION")}
          </IonCardSubtitle>
        </IonCardHeader>
        <IonCardContent>
          <IonList lines="none" className="ion-no-padding">
            <IonRadioGroup value={method} onIonChange={onChangeMethod}>
              {renderMethods()}
            </IonRadioGroup>
          </IonList>
        </IonCardContent>
      </IonCard>
    </IonCol>
  );
};

export default DeliveryMethods;
