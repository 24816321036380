import { collection, CollectionReference, doc, FirestoreError, getDoc, getDocFromCache, getDocs } from "firebase/firestore";
import { Addon } from "src/interfaces/Addon";
import { getFirestoreConverter } from "./converter";
import { getStoreDocRef } from "./store";

const COLLECTION = "addons";

const getCollection = (): CollectionReference<Addon> => {
    return collection(getStoreDocRef(), COLLECTION).withConverter(getFirestoreConverter<Addon>());
};

export const getAddonsFB = async (): Promise<Addon[]> => {
    try {
        const snapshot = await getDocs(getCollection());
        return snapshot.docs.map((doc) => doc.data());
    } catch {
        return [];
    }
}

export const getAddonByIdFB = async (id: string, fromCache = true): Promise<Addon | undefined> => {
    try {
      const document = doc(getCollection(), id);
      const snapshot = fromCache ? await getDocFromCache(document) : await getDoc(document);
      return snapshot.data();
    } catch (err) {
      const error = err as FirestoreError;
      if (error.code === "unavailable" && fromCache) {
        return await getAddonByIdFB(id, false);
      }
    }
  };
  