import { IonLabel } from "@ionic/react";
import { FC, Fragment, useMemo } from "react";
import getLocalOrDefault from "src/utils/getLocalOrDefault";
import { getFeaturedImage } from "src/utils/getFeaturedImage";
import DeleteButton from "../deleteButton";
import Price from "src/components/Price";
import { CartServicesPackage } from "src/interfaces/CartServicesPackage";
import { ServicesPackage } from "src/interfaces/ServicesPackage";
import { Service } from "src/interfaces/Service";
import SessionsInDuration from "src/components/SessionsInDuration";
import ItemThumbnail from "../../../components/ItemThumbnail";

interface CartPackageItemProps {
  cartPackage: CartServicesPackage;
  servicesPackage: ServicesPackage;
  services: Service[];
  onRemovePackage: (id: string) => void;
}

const CartPackageItem: FC<CartPackageItemProps> = ({
  cartPackage,
  servicesPackage,
  services,
  onRemovePackage,
}) => {
  const servicesName = useMemo(
    () => services.map(({ name }) => getLocalOrDefault(name)).join(", "),
    [services]
  );
  const image = getFeaturedImage(servicesPackage.gallery);
  const name = getLocalOrDefault(servicesPackage.name);

  return (
    <Fragment>
      <ItemThumbnail src={image.src} alt={name} />
      <div className="ion-display-block ion-flex-grow ion-no-margin ion-overflow-hidden">
        <div className="ion-wrap ion-no-margin">
          <IonLabel>{name}</IonLabel>
          <IonLabel className="ion-font-small ion-margin-top-auto" color="medium">
            {servicesName}
          </IonLabel>
        </div>
        <IonLabel className="ion-font-bold">
          <Price price={servicesPackage.fees} />
        </IonLabel>
        <IonLabel className="ion-font-small ion-font-bold">
          <SessionsInDuration
            sessions={servicesPackage.sessions}
            duration={servicesPackage.sessionsDuration}
            unit={servicesPackage.sessionsDurationUnit}
          />
        </IonLabel>
      </div>
      <DeleteButton id={cartPackage.id} onRemoveItem={onRemovePackage} />
    </Fragment>
  );
};

export default CartPackageItem;
