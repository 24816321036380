import {DatetimeChangeEventDetail, IonDatetimeCustomEvent} from "@ionic/core";
import {IonInput, IonLabel} from "@ionic/react";
import {FC, Fragment, useState} from "react";
import CalenderModal from "../CalenderModal";

interface DateTimeInputProps {
  onSubmit: (e: IonDatetimeCustomEvent<DatetimeChangeEventDetail>) => void;
  value: string;
  disabled?: boolean;
  label: string;
  min?: string;
  max?: string;
  name?: string;
}

const DateTimeInput: FC<DateTimeInputProps> = ({value, onSubmit, disabled, label, min, max, name}) => {
  const [isOpen, setIsOpen] = useState(false);

  const date = value ? new Date(value).toISOString().split("T")[0] : "";

  const handleCloseModal = (): void => {
    setIsOpen(false);
  };

  const handleOpenModal = (): void => {
    setIsOpen(true);
  };

  const handleSubmit = (e: IonDatetimeCustomEvent<DatetimeChangeEventDetail>): void => {
    onSubmit(e);
    handleCloseModal();
  };

  return (
    <Fragment>
      <IonLabel mode="ios" position="floating">
        {label}
      </IonLabel>
      <IonInput placeholder="" disabled={disabled} onClick={handleOpenModal} type="date" value={date} />
      <CalenderModal
        min={min}
        max={max}
        onSubmit={handleSubmit}
        value={value}
        open={isOpen}
        onClose={handleCloseModal}
        name={name}
      />
    </Fragment>
  );
};

export default DateTimeInput;
