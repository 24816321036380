import { IonButton, IonIcon } from "@ionic/react";
import { logoApple } from "ionicons/icons";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import useAuth from "src/hooks/useAuth";
import useToast from "src/hooks/useToast";
import classes from "./styles.module.scss";

const AppleSignInButton: FC = () => {
  const { t } = useTranslation();
  const { signInWithApple } = useAuth();
  const launchToast = useToast();

  const handleSigninWithApple = async (): Promise<void> => {
    try {
      await signInWithApple();
    } catch {
      launchToast(t("FAILED_TO_SIGNIN"), "danger");
    }
  };

  return (
    <div className="ion-text-center">
      <IonButton className={classes.root} onClick={handleSigninWithApple}>
        <IonIcon icon={logoApple} slot="start" />
        {t("SIGN_IN_WITH_APPLE")}
      </IonButton>
    </div>
  );
};

export default AppleSignInButton;
