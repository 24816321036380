import { collection, CollectionReference, doc, DocumentData, DocumentReference, getDoc, onSnapshot } from "firebase/firestore";
import { Store } from "src/interfaces/Store";
import { setIFrames, setStore } from "src/slices/store";
import { store } from "src/store";
import { updateTheming } from "src/theme";
import getStoreId from "src/utils/getStoreId";
import { initFacebookPixel } from "src/utils/track";
import { firestore } from ".";
import { getFirestoreConverter } from "./converter";

const COLLECTION = "stores";

const getCollection = (): CollectionReference<Store> => {
  return collection(firestore, COLLECTION).withConverter(getFirestoreConverter<Store>());
};

const getDocument = async (): Promise<DocumentReference<Store>> => {
  const storeId = await getStoreId();
  return doc(getCollection(), storeId);
}

export const getStoreDocRef = (id?: string): DocumentReference<DocumentData> => {
  const storeId = id || store.getState().storeReducer.store.id;
  return doc(getCollection(), storeId);
};


export const subscribeToStoreFB = async (): Promise<void> => {
  const document = await getDocument();
  const snapshot = await getDoc(doc(document, "payment-integration", "iframeIds"));
  const iframes = snapshot.data();
  if (iframes) {
    const valuIframeId = iframes.valuIframe;
    const iframeId = iframes.iframeIds?.pop()?.id || undefined;
    store.dispatch(setIFrames({ valuIframeId, iframeId }));
  }
  onSnapshot(document, (snapshot) => {
    const data = snapshot.data();
    if (!data) return;
    updateTheming(data.primaryColor, data.secondaryColor);
    store.dispatch(setStore(data));
    initFacebookPixel(data.pixelId);
  })
}