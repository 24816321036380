import { IonLabel } from "@ionic/react";
import { FC } from "react";
import { RequirementType } from "src/enums/RequirementType";
import { OrderRequirement } from "src/interfaces/OrderRequirement";
import getLocalOrDefault from "src/utils/getLocalOrDefault";

interface RequirementItemProps {
  requirement: OrderRequirement;
}

const RequirementItem: FC<RequirementItemProps> = ({ requirement }) => {
  const getValue = (): string => {
    switch (requirement.type) {
      case RequirementType.date:
        return new Date(requirement.value).toLocaleDateString();
      default:
        return requirement.value;
    }
  };

  return (
    <div className="ion-display-flex ion-justify-content-between">
      <IonLabel>{getLocalOrDefault(requirement.name)}</IonLabel>
      <IonLabel className="ion-font-bold ion-overflow-visible ion-text-wrap ion-max-width-half">
        {getValue()}
      </IonLabel>
    </div>
  );
};

export default RequirementItem;
