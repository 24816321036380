import {IonBadge} from "@ionic/react";
import {FC} from "react";
import classes from "./styles.module.scss";

interface CategoryBadgeProps {
  category: string;
}

const CategoryBadge: FC<CategoryBadgeProps> = ({category}) => {
  return (
    <IonBadge className={classes.root} color="primary">
      {category}
    </IonBadge>
  );
};

export default CategoryBadge;
