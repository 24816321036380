import { Color } from "./color";
import { ColorVariable, ColorName, RGB } from "./types";
import { Capacitor } from "@capacitor/core";
import { StatusBar } from "@capacitor/status-bar";
import { SplashScreen } from "@capacitor/splash-screen";

const generateColor = (value: string, property: ColorName): ColorVariable => {
  const color = new Color(value);
  const contrast = color.contrast();
  const tint = color.tint();
  const shade = color.shade();

  return {
    property,
    value,
    valueRgb: rgbToString(color.rgb),
    contrast: contrast.hex,
    contrastRgb: rgbToString(contrast.rgb),
    tint: tint.hex,
    shade: shade.hex,
  };
};

const rgbToString = (c: RGB): string => {
  return `${c.r},${c.g},${c.b}`;
};

export const updateTheming = (primaryColor: string, secondaryColor: string): void => {
  const root = document.querySelector(":root") as HTMLElement;
  const body = document.querySelector("body");

  const primary = generateColor(primaryColor, ColorName.primary);
  const secondary = generateColor(secondaryColor, ColorName.secondary);
  const tertiary = generateColor(secondaryColor, ColorName.tertiary);

  [primary, secondary, tertiary].forEach((color) => {
    [root, body].forEach((element) => {
      element.style.setProperty(`--ion-color-${color.property}`, color.value);
      element.style.setProperty(`--ion-color-${color.property}-rgb`, color.valueRgb);
      element.style.setProperty(`--ion-color-${color.property}-contrast`, color.contrast);
      element.style.setProperty(`--ion-color-${color.property}-contrast-rgb`, color.contrastRgb);
      element.style.setProperty(`--ion-color-${color.property}-shade`, color.shade);
      element.style.setProperty(`--ion-color-${color.property}-tint`, color.tint);
    })
  })

  if (Capacitor.isNativePlatform()) {
    StatusBar.setBackgroundColor({ color: primaryColor });
    SplashScreen.hide();
  }
}
