import {FC} from "react";
import classes from "./styles.module.scss";

interface YoutubeVideoProps {
  video: string;
}

const YoutubeVideo: FC<YoutubeVideoProps> = ({video}) => {
  const youtubeLink =
    video.startsWith("http://") || video.startsWith("https://")
      ? video.replace("watch?v=", "embed/")
      : `http://${video.replace("watch?v=", "embed/")}`;

  return <iframe className={classes.root} title={youtubeLink} src={youtubeLink} />;
};

export default YoutubeVideo;
