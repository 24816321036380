import {RawDraftContentState} from "draft-js";
import {FC} from "react";
import {Editor} from "react-draft-wysiwyg";
import classes from "./styles.module.scss";

interface DraftEditorPreviewProps {
  state: RawDraftContentState;
}

const DraftEditorPreview: FC<DraftEditorPreviewProps> = ({state}) => {
  return <Editor wrapperClassName={classes.wrapper} readOnly={true} contentState={state} toolbarHidden />;
};

export default DraftEditorPreview;
