import "./ServiceView.scss";
import React, {FC} from "react";
import {IonCardTitle, IonCard, IonCardHeader, IonImg, IonCardSubtitle} from "@ionic/react";
import { Service } from "src/interfaces/Service";
import {useTranslation} from "react-i18next";
import { useAppSelector } from "src/hooks/useAppSelector";
import {CMSListItemOptions, CMSItemShape} from "src/interfaces/CMS";
import getLocalOrDefault from "src/utils/getLocalOrDefault";
import getPagePath from "src/utils/getStoreIdCustomPath";

interface ServiceViewProps {
  service: Service;
  options?: CMSListItemOptions;
}

const ServiceView: FC<ServiceViewProps> = ({
  service,
  options = {height: 200, shape: CMSItemShape.square, isImageOnly: false, isResponsive: false},
}) => {
  const {t} = useTranslation();
  const {logo} = useAppSelector((state) => state.storeReducer.store);
  const isCircleShape = options.shape === CMSItemShape.circle;
  const cardStyle = {borderRadius: isCircleShape && "50%"};
  const height = options.isResponsive
    ? (options.height * document.documentElement.clientHeight) / 100 - 64
    : options.height;
  const imageStyle = {height};
  const detailsStyle = {textAlign: isCircleShape && "center"};

  const serviceLink = getPagePath(`service/${service.id}`);

  const getServiceFees = (fees: number): string => {
    return fees ? `${fees} ${t("EGP")}` : t("SERVICES.PRICE_VARIES_ON_SELECTION");
  };

  const getFeaturedImage = (): string => {
    return service.gallery.find((img) => img.isFeatured)?.src || service.gallery[0]?.src;
  };

  const handleImageError = (e: CustomEvent): void => {
    const target = e.target as HTMLIonImgElement;
    target.src = logo;
  };

  const renderServiceDetails = (shape: CMSItemShape): JSX.Element | undefined => {
    if (shape !== options.shape || options.isImageOnly) return;
    return (
      <IonCardHeader className="serviceView__header" style={detailsStyle}>
        <IonCardTitle className="serviceView__title">{getLocalOrDefault(service.name)}</IonCardTitle>
        <IonCardSubtitle className="serviceView__subtitle">{getServiceFees(service.fees)}</IonCardSubtitle>
      </IonCardHeader>
    );
  };

  return (
    <div className="serviceView">
      <IonCard className="serviceView__card" routerLink={serviceLink} style={cardStyle}>
        <IonImg
          onIonError={handleImageError}
          style={imageStyle}
          className="serviceView__image"
          src={getFeaturedImage()}
        />
        {renderServiceDetails(CMSItemShape.square)}
      </IonCard>
      {renderServiceDetails(CMSItemShape.circle)}
    </div>
  );
};

export default ServiceView;
