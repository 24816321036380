import { Color } from "@ionic/core";
import { PaymentStatus } from "src/enums/PaymentStatus";

export const getOrderPaymentStatusColor = (status: PaymentStatus): Color => {
    switch (status) {
        case PaymentStatus.canceled:
            return "danger";
        case PaymentStatus.pending:
        case PaymentStatus.postponed:
            return "warning";
        case PaymentStatus.completed:
            return "success";
        default:
            return "primary";
    }
}
