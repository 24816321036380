import {IonSpinner} from "@ionic/react";
import React, {FC} from "react";
import {CMSSlide, CMSSlideType} from "src/interfaces/CMS";
import {CMSSliderProductSlide, CMSSliderServiceSlide, CMSSliderCustomSlide} from "src/components";

interface CMSCarouselItemProps {
  slide: CMSSlide;
}

const CMSCarouselItem: FC<CMSCarouselItemProps> = ({slide}) => {
  const renderCarouselItem = (): JSX.Element => {
    if (slide.type === CMSSlideType.custom) {
      return <CMSSliderCustomSlide key={slide.id} slide={slide} />;
    } else if (slide.type === CMSSlideType.product) {
      return <CMSSliderProductSlide itemId={slide.value} key={slide.id} />;
    } else if (slide.type === CMSSlideType.service) {
      return <CMSSliderServiceSlide itemId={slide.value} key={slide.id} />;
    } else return <IonSpinner />;
  };

  return renderCarouselItem();
};

export default CMSCarouselItem;
