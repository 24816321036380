import { FC } from "react";
import { IonItem, IonLabel } from "@ionic/react";
import Price from "src/components/Price";

interface ReportItemProps {
  label: string;
  price: number;
  color?: string
}

const ReportItem: FC<ReportItemProps> = ({ label, price, color }) => {
  return (
    <IonItem lines="none">
      <IonLabel color={color} className="ion-font-bold" slot="start">
        {label}
      </IonLabel>
      <IonLabel slot="end">
        <Price price={+price.toFixed(2)} />
      </IonLabel>
    </IonItem>
  );
};

export default ReportItem;
