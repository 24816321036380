import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {WalletTransaction} from "src/interfaces/WalletTransaction";
import {subscribeToStoreWalletTransactionsFB} from "src/firebase/storeWalletTransactions";
import {AppThunk} from "src/store";

interface StoreWalletTransactionsState {
  transactions: WalletTransaction[];
  isLoaded: boolean;
}

const initialState: StoreWalletTransactionsState = {
  transactions: [],
  isLoaded: false,
};

export const storeWalletTransactionsSlice = createSlice({
  name: "STORE-WALLET-TRANSACTIONS",
  initialState,
  reducers: {
    setStoreWalletTransactions: (
      state: StoreWalletTransactionsState,
      action: PayloadAction<WalletTransaction[]>,
    ) => {
      state.transactions = action.payload;
      state.isLoaded = true;
    },
  },
});

export const {setStoreWalletTransactions} = storeWalletTransactionsSlice.actions;

export const subscribeToStoreWalletTransactions =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const {isLoaded} = getState().storeWalletTransactions;
    if (isLoaded) return;
    subscribeToStoreWalletTransactionsFB();
  };

export default storeWalletTransactionsSlice.reducer;
