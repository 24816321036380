import { BaseDocument } from "./BaseDocument";
import { Country } from "./Country";
import { PaymentMethod } from "src/enums/PaymentMethod";
import { GPSLocation } from "./GPSLocation";
import { Cart } from "./Cart";
import { NullableObject } from "./NullableObject";
import { CustomerRequirement } from "./CustomerRequirement";
import { CustomerAddress } from "./CustomerAddress";

export interface Customer extends BaseDocument {
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  country: Country;
  isDarkTheme: boolean;
  language: string;
  paymentMethod: PaymentMethod;
  addresses: CustomerAddress[];
  fcmTokens: string[];
  disabled: boolean;
  walletCredit: number;
  location: NullableObject<GPSLocation>;
  cart: Cart;
  requirements: CustomerRequirement[];
}

export enum CustomerKey {
  firstName = "firstName",
  lastName = "lastName",
  email = "email",
  country = "country",
  phone = "phone",
  paymentMethod = "paymentMethod",
  userId = "userId",
}