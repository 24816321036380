import { FC, useEffect, useRef } from "react";
import { TextareaChangeEventDetail, IonCol } from "@ionic/react";
import { OrderUserKey } from "src/interfaces/OrderUser";
import { useTranslation } from "react-i18next";
import { IonTextareaCustomEvent } from "@ionic/core";
import LocationButton from "./LocationButton";
import FloatingLabelTextarea from "src/components/FloatingLabelTextarea";
import { validateAddress, validateLocation } from "./validation";
import { NullableObject } from "src/interfaces/NullableObject";
import { GPSLocation } from "src/interfaces/GPSLocation";
import { CollectMethod } from "src/enums/CollectMethod";
import ErrorLabel from "src/components/ErrorLabel";

interface AddressProps {
  address: string;
  isError: boolean;
  location: NullableObject<GPSLocation>;
  collectMethod: CollectMethod;
  onChangeAddress: (
    e: IonTextareaCustomEvent<TextareaChangeEventDetail>
  ) => void;
  onChangeLocation: () => void;
}

const Address: FC<AddressProps> = ({
  address,
  isError,
  location,
  collectMethod,
  onChangeAddress,
  onChangeLocation,
}) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLIonColElement>();
  const isValidAddress = validateAddress(address, collectMethod);
  const isValidLocation = validateLocation(location);
  const error = isError && (!isValidAddress || !isValidLocation);

  useEffect(() => {
    if (error && ref) {
      ref.current.scrollIntoView();
    }
  }, [error]);

  const renderError = (): JSX.Element => {
    if (!error) return <div />;
    if (!isValidAddress)
      return (
        <ErrorLabel className="ion-margin-horizontal">
          {t("INVALID_FIELD")}
        </ErrorLabel>
      );

    if (!isValidLocation) {
      return (
        <ErrorLabel className="ion-margin-vertical ion-margin-start-auto">
          {t("LOCATION_REQUIRED")}
        </ErrorLabel>
      );
    }
  };

  return (
    <IonCol ref={ref} size="12" sizeSm="6" className="ion-padding-xsmall">
      <FloatingLabelTextarea
        className="ion-flex-grow"
        placeholder={t("ADDRESS")}
        value={address}
        name={OrderUserKey.address}
        onIonChange={onChangeAddress}
        rows={3}
      />
      <div className="ion-display-flex ion-justify-content-between">
        {renderError()}
        <LocationButton onChangeLocation={onChangeLocation} />
      </div>
    </IonCol>
  );
};

export default Address;
