import React, { FC } from "react";
import { Document, Page } from "@react-pdf/renderer";
import { Order } from "src/interfaces/Order";
import { Store } from "src/interfaces/Store";
import styles from "./styles";
import Header from "./Header";
import CustomerInfo from "./CustomerInfo";
import ProductsInfo from "./ProductsInfo";
import BookingsInfo from "./BookingsInfo";
import TotalInfo from "./TotalInfo";
import DeliveryInfo from "./DeliveryInfo";
import getLocalOrDefault from "src/utils/getLocalOrDefault";

interface OrderInvoicePdfProps {
  order: Order;
  store: Store;
}

const OrderInvoiceDocument: FC<OrderInvoicePdfProps> = ({
  order,
  store,

}) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Header
          logo={store.logo!}
          name={getLocalOrDefault(store.storeName)}
          order={order}
        />
        <CustomerInfo order={order} />
        <ProductsInfo order={order} />
        <BookingsInfo order={order} />
        <DeliveryInfo order={order}  />
        <TotalInfo total={order.total} voucherCode={order?.voucher?.code} />
      </Page>
    </Document>
  );
};

export default OrderInvoiceDocument;
