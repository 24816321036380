import { FC } from "react";
import PropTypes from "prop-types";
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonGrid,
  IonRow,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { Divider } from "src/components";
import { getBookingStatusColor } from "src/utils";
import BookingDetailsItem from "../BookingDetailsItem";
import { Booking } from "src/interfaces/Booking";
import getLocalOrDefault from "src/utils/getLocalOrDefault";

interface BookingInfoProps {
  booking: Booking;
}
const BookingInfo: FC<BookingInfoProps> = ({ booking }) => {
  const { t } = useTranslation();

  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>
          {t("PAGE.BOOKING_DETAILS.BOOKING_DETAILS_INFO_TITLE")}
        </IonCardTitle>
      </IonCardHeader>
      <Divider />
      <IonCardContent>
        <IonGrid>
          <IonRow>
            <BookingDetailsItem
              label={t("PAGE.BOOKING_DETAILS.SERVICE_NAME_LABEL")}
              value={getLocalOrDefault(booking.service.name)}
            />
            <BookingDetailsItem
              label={t("PAGE.BOOKING_DETAILS.BOOKING_STATUS_LABEL")}
              value={booking.bookingStatus}
              color={getBookingStatusColor(booking.bookingStatus)}
            />
            <BookingDetailsItem
              label={t("PAGE.BOOKING_DETAILS.BOOKING_TIME_LABEL")}
              value={new Date(booking.bookingTime).toLocaleString()}
            />
            <BookingDetailsItem
              label={t("PAGE.BOOKING_DETAILS.BOOKING_CREATION_TIME_LABEL")}
              value={new Date(booking.createdAt || Date.now()).toLocaleString()}
            />
          </IonRow>
        </IonGrid>
      </IonCardContent>
    </IonCard>
  );
};

BookingInfo.propTypes = {
  booking: PropTypes.any.isRequired,
};

export default BookingInfo;
