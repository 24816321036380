import "./CategoryView.scss";
import React, {FC} from "react";
import {IonCardTitle, IonCard, IonCardHeader, IonImg} from "@ionic/react";
import { useAppSelector } from "src/hooks/useAppSelector";
import {Category} from "src/interfaces/Category";
import {CMSListItemOptions, CMSItemShape} from "src/interfaces/CMS";
import getLocalOrDefault from "src/utils/getLocalOrDefault";
import getPagePath from "src/utils/getStoreIdCustomPath";

interface CategoryViewProps {
  category: Category;
  isService?: boolean;
  options?: CMSListItemOptions;
}

const CategoryView: FC<CategoryViewProps> = ({
  category,
  isService,
  options = {height: 200, isImageOnly: false, shape: CMSItemShape.square, isResponsive: false},
}) => {
  const {logo} = useAppSelector((state) => state.storeReducer.store);
  const isCircleShape = options.shape === CMSItemShape.circle;
  const cardStyle = {borderRadius: isCircleShape && "50%"};
  const height = options.isResponsive
    ? (options.height * document.documentElement.clientHeight) / 100 - 64
    : options.height;
  const imageStyle = {height};
  const link = isService ? "service-category" : "category";
  const categoryLink = getPagePath(`${link}/${category.id}`);

  const handleImageError = (e: CustomEvent): void => {
    const target = e.target as HTMLIonImgElement;
    target.src = logo;
  };

  const renderCategoryName = (shape: CMSItemShape): JSX.Element | undefined => {
    if (shape !== options.shape || options.isImageOnly) return;
    return (
      <IonCardHeader>
        <IonCardTitle className="categoryView__title">{getLocalOrDefault(category.name)}</IonCardTitle>
      </IonCardHeader>
    );
  };

  return (
    <div className="categoryView">
      <IonCard className="categoryView__card" routerLink={categoryLink} style={cardStyle}>
        <IonImg
          onIonError={handleImageError}
          className="categoryView__image"
          style={imageStyle}
          src={category.image?.src}
        />
        {renderCategoryName(CMSItemShape.square)}
      </IonCard>
      {renderCategoryName(CMSItemShape.circle)}
    </div>
  );
};

export default CategoryView;
