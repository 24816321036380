import { IonItem } from "@ionic/react";
import { FC, useCallback, useEffect, useState, memo } from "react";
import ItemPlaceholder from "../ItemPlaceholder";
import CartServiceItem from "./CartServiceItem";
import { Service } from "src/interfaces/Service";
import { getServiceByIdFB } from "src/firebase/services";
import { CartService } from "src/interfaces/CartService";
import { ServicesWorker } from "src/interfaces/ServicesWorker";
import { getServicesWorkerByIdFB } from "src/firebase/servicesWorkers";
import { getAvailableBookings } from "src/firebase/functions";
import { generateSlotFromDate } from "src/utils/generateSlotFromDate";

interface ServiceItemProps {
  cartService: CartService;
  onRemoveService: (id: string) => void;
}

const ServiceItem: FC<ServiceItemProps> = ({
  cartService,
  onRemoveService,
}) => {
  const [service, setService] = useState<Service>();
  const [worker, setWorker] = useState<ServicesWorker>();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isAvailable, setIsAvailable] = useState(false);

  const checkAvailability = useCallback(async () => {
    const { bookingTime, addons, serviceId, workerId } = cartService;
    const { availabilities, availableWorkers, isDayOrMore } =
      await getAvailableBookings(bookingTime, addons, serviceId);
    const available = isDayOrMore
      ? availableWorkers.includes(workerId)
      : availabilities.some(
          ({ id, slots }) =>
            id === workerId &&
            slots.includes(generateSlotFromDate(new Date(bookingTime)))
        );
    setIsAvailable(available);
   
  }, [cartService]);

  const fetchWorker = useCallback(async () => {
    const { workerId } = cartService;
    const fetchedWorker = await getServicesWorkerByIdFB(workerId);
    if (fetchedWorker) {
      setWorker(fetchedWorker);
    }
  }, [cartService]);

  const fetchService = useCallback(async () => {
    if (service) return;
    const { serviceId } = cartService;
    const fetchedService = await getServiceByIdFB(serviceId);
    if (fetchedService) {
      setService(fetchedService);
      await fetchWorker();
      await checkAvailability();
    }
    setIsLoaded(true);
  }, [cartService, service, fetchWorker, checkAvailability]);

  useEffect(() => {
    fetchService();
  }, [fetchService]);

  const renderContent = (): JSX.Element => {
    if (!service || !worker || !isLoaded) {
      return (
        <ItemPlaceholder
          item={cartService}
          isLoaded={isLoaded}
          onRemoveItem={onRemoveService}
        />
      );
    }
    return (
      <CartServiceItem
        worker={worker}
        isAvailable={isAvailable}
        service={service}
        cartService={cartService}
        onRemoveService={onRemoveService}
      />
    );
  };

  return <IonItem lines="full">{renderContent()}</IonItem>;
};

export default memo(ServiceItem);
