import {FC} from "react";
import {IonItem, IonLabel, IonThumbnail, useIonViewDidEnter} from "@ionic/react";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { useAppSelector } from "src/hooks/useAppSelector";
import {setNotificationsToBeViewed, setNotificationToBeClicked} from "src/slices/notifications";
import {Notification} from "src/interfaces/Notification";
import { NotificationType } from "src/enums/NotificationType";
import "./NotificationItem.scss";
import {convertRawDraftToString} from "src/utils/convertRawDraftToString";
import getPagePath from "src/utils/getStoreIdCustomPath";
import {auth} from "src/firebase";

interface NotificationItemProps {
  notification: Notification;
}

const NotificationItem: FC<NotificationItemProps> = ({notification}) => {
  const dispatch = useAppDispatch();

  const {logo: storeLogo} = useAppSelector((state) => state.storeReducer.store);

  const serviceCategoryLink = getPagePath(`${notification.type.replace("_", "-").toLowerCase()}`);
  const serviceCategoryReferenceId = getPagePath(
    `${notification.type.replace("_", "-").toLowerCase()}/${notification.referenceId}`,
  );
  const notifcationIdLink = getPagePath(`notification/${notification.id}`);

  const referenceIdLink = getPagePath(`order/${notification.referenceId}`);
  const homeLink = getPagePath("home");

  useIonViewDidEnter(() => {
    dispatch(setNotificationsToBeViewed());
  });

  const hasNotificationClicked = (notification: Notification): boolean => {
    return notification.clicks.includes(auth.currentUser!.uid);
  };

  const getNotificationLink = (notification: Notification): string => {
    switch (notification.type) {
      case NotificationType.products:
      case NotificationType.services:
      case NotificationType.categories:
      case NotificationType.serviceCategories:
        return serviceCategoryLink;
      case NotificationType.product:
      case NotificationType.service:
      case NotificationType.category:
      case NotificationType.serviceCategory:
        return serviceCategoryReferenceId;
      case NotificationType.notification:
        return notifcationIdLink;
      case NotificationType.order:
        return referenceIdLink;
      default:
        return homeLink;
    }
  };

  const handleNotificationClick = (notification: Notification): (() => void) => {
    return (): void => {
      if (!hasNotificationClicked(notification)) {
        dispatch(setNotificationToBeClicked(notification));
      }
    };
  };

  const getNotificationItemClassName = (isClicked: boolean): string => {
    return isClicked ? "" : "notificationItem__notClicked";
  };

  return (
    <IonItem
      routerLink={getNotificationLink(notification)}
      className={getNotificationItemClassName(hasNotificationClicked(notification))}
      onClick={handleNotificationClick(notification)}
    >
      <IonThumbnail slot="start">
        <img src={notification.image || storeLogo} alt={notification.title} />
      </IonThumbnail>
      <IonLabel>
        <h4>{notification.title}</h4>
        <p>{convertRawDraftToString(notification.body)}</p>
      </IonLabel>
    </IonItem>
  );
};

export default NotificationItem;
