import React, {FC} from "react";
import {
  IonHeader,
  IonToolbar,
  IonContent,
  IonPage,
  IonButtons,
  IonTitle,
  IonList,
  useIonViewDidEnter,
  IonBackButton,
} from "@ionic/react";
import {useTranslation} from "react-i18next";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { useAppSelector } from "src/hooks/useAppSelector";
import {setNotificationsToBeViewed} from "src/slices/notifications";
import EmptyView from "src/components/EmptyView/EmptyView";
import {NotificationItem} from "./components";
import {PageLoading} from "src/components";
import getPagePath from "src/utils/getStoreIdCustomPath";

const Notifications: FC = () => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const {notifications, isLoaded} = useAppSelector((state) => state.notificationsReducer);
  const homeLink = getPagePath("home");

  useIonViewDidEnter(() => {
    dispatch(setNotificationsToBeViewed());
  });

  const renderNotifications = (): JSX.Element[] => {
    return notifications.map((notification) => {
      return <NotificationItem key={notification.id} notification={notification} />;
    });
  };

  const renderNotificationsView = (): JSX.Element => {
    if (!isLoaded) return <PageLoading />;
    if (!notifications.length) return <EmptyView message={t("NOTIFICATIONS.EMPTY")} />;
    return <IonList lines="full">{renderNotifications()}</IonList>;
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton defaultHref={homeLink} />
          </IonButtons>
          <IonTitle>{t("MENU.NOTIFICATIONS_TITLE")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>{renderNotificationsView()}</IonContent>
    </IonPage>
  );
};

export default Notifications;
