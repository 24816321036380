import { FC } from "react";
import { CartAddon } from "src/interfaces/CartAddon";
import AddonItem from "./AddonItem";
import { useTranslation } from "react-i18next";
import { CartAddonsGroup } from "src/interfaces/CartAddonsGroup";
import { IonLabel } from "@ionic/react";
import { Addon } from "src/interfaces/Addon";
import { AddonsGroup } from "src/interfaces/AddonsGroup";

interface AddonsProps {
  addons: Addon[];
  addonsGroups: AddonsGroup[];
  cartProductAddons: CartAddon[];
  cartProductAddonsGroups: CartAddonsGroup[];
}

const Addons: FC<AddonsProps> = ({
  addons,
  addonsGroups,
  cartProductAddons,
  cartProductAddonsGroups,
}) => {
  const { t } = useTranslation();

  const renderAddons = (cartProductAddons: CartAddon[]): JSX.Element[] => {
    return cartProductAddons.map((cartProductAddon) => {
      const addon = addons.find(({ id }) => id === cartProductAddon.addonId);
      if (!addon) return null;
      return <AddonItem key={addon.id} addon={addon} />;
    });
  };

  const renderAddonsGroups = (): JSX.Element[][] => {
    return cartProductAddonsGroups.map((cartProductAddonsGroup) => {
      const group = addonsGroups.find(
        ({ id }) => id === cartProductAddonsGroup.groupId
      );
      if (!group) return null;
      return renderAddons(cartProductAddonsGroup.addons);
    });
  };

  return (
    <div className="ion-margin-bottom-small">
      <IonLabel className="ion-font-bold" color="medium">
        {t("ADDONS")}
      </IonLabel>
      {renderAddons(cartProductAddons)}
      {renderAddonsGroups()}
    </div>
  );
};

export default Addons;
