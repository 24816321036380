import { collection, CollectionReference, onSnapshot } from "firebase/firestore";
import { City } from "src/interfaces/City";
import { setCitites } from "src/slices/cities";
import { store } from "src/store";
import { getFirestoreConverter } from "./converter";
import { getStoreDocRef } from "./store";

const COLLECTION = "cities";

const getCollection = (): CollectionReference<City> =>
  collection(getStoreDocRef(), COLLECTION).withConverter(getFirestoreConverter<City>());

export const subscribeToCitiesFB = (): void => {
  if (store.getState().citiesReducer.isLoaded) return
  onSnapshot(getCollection(), (snapshot) => {
    store.dispatch(setCitites(snapshot.docs.map(doc => doc.data())));
  })
}
