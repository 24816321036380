import {FC, useEffect} from "react";
import {CMSItem} from "src/interfaces/CMS";
import {IonCol, IonGrid, IonRow} from "@ionic/react";
import CMSItemComponentView from "./CMSItemComponentView";
import getCMSItemViewOptions from "src/utils/getCMSItemWidth";
import useWindowDimensions from "src/hooks/useWindowDimensions";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import {subscribeToForms} from "src/slices/forms";

interface CMSContentProps {
  content: CMSItem[];
}

const CMSContent: FC<CMSContentProps> = ({content}) => {
  const {width} = useWindowDimensions();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(subscribeToForms());
  }, [dispatch]);

  const renderComponents = (): JSX.Element[] => {
    return content.map((item) => {
      const options = getCMSItemViewOptions(width, item.options);
      const maxWidth = `${options.width}%`;
      const textAlign = options.alignment.toLowerCase();
      const isHidden = options?.isHidden || false;

      if (isHidden) return null;

      return (
        <IonRow key={item.id}>
          <IonCol style={{maxWidth, textAlign}}>
            <CMSItemComponentView component={item.component} type={item.type} />
          </IonCol>
        </IonRow>
      );
    });
  };

  return <IonGrid>{renderComponents()}</IonGrid>;
};

export default CMSContent;
