import { IonLabel } from "@ionic/react";
import { FC, useCallback, useEffect, useState } from "react";
import AddonsList from "./AddonsList";
import getLocalOrDefault from "src/utils/getLocalOrDefault";
import { CartAddon } from "src/interfaces/CartAddon";
import { AddonsGroup } from "src/interfaces/AddonsGroup";
import { getAddonsGroupByIdFB } from "src/firebase/addonsGroups";

interface AddonsGroupItemProps {
  groupId: string;
  selectedAddons: CartAddon[];
  onSelectSingleAddon: (
    addonId: string,
    prevId: string,
    groupId: string
  ) => void;
  onSelectMultipleAddon: (
    addonId: string,
    checked: boolean,
    groupId: string
  ) => void;
}

const AddonsGroupItem: FC<AddonsGroupItemProps> = ({
  groupId,
  onSelectMultipleAddon,
  onSelectSingleAddon,
  selectedAddons,
}) => {
  const [group, setGroup] = useState<AddonsGroup>();

  const fetchGroup = useCallback(async () => {
    setGroup(await getAddonsGroupByIdFB(groupId));
  }, [groupId]);

  useEffect(() => {
    setGroup((prevState) => (prevState ? null : prevState));
    fetchGroup();
  }, [fetchGroup]);

  if (!group) return null;

  return (
    <div className="ion-margin-top-small">
      <IonLabel color="medium">{getLocalOrDefault(group.name)}</IonLabel>
      <AddonsList
        productAddons={group.addons}
        groupId={group.id}
        selectedAddons={selectedAddons}
        onSelectMultipleAddon={onSelectMultipleAddon}
        onSelectSingleAddon={onSelectSingleAddon}
      />
    </div>
  );
};

export default AddonsGroupItem;
