import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getUnitsFB } from "../firebase/units";
import { Unit } from "../interfaces/Unit";
import { AppThunk, RootState } from "src/store";

interface UnitsState {
  units: Unit[];
  isLoaded: boolean;
}

const initialState: UnitsState = {
  units: [],
  isLoaded: false,
};

export const unitsSlice = createSlice({
  name: "UNITS",
  initialState,
  reducers: {
    setUnits: (state, action: PayloadAction<UnitsState>) => action.payload,
  },
});

export const { setUnits } = unitsSlice.actions;

export const getUnits = (): AppThunk => async (dispatch, getState): Promise<void> => {
  const isLoaded = getState().unitsReducer.isLoaded;
  if (isLoaded) return;
  const units = await getUnitsFB();
  dispatch(setUnits({ units, isLoaded: true }));
}

export const selectUnitsState = (state: RootState) => state.unitsReducer;
export const selectAllUnits = (state: RootState) => state.unitsReducer.units;

export default unitsSlice.reducer;
