import {FC, useState, useEffect, useCallback} from "react";
import classes from "./styles.module.scss";
import { Service } from "src/interfaces/Service";
import ServiceView from "../ServiceView/ServiceView";
import {IonSlide, IonSlides} from "@ionic/react";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { useAppSelector } from "src/hooks/useAppSelector";
import {subscribeToServices} from "src/slices/services";
import {CMSListItemOptions, CMSItemShape} from "src/interfaces/CMS";
import getCarouselSlidePerView from "src/utils/getCarouselSlidePerView";

interface HorizontalServicesListProps {
  servicesIds: String[];
  options?: CMSListItemOptions;
}

const HorizontalServicesList: FC<HorizontalServicesListProps> = ({
  servicesIds,
  options = {
    width: 175,
    shape: CMSItemShape.square,
    height: 200,
    isImageOnly: false,
    isResponsive: false,
    itemPerView: 3,
  },
}) => {
  const dispatch = useAppDispatch();
  const [services, setServices] = useState<Service[]>([]);
  const {isLoaded, services: allServices} = useAppSelector((state) => state.servicesReducer);
  const [isListenerAttached, setIsListenerAttached] = useState(false);
  const [slidesPerView, setSlidesPerView] = useState(
    getCarouselSlidePerView(options.isResponsive, options.itemPerView, options.width),
  );

  const carouselOptions = {
    slidesPerView,
    loop: false,
  };

  useEffect(() => {
    setSlidesPerView(getCarouselSlidePerView(options.isResponsive, options.itemPerView, options.width));
    if (!isListenerAttached) {
      window.addEventListener("resize", () => {
        setSlidesPerView(getCarouselSlidePerView(options.isResponsive, options.itemPerView, options.width));
      });
      setIsListenerAttached(true);
    }
  }, [options, isListenerAttached]);

  const loadServices = useCallback(async () => {
    const services = servicesIds.reduce((acc, id) => {
      const selectedService = allServices.find((service) => service.id === id);
      if (selectedService) {
        acc.push(selectedService);
      }
      return acc;
    }, [] as Service[]);
    setServices(services);
  }, [allServices, servicesIds]);

  useEffect(() => {
    if (isLoaded) {
      loadServices();
    } else {
      dispatch(subscribeToServices());
    }
  }, [dispatch, isLoaded, loadServices]);

  const renderServices = (): JSX.Element[] =>
    services.map((service) => (
      <IonSlide key={service.id} className={classes.slide}>
        <ServiceView service={service} options={options} />
      </IonSlide>
    ));

  return <IonSlides options={carouselOptions}>{renderServices()}</IonSlides>;
};

export default HorizontalServicesList;
