import { FC } from "react";
import { IonItem } from "@ionic/react";
import { DatetimeChangeEventDetail } from "@ionic/core";
import classes from "./DateRequirement.module.scss";
import { RequiredField } from "src/interfaces/Requirement";
import { DateType } from "src/enums/DateType";
import DateTimeInput from "../DateTimeInput";

interface DateRequirementProps {
  value: string;
  label: string;
  field: RequiredField;
  onChangeRequirement: (e: CustomEvent<DatetimeChangeEventDetail>) => void;
}

const DateRequirement: FC<DateRequirementProps> = ({ onChangeRequirement, value, label, field }) => {
  const nowDate = new Date().toISOString();
  const max = field.hasMaxValue
    ? new Date(field.max).toISOString()
    : field.dateType === DateType.past
      ? nowDate
      : undefined;
  const min = field.hasMinValue
    ? new Date(field.min).toISOString()
    : field.dateType === DateType.future
      ? nowDate
      : undefined;

  return (
    <IonItem className={classes.root}>
      <DateTimeInput value={value} min={min} max={max} onSubmit={onChangeRequirement} label={label}/>
    </IonItem>
  );
};

export default DateRequirement;
