import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk, RootState} from "src/store";
import {
  collection,
  doc,
  getDoc,
  limit,
  getDocs,
  startAfter,
  getFirestore,
  query,
  where,
  QueryConstraint,
  QuerySnapshot,
  DocumentData,
} from "firebase/firestore";
import {Product} from "src/interfaces/Product";

const NUM_PAGINATION = 100;

interface ProductsState {
  data: Product[];
  loading: boolean;
  didLoad: boolean;
  didFetch?: boolean;
  error: string | null;
  loadingCount?: number;
  lastDoc: string | undefined;
  lastDocByCategory: Record<string, string | undefined>;
  didFetchCategory: Record<string, boolean | undefined>;
}

const initialState: ProductsState = {
  data: [],
  loading: false,
  didFetch: false,
  didFetchCategory: {},
  error: null,
  loadingCount: 0,
  didLoad: false,
  lastDocByCategory: {},
  lastDoc: undefined,
};

export const productsSlice = createSlice({
  name: "PRODUCTS",
  initialState,
  reducers: {
    setProducts: (state: ProductsState, action: PayloadAction<ProductsState>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    setProductsLoading: (state: ProductsState, action: PayloadAction<boolean>) => {
      return {
        ...state,
        loading: action.payload,
      };
    },
    setLastDoc: (state: ProductsState, action: PayloadAction<string>) => {
      return {
        ...state,
        lastDoc: action.payload,
      };
    },
    incLoading: (state: ProductsState, action: PayloadAction<void>) => {
      return {
        ...state,
        loadingCount: (state.loadingCount ?? 0) + 1,
      };
    },
    decLoading: (state: ProductsState, action: PayloadAction<void>) => {
      return {
        ...state,
        loadingCount: (state.loadingCount ?? 0) - 1,
      };
    },
  },
});
export const selectProductsState = (state: RootState) => state.productsReducer;
export const selectAllProducts = (state: RootState): Product[] =>
  state.productsReducer.data.filter(({isPublished}) => isPublished);
export const selectProductsLoading = (state: RootState) => state.productsReducer.loading;
export const selectAllProductsHighstPrice = (state: RootState): Product[] => {
  const sortedProducts = [...state.productsReducer.data];
  return sortedProducts.sort((a, b) => b.price - a.price);
};
export const selectAllProductsLowestPrice = (state: RootState): Product[] => {
  const sortedProducts = [...state.productsReducer.data];
  return sortedProducts.sort((a, b) => a.price - b.price);
};
export const selectLatestProducts =
  (state: RootState) =>
  (count: number): Product[] =>
    selectProductsState(state)
      .data.filter(({isPublished}) => isPublished)
      .slice(0, count);
export const selectOnSaleProducts = (state: RootState): Product[] =>
  selectProductsState(state).data.filter((product) => Boolean(product.salePrice) && product.isPublished);
export const selectFeaturedProducts = (state: RootState): Product[] =>
  selectProductsState(state).data.filter((product) => Boolean(product.isFeatured) && product.isPublished);
export const selectProductById = (state: RootState) => (id: string) =>
  selectAllProducts(state).filter((product: Product) => product.id === id)[0];

function removeDuplicates(products: Product[]): Product[] {
  const obj: Record<string, Product> = {};
  for (const prod of products) {
    obj[prod.id] = prod;
  }
  return Object.keys(obj).map((k) => obj[k]);
}

export const getRemoteProducts =
  (categoryId?: string, count?: number): AppThunk =>
  async (dispatch, getState) => {
    const limit = count || NUM_PAGINATION;
    const storeId = getState().storeReducer.store.id;
    if (!storeId) return;
    const {didFetchCategory, lastDoc, didFetch, lastDocByCategory} = getState().productsReducer;
    if (!categoryId && !lastDoc && didFetch) return;
    if (categoryId && !lastDocByCategory[categoryId] && didFetchCategory[categoryId]) return;
    dispatch(productsSlice.actions.incLoading());
    const resp = await getProducts(storeId, categoryId ? lastDocByCategory[categoryId] : lastDoc, categoryId, limit);
    dispatch(productsSlice.actions.decLoading());
    const newSize = resp?.docs.length;
    const newLastDoc = newSize === limit ? resp?.docs[resp.docs.length - 1]?.id : undefined;
    dispatch(
      setProducts({
        data: removeDuplicates([
          ...getState().productsReducer.data,
          ...(resp?.docs.map((p: any) => ({...p.data(), id: p.id})) as Product[]),
        ]),
        loading: false,
        error: null,
        didFetch: categoryId ? didFetch : true,
        didFetchCategory: categoryId
          ? {
              ...getState().productsReducer.didFetchCategory,
              [categoryId]: true,
            }
          : {...getState().productsReducer.didFetchCategory},
        didLoad: true,
        lastDoc: categoryId ? lastDoc : newLastDoc,
        lastDocByCategory: categoryId
          ? {
              ...getState().productsReducer.lastDocByCategory,
              [categoryId]: newLastDoc,
            }
          : {...lastDocByCategory},
      }),
    );
  };
export const getRemoteProduct =
  (pId: string): AppThunk =>
  async (dispatch, getState) => {
    const storeId = getState().storeReducer.store.id;
    if (!storeId) return;

    const {lastDoc, data, lastDocByCategory, didFetchCategory} = getState().productsReducer;
    const idx = data.findIndex(({id}) => id === pId);
    if (idx !== -1) return;
    dispatch(productsSlice.actions.incLoading());
    // dispatch(setProductsLoading(true));
    if (!pId) {
      dispatch(productsSlice.actions.decLoading());
      return;
    }

    const db = getFirestore();
    const docRef = await getDoc(doc(db, "stores", storeId, "products", pId));
    if (!docRef.data()) {
      dispatch(productsSlice.actions.decLoading());
      return;
    }

    dispatch(
      setProducts({
        data: [...data, {...docRef.data(), id: docRef.id} as Product],
        loading: false,
        error: null,
        didFetchCategory: didFetchCategory,
        didLoad: true,
        lastDoc,
        lastDocByCategory,
      }),
    );
    dispatch(productsSlice.actions.decLoading());
  };

export const {setProducts, setProductsLoading} = productsSlice.actions;

export const getProducts = async (
  storeId: string,
  startAfterId?: string,
  categoryId?: string,
  paginationLimit?: number,
  source: "cache" | "server" = "cache",
): Promise<QuerySnapshot<DocumentData>> => {
  const db = getFirestore();
  const productCollection = collection(db, "stores", storeId, "products");
  const constraints: QueryConstraint[] = [limit(paginationLimit || NUM_PAGINATION)];
  if (categoryId) {
    constraints.push(where("meta.categoryId", "array-contains", categoryId));
  }
  if (startAfterId) {
    const docRef = await getDoc(doc(db, "stores", storeId, "products", startAfterId));
    constraints.push(startAfter(docRef));
  }
  const queryRef = query(productCollection, ...constraints);
  return await getDocs(queryRef);
};

export default productsSlice.reducer;
