import { collection, CollectionReference, doc, FirestoreError, getDoc, getDocFromCache, getDocs } from "firebase/firestore";
import { Attribute } from "src/interfaces/Attribute";
import { getFirestoreConverter } from "./converter";
import { getStoreDocRef } from "./store";

const COLLECTION = "attributes";

const getCollection = (): CollectionReference<Attribute> => {
    return collection(getStoreDocRef(), COLLECTION).withConverter(getFirestoreConverter<Attribute>());
};

export const getAttributesFB = async (): Promise<Attribute[]> => {
    try {
        const snapshot = await getDocs(getCollection());
        return snapshot.docs.map((doc) => doc.data());
    } catch {
        return [];
    }
}


export const getAttributeByIdFB = async (id: string, fromCache = true): Promise<Attribute | undefined> => {
    try {
        const document = doc(getCollection(), id);
        const snapshot = fromCache ? await getDocFromCache(document) : await getDoc(document);
        return snapshot.data();
    } catch (err) {
        const error = err as FirestoreError;
        if (error.code === "unavailable" && fromCache) {
            return await getAttributeByIdFB(id, false);
        }
    }
};
