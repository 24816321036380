import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonList,
} from "@ionic/react";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import ServiceItem from "./ServiceItem";
import { OrderService } from "src/interfaces/OrderService";

interface ServicesProps {
  services: OrderService[];
}

const Services: FC<ServicesProps> = ({ services }) => {
  const { t } = useTranslation();

  const renderServices = (): JSX.Element[] =>
    services.map((service) => (
      <ServiceItem key={service.id} service={service} />
    ));

  if (!services.length) return null;

  return (
    <IonCard>
      <IonCardHeader>
        <IonCardSubtitle className="ion-font-bold">
          {t("SERVICES")}
        </IonCardSubtitle>
      </IonCardHeader>
      <IonCardContent>
        <IonList>{renderServices()}</IonList>
      </IonCardContent>
    </IonCard>
  );
};

export default Services;
