import { FC } from "react";
import { OrderCustomVariation } from "src/interfaces/OrderCustomVariation";
import CustomVariationItem from "./CustomVariationItem";

interface CustomVariationsProps {
  customVariations: OrderCustomVariation[];
}

const CustomVariations: FC<CustomVariationsProps> = ({ customVariations }) => {
  const renderCustomVariations = (): JSX.Element[] => {
    return customVariations.map((customVariation) => {
      return (
        <CustomVariationItem
          key={customVariation.id}
          customVariation={customVariation}
        />
      );
    });
  };

  if (!customVariations.length) return null;

  return (
    <div className="ion-margin-bottom-small">{renderCustomVariations()}</div>
  );
};

export default CustomVariations;
