import React, { FC } from "react";
import { IonLabel, IonItem, IonInput } from "@ionic/react";
import { InputChangeEventDetail } from "@ionic/core";
import { RequiredField } from "src/interfaces/Requirement";

interface DateRequirementProps {
  value: string;
  label: string;
  field: RequiredField;
  onChangeRequirement: (e: CustomEvent<InputChangeEventDetail>) => void;
}

const NumberRequirement: FC<DateRequirementProps> = ({ onChangeRequirement, value, label, field }) => {
  const min = field.hasMinValue ? field.min.toString() : "";
  const max = field.hasMaxValue ? field.max.toString() : "";
  return (
    <IonItem>
      <IonLabel position="floating">{label}</IonLabel>
      <IonInput
        onIonChange={onChangeRequirement}
        placeholder={label}
        value={value}
        type="number"
        inputMode="numeric"
        inputmode="numeric"
        min={min}
        max={max}
      />
    </IonItem>
  );
};

export default NumberRequirement;
