import { Cart } from "src/interfaces/Cart";
import Storage from ".";
import { store } from "src/store";

export const storeCartLS = async (cart: Cart): Promise<void> => {
    const storeId = store.getState().storeReducer.store.id;
    await Storage.set({ key: `${storeId}__cart`, value: JSON.stringify(cart) });
}

export const deleteCartLS = async (): Promise<void> => {
    const storeId = store.getState().storeReducer.store.id;
    await Storage.remove({ key: `${storeId}__cart` });
}

export const restoreCartLS = async (): Promise<Cart | null> => {
    try {
        const storeId = store.getState().storeReducer.store.id;
        const { value } = await Storage.get({ key: `${storeId}__cart` });
        return value ? JSON.parse(value) as Cart : null;
    } catch {
        return null
    }
}