import { FC } from "react";
import {
  IonHeader,
  IonButtons,
  IonMenuButton,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useTranslation } from "react-i18next";

const Header: FC = () => {
  const { t } = useTranslation();

  return (
    <IonHeader>
      <IonToolbar color="primary">
        <IonButtons slot="start">
          <IonMenuButton />
        </IonButtons>
        <IonTitle>{t("ORDERS")}</IonTitle>
      </IonToolbar>
    </IonHeader>
  );
};

export default Header;
