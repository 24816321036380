import API from "src/constants/api";
import getStoreId from "./getStoreId";

export const setupManifest = async (): Promise<void> => {
    const storeId = await getStoreId();
    const manifestElement = document.querySelector("#manifest");
    if (!manifestElement) return;
    const href = process.env.REACT_APP_API_BASE_URL.concat("/", API.MANIFEST, "/", storeId);
    manifestElement.setAttribute("href", href);
}
