import { FC, useCallback, useEffect, useState } from "react";
import { IonBadge } from "@ionic/react";
import getLocalOrDefault from "src/utils/getLocalOrDefault";
import { Category } from "src/interfaces/Category";
import { getCategoryByIdFB } from "src/firebase/categories";

interface CategoryItemProps {
  categoryId: string;
}

const CategoryItem: FC<CategoryItemProps> = ({ categoryId }) => {
  const [category, setCategory] = useState<Category>();

  const fetchCategory = useCallback(async () => {
    setCategory(await getCategoryByIdFB(categoryId));
  }, [categoryId]);

  useEffect(() => {
    setCategory((prevState) => (prevState ? null : prevState));
    fetchCategory();
  }, [fetchCategory]);

  if (!category) return null;

  return (
    <IonBadge className="ion-margin-end-small">
      {getLocalOrDefault(category.name)}
    </IonBadge>
  );
};

export default CategoryItem;
