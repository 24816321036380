import {IonSelect, IonSelectOption, IonItem, IonLabel} from "@ionic/react";
import {FC} from "react";
import getLocalOrDefault from "src/utils/getLocalOrDefault";
import {SelectChangeEventDetail} from "@ionic/core";
import {TranslationMap} from "src/interfaces/Translation";
import classes from "./styles.module.scss";
import {useTranslation} from "react-i18next";
import { FormFieldSelectOption } from "src/interfaces/FormFieldSelectOption";

interface CMSFormSelectFieldProps {
  options: FormFieldSelectOption[];
  label: string;
  value: string;
  onChangeSelectField: (value: string, selectValue: TranslationMap | null) => void;
}

const CMSFormSelectField: FC<CMSFormSelectFieldProps> = ({options, label, value, onChangeSelectField}) => {
  const {t} = useTranslation();

  const handleChangeSelectField = (e: CustomEvent<SelectChangeEventDetail>): void => {
    const value = e.detail.value || "";
    const selectValue = options.find(({id}) => id === value)?.name || null;
    onChangeSelectField(value, selectValue);
  };

  const renderOptions = (): JSX.Element[] => {
    return options.map((option) => {
      return (
        <IonSelectOption value={option.id} key={option.id}>
          {getLocalOrDefault(option.name)}
        </IonSelectOption>
      );
    });
  };

  return (
    <IonItem>
      <IonLabel className={classes.label}>{label}</IonLabel>
      <IonSelect
        interfaceOptions={{cssClass: classes.alert}}
        interface="alert"
        className={classes.select}
        value={value}
        okText={t("OK")}
        cancelText={t("CANCEL")}
        onIonChange={handleChangeSelectField}
      >
        {renderOptions()}
      </IonSelect>
    </IonItem>
  );
};
export default CMSFormSelectField;
