import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../store";
import { Notification } from "src/interfaces/Notification";
import { setNotificationToBeClickedFB, setNotificationsToBeViewedFB } from "src/firebase/notifications";
import { auth } from "src/firebase";

interface NotificationsState {
  notifications: Notification[];
  isLoaded: boolean;
}

const initialState: NotificationsState = {
  notifications: [],
  isLoaded: false,
};

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setNotifications: (state: NotificationsState, action: PayloadAction<Notification[]>) => {
      state.notifications = action.payload;
      state.isLoaded = true;
    },
  },
});

export const { setNotifications } = notificationsSlice.actions;

export const setNotificationsToBeViewed = (): AppThunk => async (dispatch, getState) => {
  const userId = auth.currentUser!.uid;
  const notifications = getState().notificationsReducer.notifications.filter(({ views }) => !views.includes(userId));
  await setNotificationsToBeViewedFB(notifications);
};

export const setNotificationToBeClicked = (notification: Notification): AppThunk => async () => {
  await setNotificationToBeClickedFB(notification);
};

export default notificationsSlice.reducer;
