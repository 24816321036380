import {FC, Fragment} from "react";
import { useAppSelector } from "src/hooks/useAppSelector";
import {Redirect} from "react-router-dom";
import useAuth from "src/hooks/useAuth";
import getPagePath from "src/utils/getStoreIdCustomPath";

export interface PrivateGuardProps {
  redirect?: string;
}

const PrivateGuard: FC<PrivateGuardProps> = ({children, redirect}) => {
  const {isAuthenticated} = useAuth();

  const store = useAppSelector((state) => state.storeReducer.store);
  const customer = useAppSelector((state) => state.customerReducer.customer);

  const disableLink = getPagePath("disabled");
  const inActiveLink = getPagePath("inactive");
  const redirectLink = getPagePath(`login?redirectUrl=${redirect}`);

  if (isAuthenticated && customer?.disabled) {
    return <Redirect to={disableLink} />;
  }
  if (!store.active) {
    return <Redirect to={inActiveLink} />;
  }

  if (!isAuthenticated) {
    return <Redirect to={redirectLink} />;
  }
  return <Fragment>{children}</Fragment>;
};

export default PrivateGuard;
