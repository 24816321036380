import {IonCol, IonRow} from "@ionic/react";
import {FC} from "react";
import useWindowDimensions from "src/hooks/useWindowDimensions";
import {CMSContainer} from "src/interfaces/CMS";
import getCMSItemViewOptions from "src/utils/getCMSItemWidth";
import {CMSItemComponentView} from ".";

interface CMSContainerViewProps {
  container: CMSContainer;
}

const CMSContainerView: FC<CMSContainerViewProps> = ({container}) => {
  const {width} = useWindowDimensions();

  const renderItems = (): JSX.Element[] => {
    return container.items.map((item) => {
      const options = getCMSItemViewOptions(width, item.options);
      const itemWidth = `${options.width}%`;
      const textAlign = options.alignment.toLowerCase();
      const style = {maxWidth: itemWidth, minWidth: itemWidth, textAlign};
      return (
        <IonCol key={item.id} style={style}>
          <CMSItemComponentView component={item.component} type={item.type} />
        </IonCol>
      );
    });
  };

  return <IonRow>{renderItems()}</IonRow>;
};

export default CMSContainerView;
