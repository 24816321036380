import React, {FC} from "react";
import {IonItem, IonLabel, IonButton, IonIcon, IonList, IonButtons} from "@ionic/react";
import {add} from "ionicons/icons";
import {useTranslation} from "react-i18next";
import {Address} from "..";
import { CustomerAddress } from "src/interfaces/CustomerAddress";

interface AddressesListProps {
  addresses: CustomerAddress[];
  onRemoveAddress: (id: string) => void;
  onOpenAddressModal: () => void;
}

const AddressesList: FC<AddressesListProps> = ({addresses, onOpenAddressModal, onRemoveAddress}) => {
  const {t} = useTranslation();

  const renderAddresses = (): JSX.Element[] => {
    return addresses.map((address) => {
      return <Address address={address} key={address.label} onRemoveAddress={onRemoveAddress} />;
    });
  };

  return (
    <IonList>
      <IonItem lines="none">
        <IonLabel>{t("PAGE.SETTINGS.ADDRESSES_HEADER_TITLE")}</IonLabel>
        <IonButtons slot="end">
          <IonButton onClick={onOpenAddressModal}>
            <IonIcon icon={add} slot="icon-only" />
          </IonButton>
        </IonButtons>
      </IonItem>
      {renderAddresses()}
    </IonList>
  );
};

export default AddressesList;
