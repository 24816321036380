import { Country } from "./Country";
import { GPSLocation } from "./GPSLocation";
import { NullableObject } from "./NullableObject";

export interface OrderUser {
    customerId: string;
    firstname: string;
    lastname: string;
    phone: string;
    email: string;
    address: string;
    location: NullableObject<GPSLocation>;
    country: Country;
}

export enum OrderUserKey {
    firstname = "firstname",
    lastname = "lastname",
    phone = "phone",
    email = "email",
    address = "address",
}