import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "src/store";
import { Customer } from "src/interfaces/Customer";
import {
  changeCustomerIsDarkThemeFB,
  updateCustomerLanguageFB,
  updateCustomerFB,
  updateCustomerFCMTokensFB,
} from "src/firebase/customer";
import { uploadCustomerFileFB } from "src/firebase/storage";

interface CustomerState {
  customer: Customer | null;
  isLoaded: boolean;
  isDeleting: boolean;
  isFCMRegistered: boolean;
}

const initialState: CustomerState = {
  customer: null,
  isLoaded: false,
  isDeleting: false,
  isFCMRegistered: false,
};

export const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    setCustomer: (state, action: PayloadAction<Customer | null>) => {
      const customer = action.payload;
      state.isFCMRegistered = !!customer;
      state.customer = customer;
      state.isLoaded = true;
    },
    setIsDeleting: (state) => {
      state.isDeleting = true;
    },
  },
});

export const { setCustomer, setIsDeleting } = customerSlice.actions;

export const saveCustomerFCMToken = (token: string): AppThunk => async (dispatch, getState): Promise<void> => {
  const customer = getState().customerReducer.customer;
  if (!customer || customer.fcmTokens.includes(token)) return;
  const fcmTokens = [...customer.fcmTokens, token];
  await updateCustomerFCMTokensFB(fcmTokens);
};

export const updateCustomerIsDarkTheme =
  (isDarkTheme: boolean): AppThunk =>
    async (dispatch, getState): Promise<void> => {
      const customer = getState().customerReducer.customer;
      if (!customer) return;
      await changeCustomerIsDarkThemeFB(isDarkTheme);
    };

export const changeCustomerLanguage =
  (language: string): AppThunk =>
    async (dispatch, getState): Promise<void> => {
      const customer = getState().customerReducer.customer;
      if (!customer) return;
      await updateCustomerLanguageFB(language);
    };

export const updateCustomer =
  (customer: Customer): AppThunk =>
    async (dispatch, getState): Promise<void> => {
      if (!customer) return;
      await updateCustomerFB(customer);
    };

export const uploadCustomerFile =
  (file: File): AppThunk =>
    async (dispatch, getState): Promise<string> => {
      return await uploadCustomerFileFB(file);
    };

export default customerSlice.reducer;
