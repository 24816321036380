import { FC } from "react";
import { OrderUser } from "src/interfaces/OrderUser";
import {
  IonCard,
  IonCardContent,
  IonCol,
  IonGrid,
  IonLabel,
  IonRow,
} from "@ionic/react";
import { useTranslation } from "react-i18next";

interface UserDetailsProps {
  user: OrderUser;
}
const UserDetails: FC<UserDetailsProps> = ({ user }) => {
  const { t } = useTranslation();

  const renderAddress = (): JSX.Element => {
    if (!user.address.trim()) return;
    return (
      <IonCol size="12">
        <div className="ion-display-flex ion-justify-content-between">
          <IonLabel>{t("ADDRESS")}</IonLabel>
          <div>
            <IonLabel color="dark" className="ion-font-bold ion-text-end">
              {user.address}
            </IonLabel>
          </div>
        </div>
      </IonCol>
    );
  };

  return (
    <IonCard>
      <IonCardContent>
        <IonGrid>
          <IonRow>
            <IonCol size="12" sizeMd="6">
              <div className="ion-display-flex ion-justify-content-between">
                <IonLabel>{t("EMAIL")}</IonLabel>
                <IonLabel color="dark" className="ion-font-bold">
                  {user.email}
                </IonLabel>
              </div>
            </IonCol>
            <IonCol size="12" sizeMd="6">
              <div className="ion-display-flex ion-justify-content-between">
                <IonLabel>{t("PHONE")}</IonLabel>
                <IonLabel color="dark" className="ion-font-bold">
                  {user.phone}
                </IonLabel>
              </div>
            </IonCol>
            {renderAddress()}
          </IonRow>
        </IonGrid>
      </IonCardContent>
    </IonCard>
  );
};

export default UserDetails;
