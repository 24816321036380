import React, { FC } from "react";
import { IonLabel, IonItem, IonInput } from "@ionic/react";
import { InputChangeEventDetail } from "@ionic/core";

interface TextRequirementProps {
  value: string;
  label: string;
  onChangeRequirement: (e: CustomEvent<InputChangeEventDetail>) => void;
}

const TextRequirement: FC<TextRequirementProps> = ({ onChangeRequirement, value, label }) => {
  return (
    <IonItem>
      <IonLabel position="floating">{label}</IonLabel>
      <IonInput onIonChange={onChangeRequirement} placeholder={label} value={value} />
    </IonItem>
  );
};

export default TextRequirement;
