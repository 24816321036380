import { FC } from "react";
import { useAppSelector } from "src/hooks/useAppSelector";
import { Redirect } from "react-router-dom";
import getPagePath from "src/utils/getStoreIdCustomPath";
import { selectStore } from "src/slices/store";

const InactiveGuard: FC = ({ children }) => {
  const store = useAppSelector(selectStore);
  const homeLink = getPagePath("home");

  if (store.active) {
    return <Redirect to={homeLink} />;
  }

  return <>{children}</>;
};

export default InactiveGuard;
