import React, { FC, useEffect } from "react";
import {
    IonHeader,
    IonContent,
    IonButtons,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonList,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { EmptyView, PageLoading } from "src/components";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { useAppSelector } from "src/hooks/useAppSelector";
import { BookingItem } from "./components";
import {Booking} from "src/interfaces/Booking";
import { subscribeToBookings } from "src/slices/bookings";

const Bookings: FC = () => {
    const { bookings, isSubscribed } = useAppSelector((state) => state.bookingsReducer);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(subscribeToBookings());
    }, [dispatch])

    const sortBookings = (): Booking[] => {
        return [...bookings].sort((b1, b2) => {            
                const d1 = new Date(b1.createdAt || new Date()).getTime();
                const d2 = new Date(b2.createdAt || new Date()).getTime();
                return d1 - d2;
            }
        );
    }

    const renderBookings = (): JSX.Element[] => {
        return sortBookings().map((booking) => <BookingItem key={booking.id} booking={booking} />)
    }

    const renderBookingsView = (): JSX.Element => {
        if (!isSubscribed) return <PageLoading />
        if (!bookings.length) return <EmptyView message={t("PAGE.BOOKINGS.NO_BOOKINGS_LABEL")} />
        return <IonList lines="none">{renderBookings()}</IonList>;
    }
    return (
        <IonPage id="bookingsPage">
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>{t("PAGE.BOOKINGS.PAGE_TITLE")}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                {renderBookingsView()}
            </IonContent>
        </IonPage>
    );
};

export default Bookings;
