import {useState, FC} from "react";
import {
  IonBadge,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonToolbar,
  IonButton,
  IonButtons,
  IonIcon,
  IonText,
} from "@ionic/react";
import "./ServicesList.scss";
import LazyImage from "../LazyImage/LazyImage";
import {useHistory} from "react-router";
import { useAppSelector } from "src/hooks/useAppSelector";
import {gridOutline, listOutline} from "ionicons/icons";
import {useTranslation} from "react-i18next";
import { Service } from "../../interfaces/Service";
import getPagePath from "src/utils/getStoreIdCustomPath";
import getLocalOrDefault from "src/utils/getLocalOrDefault";

interface ServicesListProps {
  services: Service[];
}

const ServicesList: FC<ServicesListProps> = ({services}) => {
  const history = useHistory();
  const {t} = useTranslation();

  const {servicesCategories} = useAppSelector(({servicesCategoriesReducer}) => servicesCategoriesReducer);

  const [type, setType] = useState<string>("grid");

  const handleClickService = (service: Service) => {
    const path = getPagePath(`service/${service.id}`);
    history.push(path);
  };

  const renderFees = (fees: number): JSX.Element | undefined => {
    if (fees) {
      return (
        <IonText>
          <h6 className="title">
            {fees} {t("EGP")}
          </h6>
        </IonText>
      );
    } else {
      return (
        <IonText>
          {" "}
          <h6>{t("SERVICES.PRICE_VARIES_ON_SELECTION")}</h6>
        </IonText>
      );
    }
  };
  function renderCategory(categoryId: string): JSX.Element | undefined {
    const category = servicesCategories.find(({id}) => id === categoryId);
    if (!category) return;
    return <IonBadge color="primary">{getLocalOrDefault(category.name)}</IonBadge>;
  }

  const renderServicesList = () =>
    services.map((service) => {
      return (
        <IonCol key={service.id} className="service__list" sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="12">
          <IonCard className="service__list__card" onClick={() => handleClickService(service)}>
            <div className="service__list__display">
              <LazyImage
                className="service__list__image"
                src={service.gallery.find((img) => img.isFeatured)?.src || service.gallery[0].src}
              />
            </div>
            <div>
              <IonCardContent className="service__list__badge">{renderCategory(service.category)}</IonCardContent>
              <IonCardHeader className="service__list__header">
                <div className="service__list__info">
                  <h5 className="title">{getLocalOrDefault(service.name)}</h5>
                  {renderFees(service.fees)}
                </div>
              </IonCardHeader>
            </div>
          </IonCard>
        </IonCol>
      );
    });
  const renderServicesGrid = () =>
    services.map((service) => {
      return (
        <IonCol key={service.id} className="service" sizeXs="6" sizeSm="4" sizeMd="3" sizeLg="3">
          <IonCard className="service__card" onClick={() => handleClickService(service)}>
            <div className="service__display">
              <LazyImage className="service__image" src={service.gallery.find((img) => img.isFeatured)?.src || ""} />
            </div>
            <IonCardContent className="service__badge">{renderCategory(service.category)}</IonCardContent>
            <IonCardHeader className="service__header">
              <div className="service__info">
                <h5 className="title">{getLocalOrDefault(service.name)}</h5>
                {renderFees(service.fees)}
              </div>
            </IonCardHeader>
          </IonCard>
        </IonCol>
      );
    });

  return (
    <IonGrid>
      <IonRow className="servicesListContainer">
        <IonCol sizeXl="12" sizeLg="12" sizeMd="12" sizeXs="12" sizeSm="12">
          <IonToolbar>
            <IonText>
              {services.length} {t("SERVICES")}
            </IonText>
            <IonButtons slot="end">
              <IonButton size="large" slot="end" onClick={() => setType("grid")}>
                <IonIcon icon={gridOutline} />
              </IonButton>
              <IonButton size="large" slot="end" onClick={() => setType("list")}>
                <IonIcon icon={listOutline} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonCol>
        {type === "list" ? renderServicesList() : renderServicesGrid()}
      </IonRow>
    </IonGrid>
  );
};

export default ServicesList;
