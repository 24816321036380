import { FC, Fragment } from "react";
import classes from "./styles.module.scss";

interface ValueUnitProps {
  value: number;
  unit: string;
  isSup?: boolean;
}

const ValueUnit: FC<ValueUnitProps> = ({ value, unit, isSup }) => {
  const Component = isSup ? "sup" : "sub";
  return (
    <Fragment>
      {value}&nbsp;
      <Component className={classes.unit}>{unit}</Component>
    </Fragment>
  );
};

export default ValueUnit;
