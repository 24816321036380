import { IonButton } from "@ionic/react";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { VariationAttribute } from "src/interfaces/VariationAttribute";
import { Variation } from "src/interfaces/Variation";
import { ProductAttribute } from "src/interfaces/ProductAttribute";
import AttributeItem from "./AttributeItem";

interface AttributesProps {
  selectedAttributes: VariationAttribute[];
  productAttributes: ProductAttribute[];
  variations: Variation[];
  onClearAttributes: () => void;
  onChangeAttribute: (id: string, value: string) => void;
}

const Attributes: FC<AttributesProps> = ({
  productAttributes,
  selectedAttributes,
  variations,
  onClearAttributes,
  onChangeAttribute,
}) => {
  const { t } = useTranslation();

  const renderAttributes = (): JSX.Element[] => {
    return productAttributes.map((productAttribute) => {
      return (
        <AttributeItem
          key={productAttribute.id}
          attributeId={productAttribute.id}
          values={productAttribute.values}
          selectedAttributes={selectedAttributes}
          onChangeAttribute={onChangeAttribute}
          variations={variations}
        />
      );
    });
  };
  return (
    <div className="ion-margin-bottom">
      <div className="ion-display-flex ion-justify-content-end">
        <IonButton
          onClick={onClearAttributes}
          fill="clear"
          color="secondary"
          size="small"
          disabled={!selectedAttributes.length}
        >
          {t("CLEAR")}
        </IonButton>
      </div>
      {renderAttributes()}
    </div>
  );
};

export default Attributes;
