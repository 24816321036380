import React, {FC} from "react";
import PropTypes from "prop-types";
import {View, Text} from "@react-pdf/renderer";
import styles from "./styles";
import OrderInvoiceProduct from "./OrderInvoiceProduct";
import { Order } from "src/interfaces/Order";
import { OrderProduct } from "src/interfaces/OrderProduct";

interface ProductsInfoProps {
  order: Order;
}

const ProductsInfo: FC<ProductsInfoProps> = ({order}) => {
  function renderProducts(): JSX.Element[] {
    return order.products.map((product: OrderProduct, index: number) => {
      return <OrderInvoiceProduct product={product} index={index} key={product.id} />;
    });
  }

  function renderOrderProductsTable(): JSX.Element | undefined {
    if (order.products?.length <= 0) return;
    return (
      <View style={styles.table}>
        <View style={styles.tableHeader}>
          <View style={styles.tableRow}>
            <View style={styles.tableCell}>
              <Text style={styles.h5}>Order Products</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCellSmall}>
              <Text style={styles.h6}>#</Text>
            </View>
            <View style={styles.tableNestedContainer}>
              <View style={styles.tableCellGrow}>
                <Text style={styles.h6}>Name</Text>
              </View>
              <View style={styles.tableCellGrow}>
                <Text style={styles.h6}>Attributes</Text>
              </View>
              <View style={styles.tableCellMedium}>
                <Text style={[styles.h6, styles.alignCenter]}>Quantity</Text>
              </View>
              <View style={styles.tableCellMedium}>
                <Text style={[styles.h6]}>Price</Text>
              </View>
              <View style={styles.tableCellMedium}>
                <Text style={[styles.h6]}>Addons</Text>
              </View>
              <View style={styles.tableCellMedium}>
                <Text style={[styles.h6]}>Subtotal</Text>
              </View>
            </View>
          </View>
        </View>
        {renderProducts()}
      </View>
    );
  }
  return <View style={styles.items}>{renderOrderProductsTable()}</View>;
};

ProductsInfo.propTypes = {
  order: PropTypes.any.isRequired,
};

export default ProductsInfo;
