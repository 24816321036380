import { Fragment, FC } from "react";
import {
  Product,
} from "src/interfaces/Product";
import { VariationAttribute } from "src/interfaces/VariationAttribute";
import { Variation } from "src/interfaces/Variation";
import { ProductAttribute } from "src/interfaces/ProductAttribute";
import Gallery from "src/components/Gallery";
import CartAddButton from "src/components/CartAddButton";
import {
  IonCard,
  IonCardContent,
  IonCol,
  IonGrid,
  IonLabel,
  IonRow,
} from "@ionic/react";
import Stock from "./Stock";
import { DraftEditorPreview } from "src/components";
import getLocalRawDrafOrDefault from "src/utils/getLocalRawDraftOrDefault";
import Attributes from "./Attributes";
import Addons from "./Addons";
import { CartAddon } from "src/interfaces/CartAddon";
import { CartAddonsGroup } from "src/interfaces/CartAddonsGroup";
import CustomVariations from "./CustomVariations";
import Requirements from "./Requirements";
import Price from "src/components/Price";
import { CartRequirement } from "src/interfaces/CartRequirement";
import { CartCustomVariation } from "src/interfaces/CartCustomVariation";
import CategoryItem from "./CategoryItem";

interface ContentProps {
  product: Product | Variation;
  productAttributes: ProductAttribute[];
  addons: CartAddon[];
  name: string;
  addonsGroups: CartAddonsGroup[];
  attributes: VariationAttribute[];
  variations: Variation[];
  requirements: CartRequirement[];
  customVariations: CartCustomVariation[];
  onAddToCart: () => void;
  onChangeAttribute: (id: string, value: string) => void;
  onClearAttributes: () => void;
  onClearAddons: () => void;
  onChangeCustomVariation: (id: string, value: number) => void;
  onChangeRequirement: (id: string, value: string) => void;
  onSelectSingleAddon: (
    addonId: string,
    prevId: string,
    groupId: string
  ) => void;
  onSelectMultipleAddon: (
    addonId: string,
    checked: boolean,
    groupId: string
  ) => void;
  disabled: boolean;
}

const ProductDetails: FC<ContentProps> = ({
  product,
  addons,
  productAttributes,
  addonsGroups,
  attributes,
  variations,
  requirements,
  customVariations,
  name,
  disabled,
  onAddToCart,
  onClearAttributes,
  onChangeAttribute,
  onClearAddons,
  onSelectMultipleAddon,
  onSelectSingleAddon,
  onChangeCustomVariation,
  onChangeRequirement,
}) => {
  const renderAttributes = (): JSX.Element => {
    if (!product.attributes.length) return;
    return (
      <Attributes
        productAttributes={productAttributes}
        selectedAttributes={attributes}
        onChangeAttribute={onChangeAttribute}
        variations={variations}
        onClearAttributes={onClearAttributes}
      />
    );
  };
  const renderAddons = (): JSX.Element => {
    if (!product.addons.length && !product.addonGroups.length) return;
    return (
      <Addons
        productAddons={product.addons}
        productGroups={product.addonGroups}
        addons={addons}
        selectedAddonsGroups={addonsGroups}
        onClearAddons={onClearAddons}
        onSelectMultipleAddon={onSelectMultipleAddon}
        onSelectSingleAddon={onSelectSingleAddon}
      />
    );
  };

  const renderRequirements = (): JSX.Element => {
    if (!product.requiredFields.length) return;
    return (
      <Requirements
        fields={product.requiredFields}
        onChangeRequirement={onChangeRequirement}
        requirements={requirements}
      />
    );
  };

  const renderCategories = (): JSX.Element[] =>
    product.categories.map((categoryId) => (
      <CategoryItem key={categoryId} categoryId={categoryId} />
    ));

  return (
    <Fragment>
      <Gallery gallery={product.gallery} name={name} />
      <CartAddButton disabled={disabled} onAddToCart={onAddToCart} />
      <IonCard color="light">
        <IonCardContent>
          <IonGrid className="ion-no-padding">
            <IonRow>
              <IonCol>
                <IonLabel className="ion-font-xlarge">{name}</IonLabel>
                <Stock
                  isShowQuantity={product.isShowQuantity}
                  isUnlimitedQuantity={product.isUnlimitedQuantity}
                  quantity={product.quantity}
                />
              </IonCol>
              <IonCol className="ion-text-end">
                <IonLabel className="ion-font-bold ion-font-larger">
                  <Price
                    price={product.price}
                    salePrice={product.salePrice}
                    hasSalePrice={product.hasSalePrice}
                    showSaving
                  />
                </IonLabel>
              </IonCol>
            </IonRow>
          </IonGrid>
          <div className="ion-margin-top">{renderCategories()}</div>
          <DraftEditorPreview
            state={getLocalRawDrafOrDefault(product.description)}
          />
          {renderAttributes()}
          <CustomVariations
            productVariations={product.customVariations}
            onChangeCustomVariation={onChangeCustomVariation}
            customVariations={customVariations}
          />
          {renderAddons()}
          {renderRequirements()}
        </IonCardContent>
      </IonCard>
    </Fragment>
  );
};

export default ProductDetails;
