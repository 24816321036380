import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { Country } from "src/interfaces/Country";

const COUNTRIES_BASE_URL = "https://restcountries.com/v2/all?fields=name,alpha2Code,callingCodes,flag";

const useCountries = (): Country[] => {
  const [countries, setCountries] = useState([]);

  const fetchCountries = useCallback(async () => {
    const { data } = await axios.get(COUNTRIES_BASE_URL);
    setCountries(data);
  }, []);

  useEffect(() => {
    fetchCountries();
  }, [fetchCountries]);

  return countries;
};

export default useCountries;
