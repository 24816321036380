import { createContext, useCallback, useEffect, useState } from "react";
import type { FC, ReactNode } from "react";
import i18n from "src/i18n";
import { Settings } from "src/interfaces/Settings";
import { restoreSettingsLS, storeSettingsLS } from "src/storage/settings";

interface SettingsContextValue extends Settings {
  updateSettings: (settings: Settings) => void;
}

const initialState: Settings = {
  language: "en",
  isDarkTheme: window.matchMedia("(prefers-color-scheme: dark)").matches,
};

const SettingsContext = createContext<SettingsContextValue>({
  ...initialState,
  updateSettings: () => {},
});

interface SettingsProviderProps {
  children: ReactNode;
}

const SettingsProvider: FC<SettingsProviderProps> = ({ children }) => {
  const [settings, setSettings] = useState<Settings>(initialState);

  useEffect(() => {
    i18n.changeLanguage(settings.language);
    document.body.classList.toggle("dark", settings.isDarkTheme);
    document.documentElement.setAttribute("lang", settings.language);
    document.dir = settings.language === "ar" ? "rtl" : "ltr";
  }, [settings]);

  const updateSettings = useCallback(async (settings: Settings) => {
    setSettings((prevState) => {
      if (
        settings.language !== prevState.language ||
        settings.isDarkTheme !== prevState.isDarkTheme
      )
        return settings;
      return prevState;
    });
    storeSettingsLS(settings);
  }, []);

  const restoreSettings = useCallback(async () => {
    const settings = await restoreSettingsLS();
    if (!settings) return;
    setSettings(settings);
  }, []);

  useEffect(() => {
    restoreSettings();
  }, [restoreSettings]);

  return (
    <SettingsContext.Provider value={{ ...settings, updateSettings }}>
      {children}
    </SettingsContext.Provider>
  );
};

export { SettingsContext, SettingsProvider };
