import { getAddonByIdFB } from "src/firebase/addons";
import { getAddonsGroupByIdFB } from "src/firebase/addonsGroups";
import { getAttributeByIdFB } from "src/firebase/attributes";
import { getProductByIdFB, getProductVariationByIdFB } from "src/firebase/products";
import { Addon } from "src/interfaces/Addon";
import { AddonsGroup } from "src/interfaces/AddonsGroup";
import { Attribute } from "src/interfaces/Attribute";
import { CartAddon } from "src/interfaces/CartAddon";
import { CartAddonsGroup } from "src/interfaces/CartAddonsGroup";
import { Product } from "src/interfaces/Product";
import { VariationAttribute } from "src/interfaces/VariationAttribute";
import { Variation } from "src/interfaces/Variation";

export const fetchAttributes = async (attributes: VariationAttribute[]): Promise<Attribute[]> => {
    return await Promise.all(
        await attributes.reduce(async (attributes, { id }) => {
            const attribute = await getAttributeByIdFB(id);
            if (attribute) (await attributes).push(attribute);
            return attributes;
        }, Promise.resolve([] as Attribute[]))
    );
}

export const fetchAddons = async (addons: CartAddon[], productAddons: string[], fetchedAddons: Addon[]): Promise<Addon[]> => {
    return await Promise.all(
        await addons.reduce(async (addons, { addonId }) => {
            const isFetched =
                (await addons).some(({ id }) => id === addonId) ||
                fetchedAddons.some(({ id }) => id === addonId);
            const isFound = productAddons.includes(addonId);
            if (!isFetched && isFound) {
                const addon = await getAddonByIdFB(addonId);
                if (addon) (await addons).push(addon);
            }
            return addons;
        }, Promise.resolve([] as Addon[]))
    );
}

export const fetchAddonsGroups = async (cartGroups: CartAddonsGroup[], productGroups: string[]): Promise<AddonsGroup[]> => {
    return await Promise.all(
        await cartGroups.reduce(async (groups, { groupId }) => {
            const isFetched = (await groups).some(({ id }) => id === groupId);
            const isFound = productGroups.includes(groupId);
            if (!isFetched && isFound) {
                const group = await getAddonsGroupByIdFB(groupId);
                if (group) (await groups).push(group);
            }
            return groups;
        }, Promise.resolve([] as AddonsGroup[]))
    );
}
export const fetchGroupsAddons = async (cartGroups: CartAddonsGroup[], fetchedGroups: AddonsGroup[], fetchedAddons: Addon[]): Promise<Addon[]> => {
    return await Promise.all(
        await cartGroups.reduce(async (groupsAddons, { groupId, addons }) => {
            const addonsGroup = fetchedGroups.find(({ id }) => id === groupId);
            const addonsIds = addonsGroup ? addonsGroup.addons : [];
            const groupAddons = await fetchAddons(
                addons,
                addonsIds,
                (await groupsAddons).concat(fetchedAddons)
            );
            (await groupsAddons).push(...groupAddons);
            return groupsAddons;
        }, Promise.resolve([] as Addon[]))
    );
}


export const fetchCartProduct = async (productId: string, variationId: string): Promise<Product | Variation> => {
    return variationId
        ? await getProductVariationByIdFB(productId, variationId)
        : await getProductByIdFB(productId);
}

export const checkProductAvailability = (product: Product | Variation, quantity: number): boolean => {
    return (product.isUnlimitedQuantity || product.quantity >= quantity) &&
        (!product.hasMaxQuantityPerOrder || quantity <= product.maxQuantityPerOrder);
} 