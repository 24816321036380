import React, { useState, FC } from "react";
import { IonButton, IonButtons, IonIcon, IonInput, IonItem, IonLabel } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { Country } from "src/interfaces/Country";
import { Fragment } from "react";
import CountriesModal from "../CountriesModal";
import { chevronBack, chevronForward } from "ionicons/icons";
import classes from "./CountrySelector.module.scss";
import useSettings from "src/hooks/useSettings";

interface CountrySelectorProps {
  country: Country;
  onChangeCountry: (country: Country) => void;
}

const CountrySelector: FC<CountrySelectorProps> = ({ country, onChangeCountry: onSelectCountry }) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { language } = useSettings();
  const name = country?.name || "";
  const icon = language === "ar" ? chevronBack : chevronForward;

  const handleOpenModal = (): void => {
    setIsModalOpen(true);
  };

  const handleCloseModal = (): void => {
    setIsModalOpen(false);
  };

  const handleSelectCountry = (country: Country): void => {
    onSelectCountry(country);
    handleCloseModal();
  };

  return (
    <Fragment>
      <IonItem onClick={handleOpenModal} lines="full">
        <IonLabel position="stacked">{t("SIGNUP.COUNTRY")}</IonLabel>
        <IonInput className={classes.input} value={name} placeholder={t("SIGNUP.COUNTRY")} readonly />
        <IonButtons slot="end">
          <IonButton>
            <IonIcon size="small" icon={icon} slot="icon-only" />
          </IonButton>
        </IonButtons>
      </IonItem>
      <CountriesModal isOpen={isModalOpen} onClose={handleCloseModal} onSelectCountry={handleSelectCountry} />
    </Fragment>
  );
};

export default CountrySelector;
