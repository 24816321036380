import { IonLabel, IonButton, IonIcon, IonCol } from "@ionic/react";
import { FC } from "react";
import { closeCircleOutline, document } from "ionicons/icons";

interface AttachmentItemProps {
  attachment: File;
  index: number;
  onRemoveAttachment: (index: number) => void;
}

const AttachmentItem: FC<AttachmentItemProps> = ({
  attachment,
  onRemoveAttachment,
  index,
}) => {
  const handleRemoveAttachment = (): void => {
    onRemoveAttachment(index);
  };

  return (
    <IonCol sizeXs="6" sizeSm="3" sizeLg="2" sizeXl="1.5" className="ion-padding">
      <div className="ion-margin-auto ion-text-center">
        <IonIcon color="dark" icon={document} size="large" />
        <div className="ion-display-flex ion-justify-content-center">
          <IonLabel className="ion-margin-vertical-auto ion-text-overflow-ellipsis ">
            {attachment.name}
          </IonLabel>
          <IonButton
            className="ion-no-padding ion-no-margin ion-height-auto ion-margin-horizontal-small"
            fill="clear"
            shape="round"
            color="danger"
            onClick={handleRemoveAttachment}
          >
            <IonIcon size="small" icon={closeCircleOutline} slot="icon-only" />
          </IonButton>
        </div>
      </div>
    </IonCol>
  );
};

export default AttachmentItem;
