import { IonLabel } from "@ionic/react";
import { FC, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { OrderRequirementsGroup } from "src/interfaces/OrderRequirementsGroup";
import RequirementsGroupItem from "./RequirementsGroupItem";

interface RequirementsProps {
  requirements: OrderRequirementsGroup[];
}

const Requirements: FC<RequirementsProps> = ({ requirements }) => {
  const { t } = useTranslation();

  const renderRequirements = (): JSX.Element[] =>
    requirements.map((group) => (
      <RequirementsGroupItem key={group.id} group={group} />
    ));

  if (!requirements.length) return null;

  return (
    <Fragment>
      <IonLabel className="ion-margin-top ion-font-bold" color="medium">
        {t("REQUIREMENTS")}
      </IonLabel>
      {renderRequirements()}
    </Fragment>
  );
};

export default Requirements;
