import React from 'react';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch } from 'react-instantsearch-hooks-web';
import CategoryProductListPage from './CategoryProductListPage';

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_API_KEY
);

const CategoryProductList = () => {
  return (
    <InstantSearch searchClient={searchClient} indexName='Products'>
      <CategoryProductListPage />
    </InstantSearch>
  );
};

export default CategoryProductList;
