import { FC, Fragment } from "react";
import { Product } from "src/interfaces/Product";
import { Variation } from "src/interfaces/Variation";
import { CartProduct } from "src/interfaces/CartProduct";
import Attributes from "./Attributes";
import Addons from "./Addons";
import CustomVariations from "./CustomVariations";
import Requirements from "./Requirements";
import { Addon } from "src/interfaces/Addon";
import { AddonsGroup } from "src/interfaces/AddonsGroup";
import { Attribute } from "src/interfaces/Attribute";

interface ProductDetailsProps {
  cartProduct: CartProduct;
  product: Product | Variation;
  addons: Addon[];
  addonsGroups: AddonsGroup[];
  attributes: Attribute[];
}

const ProductDetails: FC<ProductDetailsProps> = ({
  cartProduct,
  product,
  addons,
  addonsGroups,
  attributes,
}) => {
  const renderAttributes = (): JSX.Element => {
    if (!cartProduct.variationId) return;
    return (
      <Attributes
        variationAttributes={(product as Variation).attributes}
        attributes={attributes}
      />
    );
  };

  const renderAddons = (): JSX.Element => {
    if (!cartProduct.addons.length && !cartProduct.addonsGroups.length) return;
    return (
      <Addons
        cartProductAddons={cartProduct.addons}
        cartProductAddonsGroups={cartProduct.addonsGroups}
        addons={addons}
        addonsGroups={addonsGroups}
      />
    );
  };
  const renderCustomVariations = (): JSX.Element => {
    if (!cartProduct.customVariations.length) return;
    return (
      <CustomVariations
        cartProductVariations={cartProduct.customVariations}
        customVariations={product.customVariations}
      />
    );
  };
  const renderRequirements = (): JSX.Element => {
    if (!cartProduct.requirements.length) return;
    return (
      <Requirements
        fields={product.requiredFields}
        groups={cartProduct.requirements}
      />
    );
  };

  return (
    <Fragment>
      {renderAttributes()}
      {renderCustomVariations()}
      {renderAddons()}
      {renderRequirements()}
    </Fragment>
  );
};

export default ProductDetails;
