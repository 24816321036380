import { FC, useMemo } from "react";
import { IonAccordion, IonItem } from "@ionic/react";
import { ServicesWorker } from "src/interfaces/ServicesWorker";
import getLocalOrDefault from "src/utils/getLocalOrDefault";
import WorkingDayItem from "./WorkingDayItem";
import { WeekDay } from "src/enums/WeekDay";
import { checkFullWeekWorking } from "./helpers";

interface WorkerItemProps {
  worker: ServicesWorker;
}

const WorkerItem: FC<WorkerItemProps> = ({ worker }) => {
  const isFullWeekWorking = useMemo(
    () => checkFullWeekWorking(worker.workingDays),
    [worker.workingDays]
  );

  const renderDays = (): JSX.Element[] | JSX.Element => {
    const { workingDays } = worker;
    if (isFullWeekWorking)
      return <WorkingDayItem slots={Object.values(workingDays)[0]} />;

    return Object.values(WeekDay)
      .filter((day) => workingDays[day].length)
      .map((day) => (
        <WorkingDayItem key={day} day={day} slots={workingDays[day]} />
      ));
  };

  return (
    <IonAccordion>
      <IonItem slot="header" className="ion-font-bold ion-font-small">
        {getLocalOrDefault(worker.workerName)}
      </IonItem>
      <div slot="content">
        <div>{renderDays()}</div>
      </div>
    </IonAccordion>
  );
};

export default WorkerItem;
