import { useEffect, useState, FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { Order } from "src/interfaces/Order";
import { getOrderByIdFB, updateOrderNotesFB } from "src/firebase/orders";
import Header from "./Header";
import Content from "./Content";
import Page from "src/components/Page";
import { PageLoading } from "src/components";
import AlertDialog from "src/components/AlertDialog";
import useIsMountedRef from "src/hooks/useIsMountedRef";
import { cancelOrder } from "src/firebase/functions";
import { OrderStatus } from "src/enums/OrderStatus";
import useToast from "src/hooks/useToast";
import NotesModal from "./NotesModal";
import { uuidv4 } from "src/utils/uuidv4";
import { useAppSelector } from "src/hooks/useAppSelector";
import { OrderInvoiceSize } from "src/interfaces/Store";
import { pdf } from "@react-pdf/renderer";
import { OrderInvoiceDocument, ReceiptPrinterPdf } from "./components";
import downloadBlobFile from "src/utils/downloadBlobFile";

type Params = {
  orderId: string;
};

const OrderDetailsPage: FC = () => {
  const { orderId } = useParams<Params>();
  const { t } = useTranslation();
  const isMountedRef = useIsMountedRef();
  const launchToast = useToast();

  const store = useAppSelector((state) => state.storeReducer.store);

  const [order, setOrder] = useState<Order>();
  const [isLoading, setIsLoading] = useState(false);
  const [isCancelAlertOpen, setIsCancelAlertOpen] = useState(false);
  const [isNoteModalOpen, setIsNotesModalOpen] = useState(false);

  const fetchOrder = useCallback(async () => {
    const order = await getOrderByIdFB(orderId);
    if (order) setOrder(order);
  }, [orderId]);

  useEffect(() => {
    fetchOrder();
  }, [fetchOrder]);

  const handleOpenCancelAlert = (): void => {
    setIsCancelAlertOpen(true);
  };

  const handleCloseCancelAlert = (): void => {
    setIsCancelAlertOpen(false);
  };

  const handleOpenNotesModal = (): void => {
    setIsNotesModalOpen(true);
  };

  const handleCloseNotesModal = (): void => {
    setIsNotesModalOpen(false);
  };

  const handleDownloadInvoice = async (): Promise<void> => {
    const invoiceSize = store.orderInvoiceSize || OrderInvoiceSize.a4;
    const isA4Size = invoiceSize === OrderInvoiceSize.a4;
    const pdfBlob = await pdf(
      isA4Size ? (
        <OrderInvoiceDocument order={order} store={store} />
      ) : (
        <ReceiptPrinterPdf order={order} store={store} />
      )
    ).toBlob();
    downloadBlobFile(pdfBlob, order.invoiceId, "pdf");
  };

  const handleUpdateNotes = async (note: string): Promise<void> => {
    try {
      setIsLoading(true);
      handleCloseNotesModal();
      const notes = [...order.userNotes, { id: uuidv4(), note: note.trim() }];
      await updateOrderNotesFB(order.id, notes);
      setOrder((prevState) => ({ ...prevState, userNotes: notes }));
      launchToast(t("DONE"), "success");
    } catch {
      launchToast(t("FAILED"), "danger");
    } finally {
      if (isMountedRef.current) {
        setIsLoading(false);
      }
    }
  };

  const handleCancelOrder = async (): Promise<void> => {
    try {
      setIsLoading(true);
      await cancelOrder(order.id);
      setOrder((prevState) => ({
        ...prevState,
        orderStatus: OrderStatus.canceled,
      }));
      launchToast(t("DONE"), "success");
    } catch {
      launchToast(t("FAILED"), "danger");
    } finally {
      if (isMountedRef.current) {
        setIsLoading(false);
      }
    }
  };

  const renderContent = (): JSX.Element => {
    if (!order) return <PageLoading />;
    return (
      <Content
        order={order}
        isLoading={isLoading}
        onCancelOrder={handleOpenCancelAlert}
        onAddNote={handleOpenNotesModal}
        onDownloadInvoice={handleDownloadInvoice}
      />
    );
  };

  return (
    <Page title={t("ORDER_DETAILS")}>
      <Header />
      {renderContent()}
      <AlertDialog
        title={t("WARNING")}
        onClose={handleCloseCancelAlert}
        onConfirm={handleCancelOrder}
        open={isCancelAlertOpen}
        message={t("CANCEL_ORDER_MESSAGE")}
      />
      <NotesModal
        isOpen={isNoteModalOpen}
        onClose={handleCloseNotesModal}
        onSubmit={handleUpdateNotes}
      />
    </Page>
  );
};

export default OrderDetailsPage;
