import { IonBadge } from "@ionic/react";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import "./InvalidOOBCode.scss"

const InvalidOOBCode: FC = () => {
    const { t } = useTranslation();
    return (
        <IonBadge className="invalidOOBCode" color="danger">
            {t("AUTH.INVALID_ACTION_CODE")}
        </IonBadge>
    );
}

export default InvalidOOBCode;