import { IonLabel, IonListHeader } from "@ionic/react";
import { FC } from "react";

interface ListHeaderProps {
  title: string;
}

const ListHeader: FC<ListHeaderProps> = ({ title }) => {
  return (
    <IonListHeader>
      <IonLabel className="ion-font-bold" color="medium">
        {title}
      </IonLabel>
    </IonListHeader>
  );
};

export default ListHeader;
