import {IonIcon, IonItem, IonLabel, IonText} from "@ionic/react";
import {arrowDown, arrowUp} from "ionicons/icons";
import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import {WalletTransaction} from "src/interfaces/WalletTransaction";
import { WalletTransactionType } from "src/enums/WalletTransactionType";
import classes from "./TransactionItemView.module.scss";

interface TransactionItemViewProps {
  transaction: WalletTransaction;
}

const TransactionItemView: FC<TransactionItemViewProps> = ({transaction}) => {
  const {t} = useTranslation();

  const date = new Date(transaction.createdAt!).toLocaleString();
  const isDeposit = transaction.type === WalletTransactionType.deposit;
  const color = isDeposit ? "success" : "danger";
  const icon = isDeposit ? arrowUp : arrowDown;

  return (
    <IonItem>
      <IonLabel>
        <IonText color="textPrimary">
          <p className={classes.issue}>{transaction.issue}</p>
        </IonText>
        <p className={classes.date}>{date}</p>
        <p>{transaction.notes}</p>
      </IonLabel>
      <IonLabel slot="end">
        <IonText color="textPrimary">
          <p>
            {transaction.beforeCredit.toFixed(2)} {t("EGP")}
          </p>
        </IonText>
        <IonText color={color}>
          <p>
            <IonIcon icon={icon} />
            {transaction.amount.toFixed(2)} {t("EGP")}
          </p>
        </IonText>
        <IonText color="textPrimary">
          <p>
            {transaction.afterCredit.toFixed(2)} {t("EGP")}
          </p>
        </IonText>
      </IonLabel>
    </IonItem>
  );
};

export default TransactionItemView;
