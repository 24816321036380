import {IonImg, IonRouterLink, IonText} from "@ionic/react";
import {FC} from "react";
import {CMSCustomSlide} from "src/interfaces/CMS";
import classes from "./styles.module.scss";
import getCMSLinkFromLinkType from "src/utils/getCMSLinkFromLinkType";
import getCMSRouterLinkFromLinkType from "src/utils/getCMSRouterLinkFromLinkType";

interface CMSCarouselItemProps {
  slide: CMSCustomSlide;
}

const CMSCustomItem: FC<CMSCarouselItemProps> = ({slide}) => {
  const hrefLink = getCMSLinkFromLinkType(slide.linkType, slide.link);
  const routerLink = getCMSRouterLinkFromLinkType(slide.linkType, slide.link);

  return (
    <IonRouterLink routerLink={routerLink} target="_blank" className={classes.root} href={hrefLink}>
      <IonImg className={classes.image} src={slide.image} />
      <IonText className={classes.details} color="primary">
        <h3 className={classes.title}>{slide.title}</h3>
        <p className={classes.subtitle}>{slide.description}</p>
      </IonText>
    </IonRouterLink>
  );
};

export default CMSCustomItem;
