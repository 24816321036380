import {
  createContext,
  useReducer,
  useCallback,
  useEffect,
  FC,
  ReactNode,
} from "react";
import { Deploy } from "cordova-plugin-ionic";
import { IonProgressBar } from "@ionic/react";
import { isNativePlatform } from "src/utils/isNativePlatform";

interface LiveUpdateContextState {
  isFinished: boolean;
  progress: number;
}

enum ActionType {
  updateFinished,
  progressChanged,
}

type UpdateFinishAction = {
  type: ActionType.updateFinished;
};

type ProgressChangeAction = {
  type: ActionType.progressChanged;
  payload: {
    progress: number;
  };
};

type LivUpdateContextAction = UpdateFinishAction | ProgressChangeAction;

const reducer = (
  state: LiveUpdateContextState,
  action: LivUpdateContextAction
): LiveUpdateContextState => {
  switch (action.type) {
    case ActionType.updateFinished:
      return {
        ...state,
        isFinished: true,
      };

    case ActionType.progressChanged:
      const { progress } = action.payload;
      return {
        ...state,
        progress,
      };

    default:
      return { ...state };
  }
};

const initialState: LiveUpdateContextState = {
  isFinished: false,
  progress: 0,
};

const LiveUpdateContext = createContext<LiveUpdateContextState>({
  ...initialState,
});

interface LiveUpdateProviderProps {
  children: ReactNode;
}

const LiveUpdateProvider: FC<LiveUpdateProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const checkForUpdate = useCallback(async () => {
    try {
      if (!isNativePlatform) return;
      await Deploy.sync({ updateMethod: "auto" }, (progress) => {
        dispatch({
          type: ActionType.progressChanged,
          payload: { progress },
        });
      });
    } finally {
      dispatch({ type: ActionType.updateFinished });
    }
  }, []);

  useEffect(() => {
    checkForUpdate();
  }, [checkForUpdate]);

  if (!state.isFinished) return <IonProgressBar value={state.progress} />;

  return (
    <LiveUpdateContext.Provider value={{ ...state }}>
      {children}
    </LiveUpdateContext.Provider>
  );
};

export { LiveUpdateContext, LiveUpdateProvider };
