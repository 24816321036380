import { StrictMode, Suspense } from "react";
import ReactDOM from "react-dom";
import App from "src/App";
import { Provider } from "react-redux";
import { store } from "src/store";
import i18n from "src/i18n";
import { I18nextProvider } from "react-i18next";
import * as serviceWorkerRegistration from "src/serviceWorkerRegistration";
import reportWebVitals from "src/reportWebVitals";
import { HelmetProvider } from "react-helmet-async";
import SplashScreen from "./components/SplashScreen";

ReactDOM.render(
  <StrictMode>
    <Suspense fallback={<SplashScreen />}>
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <HelmetProvider>
            <App />
          </HelmetProvider>
        </Provider>
      </I18nextProvider>
    </Suspense>
  </StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
