import { useState, FC, useEffect } from "react";
import { IonItem, IonModal, IonTextarea } from "@ionic/react";
import { useTranslation } from "react-i18next";
import ModalHeader from "src/components/ModalHeader";
import { IonTextareaCustomEvent, TextareaChangeEventDetail } from "@ionic/core";

interface NotesModalProps {
  isOpen: boolean;
  onSubmit: (note: string) => void;
  onClose: () => void;
}

const NotesModal: FC<NotesModalProps> = ({ isOpen, onClose, onSubmit }) => {
  const { t } = useTranslation();

  const [note, setNote] = useState("");

  useEffect(() => {
    setNote("");
  }, [isOpen]);

  const handleSubmit = (): void => {
    if (!note.trim()) return;
    onSubmit(note);
  };

  const handleChangeNote = (
    e: IonTextareaCustomEvent<TextareaChangeEventDetail>
  ): void => {
    const { value } = e.detail;
    setNote(value);
  };

  return (
    <IonModal
      isOpen={isOpen}
      onDidDismiss={onClose}
      className="ion-height-fit-content ion-padding"
    >
      <ModalHeader onClose={onClose} onSubmit={handleSubmit} />
      <IonItem>
        <IonTextarea
          onIonChange={handleChangeNote}
          value={note}
          rows={5}
          placeholder={t("NOTES")}
        />
      </IonItem>
    </IonModal>
  );
};

export default NotesModal;
