import { useIonToast } from "@ionic/react";
import { Color } from "@ionic/core/dist/types/interface";
import { close } from "ionicons/icons";
import { useCallback } from "react";

const useToast = () => {
  const [showToast, closeToast] = useIonToast();

  const launchToast = useCallback(
    (message: string, color: Color) => {
      showToast({
        duration: 1500,
        message,
        color,
        keyboardClose: true,
        buttons: [{ icon: close, side: "end", handler: closeToast }],
      });
    },
    [showToast, closeToast]
  );

  return launchToast;
};
export default useToast;
