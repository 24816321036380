import {FC} from "react";
import classes from "./styles.module.scss";
import {IonText, IonImg, IonAvatar, IonThumbnail} from "@ionic/react";
import {CMSHeading, CMSHeadingAlignment, CMSHeadingLogoAlignment, CMSItemShape} from "src/interfaces/CMS";
import getLocalOrDefault from "src/utils/getLocalOrDefault";
import { useAppSelector } from "src/hooks/useAppSelector";
import useSettings from "src/hooks/useSettings";

interface HeadingProps {
  heading: CMSHeading;
}

enum HeadingAlignment {
  start = "start",
  end = "end",
}

const Heading: FC<HeadingProps> = ({heading}) => {
  const logo = useAppSelector((state) => state.storeReducer.store.logo);
  const {isDarkTheme} = useSettings();
  const HeadingTag = heading.type.toLowerCase() as keyof JSX.IntrinsicElements;
  const headingAlignment =
    heading.alignment === CMSHeadingAlignment.left
      ? HeadingAlignment.start
      : heading.alignment === CMSHeadingAlignment.center
      ? CMSHeadingAlignment.center
      : HeadingAlignment.end;

  const containerStyle = {
    color: isDarkTheme ? heading.darkThemeColor : heading.color,
    justifyContent: headingAlignment,
  };
  const headingStyle = {
    flexGrow: !heading.isLogoStick && 1,
    textAlign: headingAlignment,
    color: isDarkTheme ? heading.darkThemeColor : heading.color,
  };
  const logoStyle = {order: heading.logoAlignment === CMSHeadingLogoAlignment.right && 1};
  const imageStyle = {
    width: +heading.logoSize || 40,
    height: +heading.logoSize || 40,
  };

  const renderHeadingLogo = (): JSX.Element | undefined => {
    if (!heading.isLogo) return;
    if (heading.logoShape === CMSItemShape.circle)
      return (
        <IonAvatar className={classes.logo} style={logoStyle}>
          <IonImg src={logo} style={imageStyle} />
        </IonAvatar>
      );
    else {
      return (
        <IonThumbnail className={classes.logo} style={logoStyle}>
          <IonImg src={logo} style={imageStyle} />
        </IonThumbnail>
      );
    }
  };

  return (
    <IonText style={containerStyle} className={classes.root}>
      {renderHeadingLogo()}
      <HeadingTag className={classes.text} style={headingStyle}>
        {getLocalOrDefault(heading.text)}
      </HeadingTag>
    </IonText>
  );
};

export default Heading;
