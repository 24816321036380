import { getFirestore, doc, setDoc } from "firebase/firestore";

const COLLECTION = "translations";
const DOCUMENT = "storefront";
const KEYS_REGEX = new RegExp(/^[A-Z0-9_%&.]*$/g);

const addedKeys: Record<string, string[]> = {};

export async function addMissingTranslationKeysFB(
  languages: readonly string[],
  ns: string,
  key: string
): Promise<void> {
  const db = getFirestore();

  languages.forEach(async (language) => {
    if (!addedKeys[language] || !addedKeys[language].includes(key)) {
      if (!KEYS_REGEX.test(key)) return;
      const document = doc(db, COLLECTION, DOCUMENT, language, key);
      setDoc(document, { value: key });
      if (!addedKeys[language]) {
        addedKeys[language] = [];
      }
      addedKeys[language].push(key);
    }
  });
}
