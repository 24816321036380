import {
  IonButton,
  IonButtons,
  IonIcon,
  IonItem,
  IonLabel,
} from "@ionic/react";
import { attachOutline, trashOutline } from "ionicons/icons";
import { ChangeEvent, FC, Fragment } from "react";

interface CMSFormFileFieldProps {
  label: string;
  files: File[];
  disabled: boolean;
  onRemoveAttachment: (index: number) => void;
  onChangeAttachment: (e: ChangeEvent<HTMLInputElement>) => void;
}

const CMSFormFileField: FC<CMSFormFileFieldProps> = ({
  files,
  label,
  disabled,
  onChangeAttachment,
  onRemoveAttachment,
}) => {
  const handleRemoveAttachment = (index: number): (() => void) => {
    return (): void => {
      onRemoveAttachment(index);
    };
  };

  const renderAttachments = (): JSX.Element[] => {
    return files.map((file, index) => {
      return (
        <IonItem key={file.name} lines="none">
          <IonLabel>{file.name}</IonLabel>
          <IonButtons slot="end">
            <IonButton onClick={handleRemoveAttachment(index)} color="danger">
              <IonIcon size="small" icon={trashOutline} slot="icon-only" />
            </IonButton>
          </IonButtons>
        </IonItem>
      );
    });
  };

  return (
    <Fragment>
      <IonButton disabled={disabled} className="ion-margin-top">
        <IonIcon slot="start" icon={attachOutline} />
        {label}
        <input
          multiple
          type="file"
          disabled={disabled}
          onChange={onChangeAttachment}
          className="ion-position-absolute ion-no-opacity ion-pointer ion-full-height ion-full-width"
        />
      </IonButton>
      {renderAttachments()}
    </Fragment>
  );
};

export default CMSFormFileField;
