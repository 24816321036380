import {FormRequest} from "src/interfaces/FormRequest";
import {Storage} from "@capacitor/storage";
import {store} from "src/store";
import {REQUESTS_STORAGE_KEY} from "src/constants/constants";
import {RequirementAttachmentFiles} from "src/interfaces/Requirement";
import { FormFieldType } from "src/enums/FormFieldType";

export default async function saveRequestToStorage(
  request: FormRequest,
  attachments: RequirementAttachmentFiles[],
): Promise<void> {
  const requestsString = (await Storage.get({key: REQUESTS_STORAGE_KEY})).value;
  const fields = request.fields.map((field) => {
    if (field.type === FormFieldType.file) {
      const results = attachments.find(({id}) => id === field.id)?.results || [];
      return {...field, attachments: results};
    }
    return field;
  });
  const requests: FormRequest[] = requestsString ? JSON.parse(requestsString) : [];
  request.storeId = store.getState().storeReducer.store.id!;
  requests.push({...request, fields});

  await Storage.set({key: REQUESTS_STORAGE_KEY, value: JSON.stringify(requests)});
}
