import {Capacitor} from "@capacitor/core";
import {Device} from "@capacitor/device";
import {Storage} from "@capacitor/storage";
import {store} from "../store";

type FacebookPixel = (
  arg1: string,
  arg2: string,
  arg3?: Record<string, string | number> | string,
  arg4?: string,
) => void;

type FacebookGlobal = {
  fbq: FacebookPixel;
};

function getFacebookPixel(): FacebookPixel | undefined {
  const pixelId = store.getState().storeReducer.store.pixelId;
  const {fbq} = window as Window & typeof globalThis & FacebookGlobal;
  if (!pixelId || !fbq) return;
  return fbq;
}

function track(eventName: string, opts: Record<string, string | number> = {}, trackParam = "track") {
  const fbq = getFacebookPixel();
  if (!fbq) return;

  fbq(trackParam, eventName, opts);
  if (trackParam === "init") {
    const appId = store.getState().storeReducer.store.facebookAppId;
    fbq("set", "mobileBridge", eventName, appId);
  }
}

function trackPageView(): void {
  track("PageView");
}

export function initFacebookPixel(pixelId: string): void {
  if (!pixelId) return;
  track(pixelId, {}, "init");
  trackPageView();
}

export function trackAddToCart(): void {
  track("AddToCart");
}
export function trackCompleteRegistration(): void {
  track("CompleteRegistration");
}
export function trackPurchase(value: number): void {
  track("Purchase", {currency: "EGP", value});
}
export function trackProductView(): void {
  track("track");
}

export function trackInitiateCheckout(): void {
  track("InitiateCheckout");
}

async function hasInstalledBefore(): Promise<boolean> {
  let isInstalled = false;
  try {
    const installTime = (await Storage.get({key: "install-time"})).value;
    isInstalled = Boolean(installTime);
  } catch (error) {
    console.error(error);
  }
  await Storage.set({key: "install-time", value: Date.now().toString()});
  return isInstalled;
}

export async function trackAppInstalls(): Promise<void> {
  if (Capacitor.isNativePlatform()) return;
  const isInstalled = await hasInstalledBefore();
  if (isInstalled) return;
  const uid = (await Device.getId()).uuid;
  track("AppInstall", {uid}, "trackCustom");
}
