import axios from "axios";
import {auth} from "src/firebase";
import {store} from "src/store";
import { getGPSLocation } from "src/utils/getGPSLocation";
import {Storage} from "@capacitor/storage";
import {Device} from "@capacitor/device";
import {addDoc, collection, doc, updateDoc} from "firebase/firestore";
import {getStoreDocRef} from "./store";

export async function getUserIPAddress(): Promise<string> {
  try {
    const {data} = await axios.get("https://api.ipify.org/");
    return data;
  } catch {
    return "";
  }
}

export async function getIPAddressLocation(): Promise<{
  country: string;
  city: string;
  long: number;
  lat: number;
} | null> {
  const ipAddress = await getUserIPAddress();
  if (!ipAddress) return null;
  try {
    const {data} = await axios.get(`https://ipapi.co/${ipAddress}/json/`);
    return {country: data.country_name, long: data.longitude, lat: data.latitude, city: data.city};
  } catch {
    return null;
  }
}

export async function getLocationInfo(): Promise<{country: string; city: string; long: number; lat: number} | null> {
  try {
    const {lat, long} = await getGPSLocation();
    const {data} = await axios.get(
      `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${lat}&longitude=${long}&localityLanguage=en`,
    );
    return {country: data.countryName, long, lat, city: data.locality};
  } catch {
    return await getIPAddressLocation();
  }
}

export async function updateCurrentView(path: string): Promise<void> {
  const storeId = store.getState().storeReducer.store.id;
  try {
    if (!storeId) return;
    const locationInfo = await getLocationInfo();
    const uid = auth.currentUser?.uid || null;
    const docId = (await Storage.get({key: `${storeId}_currentView`})).value;
    const info = await Device.getInfo();
    const os = info.operatingSystem;
    const platform = info.platform;
    const view = {
      path,
      uid,
      platform,
      os,
      city: locationInfo?.city,
      country: locationInfo?.country,
      longitude: locationInfo?.long,
      latitude: locationInfo?.lat,
      visitedAt: Date.now(),
      type: path.split("/")[2]?.toUpperCase(),
    };
    const currentViewsCollection = collection(getStoreDocRef(), "current-views");
    if (docId) {
      await updateDoc(doc(currentViewsCollection, docId), JSON.parse(JSON.stringify(view)));
    } else {
      addDoc(currentViewsCollection, JSON.parse(JSON.stringify(view)));
      await Storage.set({key: `${storeId}_currentView`, value: docId || ""});
    }
    await addView(view);
  } catch (err) {
    console.error(err);
    await Storage.remove({key: `${storeId}_currentView`});
  }
}

export async function addView(view: any) {
  try {
    const storeId = store.getState().storeReducer.store.id;
    if (!storeId) return;
    const viewsCollection = collection(getStoreDocRef(), "views");
    await addDoc(viewsCollection, JSON.parse(JSON.stringify(view)));
  } catch (err) {
    console.error(err);
  }
}
