import { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import {
  RadioGroupChangeEventDetail,
  SelectChangeEventDetail,
  TextareaChangeEventDetail,
  InputChangeEventDetail,
  IonLoading,
} from "@ionic/react";
import Header from "./Header";
import Page from "src/components/Page";
import { useTranslation } from "react-i18next";
import { CollectMethod } from "src/enums/CollectMethod";
import { IonRadioGroupCustomEvent, IonSelectCustomEvent } from "@ionic/core";
import { PaymentMethod } from "src/enums/PaymentMethod";
import { OrderUser } from "src/interfaces/OrderUser";
import { useAppSelector } from "src/hooks/useAppSelector";
import { IonInputCustomEvent, IonTextareaCustomEvent } from "@ionic/core";
import Footer from "./Footer";
import Content from "./Content";
import { getGPSLocation } from "src/utils/getGPSLocation";
import useToast from "src/hooks/useToast";
import { validateOrder } from "./validation";
import useIsMountedRef from "src/hooks/useIsMountedRef";
import {
  getOrderReport,
  payWithCard,
  submitOrder,
} from "src/firebase/functions";
import { OrderReport } from "src/interfaces/OrderReport";
import { useHistory } from "react-router";
import getPagePath from "src/utils/getStoreIdCustomPath";
import ReportModal from "./ReportModal";
import { uploadOrderFileFB } from "src/firebase/storage";
import { Service } from "src/interfaces/Service";
import { getServiceByIdFB } from "src/firebase/services";
import { ServiceLoactionType } from "src/enums/ServiceLoactionType";
import FawryModal from "./FawryModal";
import ReferenceNumberModal from "./ReferenceNumberModal";
import PaymobModal from "./PaymobModal";

const CheckoutPage: FC = () => {
  const { t } = useTranslation();
  const launchToast = useToast();
  const isMountedRef = useIsMountedRef();
  const history = useHistory();

  const { customer } = useAppSelector((state) => state.customerReducer);
  const { store } = useAppSelector((state) => state.storeReducer);
  const { cart } = useAppSelector((state) => state.cartReducer);

  const [collectMethod, setCollectMethod] = useState<CollectMethod>(
    store.orderCollectMethods[0]
  );
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(
    store.paymentMethods[0]
  );
  const [user, setUser] = useState<OrderUser>();
  const [city, setCity] = useState("");
  const [area, setArea] = useState("");
  const [notes, setNotes] = useState("");
  const [voucherCode, setVoucherCode] = useState("");
  const [attachments, setAttachments] = useState<File[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [orderReport, setOrderReport] = useState<OrderReport>(null);
  const [isNoDelivery, setIsNoDelivery] = useState(false);
  const [isFawryModalOpen, setIsFawryModalOpen] = useState(false);
  const [paymobIframe, setPaymobIframe] = useState("");
  const [orderId, setOrderId] = useState("");
  const [referenceNumber, setReferenceNumber] = useState("");
  const [cardlink, setCardlink] = useState("");

  const isReportModalOpen = !!orderReport;

  const checkIfNoDelivery = useCallback(async () => {
    const services = await Promise.all(
      await cart.services.reduce(async (services, cartService) => {
        const service = await getServiceByIdFB(cartService.serviceId);
        if (service) (await services).push(service);
        return services;
      }, Promise.resolve([] as Service[]))
    );
    const isAllServicesAtProvider = services.every(
      (service) => service.locationType === ServiceLoactionType.provider
    );
    if (isAllServicesAtProvider && !cart.products.length) {
      setIsNoDelivery(true);
      setCollectMethod(CollectMethod.pickup);
    }
  }, [cart]);

  useEffect(() => {
    checkIfNoDelivery();
  }, [checkIfNoDelivery]);

  useEffect(() => {
    if (user || !customer) return;
    const defaultAddress = customer.addresses[0];
    const address = defaultAddress ? defaultAddress.address : "";
    setUser({
      customerId: customer.id,
      address,
      country: customer.country,
      email: customer.email,
      firstname: customer.firstName,
      lastname: customer.lastName,
      location: customer.location,
      phone: customer.phone,
    });
  }, [user, customer]);

  const handleCloseReportModal = (): void => {
    setOrderReport(null);
  };

  const goToOrders = (): void => {
    if (isMountedRef.current) history.replace(getPagePath("orders"));
  };

  const handleCloseModals = (): void => {
    setIsFawryModalOpen(false);
    setPaymobIframe("");
    setReferenceNumber("");
    goToOrders();
  };

  const handleChangeCollectMethod = (
    e: IonRadioGroupCustomEvent<RadioGroupChangeEventDetail<CollectMethod>>
  ): void => {
    const { value } = e.detail;
    setIsError(false);
    setCity("");
    setArea("");
    setCollectMethod(value);
  };

  const handleChangePaymentMethod = (
    e: IonRadioGroupCustomEvent<RadioGroupChangeEventDetail<PaymentMethod>>
  ): void => {
    const { value } = e.detail;
    setPaymentMethod(value);
  };

  const handleChangeCity = (
    e: IonSelectCustomEvent<SelectChangeEventDetail<string>>
  ): void => {
    const { value } = e.detail;
    setCity(value);
    setArea("");
  };

  const handleChangeArea = (
    e: IonSelectCustomEvent<SelectChangeEventDetail<string>>
  ): void => {
    const { value } = e.detail;
    setArea(value);
  };

  const handleChangeOrderUser = (
    e:
      | IonInputCustomEvent<InputChangeEventDetail>
      | IonTextareaCustomEvent<TextareaChangeEventDetail>
  ): void => {
    const { name } = e.target;
    const { value } = e.detail;
    setUser((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleChangeNotes = (
    e: IonTextareaCustomEvent<TextareaChangeEventDetail>
  ): void => {
    const { value } = e.detail;
    setNotes(value);
  };

  const handleAddAttachments = (e: ChangeEvent<HTMLInputElement>): void => {
    const files = Object.values(e.target.files);
    if (!files.length) return;
    setAttachments((prevState) => [
      ...prevState,
      ...files.filter(
        (attachment) =>
          !prevState.some(
            (file) =>
              file.name === attachment.name &&
              attachment.size === file.size &&
              file.type === attachment.type
          )
      ),
    ]);
  };

  const handleRemoveAttachment = (deletedIndex: number): void => {
    setAttachments((prevState) =>
      prevState.filter((attachment, index) => index !== deletedIndex)
    );
  };

  const handleChangeLocation = async (): Promise<void> => {
    try {
      const location = await getGPSLocation();
      setUser((prevState) => ({ ...prevState, location }));
      launchToast(t("DONE"), "success");
    } catch {
      launchToast(t("FAILED"), "danger");
    }
  };

  const handleChangeVoucherCode = (
    e: IonInputCustomEvent<InputChangeEventDetail>
  ): void => {
    const { value } = e.detail;
    setVoucherCode(value);
  };

  const handleSubmit = async (): Promise<void> => {
    try {
      setIsLoading(true);
      setIsError(false);
      const isValid = await validateOrder(user, collectMethod, city);
      if (!isValid) return setIsError(true);
      const report = await getOrderReport(
        paymentMethod,
        collectMethod,
        city,
        area,
        voucherCode
      );
      setOrderReport(report);
    } catch (e) {
      history.replace(getPagePath("cart"));
      launchToast(t(e.response?.data?.message), "danger");
      if (isMountedRef.current) setIsLoading(false);
    } finally {
      if (isMountedRef.current) setIsLoading(false);
    }
  };

  const handlePayWithFawryCard = async (
    token: string,
    cvv: string
  ): Promise<void> => {
    try {
      setIsLoading(true);
      const link = await payWithCard(orderId, token, cvv);
      setCardlink(link);
    } catch {
      launchToast(t("FAILED"), "danger");
    } finally {
      if (isMountedRef.current) {
        setIsLoading(false);
      }
    }
  };

  const handleSubmitReport = async (): Promise<void> => {
    try {
      setIsLoading(true);
      const total = orderReport.total;
      setOrderReport(null);
      const attachmentsLinks = await Promise.all(
        attachments.map(async (file) => await uploadOrderFileFB(file))
      );
      const { orderId, referenceNumber, paymobIframe } = await submitOrder(
        paymentMethod,
        collectMethod,
        city,
        area,
        voucherCode,
        user,
        total,
        notes,
        attachmentsLinks
      );
      setOrderId(orderId);
      switch (paymentMethod) {
        case PaymentMethod.fawryCards:
          setIsFawryModalOpen(true);
          break;
        case PaymentMethod.card:
        case PaymentMethod.wallet:
          paymobIframe ? setPaymobIframe(paymobIframe) : goToOrders();
          break;
        case PaymentMethod.payAtFawry:
        case PaymentMethod.kiosk:
          referenceNumber ? setReferenceNumber(referenceNumber) : goToOrders();
          break;
        default:
          goToOrders();
          break;
      }
    } catch (e) {
      launchToast(t(e.response?.data?.message), "danger");
      if (isMountedRef.current) setIsLoading(false);
    } finally {
      if (isMountedRef.current) setIsLoading(false);
    }
  };

  return (
    <Page title={t("CHECKOUT")}>
      <Header />
      <Content
        user={user}
        area={area}
        city={city}
        notes={notes}
        isError={isError}
        attachments={attachments}
        collectMethod={collectMethod}
        paymentMethod={paymentMethod}
        isNoDelivery={isNoDelivery}
        onChangeArea={handleChangeArea}
        onChangeCity={handleChangeCity}
        onChangeNotes={handleChangeNotes}
        onChangeUser={handleChangeOrderUser}
        onChangeLocation={handleChangeLocation}
        onAddAttachments={handleAddAttachments}
        onRemoveAttachment={handleRemoveAttachment}
        onChangeVoucherCode={handleChangeVoucherCode}
        onChangeCollectMethod={handleChangeCollectMethod}
        onChangePaymentMethod={handleChangePaymentMethod}
      />
      <Footer onSubmit={handleSubmit} disabled={false} />
      <ReportModal
        collectMethod={collectMethod}
        isOpen={isReportModalOpen}
        onClose={handleCloseReportModal}
        onSumbit={handleSubmitReport}
        report={orderReport}
        paymentMethod={paymentMethod}
      />
      <FawryModal
        isOpen={isFawryModalOpen}
        onClose={handleCloseModals}
        onSubmit={handlePayWithFawryCard}
        cardLink={cardlink}
      />
      <ReferenceNumberModal
        isOpen={!!referenceNumber}
        onClose={handleCloseModals}
        referenceNumber={referenceNumber}
      />
      <PaymobModal link={paymobIframe} onClose={handleCloseModals} />
      <IonLoading isOpen={isLoading} />
    </Page>
  );
};

export default CheckoutPage;
