import {getDownloadURL, getStorage, ref, StorageReference, uploadBytes} from "firebase/storage";
import {store} from "src/store";
import {v4 as uuidv4} from "uuid";

const getStorageRef = (): StorageReference => {
  const storage = getStorage();
  return ref(storage);
};

const removeTokenFromFileUrl = (url: string) => {
  return url.split("&").slice(0, -1).join("&");
};

export const uploadOrderFileFB = async (file: File): Promise<string> => {
  const storeId = store.getState().storeReducer.store.id;
  const fileExtension = file.name.split(".").slice(-1)[0];
  const fileName = `${uuidv4()}.${fileExtension}`;
  const storageRef = getStorageRef();
  const uploadTask = await uploadBytes(ref(storageRef, `${storeId}/orders/${fileName}`), file);
  return removeTokenFromFileUrl(await getDownloadURL(uploadTask.ref));
};

export const uploadRequestFileFB = async (file: File): Promise<string> => {
  const storeId = store.getState().storeReducer.store.id;
  const fileExtension = file.name.split(".").slice(-1)[0];
  const fileName = `${uuidv4()}.${fileExtension}`;
  const storageRef = getStorageRef();
  const uploadTask = await uploadBytes(ref(storageRef, `${storeId}/requests/${fileName}`), file);
  return removeTokenFromFileUrl(await getDownloadURL(uploadTask.ref));
};

export const uploadAttendanceFileFB = async (file: File): Promise<string> => {
  const storeId = store.getState().storeReducer.store.id;
  const fileExtension = file.name.split(".").slice(-1)[0];
  const fileName = `${uuidv4()}.${fileExtension}`;
  const storageRef = getStorageRef();
  const uploadTask = await uploadBytes(ref(storageRef, `${storeId}/attendances/${fileName}`), file);
  return removeTokenFromFileUrl(await getDownloadURL(uploadTask.ref));
};

export const uploadCustomerFileFB = async (file: File): Promise<string> => {
  const storeId = store.getState().storeReducer.store.id;
  const fileExtension = file.name.split(".").slice(-1)[0];
  const fileName = `${uuidv4()}.${fileExtension}`;
  const storageRef = getStorageRef();
  const uploadTask = await uploadBytes(ref(storageRef, `${storeId}/customers/${fileName}`), file);
  return removeTokenFromFileUrl(await getDownloadURL(uploadTask.ref));
};