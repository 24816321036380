import { FC, useEffect, useRef } from "react";
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCol,
  IonList,
  SelectChangeEventDetail,
} from "@ionic/react";
import { useAppSelector } from "src/hooks/useAppSelector";
import { subscribeToCitiesFB } from "src/firebase/cities";
import { IonSelectCustomEvent } from "@ionic/core";
import { useTranslation } from "react-i18next";
import { subscribeToAreasFB } from "src/firebase/areas";
import DestinationSelector from "./DestinationSelector";
import ErrorLabel from "src/components/ErrorLabel";

interface DestinationProps {
  city: string;
  area: string;
  isError: boolean;
  onChangeCity: (
    e: IonSelectCustomEvent<SelectChangeEventDetail<string>>
  ) => void;
  onChangeArea: (
    e: IonSelectCustomEvent<SelectChangeEventDetail<string>>
  ) => void;
}

const Destination: FC<DestinationProps> = ({
  onChangeCity,
  onChangeArea,
  city,
  area,
  isError,
}) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLIonListElement>();
  const error = isError && !city;

  useEffect(() => {
    if (error && ref) {
      ref.current.scrollIntoView();
    }
  }, [error]);
  const { cities, isLoaded: isCitiesLoaded } = useAppSelector(
    (state) => state.citiesReducer
  );
  const { areas, isLoaded: isAreasLoaded } = useAppSelector(
    (state) => state.areasReducer
  );

  const cityAreas = areas.filter(({ cityId }) => cityId === city);

  useEffect(() => {
    subscribeToCitiesFB();
    subscribeToAreasFB();
  }, []);

  const renderError = (): JSX.Element => {
    if (!error) return;
    return (
      <ErrorLabel className="ion-margin-horizontal">
        {t("DESTINATION_IS_REQUIRED")}
      </ErrorLabel>
    );
  };

  return (
    <IonCol size="12">
      <IonCard className="ion-no-margin">
        <IonCardHeader>
          <IonCardSubtitle className="ion-font-bold">
            {t("DESTINATION")}
          </IonCardSubtitle>
        </IonCardHeader>
        <IonCardContent>
          <IonList lines="none" className="ion-no-padding" ref={ref}>
            <DestinationSelector
              isLoaded={isCitiesLoaded}
              label={t("CITY")}
              destinationId={city}
              onChangeDestination={onChangeCity}
              options={cities}
              hidePrice={!!area}
            />
            <DestinationSelector
              isLoaded={isAreasLoaded}
              label={t("AREA")}
              onChangeDestination={onChangeArea}
              options={cityAreas}
              destinationId={area}
            />
          </IonList>
          {renderError()}
        </IonCardContent>
      </IonCard>
    </IonCol>
  );
};

export default Destination;
