import React, { FC } from "react";
import { View, Text } from "@react-pdf/renderer";
import styles from "./styles";
import { Order } from "src/interfaces/Order";
interface DeliveryinfoProps {
  order: Order;
}

const Deliveryinfo: FC<DeliveryinfoProps> = ({ order }) => {
  function renderDeliveryTable(): JSX.Element | undefined {
    if (!order.deliveryMethod) return;
    return (
      <View style={[styles.table]}>
        <View>
          <View style={styles.tableRow}>
            <View style={styles.tableCellGrow}>
              <Text style={styles.contentText}>Delivery Method</Text>
              <Text style={styles.contentText}>{order.deliveryMethod}</Text>
            </View>
      
            <View style={styles.tableCellMedium}>
              <Text style={[styles.contentText]}>Amount</Text>
              <Text style={[styles.contentText]}>{order.deliveryAmount}</Text>
            </View>
          </View>
        </View>
      </View>
    );
  }
  return <View style={styles.marginTop}>{renderDeliveryTable()}</View>;
};

Deliveryinfo.propTypes = {};
export default Deliveryinfo;
