import { IonList } from "@ionic/react";
import { FC, Fragment, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import AlertDialog from "src/components/AlertDialog";
import { useAppSelector } from "src/hooks/useAppSelector";
import useToast from "src/hooks/useToast";
import { removeProductFromCart } from "src/utils/cart";
import ListHeader from "../ListHeader";
import ProductItem from "./ProductItem";

const Products: FC = () => {
  const { t } = useTranslation();
  const launchToast = useToast();

  const { products } = useAppSelector((state) => state.cartReducer.cart);

  const [productId, setProductId] = useState("");

  const isDialogOpen = productId !== "";

  const handleOpenDialog = useCallback((id: string): void => {
    setProductId(id);
  }, []);

  const handleCloseDialog = (): void => {
    setProductId("");
  };
  
  const handleRemoveProduct = async (): Promise<void> => {
    try {
      const id = productId;
      handleCloseDialog();
      await removeProductFromCart(id);
      launchToast(t("DONE"), "success");
    } catch {
      launchToast(t("FAILED"), "danger");
    }
  };

  const renderProducts = (): JSX.Element[] =>
    products.map((product) => (
      <ProductItem
        key={product.id}
        onRemoveProduct={handleOpenDialog}
        cartProduct={product}
      />
    ));

  if (!products.length) return null;

  return (
    <Fragment>
      <IonList>
        <ListHeader title={t("PRODUCTS")} />
        {renderProducts()}
      </IonList>
      <AlertDialog
        message={t("REMOVE_CART_ITEM_MESSAGE")}
        onClose={handleCloseDialog}
        onConfirm={handleRemoveProduct}
        open={isDialogOpen}
        title={t("WARNING")}
      />
    </Fragment>
  );
};

export default Products;
