import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "src/store";
import { Store } from "src/interfaces/Store";

export interface StoreState {
  store: Store | null;
  isLoaded: boolean;
  iframeId?: number;
  valuIframeId?: number;
}

const initialState: StoreState = {
  store: null,
  isLoaded: false,
};

export const storeSlice = createSlice({
  name: "store",
  initialState,
  reducers: {
    setIFrames: (state, action: PayloadAction<{
      iframeId?: number
      valuIframeId?: number
    }>) => {
      const { iframeId, valuIframeId } = action.payload;
      state.iframeId = iframeId;
      state.valuIframeId = valuIframeId
    },
    setStore: (state, action: PayloadAction<Store>) => {
      state.store = action.payload;
      state.isLoaded = true
    },
  },
});

export const { setIFrames, setStore } = storeSlice.actions;

export const selectStore = (state: RootState) => state.storeReducer.store;
export const selectStoreId = (state: RootState) => state.storeReducer.store?.id!;
export const selectIframeIds = (state: RootState): { card?: number; valu?: number } => {
  return {
    card: state.storeReducer.iframeId,
    valu: state.storeReducer.valuIframeId,
  };
};

export default storeSlice.reducer;
