import { IonSlides, IonSlide } from "@ionic/react";
import { FC, memo } from "react";

export interface CarouselOptions {
  slidesPerView?: number;
  spaceBetween?: number;
  loop?: boolean;
  freeMode?: boolean;
}

interface CarouselProps {
  slides: JSX.Element[];
  pager?: boolean;
  scrollbar?: boolean;
  options?: CarouselOptions;
  className?: string;
  style?: {};
}

const Carousel: FC<CarouselProps> = ({
  pager,
  slides,
  scrollbar,
  options,
  className,
  style,
}) => {
  const renderSlides = (): JSX.Element[] =>
    slides.map((slide, i) => (
      <IonSlide className="carousel-slide" key={i}>
        {slide}
      </IonSlide>
    ));

  return (
    <IonSlides
      key={slides.length}
      className={className}
      pager={pager}
      scrollbar={scrollbar}
      options={options}
      style={style}
    >
      {renderSlides()}
    </IonSlides>
  );
};

export default memo(Carousel);
