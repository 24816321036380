import {SignupInfo} from "src/interfaces/Authentication";
import { CustomerRequirement } from "src/interfaces/CustomerRequirement";
import {RequirementAttachmentFile} from "src/interfaces/Requirement";
import { RequirementType } from "src/enums/RequirementType";
import {validateEmail, validateIdenticalTexts, validatePassword, validateRequiredText} from "src/utils/validation";

const validateRequirements = (
  requirements: CustomerRequirement[],
  attachments: RequirementAttachmentFile[],
): boolean => {
  const isValidRequirements = requirements.every((requirement) => {
    if (requirement.type === RequirementType.file || requirement.type === RequirementType.image) {
      const attachment = attachments.some(({id}) => id === requirement.fieldId);
      return attachment || !requirement.isRequired;
    }
    return requirement.value || !requirement.isRequired;
  });
  return isValidRequirements;
};

export const validateSignupInfo = (
  signupInfo: SignupInfo,
  attachments: RequirementAttachmentFile[],
): boolean => {
  const isValidFirstName = validateRequiredText(signupInfo.firstName);
  const isValidlastName = validateRequiredText(signupInfo.lastName);
  const isValidPhone = validateRequiredText(signupInfo.phone);
  const isValidEmail = validateEmail(signupInfo.email);
  const isValidPassword = validatePassword(signupInfo.password);
  const isValidConfirmPassword = validateIdenticalTexts(signupInfo.password, signupInfo.confirmPassword);
  const isValidRequirements = validateRequirements(signupInfo.requirements, attachments);
  
  return (
    isValidEmail &&
    isValidPassword &&
    isValidPhone &&
    isValidFirstName &&
    isValidlastName &&
    isValidConfirmPassword &&
    isValidRequirements
  );
};
