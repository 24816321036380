import Storage from ".";
import { Settings } from "src/interfaces/Settings";
import getStoreId from "src/utils/getStoreId";

export const storeSettingsLS = async (settings: Settings): Promise<void> => {
    const storeId = await getStoreId();
    await Storage.set({ key: storeId, value: JSON.stringify(settings) });
}

export const restoreSettingsLS = async (): Promise<Settings | null> => {
    try {
        const storeId = await getStoreId();
        const settings: string | null = (await Storage.get({ key: storeId })).value;
        return settings ? (JSON.parse(settings) as Settings) : null;
    } catch {
        return null;
    }
}