import React, { FC } from "react";
import { IonButton, IonIcon, IonImg, IonModal, IonHeader, IonToolbar, IonContent, } from "@ionic/react";
import classes from "./CMSFullscreenImage.module.scss";
import { close } from "ionicons/icons";
interface CMSFullscreenImageProps {
  image: string;
  isOpen: boolean;
  onClose: () => void;
}

const CMSFullscreenImage: FC<CMSFullscreenImageProps> = ({ image, isOpen, onClose }) => {

  return (
    <IonModal isOpen={isOpen} onDidDismiss={onClose}>
      <IonHeader className="ion-no-border" translucent>
        <IonToolbar>
          <IonButton slot="end" size="small" fill="clear" onClick={onClose}>
            <IonIcon slot="icon-only" icon={close} />
          </IonButton>
        </IonToolbar>
      </IonHeader>
      <IonContent>
       <IonImg src={image} className={classes.image}/>
      </IonContent>
    </IonModal>
  );
};

export default CMSFullscreenImage;
