import {FC} from "react";
import {
  IonHeader,
  IonToolbar,
  IonContent,
  IonPage,
  IonButtons,
  IonTitle,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonBackButton,
} from "@ionic/react";
import { useAppSelector } from "src/hooks/useAppSelector";
import {useParams} from "react-router";
import "./NotificationDetails.scss";
import {PageLoading, DraftEditorPreview} from "src/components";
import getPagePath from "src/utils/getStoreIdCustomPath";

type URLParams = {
  id: string;
};

const NotificationDetails: FC = () => {
  const {id} = useParams<URLParams>();
  const notification = useAppSelector((state) =>
    state.notificationsReducer.notifications.find((notification) => notification.id === id),
  );
  const {logo: storeLogo} = useAppSelector((state) => state.storeReducer.store);
  const notificationsLink = getPagePath("notifications");

  const renderContent = (): JSX.Element => {
    if (!notification) return <PageLoading />;
    return (
      <IonCard>
        <img className="notificationDetails__image" src={notification.image || storeLogo} alt={notification.title} />
        <IonCardHeader>
          <IonCardTitle>{notification.title}</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <DraftEditorPreview state={notification.body} />
        </IonCardContent>
      </IonCard>
    );
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton defaultHref={notificationsLink} />
          </IonButtons>
          <IonTitle>{notification?.title}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>{renderContent()}</IonContent>
    </IonPage>
  );
};

export default NotificationDetails;
