import {
  collection,
  CollectionReference,
  doc,
  DocumentData,
  DocumentReference,
  onSnapshot,
  getDoc,
  getDocFromCache,
  FirestoreError,
  getDocs,
} from "firebase/firestore";
import {Product} from "src/interfaces/Product";
import { Variation } from "src/interfaces/Variation";
import API from "src/constants/api";
import {getStoreDocRef} from "./store";

const PRODUCTS_COLLECTION_NAME = "products";
const VARIATIONS_COLLECTION_NAME = "variations";

const getProductsCollection = (): CollectionReference<DocumentData> => {
  return collection(getStoreDocRef(), PRODUCTS_COLLECTION_NAME);
};

const getProductDocRef = (productId: string): DocumentReference<DocumentData> => {
  const collection = getProductsCollection();
  return doc(collection, productId);
};

const getProductsVariationsCollection = (productId: string): CollectionReference<DocumentData> => {
  return collection(getProductDocRef(productId), VARIATIONS_COLLECTION_NAME);
};

const getProductVariationDocRef = (productId: string, variationId: string): DocumentReference<DocumentData> => {
  const collection = getProductsVariationsCollection(productId);
  return doc(collection, variationId);
};

export const getAllProducts = (storeId: string, setProducts: any) => {
  const collection = getProductsCollection();
  const unsubscribe = onSnapshot(collection, (querySnapshot) => {
    if (!querySnapshot.empty) {
      const products: any[] = [];
      querySnapshot.forEach((doc) => {
        products.push({...doc.data(), id: doc.id});
      });
      setProducts(products);
    }
  });
  return unsubscribe;
};

export const getProductVariations = (storeID: string, productId: string, setVariations: any) => {
  const collection = getProductsVariationsCollection(productId);
  const unsubscribe = onSnapshot(collection, (querySnapshot) => {
    if (!querySnapshot.empty) {
      const productVariations: any[] = [];
      querySnapshot.forEach((doc) => {
        productVariations.push({...doc.data(), id: doc.id, parentProductId: productId});
      });
      setVariations(productVariations);
    }
  });
  return unsubscribe;
};

export const getProductByIdFB = async (productId: string, fromCache = true): Promise<Product | undefined> => {
  try {
    const docRef = getProductDocRef(productId);
    const snapshot = fromCache ? await getDocFromCache(docRef) : await getDoc(docRef);
    if (snapshot.exists()) {
      return {id: snapshot.id, ...snapshot.data()} as Product;
    }
  } catch (err) {
    const error = err as FirestoreError;
    if (error.code === "unavailable" && fromCache) {
      return await getProductByIdFB(productId, false);
    }
  }
};

export const getProductVariationByIdFB = async (
  productId: string,
  variationId: string,
  fromCache = true,
): Promise<Variation | undefined> => {
  try {
    const docRef = getProductVariationDocRef(productId, variationId);
    const snapshot = fromCache ? await getDocFromCache(docRef) : await getDoc(docRef);
    if (snapshot.exists()) {
      return {id: snapshot.id, ...snapshot.data()} as Variation;
    }
  } catch (err) {
    const error = err as FirestoreError;
    if (error.code === "unavailable" && fromCache) {
      return await getProductVariationByIdFB(productId, variationId, false);
    }
  }
};

export const getProductVariationsByIdFB = async (productId: string): Promise<Variation[]> => {
  try {
    const collection = getProductsVariationsCollection(productId);
    const snapshot = await getDocs(collection);
    return snapshot.docs.map((doc) => ({id: doc.id, ...doc.data()} as Variation));
  } catch {
    return [];
  }
};

export function getCategoryFilter(categories: string[]): string {
  if (categories.length === 0) return "";
  return `(${categories.join(" OR ")})`;
}

export async function loadProducts(
  q: string,
  start: number,
  storeId: string,
  categoryFilter = "",
): Promise<{products: Product[]; numFound: number}> {
  try {
    let categoryParam = "";
    if (categoryFilter) {
      categoryParam = `&categoryId=${categoryFilter}`;
    }
    const intermediate = await fetch(
      `${API.SEARCH_PRODUCTS}/?q=${q}&start=${start}&storeId=${storeId}${categoryParam}`,
    );
    const {response} = await intermediate.json();
    if (response.numFound === 0 || response.numFound < start)
      return {
        products: [],
        numFound: 0,
      };
    const productIds: string[] = response.docs.map((doc: {_productId: string}) => doc._productId).flat();
    const ids = productIds.filter((productId, index) => productIds.indexOf(productId) === index);

    const products = await Promise.all(ids.flat().map((id) => getProductByIdFB(id)));
    return {
      products: products.filter((p) => p !== undefined) as Product[],
      numFound: response.numFound,
    };
  } catch (error) {
    console.error(error);
    return {
      products: [],
      numFound: 0,
    };
  }
}
