import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {createAttendanceFB, subscribeToAttendancesFB} from "src/firebase/attendances";
import {uploadAttendanceFileFB} from "src/firebase/storage";
import {Attendance} from "src/interfaces/Attendance";
import { AttendanceType } from "src/enums/AttendanceType";
import { GPSLocation } from "src/interfaces/GPSLocation";
import { FormRequestField } from "src/interfaces/FormRequestField";
import {AppThunk} from "src/store";

interface AttendancesState {
  attendances: Attendance[];
  isLoaded: boolean;
}

const initialState: AttendancesState = {
  attendances: [],
  isLoaded: false,
};

export const attendancesSlice = createSlice({
  name: "ATTENDANCE",
  initialState,
  reducers: {
    setAttendances: (state: AttendancesState, action: PayloadAction<Attendance[]>) => {
      state.attendances = action.payload;
      state.isLoaded = true;
    },
  },
});

export const {setAttendances} = attendancesSlice.actions;

export const createAttendance =
  (attendanceId: string, fields: FormRequestField[], location: GPSLocation | null, type: AttendanceType): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const customerId = getState().customerReducer.customer!.id!;
    const attendance: Attendance = {
      attendanceId,
      fields,
      type,
      location,
      customerId,
    };
    await createAttendanceFB(attendance);
  };

export const subscribeToAttendances =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const isLoaded = getState().attendancesReducer.isLoaded;
    if (isLoaded) return;
    subscribeToAttendancesFB();
  };

export const uploadAttendanceFile =
  (file: File): AppThunk =>
  async (): Promise<string> => {
    return await uploadAttendanceFileFB(file);
  };

export default attendancesSlice.reducer;
