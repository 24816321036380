import { FC } from "react";
import { CartCustomVariation } from "src/interfaces/CartCustomVariation";
import { CustomVariation } from "src/interfaces/CustomVariation";
import CustomVariationItem from "./CustomVariationItem";

interface CustomVariationsProps {
  cartProductVariations: CartCustomVariation[];
  customVariations: CustomVariation[];
}

const CustomVariations: FC<CustomVariationsProps> = ({
  cartProductVariations,
  customVariations,
}) => {
  const renderCustomVariations = (): JSX.Element[] => {
    return cartProductVariations.map(({ variationId, id, value }) => {
      const customVariation = customVariations.find(
        ({ id }) => id === variationId
      );
      if (!customVariation) return null;
      return (
        <CustomVariationItem
          key={id}
          variation={customVariation}
          value={value}
        />
      );
    });
  };

  return <div className="ion-margin-bottom-small">{renderCustomVariations()}</div>;
};

export default CustomVariations;
